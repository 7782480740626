import { ActionTypes } from './types';
import { isToday } from 'date-fns';
import cashierBanksHistoricRepository from '../../../repositories/CashierBanksHistoric';

export const handleChange = (value, type) => {
  return {
    type: ActionTypes.handleChange,
    payload: {
      value,
      type,
    },
  };
};

export const cashierBankHistoricValidations = async (companyId) => {
  const cashierBanksHistoric =
    await cashierBanksHistoricRepository.getAllByCompany({
      companyId,
      onlyOpen: true,
      limit: 1,
    });
  const cashierBankOpened =
    cashierBanksHistoric.count > 0 ? cashierBanksHistoric.rows[0] : null;

  if (!cashierBankOpened) {
    return {
      type: ActionTypes.noCashierBankOpened,
      payload: false,
    };
  }

  if (!isToday(new Date(cashierBankOpened.openDate))) {
    return {
      type: ActionTypes.previousDayCashierBankExists,
      payload: false,
      cashierBankOpened,
    };
  }

  return {
    type: ActionTypes.cashierBankHistoricValidationsSuccess,
    payload: true,
    cashierBankOpened,
  };
};

export const handleSubmitCashierBankModal = () => {
  return {
    type: ActionTypes.handleSubmitCashierBankModal,
  };
};

export const handleSubmitPreviousDayCashierBankModal = () => {
  return {
    type: ActionTypes.handleSubmitPreviousDayCashierBankModal,
  };
};
