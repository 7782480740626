import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';

import settingsNFeRepository from '../../../repositories/SettingsNFe';
import { currency, encrypt } from '../../components/ToNormalize/ToNormalize';
import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';
import { useModulesContext } from '../../../contexts/modules';

import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import DisableInvoiceModal from './DisableInvoiceModal';
import NFeActions from './NFeActions';
import NFCeActions from './NFCeActions';
import SATCFeActions from './SATCFeActions';
import UnavailableFeatureModal from '../../components/UnavailableFeatureModal';

import './styles.css';
import FiscalModuleInactiveModal from 'client/components/FiscalModuleInactiveModal';
import SearchInput from 'components/SearchInput';
import { InputContainer } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import { INVOICE_STATUS_OPTIONS } from 'v2/utils/constants';
import { InputLabel } from 'v2/components/Input';
import InvoicesRepository from 'v2/repositories/InvoicesRepository';
import { usePagination } from 'v2/hooks/usePagination';
import { useFilters } from 'v2/hooks/useFilters';

const HomeInvoices = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [showDisableInvoiceModal, setShowDisableInvoiceModal] = useState(false);
  const [blockTax, setBlockTax] = useState(false);
  const [isNFeSet, setIsNFeSet] = useState(false);
  const [isModuleInactiveModalOpen, setIsModuleInactiveModalOpen] =
    useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isInitialFiltersDefined, setIsInitialFiltersDefined] = useState(false);

  const { companyId } = useAuth();

  const formatStatusArray = (statusArray) => {
    if (!statusArray || !statusArray.length) {
      return '';
    }
    return statusArray
      .filter((status) => status.value !== '*')
      .map((status) => status.value)
      .join(',');
  };

  const loadInvoices = async (filters, pagination) => {
    const { initialDate, finalDate } = filters;
    if ((!initialDate && finalDate) || (initialDate && !finalDate)) {
      return;
    }

    setLoading(true);

    try {
      const { data, count, status } = await InvoicesRepository.list(companyId, {
        ...filters,
        ...pagination,
        status: formatStatusArray(filters.status),
      });

      if (status === 204) {
        return toastr.warning(
          'Nenhuma nota fiscal encontrada para os filtros selecionados.'
        );
      }

      setInvoices(data);
      setTotal(count);
    } catch (err) {
      console.error(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar as notas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFiltersChange = useCallback(
    (filters) => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        return;
      }
      loadInvoices(filters, { page, limit });
    },
    [isFirstLoad]
  );

  function handleSetInitialFilters() {
    const params = new URLSearchParams(window.location.search);
    const statusParam = params.get('status');

    const initialDateParam = params.get('initialDate');
    const finalDateParam = params.get('finalDate');

    if (statusParam) {
      const status = statusParam.split(',');
      const statusToSelect = INVOICE_STATUS_OPTIONS.filter((option) =>
        status.includes(option.value)
      );

      setStatus(statusToSelect);
    }

    if (initialDateParam) {
      setInitialDate(initialDateParam);
    }

    if (finalDateParam) {
      setFinalDate(finalDateParam);
    }

    setIsInitialFiltersDefined(true);
  }

  useEffect(() => {
    if (!isInitialFiltersDefined) handleSetInitialFilters();
  }, [isInitialFiltersDefined]);

  const handlePageOrLimitChange = useCallback(
    ({ page, limit }) => {
      if (!invoices?.length) {
        return;
      }
      loadInvoices(getFiltersPayload(), { page, limit });
    },
    [invoices]
  );

  const { page, setPage, totalPages, limit, setLimit, setTotal } =
    usePagination(handlePageOrLimitChange);

  const {
    query,
    setQuery,
    type,
    setType,
    status,
    setStatus,
    dateType,
    setDateType,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    getFiltersPayload,
    addFilter,
  } = useFilters(handleFiltersChange, { dateType: 'createdAt' });

  const [environment, setEnvironment] = useState('');

  useEffect(() => {
    addFilter('environment', environment);
  }, [environment]);

  const { nfeModule, isNFeModuleActive } = useModulesContext();

  useEffect(() => {
    if (!companyId) {
      setNoCompanyModal(true);
    } else {
      checkNFeSettings(companyId);
    }
  }, []);

  const renderBtnStatus = (value) => {
    let color = '';
    switch (value) {
      case 'Aberta':
        color = '#ffcc00';
        break;
      case 'Emitida':
        color = '#5cb85c';
        break;
      case 'Inutilizada':
        color = '#555555';
        break;
      case 'Cancelada':
        color = '#d9534f';
        break;
      case 'Contingência':
        color = '#7a43b6';
        break;
      case 'Aberta c/ Erro':
        color = '#ffcc00';
        break;
      case 'Denegada':
        color = '#000000';
        break;
      default:
        break;
    }

    return (
      <Button
        id="botaoReactTable"
        style={{
          backgroundColor: color,
          height: '25px',
          fontSize: '12px',
        }}
      >
        {value}
      </Button>
    );
  };

  function onSuccessDisableInvoiceModal() {
    setShowDisableInvoiceModal(false);
    loadInvoices(getFiltersPayload(), { page, limit });
  }

  function handleEditInvoice(invoice) {
    if (invoice.status.includes('Inutilizada')) {
      return toastr.warning('Numeração inutilizada não possui nota');
    }

    if (invoice.type === 'NFC-e') {
      handleEditNFCe(invoice);
    } else if (invoice.type === 'NF-e') {
      handleEditNFe(invoice);
    } else {
      handleEditSATCFe(invoice);
    }
  }

  async function handleEditNFCe(NFCe) {
    const hash = await encrypt(String(NFCe.id), '@OS-dig:NfceId');
    history.push(`${constants.ROUTES.EDIT_NFCE}?id=${hash}`);
  }

  async function handleEditNFe(NFe) {
    const hash = await encrypt(String(NFe.id), '@OS-dig:NfeId');
    history.push(`${constants.ROUTES.EDIT_NFE}?id=${hash}`);
  }

  async function handleEditSATCFe(SATCFe) {
    const hash = await encrypt(String(SATCFe.id), '@OS-dig:SATCFeId');
    history.push(`${constants.ROUTES.EDIT_SATCFE}?id=${hash}`);
  }

  async function checkNFeSettings() {
    try {
      const { data } = await settingsNFeRepository.search({
        companyId,
      });

      setIsNFeSet(data.isNFeModuleSet);
    } catch (err) {
      console.error(err);
      setIsNFeSet(false);
    }
  }

  function handleNewInvoiceButton() {
    if (!isNFeModuleActive) {
      return setIsModuleInactiveModalOpen(true);
    }

    if (!isNFeSet)
      return toastr.error(
        'Falha ao gerar NF-e',
        'Realize a configuração da NF-e em "Minha Empresa > Dados Fiscais" para emissão da nota.'
      );

    if (!!companyId) {
      history.push(constants.ROUTES.NFE);
    } else {
      setBlockTax(true);
    }
  }

  const fiscalTagsColors = {
    'NFC-e': '#FFA834',
    'NF-e': '#0844A4',
    SAT: '#5BC0DE',
  };

  async function handleDisableFlow() {
    !!companyId ? setShowDisableInvoiceModal(true) : setBlockTax(true);
  }

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={handleNewInvoiceButton}
                style={{ fontSize: '12px' }}
              >
                + Nova Nota Fiscal
              </button>
            </div>

            <div>
              <SearchInput
                placeholder="Pesquisa por NF-e, NFC-e, SAT, Cliente e Valor"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todos</option>
                <option value="NF-e">NF-e</option>
                <option value="NFC-e">NFC-e</option>
                <option value="SAT">SAT</option>
              </select>
            </div>
            <InputContainer variant="column">
              <InputLabel
                style={{
                  fontSize: '12px',
                  margin: 0,
                  padding: 0,
                  fontWeight: 'bold',
                  position: 'relative',
                  top: '5px',
                }}
              >
                Status:
              </InputLabel>
              <SelectMulti
                width={130}
                height={35}
                options={INVOICE_STATUS_OPTIONS}
                value={status}
                onChange={(e) => setStatus(e)}
                hasAllOption
                selectAllByDefault
                fontSize={'12px'}
                borderColor="#E3E3E3"
              />
            </InputContainer>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '12px' }}>
                <strong>Ambiente: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={environment}
                onChange={(e) => setEnvironment(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="Produção">Produção</option>
                <option value="Homologação">Homologação</option>
              </select>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginBottom: '10px',
              padding: 0,
              gap: '10px',
            }}
          >
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo de Data:</strong>
              </span>
              <select
                className="form-control foco-input"
                type="date"
                name="dateType"
                value={dateType}
                onChange={(e) => setDateType(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="createdAt">Criação</option>
                <option value="issuedAt">Emissão</option>
              </select>
            </div>

            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Inicial:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataInicial"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>

            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Final:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataFinal"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>
            <div>
              <button
                className="btn btn-next"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '35px',
                  marginTop: '15px',
                  fontSize: '12px',
                }}
                onClick={() => handleDisableFlow()}
              >
                Inutilizar Numeração
              </button>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={invoices}
              columns={[
                {
                  Header: 'Criação',
                  accessor: 'createdAt',
                  width: 80,
                  Cell: (props) => (
                    <>
                      {props.value
                        ? new Date(props.value).toLocaleDateString()
                        : ''}
                    </>
                  ),
                },
                {
                  Header: 'Emissão',
                  accessor: 'issuedAt',
                  width: 80,
                  Cell: (props) => (
                    <>
                      {props.value
                        ? new Date(props.value).toLocaleDateString()
                        : ''}
                    </>
                  ),
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 80,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: fiscalTagsColors[props.value],
                        height: '25px',
                        fontSize: '12px',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Nota',
                  accessor: 'code',
                  width: 80,
                  Cell: (props) => (
                    <a onClick={() => handleEditInvoice(props.original)}>
                      <button className="btn-link">{props.value}</button>
                    </a>
                  ),
                },
                {
                  Header: 'Venda',
                  accessor: 'saleCode',
                  width: 80,
                  Cell: (props) => (
                    <span>
                      {props.value
                        ? props.value
                        : props.original.hasManySales === 1
                        ? 'Varias'
                        : ''}
                    </span>
                  ),
                },
                {
                  Header: 'Série',
                  accessor: 'serie',
                  width: 80,
                },
                {
                  Header: 'Cliente',
                  accessor: 'clientName',
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Valor',
                  accessor: 'total',
                  width: 120,
                  Cell: (props) => <>{currency(props.value)}</>,
                },
                {
                  Header: 'Ambiente',
                  accessor: 'environment',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          props.value === 'Homologação' ? '#1c0c4f' : 'b70f0a',
                        height: '25px',
                        fontSize: '12px',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  width: 120,
                  Cell: (props) => renderBtnStatus(props.value),
                },
                {
                  Header: 'Ações',
                  accessor: 'type',
                  width: 150,
                  Cell: (props) =>
                    props.value === 'NF-e' ? (
                      <NFeActions
                        NFe={props.original}
                        onChange={loadInvoices}
                        onEdit={handleEditNFe}
                      />
                    ) : props.value === 'SAT' ? (
                      <SATCFeActions
                        SATCFe={props.original}
                        onChange={loadInvoices}
                        onEdit={handleEditSATCFe}
                      />
                    ) : (
                      <NFCeActions
                        NFCe={props.original}
                        onChange={loadInvoices}
                        onEdit={handleEditNFCe}
                      />
                    ),
                },
              ]}
              manual
              pageSize={limit}
              pages={totalPages}
              onPageSizeChange={(value) => {
                setLimit(value);
              }}
              onPageChange={(page) => {
                setPage(page);
              }}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma nota fiscal encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>

          <DisableInvoiceModal
            show={showDisableInvoiceModal}
            onCancel={() => setShowDisableInvoiceModal(false)}
            onHide={() => setShowDisableInvoiceModal(false)}
            onSuccess={onSuccessDisableInvoiceModal}
          />

          <UnavailableFeatureModal
            show={blockTax}
            onHide={() => setBlockTax(false)}
          />

          <FiscalModuleInactiveModal
            show={isModuleInactiveModalOpen}
            onHide={() => setIsModuleInactiveModalOpen(false)}
            module={nfeModule}
          />
        </div>
      }
    />
  );
};

export default withRouter(HomeInvoices);
