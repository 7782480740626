import useDebounce from 'hooks/useDebounce';
import { useState, useEffect, useCallback } from 'react';

export const useFilters = (onFiltersChange, initialValues = {}) => {
  const [query, setQuery] = useState(initialValues.query || '');
  const [queryDebounced, setQueryDebounced] = useState(query);
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const [type, setType] = useState(initialValues.type || null);
  const [status, setStatus] = useState(initialValues.status || null);
  const [dateType, setDateType] = useState(initialValues.dateType || null);

  const [initialDate, setInitialDate] = useState(
    initialValues.initialDate || null
  );

  const [initialDateDebounced, setInitialDateDebounced] = useState(initialDate);
  const debouncedSaveInitialDate = useDebounce(setInitialDateDebounced, 600);

  const [finalDate, setFinalDate] = useState(initialValues.finalDate || null);
  const [finalDateDebounced, setFinalDateDebounced] = useState(finalDate);
  const debouncedSaveFinalDate = useDebounce(setFinalDateDebounced, 600);

  const [additionalFilters, setAdditionalFilters] = useState({});

  const addFilter = useCallback((key, value) => {
    setAdditionalFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const removeFilter = useCallback((key) => {
    setAdditionalFilters((prev) => {
      const { [key]: removed, ...rest } = prev;
      return rest;
    });
  }, []);

  const resetFilters = () => {
    setQuery('');
    setType(null);
    setStatus(null);
    setDateType(null);
    setInitialDate(null);
    setFinalDate(null);
    setAdditionalFilters({});
  };

  const getFiltersPayload = useCallback(
    () => ({
      query: queryDebounced,
      type,
      status,
      dateType,


      initialDate: initialDateDebounced,
      finalDate: finalDateDebounced,

      ...additionalFilters,
    }),
    [
      queryDebounced,
      type,
      status,
      dateType,

      initialDateDebounced,
      finalDateDebounced,

      additionalFilters,
    ]
  );

  useEffect(() => {
    debouncedSaveQuery(query);
  }, [query]);

  useEffect(() => {
    debouncedSaveInitialDate(initialDate);
  }, [initialDate]);

  useEffect(() => {
    debouncedSaveFinalDate(finalDate);
  }, [finalDate]);

  useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange(getFiltersPayload());
    }
  }, [
    queryDebounced,
    type,
    status,
    dateType,


    initialDateDebounced,
    finalDateDebounced,

    additionalFilters,
  ]);

  return {
    query,
    setQuery,
    type,
    setType,
    status,
    setStatus,
    dateType,
    setDateType,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    queryDebounced,

    initialDateDebounced,
    finalDateDebounced,

    additionalFilters,
    addFilter,
    removeFilter,
    resetFilters,
    getFiltersPayload,
  };
};
