import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

import { getDateOnlyFromDate } from 'utils/dateHelpers';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from 'contexts/auth';

import AlertModal from 'components/AlertModal/AlertModal';
import Button from 'client/components/CustomButton/Button.jsx';
import Card from 'components/Card/Card.jsx';
import inventoryRepository from 'repositories/InventoryMovement';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import constants from '../../../utils/constants';
import SearchInput from 'components/SearchInput';

const HomeStock = ({ history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [movement, setMovement] = useState([]);
  const [modalCancel, setModalCancel] = useState(false);
  const [movementCode, setMovementCode] = useState(false);
  const [idMovement, setIdMovement] = useState('');
  const [loadingCancel, setLoadingCancel] = useState(false);

  const { companyId } = useAuth();
  const { typeFilter, queryFilter, dateFilter, statusFilter } = useFilters();
  const { type, setType, filterByType } = typeFilter;
  const { query, setQuery, filterByQuery } = queryFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (companyId) {
      getInventoryMovement();
    }
  }, [currentPage, pageLimit]);

  const getInventoryMovement = async () => {
    setLoading(true);
    try {
      const response = await inventoryRepository.getAllByCompany({
        companyId,
        limit: pageLimit,
        page: currentPage + 1,
      });
      setMovement(response.data);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      toastr.error(
        'Ocorreu ao carregar as movimentações. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const cancel = async () => {
    setLoadingCancel(true);
    try {
      await inventoryRepository.cancel(idMovement);
      getInventoryMovement();
      toastr.success('Movimentação cancelada com sucesso');
    } catch (err) {
      toastr.error(
        'Ocorreu ao cancelar a movimentação. Por favor, tente novamente'
      );
    } finally {
      setLoadingCancel(false);
      setModalCancel(false);
    }
  };

  function handleFilter(movement) {
    if (!!initialDate && !!finalDate) {
      if (finalDate < initialDate) {
        return toastr.warning('Data final deve ser maior que a data inicial');
      }
    }

    const querySearch = [
      movement['Provider']?.['companyName'],
      movement['code'],
    ];
    return (
      filterByQuery(querySearch) &&
      filterByType('movementType', movement) &&
      filterByDate('registerDate', movement) &&
      filterByStatus('status', movement)
    );
  }

  function showModalCancel(value) {
    setIdMovement(value.id);
    setMovementCode(value.code);
    setModalCancel(true);
  }

  return (
    <Card
      content={
        <div>
          <section
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  !!companyId
                    ? history.push(constants.ROUTES.STOCK)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Nova Movimentação
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Movimentação ou Fornecedor"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="Entrada">Entrada</option>
                <option value="Saída">Saída</option>
              </select>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todos</option>
                <option value="Aberta">Aberta</option>
                <option value="Finalizada">Finalizada</option>
                <option value="Cancelada">Cancelada</option>
              </select>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Inicial: </strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataInicial"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>

            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Final: </strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataFinal"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>
          </section>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={movement.filter(handleFilter)}
              columns={[
                {
                  Header: 'Data',
                  accessor: 'registerDate',
                  id: 'registerDate',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 150,
                  Cell: (props) => (
                    <span>
                      {format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')}
                    </span>
                  ),
                },
                {
                  Header: 'Tipo',
                  accessor: 'movementType',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 90,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === 'Entrada' ? '#428bca' : '#5cb85c',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Movimentação',
                  accessor: 'code',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 120,
                },
                {
                  Header: 'Fornecedor',
                  accessor: 'Provider.companyName',
                  headerClassName: 'text-center',
                  className: 'texto',
                  Cell: (props) =>
                    props.value ? (
                      <span>{props.value}</span>
                    ) : (
                      <span style={{ color: 'red' }}>Não Informado</span>
                    ),
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  headerClassName: 'text-center',
                  className: 'texto',
                  width: 90,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor:
                          props.value === 'Aberta'
                            ? '#ffc40d'
                            : props.value === 'Finalizada'
                            ? '#46a546'
                            : '#bd362f',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  filterable: false,
                  className: 'texto',
                  width: 100,
                  style: {
                    display: 'flex',
                    justifyContent: 'left',
                    marginLeft: '5px',
                  },
                  Cell: (props) => (
                    <>
                      <Link
                        to={{ pathname: `stock#${props.value}` }}
                        title="Editar"
                      >
                        <FontAwesomeIcon
                          cursor="pointer"
                          icon={faEdit}
                          className="btn-acoes"
                        />
                      </Link>
                      {props.original.status !== 'Cancelada' && (
                        <FontAwesomeIcon
                          cursor="pointer"
                          icon={faWindowClose}
                          color="red"
                          className="btn-acoes"
                          onClick={() => {
                            if (props.original.isFromInventoryCount) {
                              const inventoryName =
                                props.original.observation.split(
                                  'Movimentação de inventário - '
                                )[1];

                              return toastr.warning(
                                'OS Digital',
                                `A movimentação não pode ser cancelada pois foi de origem do inventário ${inventoryName}`
                              );
                            }
                            showModalCancel(props.original);
                          }}
                        />
                      )}
                    </>
                  ),
                },
              ]}
              defaultPageSize={10}
              pageSize={pageLimit}
              onPageSizeChange={(value) => {
                setPageLimit(value);
              }}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
              pages={totalPages}
              manual
              loading={loading}
              showPagination={true}
              sortable={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma movimentação foi encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
          <AlertModal
            show={modalCancel}
            animation={false}
            title="Cancelar "
            message={`Deseja cancelar a movimentação? O processo é definitivo e a quantidade de estoque movimentado será devolvida.`}
            onHide={() => (!!loadingCancel ? '' : setModalCancel(false))}
            onCancel={() => setModalCancel(false)}
            onSubmit={() => cancel()}
            loading={loadingCancel}
          />
        </div>
      }
    />
  );
};

export default withRouter(HomeStock);
