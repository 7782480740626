import React from 'react';
import CustomLabel from 'components/CustomLabel';
import { validatePlanWorkmotorEquivalent } from 'utils/signatureHelpers';
import { validatePlanStart } from 'utils/signatureHelpers';
import { validatePlanPrime } from 'utils/signatureHelpers';
import { validatePlanEssencial } from 'utils/signatureHelpers';

function getPlanNameShort(planName) {
  const planLowerCase = planName?.toLowerCase();
  const isFiscal = planName?.toLowerCase().includes('fiscal');

  let planNameShort = '-';

  switch (true) {
    case planLowerCase?.includes('free'):
      planNameShort = `Free`;
      break;
    case planLowerCase?.includes('start'):
      planNameShort = 'Start';
      break;
    case planLowerCase?.includes('essencial'):
      planNameShort = 'Essencial';
      break;
    case planLowerCase?.includes('prime'):
      planNameShort = 'Prime';
      break;
    case planLowerCase?.includes('start'):
      planNameShort = 'Start';
      break;
    case planLowerCase?.includes('workmotor'):
      planNameShort = 'Workmotor';
      break;
    case planLowerCase?.includes('enterprise'):
      planNameShort = 'Enterprise';
      break;
    default:
      planNameShort = '-';
      break;
  }

  return `${planNameShort}${isFiscal ? ' Fiscal' : ''}`;
}

function getPlanLabelColor(planId) {
  switch (true) {
    case validatePlanEssencial(planId):
      return '#428bca';
    case validatePlanPrime(planId):
      return '#5cb85c';
    case validatePlanStart(planId):
      return '#ffa834';
    case validatePlanWorkmotorEquivalent(planId):
      return '#142a3d';
    default:
      return '#c1c1c1';
  }
}

const PlanLabel = ({ planId, planName }) => {
  const planNameShort = getPlanNameShort(planName);
  const planLabelColor = getPlanLabelColor(planId);

  return <CustomLabel text={planNameShort} backgroundColor={planLabelColor} />;
};

export const planHelper = {
  getPlanNameShort,
  getPlanLabelColor,
  PlanLabel,
};
