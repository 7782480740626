import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useAuth } from 'contexts/auth';
import SegmentsRepository from '../../../repositories/Segments';
import Button from '../CustomButton/CustomButton';
import constants from '../../../utils/constants';
import { toastr } from 'react-redux-toastr';

import './styles.css';
import { useSelector } from 'react-redux';
import { encrypt } from '../ToNormalize/ToNormalize';
import { getChecklistHeaderId } from 'v2/repositories/ChecklistSalesRepository';

export const CheckListPrintButton = ({ disabled, currentKilometers }) => {
  const [mySegments, setMySegments] = useState([]);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const { userId, company } = useAuth();
  const [hasMobileChecklistId, setHasMobileChecklistId] = useState(undefined);

  const url = new URL(window.location.href);
  const id = url.hash.substring(1);

  let Kilometers = currentKilometers;

  const { selectedClient, selectedVehicle, saleId, items, prismaBoxId } =
    useSelector((state) => state.saleReducer);

  const loadSegment = async () => {
    try {
      const { data } = await SegmentsRepository.getByUser(userId);
      const filteredSegments = data.filter(
        (segment) =>
          segment.Description !== `Trator` &&
          segment.Description !== `Escavadeira`
      );
      setMySegments(filteredSegments);
    } catch (err) {
      console.error(err);
    }
  };

  const getMobileChecklistId = async () => {
    try {
      const mobileChecklistId = await getChecklistHeaderId(id);
      setHasMobileChecklistId(mobileChecklistId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (userId) loadSegment();
  }, [userId]);

  useEffect(() => {
    if (saleId) getMobileChecklistId(saleId);
  }, [saleId]);

  const handleButtonColor = (description) => {
    const values = {
      Motos: '#428bca',
      Carros: '#5cb85c',
      Pesados: '#f0ad4e',
      Van: '#5bc0de',
      Bicicleta: '#0d47a1',
    };

    return values[description];
  };

  const handleClickOption = useCallback(
    (description, Kilometers) => {
      const clientId = selectedClient.id;
      const vehicleId = selectedVehicle.id;

      let clientIdHash;
      let vehicleIdHash;
      let saleIdHash;
      let prismaBoxIdHash;

      if (clientId) {
        clientIdHash = encrypt(clientId, '@OS-dig:clientId');
      }

      if (vehicleId) {
        vehicleIdHash = encrypt(vehicleId, '@OS-dig:vehicleId');
      }

      if (saleId) {
        saleIdHash = encrypt(saleId, '@OS-dig:saleId');
      }

      if (prismaBoxId) {
        prismaBoxIdHash = encrypt(prismaBoxId, '@OS-dig:prismaBoxId');
      }
      if (prismaBoxId) {
        prismaBoxIdHash = encrypt(prismaBoxId, '@OS-dig:prismaBoxId');
      }

      window.open(
        window.location.origin +
          `/checklist?type=${description}${
            clientIdHash ? `&clientId=${clientIdHash}` : ''
          }${vehicleIdHash ? `&vehicleId=${vehicleIdHash}` : ''}${
            saleIdHash ? `&saleId=${saleIdHash}` : ''
          }${prismaBoxId ? `&prismaBoxId=${prismaBoxIdHash}` : ''}
          ${Kilometers ? `&Kilometers=${Kilometers.replace('.', '')}` : ''}`,
        '_blank'
      );
    },
    [selectedClient, selectedVehicle, prismaBoxId]
  );

  async function findTypeByNumber(type) {
    const segments = {
      1: 'Vazio',
      2: 'Carros',
      3: 'Motos',
      4: 'Pesados',
      5: 'Bicicleta',
    };

    openAnnotationSheet(segments[type]);
  }

  const handlePrint = () => {
    if (!selectedVehicle.id || items.length === 0) {
      toastr.warning(
        'Selecione um veículo e ao menos 1 item para imprimir a ordem de produção'
      );
    } else {
      const link = getSaleHashLink();
      window.open(link, '_blank');
    }
  };

  const getSaleHashLink = () => {
    const hash = `Vend=v723dha5tHSSpS83711${saleId}5422hsGsdAQLk8423oPL31`;
    return `${window.location.origin}/sheets/${hash}?productionOrder=true`;
  };

  function openAnnotationSheet(description) {
    const clientId = selectedClient.id;
    const vehicleId = selectedVehicle.id;

    let clientIdHash;
    let vehicleIdHash;
    let saleIdHash;
    let prismaBoxIdHash;

    if (clientId) {
      clientIdHash = encrypt(clientId, '@OS-dig:clientId');
    }

    if (vehicleId) {
      vehicleIdHash = encrypt(vehicleId, '@OS-dig:vehicleId');
    }

    if (saleId) {
      saleIdHash = encrypt(saleId, '@OS-dig:saleId');
    }

    if (prismaBoxId) {
      prismaBoxIdHash = encrypt(prismaBoxId, '@OS-dig:prismaBoxId');
    }

    window.open(
      window.location.origin +
        `/mechanical-annotation?type=${description}${
          clientIdHash ? `&clientId=${clientIdHash}` : ''
        }${vehicleIdHash ? `&vehicleId=${vehicleIdHash}` : ''}${
          saleIdHash ? `&saleId=${saleIdHash}` : ''
        }${prismaBoxId ? `&prismaBoxId=${prismaBoxIdHash}` : ''}`,
      '_blank'
    );
  }

  const handleShareMobileChecklistApplication = async () => {
    const hash = encrypt(saleId, '@OS-dig:saleId');

    const link = `/print/mobile-checklist/${hash}`;

    window.open(window.location.origin + link, '_blank');
  };

  return (
    <>
      {mySegments.length === 1 &&
      mySegments.filter(
        (seg) => seg.Description === constants.SEGMENTS_DESCRIPTION.Outros
      ).length ? null : (
        <button
          className="checklist-modal-button"
          onClick={() => setIsOptionsOpen(true)}
          disabled={disabled}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '35px',
          }}
        >
          Imprimir
        </button>
      )}

      <Modal show={isOptionsOpen} onHide={() => setIsOptionsOpen(false)}>
        <Modal.Header>
          <Modal.Title>
            <strong>Impressões - Venda</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Selecione a opção de checklist desejada:</span>
          <div className="checklist-modal-options">
            {mySegments
              .filter(
                (seg) =>
                  seg.Description !== constants.SEGMENTS_DESCRIPTION.Outros &&
                  seg.Description !==
                    constants.SEGMENTS_DESCRIPTION.Colheitadeira
              )
              .map(({ id, Description }) => (
                <Button
                  key={id}
                  style={{
                    backgroundColor: handleButtonColor(Description),
                    width: '200px',
                  }}
                  fill
                  onClick={() => handleClickOption(Description, Kilometers)}
                >
                  Checklist{' '}
                  {Description === `Pesados` ? `Caminhões` : Description}
                </Button>
              ))}
            <Button
              disabled={!hasMobileChecklistId}
              style={{
                backgroundColor: '#5b0cde',
                width: '200px',
              }}
              fill
              onClick={() => handleShareMobileChecklistApplication()}
            >
              Checklist (Aplicativo)
            </Button>
            <Button
              style={{
                backgroundColor: '#169db3',
                width: '200px',
              }}
              fill
              onClick={() => findTypeByNumber(company.vehiclesAnnotationType)}
            >
              Anotações do Mecânico
            </Button>
            {!!saleId ? (
              <Button
                style={{
                  backgroundColor: '#169db3',
                  width: '200px',
                }}
                fill
                onClick={() => handlePrint()}
              >
                Ordem de Produção
              </Button>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="danger" onClick={() => setIsOptionsOpen(false)} fill>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
