import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './styles.css';

import InputMask from 'react-input-mask';
import Button from 'client/components/CustomButton/CustomButton';
import { cpfMask, cnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { useEffect } from 'react';

export default function CleanTaxDataCompanyModal({
  onCancel,
  onConfirm,
  cleanTaxData,
}) {
  return (
    <Modal show animation onHide={onCancel}>
      <Modal.Header closeButton={onCancel}>
        <Modal.Title>
          <strong>OS Digital</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>O cliente possui os seguintes registros:</p>
        <div className="content-tax">
          <ul className="tax-list">
            <li>
              <strong>NF-e: </strong> {cleanTaxData.nfeCount || 0} notas
              emitidas
            </li>
            <li>
              <strong>NFC-e/SAT: </strong>
              {cleanTaxData.nfceCount || 0} notas emitidas
            </li>

            <li>
              <strong>NFS-e: </strong> {cleanTaxData.nfseCount || 0} notas
              emitidas
            </li>

            <li>
              <strong>Boletos Bancários:</strong>{' '}
              {cleanTaxData.openBillsCount || 0} boletos em aberto
            </li>
          </ul>
          <br />
        </div>

        <div className="footer-alert">
          <span style={{ fontSize: '14px' }}>
            Ao confirmar, toda a configuração fiscal será limpa e necessário
            reconfigurar, seguindo o fluxo conforme a{' '}
            <a
              href="https://workgroupserv.sharepoint.com/sites/OSDig/SitePages/Troca-de-CNPJ.aspx"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              documentação desse link
            </a>
          </span>
          <br />
          <span style={{ fontSize: '14px' }}>Você deseja continuar?</span>
          <br />

          <span
            style={{ textAlign: 'center', color: '#d43f3a', fontSize: '12px' }}
          >
            Em casos de dúvidas, veja os módulos contratados e os pontos de
            sucesso desse cliente
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button fill bsStyle="danger" onClick={onCancel}>
          Cancelar
        </Button>
        <Button fill bsStyle="info" onClick={onConfirm}>
          Confirmar Atualização
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
