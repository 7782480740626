import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { useAuth } from 'contexts/auth';
import { InputReactSelectPaginate, InputWrapper } from './styles';

import brandsRepository from '../../../../repositories/Brands';
import { toastr } from 'react-redux-toastr';

const defaultPlaceholder = 'Selecione...';

const defaultStyles = (theme, height, width) => {
  return {
    container: (base) => ({
      ...base,
      width: width || 'auto',
      height: height,
      minHeight: height,
    }),
    control: (base) => ({
      ...base,
      boxSizing: 'border-box',
      height: height,
      border: 'none',
      boxShadow: 'none',
      minHeight: '35px',
      background: 'transparent',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }),
    placeholder: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      boxSizing: 'border-box',
      height: height,
      paddingTop: 0,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    input: (provided, state) => ({
      ...provided,
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
      height: height,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      height: height,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? theme.colors.branding1000 : 'inherit',
      '&:hover': {
        backgroundColor: state.isSelected
          ? theme.colors.branding900
          : 'rgb(222, 235, 255)',
      },
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }),
  };
};

export function SelectBrand({
  onChange,
  value,
  styles,
  placeholder = defaultPlaceholder,
  reloadTrigger,
  setReloadTrigger,
  disabled = false,
  height = '35px',
  type = '',
  width,
  wrapperWidth,
  isClearable = false,
  ...rest
}) {
  const { companyId } = useAuth();
  const [currentInputKey, setCurrentInputKey] = useState(1);

  const theme = useTheme();

  useEffect(() => {
    if (reloadTrigger) {
      setReloadTrigger(false);
      setCurrentInputKey(currentInputKey + 1);
    }
  }, [reloadTrigger]);

  async function handleFetch(search, loadedOptions, { page }) {
    try {
      let serializedType;
      if (type && Array.isArray(type) && type.length > 0) {
        serializedType = type.join(',');
        if (type[0] === '') {
          serializedType = '';
        }
      } else {
        serializedType = type || '';
      }
      const res = await brandsRepository.getAllByCompany(companyId, {
        page,
        limit: 10,
        query: search,
        Type: serializedType,
      });

      const options = res.rows.map((option) => ({
        label: option.Description,
        value: {
          id: option.id,
          description: option.Description,
        },
      }));

      return {
        options,
        hasMore: page * 10 < res.count,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as marcas. Por favor, tente novamente'
      );
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  return (
    <InputWrapper width={wrapperWidth}>
      <InputReactSelectPaginate
        isDisabled={disabled}
        key={currentInputKey}
        debounceTimeout={500}
        loadOptions={handleFetch}
        cacheOptions
        defaultValue={null}
        placeholder={placeholder}
        value={value}
        isClearable={isClearable}
        onChange={(e) => onChange(e)}
        additional={{
          page: 1,
        }}
        noOptionsMessage={() => 'Sem opções'}
        loadingMessage={() => 'Carregando...'}
        styles={styles || defaultStyles(theme, height, width)}
        {...rest}
      />
    </InputWrapper>
  );
}
