import React from 'react';
import { Col } from 'react-bootstrap';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const TextsTable = ({
  data,
  loading,
  onNewText,
  onEditText,
  onInclude,
  pages,
  page,
  setPage,
  limit,
  setLimit,
}) => {
  return (
    <>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          marginLeft: '0px',
          paddingLeft: 0,
          marginBottom: 10,
        }}
      >
        <div>
          <button
            id="btn-nova-revisao"
            className="btn btn-sucesso"
            onClick={onNewText}
          >
            + Novo Texto
          </button>
        </div>
      </Col>
      <Col>
        <ReactTable
          style={{
            marginTop: 10,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
          data={data}
          columns={[
            {
              accessor: 'checked',
              width: 50,
              className: 'text-center',
              Cell: (props) => (
                <input
                  style={{
                    position: 'relative',
                    verticalAlign: 'bottom',
                  }}
                  type="checkbox"
                  checked={props.value}
                  onChange={() => onInclude(props.index)}
                  id="checkbox-ncm_table"
                />
              ),
            },
            {
              Header: 'Texto',
              accessor: 'text',
              width: 500,
            },
            {
              Header: 'Ações',
              accessor: 'id',
              headerClassName: 'text-center',
              filterable: false,
              className: 'texto',
              width: 50,
              Cell: (props) => (
                <FontAwesomeIcon
                  title="Editar"
                  cursor="pointer"
                  style={{ height: '1.5em', width: '1.5em', color: 'black' }}
                  icon={faEdit}
                  onClick={() => onEditText(props.value)}
                />
              ),
            },
          ]}
          page={page}
          pages={pages}
          onPageChange={(page) => setPage(page)}
          pageSize={limit}
          onPageSizeChange={(size) => setLimit(size)}
          manual
          defaultPageSize={5}
          loading={loading}
          sortable
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: 'text',
              desc: false,
            },
          ]}
        />
      </Col>
    </>
  );
};

export default TextsTable;
