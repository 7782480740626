import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function InfoCard({
  icon,
  iconStyle,
  containerStyle,
  contentStyle,
  primaryText,
  secondaryText,
  primaryTextStyle,
  secondaryTextStyle,
  emoji,
}) {
  return (
    <div style={{ ...defaultStyles.container, ...containerStyle }}>
      <div style={{ ...defaultStyles.icon, ...iconStyle }}>
        {emoji ? (
          <span style={{ fontSize: '24px' }}>{emoji}</span>
        ) : (
          icon && (
            <FontAwesomeIcon
              style={{ width: '30px', height: '30px', color: '#fff' }}
              icon={icon}
            />
          )
        )}
      </div>
      <div style={{ ...defaultStyles.content, ...contentStyle }}>
        <span style={{ ...primaryTextStyle, fontSize: '18px' }}>
          {primaryText}
        </span>
        <span style={{ ...secondaryTextStyle, fontSize: '11px' }}>
          {secondaryText}
        </span>
      </div>
    </div>
  );
}

const defaultStyles = {
  container: {
    display: 'flex',
    background: 'red',
    width: '205px',
    height: '54px',
    borderRadius: '4px',
    flexDirection: 'row',
    color: '#fff',
  },

  icon: {
    width: '52px',
    background: 'pink',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px 0px 0px 4px',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    marginLeft: '10px',
  },
};
