import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import {
  Body,
  ButttonLink,
  Container,
  Header,
  HeaderItem,
  Item,
  ParcelsGrid,
} from './FormParcels.styles';

import { SelectPaymentCondition } from '../SelectPaymentCondition';

import { InputContainer } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import { InputCurrency } from 'v2/components/Input';
import { InputSelectDefault } from 'v2/components/Input';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

import HomeFormOfPayment from 'client/views/FormOfPayment/FormOfPayment';

import { useAuth } from 'contexts/auth';

import paymentFormsRepository from 'repositories/PaymentForms';
import cashierBanksRepository from 'repositories/CashierBank';
import { SelectFormOfPayment } from '../SelectFormOfPayment';

export function FormParcels({
  total,
  installments,
  setInstallments,
  feeValue,
  setFeeValue,
  hideCashierBankColumn,
  onChangePaymentCondition,
}) {
  const { companyId, company } = useAuth();

  const [paymentCondition, setPaymentCondition] = useState(null);

  const [paymentValues, setPaymentValues] = useState([]);
  const [cashierBanks, setCashierBanks] = useState([]);

  const [paymentFormModalOpen, setPaymentFormModalOpen] = useState(false);

  const handleOpenPaymentFormsModal = () => {
    setPaymentFormModalOpen(true);
  };

  const handleInstallments = (quantity, paymentCondition, feeValue) => {
    const date = moment().format('YYYY-MM-DD');

    const installments = Array(quantity)
      .fill()
      .map((_, i) => {
        const c = {
          date: '',
          value: 0,
          formOfPayment: null,
          received: false,
          cashierBank: cashierBanks.find(
            (cashierBank) =>
              cashierBank.value.id === company.defaultCashierBankId
          ),
          authorizationCode: '',
        };

        const convertDays = parseInt(paymentCondition.daysBetweenInstallment);

        if (convertDays === 30) {
          if (paymentCondition.hasEntry) {
            c.date =
              i === 0
                ? moment().format('YYYY-MM-DD')
                : moment(date).add(i, 'months').format('YYYY-MM-DD');
          } else {
            c.date = moment(date)
              .add(i + 1, 'months')
              .format('YYYY-MM-DD');
          }
        } else {
          const hasEntry = paymentCondition.hasEntry;
          const daysBetweenInstallment = (i + (hasEntry ? 0 : 1)) * convertDays;
          c.date = moment(date)
            .add(daysBetweenInstallment, 'd')
            .format('YYYY-MM-DD');
        }

        c.value = Number(((total + feeValue) / quantity).toFixed(2));
        c.formOfPayment = { id: '' };

        return c;
      });

    if (paymentCondition.Description === 'À vista') {
      installments[0].date = moment().format('YYYY-MM-DD');
    }

    if (installments.length > 0) {
      const rest =
        total + feeValue - installments[0].value * installments.length;
      installments[installments.length - 1].value += rest;
    }

    const totalReceivedCaclInHandleInstallments = installments.reduce(
      (total, installment) => {
        return total + feeValue + Number(installment.value.toFixed(2));
      },
      0
    );

    setInstallments(installments);
  };

  const handleChangePaymentCondition = (newCondition) => {
    setPaymentCondition(newCondition);

    if (onChangePaymentCondition) onChangePaymentCondition(newCondition.value);

    const { value } = newCondition;

    let feeValue = 0;

    if (value.isFeeActive) {
      feeValue = +((total * value.feePercent) / 100).toFixed(2);
      setFeeValue(feeValue);
    } else {
      setFeeValue(0);
    }

    let installments = 0;

    if (value.Condition === 'À vista' || value.Condition === '0') {
      installments = 1;
    } else {
      if (value.hasEntry) {
        installments = parseInt(value.Condition.split('x')[0]) + 1;
      } else {
        installments = parseInt(value.Condition.split('x')[0]);
      }
    }

    handleInstallments(installments, value, feeValue);
  };

  const handleInstallmentChange = (field, value, index) => {
    const updatedInstallments = [...installments];
    updatedInstallments[index][field] = value;

    if (field === 'value') {
      if (value === 0) {
        toastr.warning(
          currentBrandingName,
          'O valor da parcela não pode ser menor que R$ 0,01'
        );
      }

      const updatedValue = value === 0 ? 0.01 : value;
      updatedInstallments[index][field] = updatedValue;

      const valuesPrevious = installments
        .slice(0, index)
        .reduce((acc, curr) => acc + curr.value, 0);

      const remainingCalculation = total + feeValue - value - valuesPrevious;
      const restValues =
        remainingCalculation / (installments.length - index - 1);
      const convertedRestValues = parseFloat(restValues).toFixed(2);
      const realProof = convertedRestValues * (installments.length - index - 1);
      const remainder = remainingCalculation - realProof;

      let totalReceivedCalc = 0;

      for (let i = index + 1; i < installments.length; i++) {
        if (i === index) updatedInstallments[i].value = updatedValue;

        const divided = parseFloat(convertedRestValues);
        const remaining = parseFloat(remainder);
        if (index < i) {
          updatedInstallments[i].value = divided;
          if (installments.length - 1 === i) {
            updatedInstallments[i].value += remaining;
          }
        }
        totalReceivedCalc += updatedInstallments[i].value;
      }

      if (totalReceivedCalc > total + feeValue) {
        const difference = totalReceivedCalc - total + feeValue;
        updatedInstallments[installments.length - 1].value -= difference;
        toastr.warning(
          currentBrandingName,
          'A soma dos valores das parcelas é maior que o total. Os valores foram ajustados.'
        );
      }
    }

    setInstallments(updatedInstallments);
  };

  const getInstallmentLabel = (selectedCondOfPayment, index, installments) => {
    if (
      selectedCondOfPayment.value.Condition === 'À vista' ||
      selectedCondOfPayment.value.Condition === '0'
    ) {
      return 'À vista';
    }

    if (selectedCondOfPayment.value.hasEntry && index === 0) {
      return 'Entrada';
    }

    if (!selectedCondOfPayment.value.hasEntry) {
      return `${index + 1}/${installments.length}`;
    }

    return `${index}/${installments.length - 1}`;
  };

  async function loadCashierBanks() {
    try {
      const cashierBanks =
        await cashierBanksRepository.getCashierBanksByCompany(companyId);

      const serializedCashierBanks = cashierBanks
        .filter((cashierBank) => cashierBank.isActive)
        .map((cashierBank) => ({
          value: cashierBank,
          label: cashierBank.description,
        }));

      setCashierBanks(serializedCashierBanks);
    } catch (error) {
      toastr.error(
        currentBrandingName,
        'Ocorreu um erro ao carregar os caixas/bancos. Por favor, tente novamente'
      );
    }
  }

  useEffect(() => {
    loadCashierBanks();
  }, []);

  return (
    <div className="w-100">
      <div className="mt-2">
        <SelectPaymentCondition
          value={paymentCondition}
          onChange={handleChangePaymentCondition}
        />
      </div>

      <div className="mt-2 w-100">
        <Container>
          <Header>
            <ParcelsGrid>
              <HeaderItem className="flex center">Parcela</HeaderItem>
              <HeaderItem>Vencimento</HeaderItem>
              <HeaderItem>Valor</HeaderItem>
              <HeaderItem className="flex center between">
                Forma de pagamento
                <ButttonLink onClick={handleOpenPaymentFormsModal}>
                  Visualizar Formas
                </ButttonLink>
              </HeaderItem>
              <HeaderItem className="flex center">Valor Recebido?</HeaderItem>
              {!hideCashierBankColumn && <HeaderItem>Caixa/Banco</HeaderItem>}
            </ParcelsGrid>
          </Header>
          <Body>
            {paymentCondition !== null &&
              installments.length &&
              installments.map((installment, index) => {
                return (
                  <ParcelsGrid>
                    <Item className="flex center">
                      {getInstallmentLabel(
                        paymentCondition,
                        index,
                        installments
                      )}
                    </Item>
                    <Item>
                      <InputText
                        type="date"
                        height="40"
                        value={installment.date}
                        onChange={(e) =>
                          handleInstallmentChange('date', e.target.value, index)
                        }
                      />
                    </Item>
                    <Item>
                      <InputCurrency
                        height="40"
                        value={installment.value}
                        onChange={(value) =>
                          handleInstallmentChange('value', value, index)
                        }
                      />
                    </Item>
                    <Item>
                      <SelectFormOfPayment
                        key={index}
                        isActive={true}
                        value={paymentValues[index] || ''}
                        onChange={(value) => {
                          setPaymentValues({
                            ...paymentValues,
                            [index]: value,
                          });
                          handleInstallmentChange(
                            'formOfPayment',
                            value,
                            index
                          );
                        }}
                        width="100%"
                      />
                    </Item>
                    <Item className="flex center">
                      <input
                        type="checkbox"
                        checked={installment.received}
                        onChange={(e) =>
                          handleInstallmentChange(
                            'received',
                            e.target.checked,
                            index
                          )
                        }
                      />
                    </Item>
                    {!hideCashierBankColumn && (
                      <Item>
                        <InputSelectDefault
                          options={cashierBanks}
                          width="100%"
                          height="35px"
                          value={installment.cashierBank}
                          onChange={(value) =>
                            handleInstallmentChange('cashierBank', value, index)
                          }
                        />
                      </Item>
                    )}
                  </ParcelsGrid>
                );
              })}
          </Body>
        </Container>
      </div>

      <Modal
        dialogClassName="modal-formProduto"
        show={paymentFormModalOpen}
        onHide={() => setPaymentFormModalOpen(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Condições de Pagameno</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-formProduto-body">
          <HomeFormOfPayment />
        </Modal.Body>
      </Modal>
    </div>
  );
}
