import React from 'react';
import { StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { styleChecklist } from './MobileChecklist.styles';

const styles = StyleSheet.create({
  radioButton: {
    width: 12,
    height: 12,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
  },
  radioButtonSelected: {
    width: 5,
    height: 5,
    borderRadius: 2,
    backgroundColor: '#000',
  },
  label: {
    marginLeft: 0,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    width: 200,
    height: 100,
  },
});

const Base64Image = ({ src }) => <Image style={styles.image} src={src} />;
const RadioButton = ({ selected }) => (
  <View style={styles.radioButton}>
    {selected && <View style={styles.radioButtonSelected} />}
  </View>
);

export const ConclusionChecklist = ({ conclusion }) => {
  if (!conclusion) return <View />;
  const {
    isFollowUp,
    followUpResponsible,
    allCheckItems,
    checkItems,
    signature,
    observations,
  } = conclusion;
  return (
    <View>
      <View style={styleChecklist.line}>
        <View style={[styleChecklist.flexRow, styleChecklist.center]}>
          <Text style={styles.description}>
            Houve acompanhamento do cliente durante a inspeção?
          </Text>
          <View style={styles.container}>
            <RadioButton selected={isFollowUp} />
            <Text style={styles.label}>Sim</Text>
            <RadioButton selected={!isFollowUp} />
            <Text style={styles.label}>Não</Text>
            {isFollowUp ? (
              <Text style={{ ...styles.label, marginLeft: '5px' }}>
                ({followUpResponsible})
              </Text>
            ) : null}
          </View>
        </View>
        <View style={[styleChecklist.flexRow, styleChecklist.center]}>
          <Text style={styles.description}>
            Todos os pertences foram retirados pelo cliente?
          </Text>
          <View style={[styles.container]}>
            <RadioButton selected={allCheckItems} />
            <Text style={styles.label}>Sim</Text>
            <RadioButton selected={!allCheckItems} />
            <Text style={styles.label}>Não</Text>
            {!allCheckItems && checkItems !== null ? (
              <Text style={{ ...styles.label, marginLeft: '5px' }}>
                {checkItems}
              </Text>
            ) : null}
          </View>
        </View>
      </View>
      <View>
        <Text style={styleChecklist.bold}>Observações Gerais:</Text>
        <Text>{observations}</Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Base64Image src={signature} />
        <View
          style={[styleChecklist.line, { width: '50%', textAlign: 'center' }]}
        />

        <Text style={styleChecklist.bold}> Nome do Cliente</Text>
      </View>
    </View>
  );
};
