import api from 'v2/services/api';

const BASE_URL = 'invoice';

const list = async (companyId, params) => {
  try {
    const response = await api.get(`${BASE_URL}/${companyId}`, {
      params,
    });

    const { data, status } = response;

    return { ...data, status };
  } catch (err) {
    throw err;
  }
};

const InvoicesRepository = {
  list,
};

export default InvoicesRepository;
