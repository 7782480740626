import React from 'react';
import { InputTextStyled, InputTextWrapper } from './styles';

export const InputText = React.forwardRef(
  (
    {
      width,
      height = 40,
      fontWeight = 'bold',
      isLoading = false,
      isDisabled,
      ...props
    },
    ref
  ) => {
    return (
      <InputTextWrapper width={width} height={height}>
        <InputTextStyled
          {...props}
          ref={ref}
          isLoading={isLoading}
          disabled={isLoading || isDisabled}
          fontWeight={fontWeight}
        />
      </InputTextWrapper>
    );
  }
);

InputText.displayName = 'InputText';
