import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { change, initialize } from 'redux-form';
import { format, addHours } from 'date-fns';

import { Modal } from 'react-bootstrap';
import Button from 'client/components/CustomButton/CustomButton.jsx';

import ErrorsModal from './ErrorsModal';
import InvoiceProcessLoader from '../../../components/InvoiceProcessLoader';
import NFSeForm from './NFSeForm';
import NFSesRepository from '../../../../repositories/NFSes';
import settingsNFSeRepository from '../../../../repositories/SettingsNFSe';
import {
  onlyNumbers,
  cnpjMask,
  cpfMask,
} from '../../../../client/components/ToNormalize/ToNormalize';

import { INVOICE_STATUS_DESCRIPTION } from '../../../../utils/constants';
import { useAuth } from '../../../../contexts/auth';
import { indexCustomersWithVehicles } from 'v2/repositories/CustomerRepository';
import { cpfOrCnpjMask } from 'client/components/TitleQuickDischargeModal/ToNormalize/ToNormalize';
import { currentBrandingName } from 'v2/helpers/brandingHelpers';

const BundleNFSe = ({ setInvoiceCode, NFSeId, onSubmit, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [isInvoiceLoaderOpen, setIsInvoiceLoaderOpen] = useState(false);
  const [isErrorsModalOpen, setIsErrorsModalOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  const [isNFSeIssueErrorModalOpen, setIsNFSeIssueErrorModalOpen] =
    useState(false);

  const [nfseErrorDescription, setNfseErrorDescription] = useState('');

  const { companyId } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    loadNFSeSettings();
  }, [NFSeId]);

  async function loadNFSeSettings() {
    setLoading(true);

    try {
      const settingsNFSe = await settingsNFSeRepository.search({
        companyId: companyId,
      });

      dispatch([
        change('NFSe', 'serie', settingsNFSe.serie),
        change(
          'NFSe',
          'CNAE',
          settingsNFSe.NFSPatternId === 138 ? null : settingsNFSe.CNAE
        ),
        change('NFSe', 'ISS', settingsNFSe.ISS),
        change('NFSe', 'specialTaxRegime', settingsNFSe.specialTaxRegimeCode),
        change('NFSe', 'environment', settingsNFSe.environment),
        change('NFSe', 'NFSPatternId', settingsNFSe.NFSPatternId),
        change('NFSe', 'operationType', settingsNFSe.operationType),
        change('NFSe', 'taxType', settingsNFSe.taxType),
        change('NFSe', 'settingsNFSeId', settingsNFSe.id),
      ]);

      if (!!NFSeId) {
        loadNFSe();
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os dados fiscais da empresa. Por favor, tente novamente'
      );
    }
  }

  async function loadNFSe() {
    try {
      const NFSe = await NFSesRepository.show(NFSeId);

      const {
        CNAE,
        ISS,
        ISSValue,
        NFSeItems,
        TextForInvoices,
        additionalInformation,
        code,
        customerCpfCnpj,
        customerIE,
        customerName,
        customerId,
        date,
        discountValue,
        environment,
        issuedAt,
        saleCode,
        saleId,
        serie,
        servicesTotal,
        specialTaxRegime,
        status,
        subTotal,
        subs,
        total,
        operationType,
        taxType,
      } = NFSe;

      const textsForInvoice = TextForInvoices.map((text) => text.id);

      const customersList = await indexCustomersWithVehicles({
        company_id: companyId,
        page: 1,
        limit: 10,
        query: customerName,
        show_label: false,
        show_vehicles: false,
      });

      const currentCustomer = customersList.rows.find(
        (item) => item.customer_id === customerId
      );

      const nfseData = {
        NFSeId,
        CNAE,
        ISS,
        ISSValue,
        NFSeItems,
        textsForInvoice,
        additionalInformation,
        code,
        customerCpfCnpj:
          customerCpfCnpj.length === 11
            ? cpfMask(customerCpfCnpj)
            : cnpjMask(customerCpfCnpj),
        customerIE,
        customerId,
        customerName,
        date: format(addHours(new Date(date), 3), 'yyyy-MM-dd'),
        discountValue,
        environment,
        issuedAt: !issuedAt
          ? ''
          : format(new Date(issuedAt), 'yyyy-MM-dd') +
            'T' +
            format(new Date(issuedAt), 'hh:mm'),
        saleCode,
        saleId,
        serie,
        servicesTotal,
        specialTaxRegime,
        status: `NFS-e ${status}`,
        subTotal,
        subs,
        total,
        isInvoiceIssued: status === INVOICE_STATUS_DESCRIPTION.ISSUED,
        isInvoiceOpenedWithErrors:
          status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS,
        isInvoiceIssuedInContingency:
          status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY,
        isInvoiceDenied: status === INVOICE_STATUS_DESCRIPTION.DENIED,
        isInvoiceCanceled: status === INVOICE_STATUS_DESCRIPTION.CANCELED,
        isInvoiceProcessing: status === INVOICE_STATUS_DESCRIPTION.PROCESSING,
        operationType,
        taxType,
        selectedCustomer: currentCustomer
          ? {
              ...currentCustomer,
              label: `${currentCustomer.customer_name} ${
                currentCustomer.customer_cpfcnpj &&
                `- ${cpfOrCnpjMask(currentCustomer.customer_cpfcnpj)}`
              }`,
            }
          : {},
      };

      dispatch(initialize('NFSe', nfseData));
      setInvoiceCode(code);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar a NFS-e. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(values) {
    const { customerId, NFSeItems, date, NFSeId, toEmit, customerCpfCnpj } =
      values;

    if (!customerId) {
      return toastr.warning('Insira um cliente para salvar a nota.');
    }

    if (!customerCpfCnpj) {
      return toastr.warning(
        'Cliente sem CPF/CNPJ',
        'Informe um CPF/CNPJ válido no cadastro do cliente para salvar a nota'
      );
    }

    if (!NFSeItems.length) {
      return toastr.warning('Insira um serviço para salvar a nota.');
    }
    if (!date) {
      return toastr.warning('Insira uma data de criação para salvar a nota.');
    }

    if (toEmit) {
      setIsInvoiceLoaderOpen(true);
    }
    setLoading(true);

    if (!NFSeId) {
      create(values);
    } else {
      update(values);
    }
  }

  async function create(values) {
    const {
      CNAE,
      ISS,
      ISSValue,
      NFSeItems,
      toEmit,
      additionalInformation,
      customerCpfCnpj,
      customerIE,
      customerId,
      date,
      discountValue,
      serie,
      servicesTotal,
      subTotal,
      specialTaxRegime,
      customerName,
      subs,
      textsForInvoice,
      issuedAt,
      environment,
      operationType,
      taxType,
    } = values;

    try {
      const NFSe = await NFSesRepository.create({
        NFSe: {
          issuedAt,
          environment,
          serie,
          subs,
          CNAE,
          date,
          customerCpfCnpj: onlyNumbers(customerCpfCnpj),
          customerIE,
          customerName,
          servicesTotal,
          subTotal,
          discountValue,
          total: servicesTotal,
          ISS,
          ISSValue,
          additionalInformation,
          specialTaxRegime,
          companyId,
          customerId,
          operationType,
          taxType,
        },
        items: NFSeItems,
        textsForInvoice: textsForInvoice,
      });

      if (!toEmit) {
        return onSubmit(NFSe);
      }

      setInvoiceCode(NFSe.code);
      dispatch([
        change('NFSe', 'NFSeId', NFSe.id),
        change('NFSe', 'code', NFSe.code),
      ]);
      handleEmit(NFSe.id);
    } catch (err) {
      console.error(err);
      setLoading(false);
      if (toEmit) {
        dispatch(change('NFSe', 'toEmit', false));
        setIsInvoiceLoaderOpen(false);
      }

      toastr.warning(
        'Ocorreu um erro ao salvar a NFS-e. Por favor, tente novamente'
      );
    }
  }

  async function update(values) {
    const {
      CNAE,
      NFSeId,
      ISS,
      ISSValue,
      NFSeItems,
      toEmit,
      additionalInformation,
      customerCpfCnpj,
      customerIE,
      customerId,
      date,
      discountValue,
      serie,
      servicesTotal,
      subTotal,
      specialTaxRegime,
      customerName,
      subs,
      textsForInvoice,
      issuedAt,
      operationType,
      taxType,
    } = values;

    try {
      await NFSesRepository.update(NFSeId, {
        NFSe: {
          issuedAt,
          serie,
          subs,
          CNAE,
          date,
          customerCpfCnpj: onlyNumbers(customerCpfCnpj),
          customerIE,
          customerName,
          servicesTotal,
          subTotal,
          discountValue,
          total: servicesTotal,
          ISS,
          ISSValue,
          additionalInformation,
          specialTaxRegime,
          customerId,
          operationType,
          taxType,
        },
        items: NFSeItems,
        textsForInvoice: textsForInvoice,
      });

      if (!toEmit) {
        return onSubmit(values);
      }
      handleEmit(NFSeId);
    } catch (err) {
      console.error(err);
      setLoading(false);
      if (toEmit) {
        dispatch(change('NFSe', 'toEmit', false));
        setIsInvoiceLoaderOpen(false);
      }
      toastr.warning(
        'Ocorreu um erro ao atualizar a NFS-e. Por favor, tente novamente'
      );
    }
  }

  async function handleEmit(NFSeId) {
    dispatch(change('NFSe', 'toEmit', false));

    try {
      const result = await NFSesRepository.emit(NFSeId);
      const { status, situationCode, situationDescription } = result;

      dispatch([
        change(
          'NFSe',
          'status',
          `NFS-e ${getNFSeStatusLabel(situationCode, situationDescription)}`
        ),
        change(
          'NFSe',
          'isInvoiceIssued',
          status === INVOICE_STATUS_DESCRIPTION.ISSUED
        ),
        change(
          'NFSe',
          'isInvoiceOpenedWithErrors',
          status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS
        ),
        change(
          'NFSe',
          'isInvoiceIssuedInContingency',
          status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY
        ),
        change(
          'NFSe',
          'isInvoiceDenied',
          status === INVOICE_STATUS_DESCRIPTION.DENIED
        ),
        change(
          'NFSe',
          'isInvoiceCanceled',
          status === INVOICE_STATUS_DESCRIPTION.CANCELED
        ),
        change(
          'NFSe',
          'isInvoiceProcessing',
          status === INVOICE_STATUS_DESCRIPTION.PROCESSING
        ),
      ]);

      if (status === 'Aberta c/ Erro') {
        setNfseErrorDescription(situationDescription);
        setIsNFSeIssueErrorModalOpen(true);
      }

      setIsInvoiceLoaderOpen(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setIsInvoiceLoaderOpen(false);
      if (
        !err.response?.data?.validated &&
        err.response?.data?.errors?.length
      ) {
        handleOpenErrorsModal(err.response.data.errors);
      } else {
        const details = err.response.data?.details;
        if (details && Object.values(details).length > 0) {
          const detailsError = Object.values(details)[0][0];
          toastr.warning(err.response.data.message, detailsError);
        } else {
          console.log(err.response.data.message);
          toastr.warning(
            'Ocorreu uma falha ao enviar a nota',
            err.response.data.message
          );
        }
      }
    }
  }

  function handleOpenErrorsModal(errors) {
    const serializedErrors = errors.map((error) => ({
      ...error,
      isResolved: false,
    }));
    setErrors(serializedErrors);
    setIsErrorsModalOpen(true);
  }

  function handleRetryEmit() {
    setIsErrorsModalOpen(false);
    setIsInvoiceLoaderOpen(true);
    handleEmit(NFSeId);
  }

  function getNFSeStatusLabel(code, description) {
    switch (code) {
      case 101:
        toastr.danger('NFS-e Cancelada');
        return 'Cancelada';
      case 100:
        toastr.success('NFS-e Emitida');
        return 'Emitida';
      case 104:
        toastr.info('NFS-e Processando');
        return 'Processando';
      case 105:
        toastr.info('NFS-e Processando');
        return 'Processando';
      case 111:
        toastr.info('NFS-e Processando');
        return 'Processando';
      default:
        toastr.warning('NFS-e Aberta c/ Erro');
        return 'Aberta c/ Erro';
    }
  }

  async function handleConsultInvoice(NFSeId) {
    setIsInvoiceLoaderOpen(true);

    try {
      const response = await NFSesRepository.consult(NFSeId);
      const { status, situationCode } = response;

      dispatch([
        change('NFSe', 'status', `NFS-e ${getNFSeStatusLabel(situationCode)}`),
        change(
          'NFSe',
          'isInvoiceIssued',
          status === INVOICE_STATUS_DESCRIPTION.ISSUED
        ),
        change(
          'NFSe',
          'isInvoiceOpenedWithErrors',
          status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS
        ),
        change(
          'NFSe',
          'isInvoiceIssuedInContingency',
          status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY
        ),
        change(
          'NFSe',
          'isInvoiceDenied',
          status === INVOICE_STATUS_DESCRIPTION.DENIED
        ),
        change(
          'NFSe',
          'isInvoiceCanceled',
          status === INVOICE_STATUS_DESCRIPTION.CANCELED
        ),
        change(
          'NFSe',
          'isInvoiceProcessing',
          status === INVOICE_STATUS_DESCRIPTION.PROCESSING
        ),
      ]);

      setIsInvoiceLoaderOpen(false);
    } catch (err) {
      console.error(err);
      setIsInvoiceLoaderOpen(false);

      const message =
        err.response?.data?.message ||
        'Ocorreu um erro ao consultar a NFS-e. Por favor, tente novamente';
      toastr.warning(currentBrandingName, message);
    }
  }

  const initialValues = {
    searchCustomer: '',
    date: format(new Date(), 'yyyy-MM-dd'),

    NFSeItems: [],

    subTotal: 0,
    servicesTotal: 0,
    discountValue: 0,
    ISS: 0,

    specialTaxRegime: '',
    textsForInvoice: [],
  };

  return (
    <div>
      <NFSeForm
        onSubmit={handleSubmit}
        NFSeId={NFSeId}
        loading={loading}
        setLoading={setLoading}
        initialValues={initialValues}
        onCancel={onCancel}
        onConsult={handleConsultInvoice}
      />

      {isErrorsModalOpen && (
        <ErrorsModal
          setErrors={setErrors}
          errors={errors}
          onCancel={() => setIsErrorsModalOpen(false)}
          onRetry={handleRetryEmit}
        />
      )}

      {isNFSeIssueErrorModalOpen && (
        <Modal
          show
          onHide={() => {
            setNfseErrorDescription('');
            setIsNFSeIssueErrorModalOpen(false);
          }}
          dialogClassName="modal-60w"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{'Erro na Emissão'}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre
              style={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-line',
              }}
            >
              {`${nfseErrorDescription}`}
            </pre>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                bsStyle="danger"
                name="Fechar"
                onClick={(e) => {
                  setNfseErrorDescription('');
                  setIsNFSeIssueErrorModalOpen(false);
                }}
                fill
              >
                Fechar
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {isInvoiceLoaderOpen && <InvoiceProcessLoader />}
    </div>
  );
};

export default BundleNFSe;
