import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MobileChecklist } from './MobileChecklist';
import { PRINTING_TYPES } from 'v2/utils/constants';
import { decrypt } from 'client/components/ToNormalize/ToNormalize';

export function Prints() {
  const [decryptedParams, setDecryptedParams] = useState(null);
  const { type, params } = useParams();

  async function handleDecryptParams() {
    const decodedParams = decodeURIComponent(params);
    const decrypted = await decrypt(decodedParams, '@OS-dig:saleId');
    setDecryptedParams(decrypted);
  }

  useEffect(() => {
    handleDecryptParams();
  }, [params]);

  function renderPrint() {
    const prints = {
      [PRINTING_TYPES.MOBILE_CHECKLIST]: (
        <MobileChecklist params={decryptedParams} />
      ),
      // ... outros impressos
    };

    return prints[type] || <div>Tipo de impressão não encontrado</div>;
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      {renderPrint(decryptedParams)}
    </div>
  );
}
