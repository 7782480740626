import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const FuelLevels = {
  1: { value: 0, label: 'Vazio' },
  2: { value: 25, label: '1/4' },
  3: { value: 50, label: '1/2' },
  4: { value: 75, label: '3/4' },
  5: { value: 100, label: 'Cheio' },
};

export const FuelGaugeChecklist = ({ level = 1 }) => {
  const fuelInfo = FuelLevels[level];
  const width = `${fuelInfo.value}%`;

  return (
    <View style={fuelStyles.container}>
      <View style={fuelStyles.sliderContainer}>
        <View style={fuelStyles.sliderBar}>
          <View style={[fuelStyles.filledBar, { width }]} />
        </View>
        <View style={[fuelStyles.circle, { left: `${fuelInfo.value}%` }]} />
      </View>
      <View style={fuelStyles.labelsContainer}>
        {Object.values(FuelLevels).map((level) => (
          <Text key={level.value} style={fuelStyles.label}>
            {level.label}
          </Text>
        ))}
      </View>
    </View>
  );
};

const fuelStyles = StyleSheet.create({
  container: {
    width: '60%',
  },
  sliderContainer: {
    position: 'relative',
    height: 20,
    justifyContent: 'center',
  },
  sliderBar: {
    height: 5,
    backgroundColor: '#e0e0e0',
    borderRadius: 2,
  },
  filledBar: {
    position: 'absolute',
    height: '100%',
    backgroundColor: '#00FF00',
    borderRadius: 2,
  },
  circle: {
    position: 'absolute',
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: '#FFF',
    border: '1px solid gray',
    top: 4,
  },
  labelsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 9,
    textAlign: 'center',
  },
});
