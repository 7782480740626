import config from '../config';
import api from '../services/api';

const getAllByCompany = async (params) => {
  try {

    const response = await api.get(config.endpoint + `review`, { params });

    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getAllByCompany,
};
