import CustomButton from 'client/components/CustomButton/CustomButton';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import {
  milhar,
  onlyNumbers,
  onlyNumbersLetters,
} from 'client/components/ToNormalize/ToNormalize';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { addHours, addYears, format, getYear } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import attendanceTypesRepository from 'repositories/AttendanceType';
import employeesRepository from 'repositories/Employees';
import TradeRepresentativeRepository from 'repositories/TradeRepresentative';
import SelectCustomers from 'v2/client/components/SelectCustomers';
import { listAllSaleSubtypeActive } from 'v2/repositories/SalesSubtypeRepository';
import AlertModal from '../../../../../components/AlertModal/index';
import { useAuth } from '../../../../../contexts/auth';
import customersRepository from '../../../../../repositories/Customers';
import boxPrismRepository from '../../../../../repositories/PrismaBox';
import vehiclesRepository from '../../../../../repositories/Vehicles';
import constants from '../../../../../utils/constants';
import FormVeiculo from '../../../../components/Cadastros/Vehicle';
import { CheckListPrintButton } from '../../../../components/CheckListPrintButton';
import FormCliente from '../../../Clients/NewClient/FormClient';
import FormPrismabox from '../../../PrismaBox/NewPrismaBox/BundleFormPrismaBox';
import SalesEmployeesSelectModal from '../SalesEmployeesSelectModal';
import FormClientByLicensePlate from './FormClientByLicensePlate';
import {
  clearClientVehicle,
  handleChange,
  resetCustomerSaleData,
  setClient,
  setClientById,
  setClientVehicle,
  setVehicleLabel,
} from './redux/actions';

import { formatLicensePlate } from 'client/components/ToNormalize/ToNormalize';
import SelectVehicles from 'v2/client/components/SelectVehicles';
import Vehicles from '../../../../../repositories/Vehicles';

export const getVehicleLabel = (vehicle) => {
  if (vehicle.vehicleTypeId === 1 && vehicle.License_Plate) {
    return formatLicensePlate(vehicle.License_Plate);
  } else if ([2, 3, 4].includes(vehicle.vehicleTypeId)) {
    return vehicle.serialIdentification;
  } else if (!vehicle.License_Plate) {
    return vehicle.Maintenance_Object;
  } else {
    return vehicle.License_Plate;
  }
};

export default function ClientData({ customerId, onSubmit }) {
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();
  const [selectClient, setSelectClient] = useState('');
  const [viewVehichleData, setViewVehichleData] = useState(false);
  const [modalComponentObject, setModalComponentObject] = useState({});
  const [isModalRegistrationOption, setIsModalRegistrationOption] =
    useState(false);
  const [isModalClientByLicensePlate, setIsModalClientByLicensePlate] =
    useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);
  const [saleOriginalVehichle, setSaleOriginalVehicle] = useState({});
  const [loadingCreateByPlate, setLoadingCreateByPlate] = useState();
  const { saleReducer } = useSelector((state) => state);
  const { companyId, company } = useAuth();
  const dispatch = useDispatch();

  const [prismaBoxes, setPrismaBoxes] = useState([]);
  const [salesSubtypes, setSalesSubtypes] = useState([]);
  const [isRegisterPrismaBoxModalOpen, setIsRegisterPrismaBoxModalOpen] =
    useState(false);

  const [sellerOptions, setSellerOptions] = useState([]);
  const [isSelectEmployeesModalOpen, setIsSelectEmployeesModalOpen] =
    useState(false);
  const [isResetFormModalOpen, setIsResetFormModalOpen] = useState(false);

  const [tradeRepresentativeOptions, setTradeRepresentativeOptions] = useState(
    []
  );
  const [reloadTrigger, setReloadTrigger] = useState(false);

  const [attendanceTypeOptions, setAttendanceTypeOptions] = useState([]);

  const { user } = useAuth();

  useEffect(() => {
    let vehicle = { Brand: 0, hasSale: false, id: 0 };
    dispatch(initialize('vehicle', vehicle));
  }, []);

  const {
    items,
    forecastReturn,
    clients,
    selectedClient,
    saleStatusId,
    selectedVehicle,
    prismaBoxId,
    salesSubtypeId,
    generateTitleAfterCloseSale,
    sellerId,
    tradeRepresentativeId,
    attendanceTypeId,
    clientVehicles,
    observations,
    malfunctions,
    currentKilometers,
    previousKilometers,
    defects,
    openClientDataModal,
    saleId,
    saleTypeId,
    loading,
    salesEmployeesIds,
    customerRequest,
    isDisabledButtonsSale,
    vehicleLabel,
  } = saleReducer;

  const { vehicle = { values: {} } } = useSelector((state) => state.form);

  const {
    Brand,
    hasSale,
    id,
    License_Plate = '',
    Maintenance_Object = '',
    Year_Manufacture,
    Year_Model,
    Color,
    Model,
    Kilometers,
    Chassi,
    Renavam,
    phone,
    responsible,
    Type,
    name,
    approachId,
    type,
    engineNumber,
    NChassi,
    Fuel_Type,
    bicycleModelId,
    serialIdentification,
    bicycleModalityId,
    bicycleFrameMaterialId,
    bicycleFrameSizeId,
    bicycleRimSizeId,
    bicycleSuspensionTypeId,
    bicycleBrakeTypeId,
    bicycleRearDerailleurId,
    bicycleFrontGearId,
    bicycleRearGearId,
    vehicleTypeId,
    otherRearDerailleurDescription,
  } = vehicle.values;

  const loadPrismaBoxes = async () => {
    try {
      const { data: prismaBoxesArr } = await boxPrismRepository.index({
        companyId,
        status: 1,
      });
      setPrismaBoxes(prismaBoxesArr);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Box/Prismas',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const loadSalesSubtype = async () => {
    try {
      const response = await listAllSaleSubtypeActive(companyId);
      setSalesSubtypes(response);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os subtypes',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const loadSellers = async () => {
    try {
      const response =
        await employeesRepository.getEmployeesThatPerformServicesOrSellProducts(
          companyId,
          {
            isActive: 1,
            sellProduct: 1,
            performService: 1,
          }
        );

      const employeesThatSellProducts = response.data?.filter(
        (employee) => employee?.Position.sellProducts
      );

      const employeesThatSellProductsIds = employeesThatSellProducts.map(
        (employee) => employee.id
      );
      if (employeesThatSellProductsIds.includes(user.employeeId)) {
        dispatch(handleChange(user.employeeId, 'sellerId'));
        dispatch(handleChange(user.name, 'sellerName'));
      }

      setSellerOptions(employeesThatSellProducts);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Vendedores',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const loadTradeRepresentatives = async () => {
    try {
      const response = await TradeRepresentativeRepository.list(companyId, {
        status: 1,
      });

      setTradeRepresentativeOptions(response.tradeRepresentativeList);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao Carregar Representantes',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  const loadAttendanceTypes = async () => {
    try {
      const response = await attendanceTypesRepository.list();

      setAttendanceTypeOptions(response);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar Tipos de Atendimento',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  };

  useEffect(() => {
    let customer = localStorage.getItem('CUSTOMER');
    if (customer) {
      localStorage.removeItem('CUSTOMER');
      customer = JSON.parse(customer);
      dispatch(setClientById(customer.id));
    }
  }, []);

  useEffect(() => {
    if (companyId) {
      loadSellers();
      loadPrismaBoxes();
      loadSalesSubtype();
      loadTradeRepresentatives();
      loadAttendanceTypes();
    }
  }, [companyId]);

  useEffect(() => {
    if (saleOriginalVehichle.License_Plate) return;
    setSaleOriginalVehicle({ ...selectedVehicle, previousKilometers });
  }, [selectedVehicle]);

  const handleSelectClient = (client) => {
    const serializedClient = {
      id: client.customer_id,
      Company_Name: client.customer_name,
      Email: client.customer_email,
      Cpf_Cnpj: client.customer_cpfcnpj,
      Type: client.customer_type,
      Date_Birth: client.customer_birthdate,
      availableCredit: client.customer_available_credit,
      Phones: [
        {
          Number_Phone2: client.customer_phone,
        },
      ],
      Vehicle: client.customer_vehicles.map((vehicle) => ({
        id: vehicle.vehicle_id,
        License_Plate: vehicle.vehicle_plate,
        maintenanceObject: vehicle.vehicle_maintenance_object,
        serialIdentification: vehicle.vehicle_serial_identification,
        Model: vehicle.vehicle_model,
        Kilometers: vehicle.vehicle_kilometers || 10,
        vehicleTypeId: vehicle.vehicle_type_id,
      })),
    };

    setSelectClient({
      label: client.customer_name,
      value: client.customer_id,
    });

    dispatch(setClient(serializedClient));
    if (company.salesRepresentative !== '') {
      dispatch(
        handleChange(
          client.customer_trade_representative_id,
          'tradeRepresentativeId'
        )
      );
    }
    setReloadTrigger(true);
    dispatch(clearClientVehicle());
  };

  const handleValue = (vehicle) => {
    return {
      id: vehicle.vehicle_id,
      License_Plate: vehicle.vehicle_plate,
      maintenanceObject: vehicle.vehicle_maintenance_object,
      serialIdentification:
        vehicle.vehicle_serial_identification ||
        vehicle.vehicle_identification_serial,
      Model: vehicle.vehicle_model,
      Kilometers: vehicle.vehicle_kilometers || 10,
      vehicleTypeId: vehicle.vehicle_type_id,
    };
  };

  async function loadVehicleOptions(search, _loadedOptions, { page }) {
    if (!selectedClient.id) return toastr.warning('Informe um cliente');
    try {
      const res = await Vehicles.getAllByCompanyPaginated({
        company_id: companyId,
        page,
        limit: 10,
        query: search,
        customerId: selectedClient.id,
      });

      const vehiclesOptions = res.rows.map((vehicle) => {
        const value = handleValue(vehicle);

        return {
          label: getVehicleLabel(value),
          value,
        };
      });

      return {
        options: vehiclesOptions,
        hasMore: page * 10 < res.count,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  const handleVehicle = (vehicle) => {
    if (!vehicle || vehicle === null) return dispatch(clearClientVehicle());
    const { value, label } = vehicle;
    dispatch(setVehicleLabel(label));
    dispatch(setClientVehicle(value));

    const previousKilometers =
      value.License_Plate === saleOriginalVehichle.License_Plate
        ? saleOriginalVehichle.previousKilometers
        : value.Kilometers;

    dispatch([
      handleChange(previousKilometers, 'previousKilometers'),
      dispatch(handleChange(0, 'currentKilometers')),
    ]);

    loadVehicle(vehicle.value.id);
  };
  async function loadVehicle(vehicleId) {
    try {
      const vehicle = await vehiclesRepository.show(vehicleId);
      const {
        id,
        Brand,
        Model,
        engineNumber,
        Maintenance_Object,
        License_Plate,
        Year_Manufacture,
        Year_Model,
        Color,
        Kilometers,
        hasSale,
        NChassi,
        Vin_Renavam,
        vehicleTypeId,
      } = vehicle;

      const data = {
        id,
        Brand: Brand,
        Model,
        engineNumber,
        License_Plate,
        Maintenance_Object,
        Year_Manufacture,
        Year_Model,
        Color,
        Kilometers,
        NChassi,
        Vin_Renavam,
        hasSale,
        Type: vehicleTypeId.toString(),
        Editing: true,
      };

      dispatch(initialize('vehicle', data));
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os dados do veículo. Por favor, tente novamente'
      );
    }
  }

  const onCancel = () => {
    setIsConfirmCancelModalOpen(false);
    setIsModalClientByLicensePlate(false);
  };

  const registrationOption = () => {
    setIsModalRegistrationOption(true);
  };

  const createClientByLicensePlate = () => {
    setIsModalClientByLicensePlate(true);
    setIsModalRegistrationOption(false);
  };

  const createNewClient = () => {
    let obj = {
      style: {
        maxHeight: 'calc(100vh - 120px)',
        overflowY: 'auto',
        margin: 0,
      },
      title: 'Novo Cliente',
    };

    setModalComponentObject(obj);
    dispatch(handleChange(true, 'openClientDataModal'));
    setIsModalRegistrationOption(false);
  };

  const viewClientData = () => {
    let obj = {
      style: {
        maxHeight: 'calc(100vh - 120px)',
        overflowY: 'auto',
      },
      title: 'Dados do Cliente',
    };

    if (saleStatusId !== 5 && saleStatusId !== 2 && saleStatusId !== 3) {
      if (!selectedClient.id) return toastr.warning('Informe um cliente');
      setModalComponentObject(obj);

      dispatch(handleChange(true, 'openClientDataModal'));
    }
  };

  const createNewVehicle = () => {
    if (selectedVehicle?.Model) {
      setViewVehichleData(true);
    } else {
      setViewVehichleData(false);
    }
    let obj = {
      title: selectedVehicle?.Model
        ? 'Dados do Veículo/Obj. Manutenção'
        : 'Novo Veículo',
    };

    if (saleStatusId !== 5 && saleStatusId !== 2) {
      if (!selectedClient.id) return toastr.warning('Informe um cliente');
      setModalComponentObject(obj);

      dispatch(handleChange(true, 'openClientDataModal'));
    }
  };

  const createNewVehicleSuccess = (vehicle) => {
    const vehicleSerialized = {
      label: getVehicleLabel(vehicle),
      value: { ...vehicle, vehicleTypeId: Number(vehicle.vehicleTypeId) },
    };
    handleVehicle(vehicleSerialized);
    setReloadTrigger(true);
    dispatch(handleChange(false, 'openClientDataModal'));
  };

  const validateVehicle = (type, vehicleData) => {
    const requiredFields = {
      1: [
        'License_Plate',
        'Brand',
        'Model',
        'Year_Model',
        'Year_Manufacture',
        'Kilometers',
      ],
      2: [
        'serialIdentification',
        'Brand',
        'Model',
        'Year_Model',
        'Year_Manufacture',
      ],
      3: [
        'serialIdentification',
        'Brand',
        'Model',
        'Year_Model',
        'Year_Manufacture',
      ],
      4: ['serialIdentification', 'Brand', 'Model'],
      5: [
        'Maintenance_Object',
        'Brand',
        'Model',
        'Year_Model',
        'Year_Manufacture',
        'Kilometers',
      ],
    };

    const validateRequiredFields = () => {
      const missingFields = requiredFields[type].filter(
        (field) => !vehicleData[field]
      );

      if (missingFields.length > 0) {
        return toastr.warning(
          'Por favor, preencha todos os campos obrigatórios'
        );
      }
    };

    const validateYear = () => {
      if (
        parseInt(vehicleData.Year_Model) <
        parseInt(vehicleData.Year_Manufacture)
      ) {
        return toastr.warning(
          'Atenção: ano de fabricação maior que ano modelo'
        );
      }
      if (parseInt(vehicleData.Year_Manufacture) < 1900) {
        return toastr.warning('Ano de fabricação inválido');
      }
      if (parseInt(vehicleData.Year_Manufacture) > getYear(new Date())) {
        return toastr.warning(
          'Por favor, altere o ano de fabricação e o ano do veículo para prosseguir.'
        );
      }
    };

    const validateYearModel = () => {
      const twoYears = addYears(new Date(), 2);
      if (parseInt(vehicleData.Year_Model) >= getYear(new Date(twoYears))) {
        return toastr.warning(
          'Por favor, altere o ano de fabricação e o ano do veículo para prosseguir.'
        );
      }
    };

    const validateTypeSpecific = () => {
      if (
        type === '1' &&
        vehicleData.License_Plate &&
        vehicleData.License_Plate.length < 7
      ) {
        return toastr.warning('Placa incompleta');
      }
    };

    return [
      validateRequiredFields,
      validateYear,
      validateYearModel,
      validateTypeSpecific,
    ];
  };

  function handleSubmit() {
    const vehicleData = {
      License_Plate,
      Maintenance_Object,
      Brand,
      Model,
      Year_Model,
      Year_Manufacture,
      Kilometers,
      serialIdentification,
    };

    const validations = validateVehicle(Type, vehicleData);
    for (const validation of validations) {
      const result = validation();
      if (result) return result;
    }

    createCustomer();
  }

  async function createVehicle(customerId) {
    setLoadingCreateByPlate(true);

    try {
      const vehicle = await vehiclesRepository.create({
        Type,
        License_Plate,
        Maintenance_Object,
        Brand,
        Model,
        Year_Manufacture,
        Year_Model,
        Color,
        Kilometers: Number(onlyNumbers(Kilometers)),
        Company_id: companyId,
        Customer_id: customerId,
        Chassis: NChassi,
        engineNumber,
        Fuel_Type,
        Renavam,
        serialIdentification,
        bicycleModelId,
        bicycleModalityId,
        bicycleFrameMaterialId,
        bicycleFrameSizeId,
        bicycleRimSizeId,
        bicycleSuspensionTypeId,
        bicycleBrakeTypeId,
        bicycleRearDerailleurId,
        bicycleFrontGearId,
        bicycleRearGearId,
        vehicleTypeId: Number(Type),
        otherRearDerailleurDescription,
      });

      toastr.success('Veículo Cadastrado');

      await setClientOnSale(customerId);
      createNewVehicleSuccess(vehicle);
    } catch (err) {
      console.error(err);
      setLoadingCreateByPlate(false);
      toastr.warning(
        'Ocorreu um erro ao salvar o veículo. Por favor, tente novamente'
      );
    } finally {
      setLoadingCreateByPlate(false);
    }
  }

  async function createCustomer() {
    if (phone.length < 14) {
      return toastr.warning(
        'Por favor, preencha com número de celular válido.'
      );
    }
    try {
      const customer = await customersRepository.create({
        customer: {
          Company_id: companyId,
          Company_Name: name,
          Type: type,
          Status: 'Ativo',
          approachId,
        },
        phones: {
          Type_Phone2: 'Celular',
          Contact: responsible,
          Number_Phone2: phone ? phone.replace(/\D/g, '') : undefined,
        },
      });

      createVehicle(customer.id);

      setLoadingCreateByPlate(false);
      setIsConfirmSaveModalOpen(false);
      setIsModalClientByLicensePlate(false);
      setIsModalRegistrationOption(false);
      toastr.success('Sucesso', `Cliente salvo com sucesso`);
    } catch (err) {
      setLoadingCreateByPlate(false);
      console.log(err);
      return toastr.error(
        'Erro no salvamento',
        'Algo inesperado aconteceu. Por favor, repita o procedimento'
      );
    }
  }

  async function setClientOnSale(clientId) {
    try {
      const customer = await customersRepository.show(clientId);
      dispatch(setClient(customer));
    } catch (err) {
      console.log(err);
    }
  }

  const handleCancel = () => {
    if (
      !Brand &&
      !License_Plate &&
      !Year_Manufacture &&
      !Year_Model &&
      !Color &&
      !Model &&
      !Kilometers &&
      !Chassi &&
      !Renavam &&
      !phone &&
      !responsible &&
      !name
    ) {
      onCancel();
    } else {
      setIsConfirmCancelModalOpen(true);
    }
  };

  const currentDate = format(new Date(), 'yyyy-MM-dd');
  const maxYear = format(addYears(new Date(), 10), 'yyyy-MM-dd');

  const handleSetSubType = (value) => {
    const subType = salesSubtypes.find(
      (item) => Number(item.id) === Number(value)
    );
    if (subType) {
      dispatch(handleChange(Number(value), 'salesSubtypeId'));
      dispatch(handleChange(subType.description, 'salesSubtypeDescription'));

      dispatch(
        handleChange(
          subType.generateTitleAfterCloseSale,
          'generateTitleAfterCloseSale'
        )
      );
    }
  };

  const handleResetCustomerSaleData = () => {
    dispatch(resetCustomerSaleData());
    setIsResetFormModalOpen(false);
  };

  const isSaleDisabled =
    [2, 3, 5, 6, 7, 9].includes(saleStatusId) &&
    isDisabledButtonsSale === false;

  return (
    <>
      <Col>
        <Col xs={12} sm={12} md={12} lg={12} id="reviewHeader">
          <Col>
            <Button
              type="button"
              className="btn btn-sucesso"
              bsStyle="info"
              fill
              onClick={registrationOption}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '35px',
              }}
              disabled={isSaleDisabled}
            >
              + Novo Cliente
            </Button>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            style={{
              width: '460px',
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <SelectCustomers
              onChange={(item) => handleSelectClient(item.value)}
              value={selectClient}
              isDisabled={isSaleDisabled}
              width={'100%'}
              height={'35px'}
            />
            {selectedClient.Company_Name && saleStatusId === 3 && saleId && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  width: '100%',
                }}
              >
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    color: '#D9534F',
                    fontSize: '12px',
                    textDecoration: 'underline',
                    alignItems: 'e',
                  }}
                  onClick={() => setIsResetFormModalOpen(true)}
                >
                  Alterar cliente
                </button>
              </div>
            )}
          </Col>
          <CheckListPrintButton
            disabled={
              saleStatusId == constants.SALE_STATUS_ID.CANCELED ||
              saleStatusId == constants.SALE_STATUS_ID.FINISHED ||
              saleStatusId === 6 ||
              saleStatusId === 9
            }
            currentKilometers={milhar(currentKilometers)}
          />
          {!isPlanFree && !isPlanStart ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: '10px',
                height: '35px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  height: '100%',
                }}
              >
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 'normal',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Prev. de retorno:
                </span>
                <input
                  style={{
                    width: '130px',
                    marginLeft: '5px',
                    fontSize: '12px',
                  }}
                  className="form-control foco-input"
                  type="date"
                  disabled={
                    saleStatusId === 5 ||
                    saleStatusId === 6 ||
                    saleStatusId === 9
                  }
                  min={currentDate}
                  max={maxYear}
                  value={
                    forecastReturn !== null &&
                    forecastReturn !== '' &&
                    forecastReturn !== undefined
                      ? format(
                          addHours(new Date(forecastReturn), 3),
                          'yyyy-MM-dd'
                        ) || null
                      : forecastReturn
                  }
                  onChange={(e) => {
                    dispatch(handleChange(e.target.value, 'forecastReturn'));
                  }}
                ></input>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  height: '100%',
                }}
              >
                <label style={{ fontSize: '12px', fontWeight: 'normal' }}>
                  Subtipo:
                </label>
                <select
                  name="salesSubtypeId"
                  className="form-control foco-input"
                  value={salesSubtypeId}
                  onChange={(e) => handleSetSubType(e.target.value)}
                  disabled={saleStatusId === 5 || saleStatusId === 2}
                >
                  <option value="">Selecione</option>
                  {salesSubtypes.map((subTypes) => (
                    <option key={subTypes.id} value={subTypes.id}>
                      {subTypes.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            ''
          )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          id="reviewHeader"
          style={{
            marginTop: '-20px',
          }}
        >
          <Col lg={3}>
            <div>
              <div className="div-label-modal">
                <label>Nome</label>
              </div>
              <div
                className={
                  saleStatusId === 5 ||
                  saleStatusId === 2 ||
                  saleStatusId === 3 ||
                  saleStatusId === 6 ||
                  saleStatusId === 9
                    ? 'div-a-modal-disabled'
                    : 'div-a-modal'
                }
              >
                <a onClick={viewClientData}>Visualizar Dados Cliente </a>
              </div>
            </div>
            <input
              className="form-control foco-input"
              type="text"
              value={
                selectedClient.Company_Name ? selectedClient.Company_Name : ''
              }
              disabled
            />
          </Col>
          <Col lg={2}>
            <label>Veículo</label>
            <SelectVehicles
              value={
                selectedVehicle &&
                Object.keys(selectedVehicle).length > 0 &&
                selectedVehicle.id !== 0 &&
                selectedVehicle.id !== null
                  ? { label: vehicleLabel, value: selectedVehicle }
                  : null
              }
              placeholder="Selecione"
              loadOptions={loadVehicleOptions}
              onChange={(e) => {
                handleVehicle(e);
              }}
              disabled={
                !selectedClient.id ||
                saleStatusId === 5 ||
                saleStatusId === 2 ||
                saleStatusId === 6 ||
                saleStatusId === 9
              }
              width="100%"
              reloadTrigger={reloadTrigger}
              setReloadTrigger={setReloadTrigger}
              isClearable
            />
          </Col>
          <Col lg={3}>
            <div>
              <div className="div-label-modal">
                <label>Modelo</label>
              </div>
              <div
                className={
                  clientVehicles.length !== 0 &&
                  (saleStatusId === 5 ||
                    saleStatusId === 2 ||
                    saleStatusId === 6 ||
                    saleStatusId === 9)
                    ? 'div-a-modal-disabled'
                    : 'div-a-modal'
                }
              >
                <a
                  onClick={() => {
                    return !saleId || (saleId && License_Plate === '')
                      ? createNewVehicle()
                      : '';
                  }}
                >
                  {selectedVehicle?.Model
                    ? 'Exibir Dados'
                    : 'Adicionar Veículo'}
                </a>
              </div>
            </div>
            <input
              className="form-control foco-input"
              type="text"
              value={selectedVehicle?.Model}
              disabled
            />
          </Col>
          <Col lg={2}>
            <label>KM Anterior</label>
            <input
              className="form-control foco-input"
              value={
                selectedVehicle?.Kilometers || selectedVehicle?.Kilometers === 0
                  ? milhar(selectedVehicle?.Kilometers)
                  : previousKilometers || previousKilometers === 0
                  ? milhar(previousKilometers)
                  : ''
              }
              disabled
            />
          </Col>
          <Col lg={2}>
            <label>KM Atual</label>
            <input
              className="form-control foco-input"
              maxLength="10"
              type="text"
              value={milhar(currentKilometers)}
              onChange={(e) => {
                if (!e.target.value) e.target.value = 0;
                dispatch(
                  handleChange(onlyNumbers(e.target.value), 'currentKilometers')
                );
              }}
              disabled={
                !!!selectedVehicle?.id ||
                saleStatusId === 5 ||
                saleStatusId === 2 ||
                saleStatusId === 6 ||
                saleStatusId === 9
              }
            />
          </Col>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          id="reviewHeader"
          style={{
            marginTop: '-15px',
          }}
        >
          {!isPlanFree && !isPlanStart && (
            <>
              <Col lg={2}>
                <label>Pedido do Cliente</label>
                <input
                  className="form-control foco-input"
                  maxLength="8"
                  type="text"
                  value={customerRequest}
                  onChange={(e) => {
                    if (e.target.value.startsWith(' ')) {
                      e.preventDefault();
                      e.target.value = e.target.value.slice(1);
                    }

                    if (
                      e.nativeEvent.dataTransfer &&
                      e.nativeEvent.dataTransfer.getData('text')
                    ) {
                      e.preventDefault();
                    }

                    const normalizedValue = onlyNumbersLetters(e.target.value);

                    dispatch(handleChange(normalizedValue, 'customerRequest'));
                  }}
                  disabled={saleStatusId === 5 || saleStatusId === 2}
                />
              </Col>
              <Col lg={3}>
                <div>
                  <div className="div-label-modal">
                    <label>Box/Prisma:</label>
                  </div>
                  <div className="div-a-modal">
                    <a
                      onClick={() =>
                        selectedVehicle?.id
                          ? setIsRegisterPrismaBoxModalOpen(true)
                          : toastr.warning('Selecione um veículo')
                      }
                      disabled={
                        !selectedVehicle?.id ||
                        saleStatusId === 6 ||
                        saleStatusId === 9
                          ? true
                          : false
                      }
                    >
                      Adicionar Box/Prisma
                    </a>
                  </div>
                </div>
                <select
                  name="prismaBoxId"
                  className="form-control foco-input"
                  value={prismaBoxId}
                  disabled={
                    saleStatusId === 5 ||
                    saleStatusId === 2 ||
                    !selectedVehicle?.id ||
                    saleStatusId === 6 ||
                    saleStatusId === 9
                  }
                  onChange={(e) => {
                    dispatch(handleChange(e.target.value, 'prismaBoxId'));
                  }}
                >
                  <option value="">Selecione</option>
                  {prismaBoxes.map((prismaBoxes) => (
                    <option key={prismaBoxes.id} value={prismaBoxes.id}>
                      {prismaBoxes.description}
                    </option>
                  ))}
                </select>
              </Col>
              <Col lg={2}>
                <div>
                  <div className="div-label-modal">
                    <label>Vendedor:</label>
                  </div>
                </div>
                <select
                  name="sellerId"
                  className="form-control foco-input"
                  value={sellerId}
                  disabled={
                    saleStatusId === 5 ||
                    saleStatusId === 2 ||
                    saleStatusId === 6 ||
                    saleStatusId === 7 ||
                    saleStatusId === 9
                  }
                  onChange={(e) => {
                    dispatch(handleChange(e.target.value, 'sellerId'));
                    const sellerToSelect = sellerOptions.find(
                      (seller) => +seller.id === +e.target.value
                    );
                    dispatch(handleChange(sellerToSelect.name, 'sellerName'));
                  }}
                >
                  <option value="">Selecione</option>
                  {sellerOptions.map((sellerOptions) => (
                    <option key={sellerOptions.id} value={sellerOptions.id}>
                      {sellerOptions.name}
                    </option>
                  ))}
                </select>
              </Col>
              {company.salesRepresentative === true && (
                <Col lg={2}>
                  <div>
                    <div className="div-label-modal">
                      <label>Representante:</label>
                    </div>
                  </div>
                  <select
                    name="tradeRepresentativeId"
                    className="form-control foco-input"
                    value={tradeRepresentativeId}
                    onChange={(e) => {
                      dispatch(
                        handleChange(e.target.value, 'tradeRepresentativeId')
                      );
                    }}
                    disabled={
                      saleStatusId == constants.SALE_STATUS_ID.CANCELED ||
                      saleStatusId == constants.SALE_STATUS_ID.FINISHED ||
                      saleStatusId === 6 ||
                      saleStatusId === 9
                    }
                  >
                    <option value="">Selecione</option>
                    {tradeRepresentativeOptions.map((representative) => (
                      <option key={representative.id} value={representative.id}>
                        {representative.companyName ||
                          representative.tradingName}
                      </option>
                    ))}
                  </select>
                </Col>
              )}
              {company.typeOfService === true && (
                <Col lg={3}>
                  <div>
                    <div className="div-label-modal">
                      <label>Tipo de Atendimento:</label>
                    </div>
                  </div>
                  <select
                    name="sellerId"
                    className="form-control foco-input"
                    value={attendanceTypeId}
                    onChange={(e) => {
                      dispatch(
                        handleChange(e.target.value, 'attendanceTypeId')
                      );
                    }}
                    disabled={
                      saleStatusId == constants.SALE_STATUS_ID.CANCELED ||
                      saleStatusId == constants.SALE_STATUS_ID.FINISHED ||
                      saleStatusId === 6 ||
                      saleStatusId === 9
                    }
                  >
                    {attendanceTypeOptions.map((attendanceType) => (
                      <option key={attendanceType.id} value={attendanceType.id}>
                        {attendanceType.description}
                      </option>
                    ))}
                  </select>
                </Col>
              )}
            </>
          )}
          {/* <Col lg={2}>
            <CheckboxOSPanel
              vehicles={selectedVehicle}
              items={items}
              companyId={companyId}
              saleTypeId={saleTypeId}
              saleId={saleId}
            />
          </Col> */}
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            marginTop: '-20px',
          }}
        >
          <Col lg={4}>
            <label>Observações</label>
            <textarea
              className="form-control foco-input"
              type="text"
              style={{ resize: 'vertical' }}
              value={observations}
              onChange={(e) =>
                dispatch(handleChange(e.target.value, 'observations'))
              }
              maxLength="500"
              disabled={
                saleStatusId === 5 ||
                saleStatusId === 2 ||
                saleStatusId === 6 ||
                saleStatusId === 9
              }
            />
          </Col>
          <Col lg={4}>
            <label>Defeitos</label>
            <textarea
              maxLength="500"
              className="form-control foco-input"
              style={{ resize: 'vertical' }}
              value={defects}
              onChange={(e) =>
                dispatch(handleChange(e.target.value, 'defects'))
              }
              disabled={
                saleStatusId === 5 ||
                saleStatusId === 2 ||
                saleStatusId === 6 ||
                saleStatusId === 9
              }
            />
          </Col>
          <Col lg={4}>
            <label>Avarias</label>
            <textarea
              maxLength="500"
              className="form-control foco-input"
              style={{ resize: 'vertical' }}
              value={malfunctions}
              onChange={(e) =>
                dispatch(handleChange(e.target.value, 'malfunctions'))
              }
              disabled={
                saleStatusId === 5 ||
                saleStatusId === 2 ||
                saleStatusId === 6 ||
                saleStatusId === 9
              }
            />
          </Col>
        </Col>

        <Modal
          dialogClassName="modal-formProduto modal-90w"
          show={openClientDataModal}
          onHide={() => dispatch(handleChange(false, 'openClientDataModal'))}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <strong>{modalComponentObject.title}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            id={
              modalComponentObject.title === 'Novo Cliente' ||
              modalComponentObject.title === 'Dados do Cliente'
                ? 'modal-edit-customer'
                : ''
            }
            style={modalComponentObject.style}
          >
            {modalComponentObject.title === 'Novo Cliente' ||
            modalComponentObject.title === 'Dados do Cliente' ? (
              <FormCliente
                modal={true}
                hideModal={() =>
                  dispatch(handleChange(false, 'openClientDataModal'))
                }
                handleModal={(id) =>
                  dispatch(setClientById(id, saleId !== (null || 0)))
                }
                dispatch={dispatch}
                ClientId={
                  modalComponentObject.title === 'Dados do Cliente'
                    ? selectedClient.id
                    : null
                }
                saleId={saleId || null}
              />
            ) : (
              <FormVeiculo
                isViewingData={viewVehichleData}
                vehicleId={selectedVehicle.id}
                companyId={companyId}
                customerId={selectedClient.id}
                onCancel={() =>
                  dispatch(handleChange(false, 'openClientDataModal'))
                }
                saleId={saleId || null}
                onSubmit={(vehicle) => createNewVehicleSuccess(vehicle)}
              />
            )}
          </Modal.Body>
        </Modal>
      </Col>
      {isModalRegistrationOption && (
        <Modal
          show={isModalRegistrationOption}
          animation
          dialogClassName="modal-40w modal-80h "
          onHide={() => setIsModalRegistrationOption(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <strong>OS Digital</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Selecione a opção que deseja realizar o cadastro do seu cliente:
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <CustomButton
                bsStyle="primary"
                style={{
                  width: '400px',
                  marginBottom: '10px',
                  backgroundColor: '#428bca',
                  border: 'none',
                }}
                fill
                onClick={createNewClient}
              >
                Cadastro Completo Manual
              </CustomButton>
              <CustomButton
                bsStyle="success"
                style={{
                  width: '400px',
                  backgroundColor: '#5cb85c',
                  border: 'none',
                }}
                fill
                onClick={createClientByLicensePlate}
              >
                Cadastro Resumido via Consulta de Placa
              </CustomButton>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton
              bsStyle="danger"
              fill
              onClick={() => setIsModalRegistrationOption(false)}
            >
              Voltar
            </CustomButton>
          </Modal.Footer>
        </Modal>
      )}
      {isModalClientByLicensePlate && (
        <Modal
          show={isModalClientByLicensePlate}
          animation
          dialogClassName="modal-70w modal-90h "
          onHide={() => setIsModalClientByLicensePlate(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <strong>Cadastro via Consulta de Placa</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormClientByLicensePlate onSubmit={handleSubmit} />
          </Modal.Body>
          <Modal.Footer>
            <FormSubmitButtons
              onCancel={handleCancel}
              onSubmit={() => setIsConfirmSaveModalOpen(true)}
              loading={loading}
            />
          </Modal.Footer>
        </Modal>
      )}
      {isConfirmCancelModalOpen && (
        <AlertModal
          show={true}
          subtitle="O cliente e seu veículo ainda não foram cadastrados. Deseja sair mesmo assim?"
          message="Ao sair do cadastro, nenhum dado será salvo, mas você poderá cadastrar o cliente mais tarde."
          onHide={() => setIsConfirmCancelModalOpen(false)}
          onCancel={() => setIsConfirmCancelModalOpen(false)}
          onSubmit={onCancel}
        />
      )}
      {isConfirmSaveModalOpen && (
        <AlertModal
          show={true}
          subtitle="O cliente e seu veículo serão cadastrados. Deseja confirmar o cadastro do cliente?"
          message="O cadastro do cliente será acessível para edição posteriormente, se houver necessidade."
          onHide={() => setIsConfirmSaveModalOpen(false)}
          onCancel={() => setIsConfirmSaveModalOpen(false)}
          onSubmit={handleSubmit}
        />
      )}

      <Modal
        dialogClassName="modal-formProduto"
        show={isRegisterPrismaBoxModalOpen}
        onHide={() => setIsRegisterPrismaBoxModalOpen(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>{'OS Digital'}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="modal-formProduto-body">
          <FormPrismabox
            modal={true}
            initialValues={{ status: true }}
            handleModal={(value) => {
              setIsRegisterPrismaBoxModalOpen(false);
              toastr.success('Prisma/Box registrado com sucesso!');
              dispatch(handleChange(value, 'prismaBoxId'));
              loadPrismaBoxes();
            }}
            onCancel={() => setIsRegisterPrismaBoxModalOpen(false)}
          />
        </Modal.Body>
      </Modal>

      {isSelectEmployeesModalOpen && (
        <SalesEmployeesSelectModal
          open={isSelectEmployeesModalOpen}
          handleClose={() => setIsSelectEmployeesModalOpen(false)}
          selectedEmployees={salesEmployeesIds}
          disabledSubmit={
            saleStatusId === 5 ||
            saleStatusId === 2 ||
            saleStatusId === 6 ||
            saleStatusId === 9
          }
          onSubmit={(selectedEmployees) => {
            dispatch(handleChange(selectedEmployees, 'salesEmployeesIds'));
          }}
        />
      )}
      {isResetFormModalOpen && (
        <Modal
          show={isResetFormModalOpen}
          animation
          dialogClassName="modal-40w modal-90h "
          onHide={() => setIsResetFormModalOpen(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <strong>Alteração do cliente</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 style={{ fontWeight: 'bold', margin: '0px', fontSize: '16px' }}>
              Você tem certeza que deseja alterar o cliente?
            </h4>
            <p>
              As informações do cliente (e veículos) serão removidas da
              OS/Orçamento. As alterações após esse momento serão aplicadas
              apenas ao salvar
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                bsStyle="danger"
                name="Voltar"
                onClick={() => setIsResetFormModalOpen(false)}
                fill
              >
                Não
              </Button>
              <Button
                bsStyle="info"
                fill
                onClick={() => handleResetCustomerSaleData()}
              >
                Salvar
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
