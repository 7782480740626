import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { SearchInput } from 'v2/components/form';
import { InputSelectDefault } from 'v2/components/Input';
import { InputContainer } from 'v2/components/Input';
import { Table } from 'v2/components/Table';
import FragaRepository from 'v2/repositories/FragaRepository';
import { toastr } from 'react-redux-toastr';
import logo from 'assets/img/fraga/logo-fraga.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { ImageSliderModal } from 'v2/components/PicturesModal';
import { useAuth } from 'contexts/auth';
import suivCompanyRepository from 'repositories/SuivCompany';
import { usePlanSignatureContext } from 'contexts/plan-signature';

export function FragaModal({
  show,
  onHide,
  history,
  setShowFragaModalOpen,
  onSubmit,
  isLocal,
  openedBy,
  selectedVehicleData,
}) {
  const { companyId } = useAuth();
  const { isPlanBasic, isPlanPrime } = usePlanSignatureContext();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [valueSkip, setValueSkip] = useState(0);
  const [rows, setRows] = useState([]);

  const [isFetching, setIsFetching] = useState(false);

  const [vehicleLines, setVehicleLines] = useState([]);
  const [selectedVehicleLine, setSelectedVehicleLine] = useState({
    label: 'Automóveis e Utilitários Leves',
    value: 0,
  });

  const [vehicleModels, setVehicleModels] = useState([]);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState({
    label: 'Modelo do Veículo(Aplicação)',
    value: '',
  });

  const [vehicleNames, setVehicleNames] = useState([]);
  const [selectedVehicleName, setSelectedVehicleName] = useState({
    label: 'Marca',
    value: '',
  });

  const [vehicleYears, setVehicleYears] = useState([]);
  const [selectedVehicleYear, setSelectedVehicleYear] = useState({
    label: 'Ano',
    value: '',
  });

  const [manufacturers, setManufacturers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState({
    label: 'Montadora',
    value: '',
  });

  const [searchInfoProduct, setSearchInfoProduct] = useState('');
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
  const [listProductPhotos, setListProductPhotos] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleBackAndClear = () => {
    setSearchInfoProduct('');
    setRows([]);
    setSelectedProducts([]);
    setSelectedVehicleLine({
      label: 'Automóveis e Utilitários Leves',
      value: 0,
    });
    setSelectedVehicleModel({
      label: 'Modelo do Veículo(Aplicação)',
      value: '',
    });
    setSelectedVehicleName({
      label: 'Marca',
      value: '',
    });
    setSelectedVehicleYear({
      label: 'Ano',
      value: '',
    });
    setSelectedManufacturer({
      label: 'Montadora',
      value: '',
    });
  };

  const handleClose = () => {
    if (isLocal) {
      history.push('/client/home');
    }
    setShowFragaModalOpen(false);
  };

  async function handleLoadVehicleLines() {
    try {
      const response = await FragaRepository.getVehicleLines();

      const serializedVehicleLines = [
        { label: 'Linha', value: '' },
        ...response.vehicleLines.map((line, index) => ({
          label: line,
          value: index,
        })),
      ];

      setVehicleLines(serializedVehicleLines);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Aviso',
        'Ocorreu um erro ao carregar as linhas veiculares. Tente novamente!'
      );
    }
  }

  async function handleLoadManufacturers() {
    try {
      const response = await FragaRepository.getManufacturers({
        classification: selectedVehicleLine.label,
      });

      const serializedManufacturers = [
        { label: 'Montadora', value: '' },
        ...response.vehicleBrands.map((line, index) => ({
          label: line,
          value: index,
        })),
      ];

      setManufacturers(serializedManufacturers);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Aviso',
        'Ocorreu um erro ao carregar as montadoras. Tente novamente!'
      );
    }
  }

  async function handleLoadVehicleNames() {
    try {
      const response = await FragaRepository.getVehicleNames({
        classification: selectedVehicleLine.label,
        brand: selectedManufacturer.label,
      });

      const serializedVehicleNames = [
        { label: 'Marca', value: '' },
        ...response.vehicleNames.map((line, index) => ({
          label: line,
          value: index,
        })),
      ];

      setVehicleNames(serializedVehicleNames);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Aviso',
        'Ocorreu um erro ao carregar as montadoras. Tente novamente!'
      );
    }
  }

  async function handleLoadVehicleModels() {
    try {
      const response = await FragaRepository.getVehicleModels({
        classification: selectedVehicleLine.label,
        brand: selectedManufacturer.label,
        name: selectedVehicleName.label,
      });

      const serializedVehicleModels = [
        { label: 'Modelo do Veículo(Aplicação)', value: '' },
        ...response.vehicleModels.map((line, index) => ({
          label: line,
          value: index,
        })),
      ];

      setVehicleModels(serializedVehicleModels);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Aviso',
        'Ocorreu um erro ao carregar os modelos veiculares. Tente novamente!'
      );
    }
  }

  async function handleLoadVehicleYears() {
    try {
      const response = await FragaRepository.getYearsByModel({
        classification: selectedVehicleLine.label,
        brand: selectedManufacturer.label,
        name: selectedVehicleName.label,
        model: selectedVehicleModel.label,
      });

      const serializedVehicleYears = [
        { label: 'Ano', value: '' },
        ...response.vehicleYears.map((line, index) => ({
          label: line,
          value: index,
        })),
      ];

      setVehicleYears(serializedVehicleYears);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Aviso',
        'Ocorreu um erro ao carregar os anos veiculares. Tente novamente!'
      );
    }
  }

  async function handleLoadProductsByVehicle(skip) {
    setIsFetching(true);
    setRows([]);
    try {
      const response = await FragaRepository.getProductsByVehicle({
        searchInfoProduct,
        skip,
        classification: selectedVehicleLine.label,
        brand: selectedManufacturer.label,
        name: selectedVehicleName.label,
        year: selectedVehicleYear.label,
        model: selectedVehicleModel.label,
      });

      const serializedProductsByVehicle = {
        pageInfo: response.catalogSearch.pageInfo,
        product: response.catalogSearch.nodes.map((node, index) => ({
          id: node.product.id,
          description: node.product.applicationDescription,
          manufacturerRef: node.product.partNumber,
          barCode:
            node.product.specifications &&
            node.product.specifications.find(
              (item) => item.description === 'Código de barras (EAN)'
            )?.value,
          brand: node.product.brand.name,
          additionalInformation: node.product.summaryApplication,
          images: node.product.images,
        })),
      };

      setPages(Math.ceil(response.catalogSearch.pageInfo.total / limit));

      if (response.catalogSearch.nodes.length === 0) {
        toastr.error(
          'Aviso',
          'Não foi encontrado nenhuma peça para o veículo pesquisado.'
        );
      } else {
        setRows(serializedProductsByVehicle.product);
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Aviso',
        'Ocorreu um erro ao carregar os produtos. Tente novamente!'
      );
    } finally {
      setIsFetching(false);
    }
  }

  async function handleChangeSelectedVehicleLine(e) {
    setSelectedVehicleLine(e);
    setSelectedManufacturer({
      label: 'Montadora',
      value: '',
    });
    setSelectedVehicleName({
      label: 'Marca',
      value: '',
    });
    setSelectedVehicleModel({
      label: 'Modelo do Veículo(Aplicação)',
      value: '',
    });
    setSelectedVehicleYear({
      label: 'Ano',
      value: '',
    });
  }

  async function handleChangeSelectedManufacturer(e) {
    setSelectedManufacturer(e);
    setSelectedVehicleName({
      label: 'Marca',
      value: '',
    });
    setSelectedVehicleModel({
      label: 'Modelo do Veículo(Aplicação)',
      value: '',
    });
    setSelectedVehicleYear({
      label: 'Ano',
      value: '',
    });
  }

  async function handleChangeSelectedVehicleName(e) {
    setSelectedVehicleName(e);
    setSelectedVehicleModel({
      label: 'Modelo do Veículo(Aplicação)',
      value: '',
    });
    setSelectedVehicleYear({
      label: 'Ano',
      value: '',
    });
  }

  async function handleChangeSelectedVehicleModel(e) {
    setSelectedVehicleModel(e);
    setSelectedVehicleYear({
      label: 'Ano',
      value: '',
    });
  }

  async function handleChangeSelectedVehicleYear(e) {
    setSelectedVehicleYear(e);
  }

  async function handleOpenImagesModal(images) {
    setListProductPhotos(images);
    setIsImagesModalOpen(true);
  }

  async function handleRegisterProducts() {
    setIsFetching(true);
    try {
      const items = await suivCompanyRepository.createGenuineParts({
        companyId,
        parts: selectedProducts.map((product) => ({
          description: product.description,
          partNumber: product.manufacturerRef,
          price_cost: 0.01,
          sale_price: 0.02,
          profit_margin: 100,
          images: product.images,
        })),
      });

      if (onSubmit) {
        const formatedItems = items.map((item) => ({
          ...item,
          Product_id: item.id,
          Quantity: 1,
          Unit_Value: item.Sale_Price,
          Value_Cost: item.Price_Cost,
          Discount_Value: 0,
          edit: false,
          updateDescriptionOnSale: false,
          Total: item.Sale_Price,
        }));

        onSubmit(formatedItems);
      }

      if (isLocal) {
        history.push('/client/home');
      } else {
        setShowFragaModalOpen(false);
      }
      if (openedBy === 'pdv')
        return toastr.success(
          'Produto(s) cadastrado(s) com sucesso e adicionados a venda.'
        );

      return toastr.success('Produto(s) cadastrado(s) com sucesso.');
    } catch (err) {
      console.log(err);
      return toastr.error('Erro ao cadastrar o(s) produto(s).');
    } finally {
      setIsFetching(false);
    }
  }

  const handleSelectProduct = (product) => {
    setSelectedProducts((prevSelectedProducts) => {
      if (prevSelectedProducts.includes(product)) {
        return prevSelectedProducts.filter((element) => element !== product);
      } else {
        return [...prevSelectedProducts, product];
      }
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);

    let newValueSkip = newPage > page ? valueSkip + 10 : valueSkip - 10;
    newValueSkip = Math.max(newValueSkip, 0);

    setValueSkip(newValueSkip);

    handleLoadProductsByVehicle(newValueSkip);
  };

  const loadData = async () => {
    setIsFetching(true);
    try {
      await Promise.all([
        handleLoadManufacturers(),
        selectedManufacturer && handleLoadVehicleNames(),
        selectedVehicleName && handleLoadVehicleModels(),
        selectedVehicleModel && handleLoadVehicleYears(),
      ]);
    } catch (error) {
      console.error('Erro ao carregar os dados:', error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    handleLoadVehicleLines();
  }, []);

  useEffect(() => {
    loadData();
  }, [
    selectedVehicleLine,
    selectedManufacturer,
    selectedVehicleName,
    selectedVehicleModel,
    selectedVehicleYear,
  ]);

  const columns = [
    {
      Header: '',
      accessor: 'id',
      width: 90,
      Cell: (props) => {
        const isSelected = selectedProducts.includes(props.row._original);
        return (
          <div className="flex center gap-050">
            <FontAwesomeIcon
              title="Selecionar"
              cursor="pointer"
              icon={isSelected ? faCheckSquare : faSquare}
              style={{ width: '20px', height: '20px' }}
              onClick={() => {
                handleSelectProduct(props.row._original);
              }}
            />
            {isPlanBasic ||
              (isPlanPrime && (
                <FontAwesomeIcon
                  title="Abrir imagens"
                  cursor="pointer"
                  icon={faImages}
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  onClick={() =>
                    handleOpenImagesModal(props.row._original.images)
                  }
                />
              ))}
          </div>
        );
      },
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      width: 400,
    },
    {
      Header: 'Ref. Fabricante',
      accessor: 'manufacturerRef',
    },
    {
      Header: 'Cód. de Barra',
      accessor: 'barCode',
    },
    {
      Header: 'Marca',
      accessor: 'brand',
    },
    {
      Header: 'Inf. Complementar/Aplicação',
      accessor: 'additionalInformation',
    },
  ];

  return (
    <Modal show={show} animation onHide={onHide} dialogClassName="modal-90w">
      <Modal.Header>
        <div className="flex column gap-050 w-100">
          <div className="flex row gap-050 w-100">
            <div className="flex column gap-050 align-start w-100">
              <div className="flex gap-050 align-start">
                <InputContainer>
                  <SearchInput
                    placeholder={'Descrição / Apelido / Ref. Fab'}
                    value={searchInfoProduct}
                    onChange={(e) => setSearchInfoProduct(e.target.value)}
                    width={300}
                    style={{
                      fontSize: '12px',
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputSelectDefault
                    height={35}
                    width={200}
                    options={manufacturers}
                    value={selectedManufacturer}
                    onChange={(e) => handleChangeSelectedManufacturer(e)}
                    placeholder={'Montadora'}
                    isLoading={isFetching}
                    isSearchable={true}
                    disabled={
                      selectedVehicleLine.value === '' ||
                      manufacturers.length === 0
                    }
                    style={{
                      fontSize: '12px',
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputSelectDefault
                    height={35}
                    width={200}
                    options={vehicleNames}
                    value={selectedVehicleName}
                    onChange={(e) => handleChangeSelectedVehicleName(e)}
                    isLoading={isFetching}
                    disabled={
                      selectedVehicleLine.value === '' ||
                      selectedManufacturer.value === '' ||
                      vehicleNames.length === 0
                    }
                    isSearchable={true}
                    style={{
                      fontSize: '12px',
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputSelectDefault
                    height={35}
                    width={250}
                    options={vehicleModels}
                    value={selectedVehicleModel}
                    onChange={(e) => handleChangeSelectedVehicleModel(e)}
                    placeholder={'Modelo do Veículo(Aplicação)'}
                    isLoading={isFetching}
                    disabled={
                      selectedVehicleLine.value === '' ||
                      selectedManufacturer.value === '' ||
                      selectedVehicleName.value === '' ||
                      vehicleModels.length === 0
                    }
                    isSearchable={true}
                    style={{
                      fontSize: '12px',
                    }}
                  />
                </InputContainer>

                <InputContainer>
                  <InputSelectDefault
                    height={35}
                    width={100}
                    options={vehicleYears}
                    value={selectedVehicleYear}
                    onChange={(e) => handleChangeSelectedVehicleYear(e)}
                    placeholder={'Ano'}
                    isLoading={isFetching}
                    disabled={
                      selectedVehicleLine.value === '' ||
                      selectedManufacturer.value === '' ||
                      selectedVehicleName.value === '' ||
                      selectedVehicleModel.value === '' ||
                      vehicleYears.length === 0
                    }
                    isSearchable={true}
                    style={{
                      fontSize: '12px',
                    }}
                  />
                </InputContainer>
              </div>

              <div className="flex gap-050 align-start w-100">
                <InputContainer>
                  <InputSelectDefault
                    height={35}
                    width={300}
                    options={vehicleLines}
                    value={selectedVehicleLine}
                    onChange={(e) => handleChangeSelectedVehicleLine(e)}
                    placeholder={'Linha'}
                    isLoading={isFetching}
                    style={{
                      fontSize: '12px',
                    }}
                  />
                </InputContainer>
                <InputContainer>
                  <button
                    className="button button-green button-h35"
                    onClick={() => handleLoadProductsByVehicle(0)}
                    disabled={
                      selectedVehicleLine.value === '' ||
                      selectedManufacturer.value === '' ||
                      selectedVehicleName.value === '' ||
                      selectedVehicleModel.value === '' ||
                      selectedVehicleYear.value === '' ||
                      isFetching
                    }
                  >
                    Pesquisar
                  </button>
                </InputContainer>
                {selectedVehicleData && selectedVehicleData.id ? (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ marginRight: '5px' }}>
                        <strong>Marca: </strong>
                        {`${selectedVehicleData?.Brand}`}
                      </span>
                      <span style={{ marginRight: '5px' }}>
                        <strong>Modelo: </strong>{' '}
                        {`${selectedVehicleData?.Model}`}
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '2px',
                        }}
                      >
                        <span>
                          <strong>Ano Fab.: </strong>
                          {`${selectedVehicleData?.Year_Manufacture}`}
                        </span>
                        |
                        <span>
                          <strong>Ano Mod.: </strong>
                          {`${selectedVehicleData?.Year_Model}`}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div
                  className="gap-050"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        fontSize: '12px',
                        color: '#d9534f',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Essa consulta de peça é realizada no catálogo digital
                      da&nbsp;
                    </span>
                    <a href="https://fraga.com.br">
                      <img
                        src={logo}
                        alt="https://fraga.com.br"
                        style={{ width: '120px', height: '40px' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'left' }}>
        <Table
          columns={columns}
          data={rows}
          pageSize={limit}
          page={page}
          onPageChange={handlePageChange}
          manual
          onPageSizeChange={setLimit}
          pages={pages}
          loading={isFetching}
          noDataText={'Preencha os campos com as peças que deseja pesquisar.'}
          pageSizeOptions={[10]}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="upgrade-modal-footer">
          <div className="w-100 flex end gap-050">
            <button
              className="button button-red button-h35"
              onClick={handleClose}
            >
              Voltar(ESC)
            </button>
            <button
              className="button button-yellow button-h35"
              onClick={handleBackAndClear}
            >
              Limpar Pesquisa
            </button>
            <button
              className="button button-green button-h35"
              onClick={handleRegisterProducts}
              disabled={selectedProducts.length === 0}
            >
              Cadastrar Produto
            </button>
          </div>
        </div>
      </Modal.Footer>

      {listProductPhotos.length !== 0 && (
        <ImageSliderModal
          show={isImagesModalOpen}
          onCancel={() => setIsImagesModalOpen(false)}
          onHide={() => setIsImagesModalOpen(false)}
          productPhotos={listProductPhotos}
        />
      )}
    </Modal>
  );
}
