import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

import Button from '../../components/CustomButton/Button.jsx';
import Card from 'components/Card/Card';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany';
import DownloadXlsButton from 'components/DownloadXlsButton';
import SearchInput from '../../../components/SearchInput';

import { useAuth } from '../../../contexts/auth';

import accountPlansRepository from 'repositories/AccountPlans';
import accountPlanCategoriesRepository from 'repositories/AccountPlanCategories';

import { xlsColumns, getAccountPlansToExport } from './excelHelpers';
import constants from '../../../utils/constants';
import getCategoryDescriptionById from 'utils/accountPlanHelpers';

const HomeAccountPlans = () => {
  const { companyId } = useAuth();
  const history = useHistory();
  const [accountPlans, setAccountPlans] = useState([]);
  const [accountPlanCategories, setAccountPlanCategories] = useState([]);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [accountPlansToExport, setAccountPlansToExport] = useState([]);
  const [loading, setLoading] = useState(false);

  const [type, setType] = useState('');
  const [isActive, setIsActive] = useState('');
  const [query, setQuery] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (companyId) {
      loadAccountPlanTable();
    }
  }, [type, isActive, query, categoryId, currentPage, pageLimit]);

  useEffect(() => {
    if (companyId) {
      loadAccountPlanCategories();
    }
  }, []);

  async function loadAccountPlanCategories() {
    setLoading(true);

    try {
      const accountPlanCategories =
        await accountPlanCategoriesRepository.getAllAccountPlanCategories();

      setAccountPlanCategories(accountPlanCategories);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as categorias do plano de contas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  async function loadAccountPlanTable() {
    setLoading(true);

    try {
      const response = await accountPlansRepository.index({
        companyId,
        limit: pageLimit,
        page: currentPage + 1,
        type,
        isActive,
        query,
        categoryId,
      });

      const accountPlansToExport = getAccountPlansToExport(
        response.data,
        accountPlanCategories
      );
      setAccountPlansToExport(accountPlansToExport);
      setAccountPlans(response.data);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar o Plano de Contas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      content={
        <>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.ACCOUNT_PLAN)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Nova Conta
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <DownloadXlsButton
                archiveName={`Plano de Contas - ${format(
                  new Date(),
                  'dd/MM/yyyy'
                )}`}
                data={accountPlansToExport}
                disabled={loading || !accountPlansToExport.length}
                className="btn btn-export"
                columns={xlsColumns}
                style={{ fontSize: '12px' }}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                &nbsp;Exportar .xls
              </DownloadXlsButton>
            </div>
          </div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo:</strong>
              </span>
              <select
                className="form-control foco-input"
                onChange={(event) => setType(event.target.value)}
                value={type}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="Crédito">Crédito (+)</option>
                <option value="Débito">Débito (-)</option>
              </select>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status:</strong>
              </span>
              <select
                className="form-control foco-input"
                onChange={(e) => setIsActive(e.target.value)}
                value={isActive}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="true">Ativo</option>
                <option value="false">Inativo</option>
              </select>
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Categorias:</strong>
              </span>
              <select
                className="form-control foco-input"
                onChange={(event) => setCategoryId(event.target.value)}
                value={categoryId}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todas</option>
                {accountPlanCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.description}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={accountPlans}
              columns={[
                {
                  Header: 'Descrição',
                  accessor: 'description',
                  Cell: (props) => props.value,
                },
                {
                  Header: 'Tipo',
                  accessor: 'type',
                  width: 200,
                  Cell: (props) => props.value,
                },
                {
                  Header: 'Categorias',
                  accessor: 'categoryId',
                  width: 250,
                  Cell: (props) =>
                    getCategoryDescriptionById(
                      props.value,
                      accountPlanCategories
                    ) || '',
                },
                {
                  Header: 'Status',
                  accessor: 'isActive',
                  width: 150,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: props.value ? 'green' : 'red',
                      }}
                    >
                      {props.value ? 'Ativo' : 'Inativo'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'id',
                  width: 100,
                  Cell: (props) => (
                    <div>
                      <FontAwesomeIcon
                        title="Edição de conta"
                        color="black"
                        icon={faEdit}
                        cursor="pointer"
                        onClick={() =>
                          history.push({
                            pathname: `account-plan#${props.value}`,
                          })
                        }
                      />
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              pageSize={pageLimit}
              onPageSizeChange={(value) => {
                setPageLimit(value);
              }}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
              pages={totalPages}
              manual
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              noDataText="Nenhuma conta encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </>
      }
    />
  );
};

export default HomeAccountPlans;
