import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { OnboardingFilter } from './components/OnboardingFilter';
import { DefaultContentContainer } from 'v2/components/layout';
import { getBreadcrumbData } from './utils';
import BreadCrumb from 'components/BreadCrumb/BreadCrumb';
import { OnboardingContainer } from './Onboarding.styles';
import { OnboardingTable } from './components/OnboardingTable';
import { InfoCard } from 'admin/components/InfoCard/InfoCard';
import { useOnboarding } from './hooks/useOnboarding';
import { OnboardingModal } from 'admin/components/OnboardingModal';

export function Onboarding() {
  const {
    initialEndDate,
    initialStartDate,
    getPlans,
    populateInfoCardsData,
    getOnboardingData,
  } = useOnboarding();

  const methods = useForm({
    defaultValues: {
      dateType: { label: 'Cadastro da Company', value: 'register' },
      status: { label: 'Todos', value: '' },
      initialDate: initialStartDate,
      finalDate: initialEndDate,
      query: '',
      onboardingDataTable: [],
      controlTable: {
        page: 0,
        pageLimit: 10,
        totalPages: 1,
      },
      onBoardingModal: {
        isOpen: false,
        companyId: '',
        onboardingHeaderId: '',
        companyName: '',
      },
    },
  });

  const onboardingDataTable = methods.watch('onboardingDataTable');
  useEffect(() => {
    getPlans(methods.setValue);
  }, []);

  const updatedInfoCardsData = populateInfoCardsData(onboardingDataTable);

  const isOpenOnboardingModal = methods.watch('onBoardingModal.isOpen');
  return (
    <DefaultContentContainer>
      <BreadCrumb {...getBreadcrumbData()} />
      <FormProvider {...methods}>
        <OnboardingContainer>
          <div>
            <OnboardingFilter />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '2rem',
              marginTop: '2rem',
            }}
          >
            {updatedInfoCardsData.map((card, index) => (
              <InfoCard
                key={index}
                icon={card.icon}
                primaryText={card.primaryText}
                secondaryText={card.secondaryText}
                containerStyle={card.containerStyle}
                iconStyle={card.iconStyle}
                emoji={card.emoji}
              />
            ))}
          </div>

          <div style={{ width: '100%', height: '100%' }}>
            <OnboardingTable />
          </div>
        </OnboardingContainer>
        {isOpenOnboardingModal && (
          <OnboardingModal
            isOpen={isOpenOnboardingModal}
            handleClose={() =>
              methods.setValue('onBoardingModal.isOpen', false)
            }
            companyId={methods.watch('onBoardingModal.companyId')}
            onboardingHeaderId={methods.watch(
              'onBoardingModal.onboardingHeaderId'
            )}
            handleReload={() => {
              getOnboardingData(methods.setValue, methods.getValues, 'table');
            }}
            companyName={methods.watch('onBoardingModal.companyName')}
          />
        )}
      </FormProvider>
    </DefaultContentContainer>
  );
}
