import React, { useState, useEffect, useRef } from 'react';
import { change, Field, initialize } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { Modal } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { companySegments as companySegmentsAtom } from '../../../../storage/companyDetailsStorage';
import { useSelector, useDispatch } from 'react-redux';
import { licensePlateMask } from '../../../../utils/masks';
import api from '../../../../services/api';
import config from '../../../../config';
import suivCompanyRepository from '../../../../repositories/SuivCompany';
import tableFipeRepository from '../../../../repositories/TableFipe';
import brandsRepository from '../../../../repositories/Brands';
import vehiclesRepository from 'repositories/Vehicles';
import MegalaudoRepository from 'repositories/Megalaudo';
import RenderField from 'components/RenderField';
import CarMotobikeTruckForm from './components/CarMotobikeTruckForm';
import OthersVehiclesForm from './components/OthersVehiclesForm';
import BikeForm from './components/BikeForm';
import TractorExcavatorForm from './components/TractorExcavatorForm';
import HarvesterForm from './components/HarvesterForm';
import ModalNewBrand from 'client/views/Brands/NewBrand/ModalNewBrand';

const MainData = ({ selectedModel, setSelectedModel, isViewingData }) => {
  const [brands, setBrands] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [loadingBrandModels, setLoadingBrandModels] = useState(false);
  const [loadingVehiclesByPlate, setLoadingVehiclesByPlate] = useState(false);
  const [isVehicleByPlate, setIsVehicleByPlate] = useState(false);
  const companyId = localStorage.getItem('ID_EMPRESA');
  const [createdManufacturer, setCreatedManufacturer] = useState('');

  const [vehicleTypes, setVehicleTypes] = useState([]);

  const [bicycleBrakeTypes, setBicycleBrakeTypes] = useState([]);
  const [bicycleSuspensionTypes, setBicycleSuspensionTypes] = useState([]);
  const [bicycleFrontGears, setBicycleFrontGears] = useState([]);
  const [bicycleRearGears, setBicycleRearGears] = useState([]);
  const [bicycleRimSizes, setBicycleRimSizes] = useState([]);
  const [bicycleFrameSizes, setBicycleFrameSizes] = useState([]);
  const [bicycleModalities, setBicycleModalities] = useState([]);
  const [bicycleRearDerailleurs, setBicycleRearDerailleurs] = useState([]);
  const [bicycleFrameMaterials, setBicycleFrameMaterials] = useState([]);
  const [bicycleModels, setBicycleModels] = useState([]);
  const [bicycleManufacturer, setBicycleManufacturer] = useState([]);

  const [tractorExcavatorBrands, setTractorExcavatorBrands] = useState([]);

  const [harvesterBrands, setHarvesterBrands] = useState([]);

  const [modalTitle, setModalTitle] = useState('');
  const [styleModal, setStyleModal] = useState('');
  const [openModal, setOpenModal] = useState('');

  const [vehiclesOptions, setVehiclesOptions] = useState([]);
  const [defaultModelIndex, setDefaultModelIndex] = useState(null);

  const [companySegments] = useRecoilState(companySegmentsAtom);

  const {
    Brand,
    hasSale,
    License_Plate,
    Model,
    Type,
    Editing,
    bicycleRearDerailleurId,
    bicycleModelId,
  } = useSelector((state) => state.form.vehicle?.values);

  const dispatch = useDispatch();

  const modelInput = useRef(null);

  const loadBicycleManufacturers = async () => {
    try {
      let manufacturers = await brandsRepository.getBicycleBrandsOrdered(
        companyId
      );

      setBicycleManufacturer(manufacturers);
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  function handleUnselectVehicle() {
    if (Type !== '1') {
      dispatch(change('vehicle', 'License_Plate', ''));
    }
    dispatch(change('vehicle', 'Year_Manufacture', ''));
    dispatch(change('vehicle', 'Year_Model', ''));
    dispatch(change('vehicle', 'Color', ''));
    dispatch(change('vehicle', 'NChassi', ''));
    dispatch(change('vehicle', 'Maintenance_Object', ''));
    dispatch(change('vehicle', 'Model', ''));

    if (Type !== '2' || Type !== '3') {
      dispatch(change('vehicle', 'serialIdentification', ''));
    }
  }

  useEffect(() => {
    if (!Editing) {
      handleUnselectVehicle();
    }
  }, [Type]);

  useEffect(() => {
    if (!Brand) {
      return setVehicles([]);
    }

    if (!Editing && !isVehicleByPlate) {
      setSelectedModel('');
    }

    if (Type === '1') {
      if (!Editing && !isVehicleByPlate) {
        handleUnselectVehicle();
      }

      if (!isVehicleByPlate) {
        loadBrandModels();
      }
    }

    if (Type === '2' || Type === '3') {
      if (!Editing) {
        dispatch(change('vehicle', 'Model', ''));
      }

      if (!isVehicleByPlate) {
        loadBrandModels();
      }
    }
  }, [Brand]);

  useEffect(() => {
    if (!!bicycleModelId) {
      const selectedBicycleModel = bicycleModels.find(
        (model) => model.id === Number(bicycleModelId)
      );
      dispatch(
        change(
          'vehicle',
          'Model',
          selectedBicycleModel?.description?.toUpperCase()
        )
      );
    }
  }, [bicycleModelId]);

  useEffect(() => {
    if (Editing) {
      const modelIndex = vehicles.findIndex(
        (vehicle) => vehicle.model === Model
      );

      setSelectedModel(vehicles[modelIndex]);
    }

    if (!isVehicleByPlate) {
      setVehiclesOptions(vehicles);
    }
  }, [vehicles]);

  async function loadBrands() {
    setLoadingBrandModels(true);
    try {
      const response = await api.get(config.endpoint + `tabelaFipe/getBrands`);
      setBrands(
        response.data.data.sort((a, b) =>
          a.Brand.toUpperCase() > b.Brand.toUpperCase() ? 1 : -1
        )
      );
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    } finally {
      setLoadingBrandModels(false);
    }
  }

  // MEGALAUDO
  // async function loadVehicleByPlate() {
  //   setLoadingVehiclesByPlate(true);
  //   try {
  //     const searchVehicle = await MegalaudoRepository.show(License_Plate);
  //     const vehicle = searchVehicle?.data?.resposta?.veiculo;
  //     const fipe = searchVehicle?.data?.resposta?.fipe;

  //     let fipeCode =
  //       fipe?.codigo_fipe.length === 0
  //         ? ''
  //         : fipe?.codigo_fipe[0].toString().replace('-', '') || '';

  //     const fuelType = serializeFuelType(
  //       vehicle?.tipo_combustivel?.toLowerCase()
  //     );

  //     if (fipeCode === '') {
  //       const message = `A placa do veículo pesquisado é ${
  //         vehicle?.ano_fabricacao
  //           ? `do ano de ${vehicle.ano_fabricacao}`
  //           : 'inferior ao ano de 1987'
  //       } e não existe esse veículo na tabela FIPE. Por favor, realize o cadastro manual.`;

  //       dispatch(
  //         change(
  //           'vehicle',
  //           'Year_Manufacture',
  //           vehicle?.ano_fabricacao?.toString()
  //         )
  //       );
  //       dispatch(
  //         change('vehicle', 'Year_Model', vehicle?.ano_modelo?.toString())
  //       );
  //       dispatch(change('vehicle', 'NChassi', vehicle?.chassi));
  //       dispatch(change('vehicle', 'Fuel_Type', fuelType));
  //       return toastr.warning('Informação', message);
  //     }

  //     fipeCode = fipeCode?.toString().padStart(7, '0');
  //     fipeCode = fipeCode?.slice(0, -1) + '-' + fipeCode.slice(-1);

  //     const tableFipeData = await tableFipeRepository.getByFipe(fipeCode);

  //     let serializedVehicle = [
  //       {
  //         codeFipe: tableFipeData?.Code_FIPE,
  //         model: tableFipeData?.Model,
  //         brand: tableFipeData?.Brand,
  //         type: tableFipeData?.Type,
  //         label: tableFipeData.Model?.toUpperCase(),
  //         value: tableFipeData?.Model,
  //         color: vehicle.cor?.toUpperCase() || '',
  //       },
  //     ];

  //     setVehicles(serializedVehicle);
  //     setSelectedModel(serializedVehicle[0]);
  //     setIsVehicleByPlate(true);

  //     dispatch(change('vehicle', 'Color', serializedVehicle[0]?.color));
  //     dispatch(change('vehicle', 'Model', serializedVehicle[0]?.model));
  //     dispatch(change('vehicle', 'Brand', serializedVehicle[0]?.brand));
  //     dispatch(
  //       change(
  //         'vehicle',
  //         'Year_Manufacture',
  //         vehicle?.ano_fabricacao?.toString()
  //       )
  //     );
  //     dispatch(
  //       change('vehicle', 'Year_Model', vehicle?.ano_modelo?.toString())
  //     );
  //     dispatch(change('vehicle', 'NChassi', vehicle?.chassi));
  //     dispatch(change('vehicle', 'Fuel_Type', fuelType));
  //   } catch (err) {
  //     console.log(err);
  //     return toastr.error(
  //       'OS Digital',
  //       'O veículo não foi localizado. Verifique se a placa está correta e tente novamente. Caso o problema persista, preencha o cadastro do veículo manualmente.'
  //     );
  //   } finally {
  //     setLoadingVehiclesByPlate(false);
  //   }
  // }

  async function loadVehicleByPlate() {
    setLoadingVehiclesByPlate(true);
    try {
      const { data } = await suivCompanyRepository.showVehicleInfo(
        License_Plate,
        companyId,
        true // setando onlyVehicleInfo como true, para retornar apenas info do veículo
      );

      let fipeCode = data.fipeDataCollection[0]?.fipeId.toString() || '';

      if (fipeCode === '') {
        return toastr.error(
          'OS Digital',
          'O veículo não está cadastrado na tabela FIPE (fipe.org), sendo assim, altere o campo "Tipo" para opção "Outros objetos" e realize o cadastro manualmente.'
        );
      }

      //transformar fipeCode no padrão de tabela Fipe, no mínimo 7 digitos, o que faltar adiciona de 0 na esquerda
      fipeCode = fipeCode?.toString().padStart(7, '0');
      //separar ultimo digito com -, exemplo: xxxxxx-0
      fipeCode = fipeCode?.slice(0, -1) + '-' + fipeCode.slice(-1);

      const tableFipeData = await tableFipeRepository.getByFipe(fipeCode);

      let serializedVehicle = [
        {
          codeFipe: tableFipeData?.Code_FIPE,
          model: tableFipeData?.Model,
          brand: tableFipeData?.Brand,
          type: tableFipeData?.Type,
          label: tableFipeData.Model?.toUpperCase(),
          value: tableFipeData?.Model,
        },
      ];

      setVehicles(serializedVehicle);
      setSelectedModel(serializedVehicle[0]);
      setIsVehicleByPlate(true);

      const fuelType = serializeFuelType(data.fuel);

      dispatch(change('vehicle', 'Model', serializedVehicle[0]?.model));
      dispatch(change('vehicle', 'Brand', serializedVehicle[0]?.brand));
      dispatch(change('vehicle', 'engineNumber', data?.engineNumber));
      dispatch(change('vehicle', 'Year_Manufacture', data?.yearFab.toString()));
      dispatch(change('vehicle', 'Year_Model', data?.yearModel.toString()));
      dispatch(change('vehicle', 'NChassi', data?.chassis));
      dispatch(change('vehicle', 'Fuel_Type', fuelType));
    } catch (err) {
      console.log(err);
      return toastr.error(
        'OS Digital',
        'O veículo não foi localizado. Verifique se a placa está correta e tente novamente. Caso o problema persista, preencha o cadastro do veículo manualmente.'
      );
    } finally {
      setLoadingVehiclesByPlate(false);
    }
  }

  function serializeFuelType(fuelType) {
    switch (fuelType) {
      case 'alcool':
        return 'Álcool';
      case 'gasolina':
        return 'Gasolina';
      case 'gas':
        return 'Gás';
      case 'diesel':
        return 'Diesel';
      case 'alcool / gasolina':
        return 'Gasolina/Álcool';
      default:
        return '';
    }
  }

  async function setBrandByVehicle() {
    const vehicle = vehicles.find((vehicle) => vehicle.model === Model);
    if (vehicle && vehicle?.brand)
      dispatch(change('vehicle', 'Brand', vehicle.brand));
  }

  async function loadBrandModels() {
    const res = await api.get(config.endpoint + `tabelaFipe/byBrands/${Brand}`);
    if (!res.data.status) {
      return toastr.warning(
        'Ocorreu um erro ao carregar os modelos. Por favor, tente novamente'
      );
    }

    const models = res.data.data.map((child) => ({
      model: child.Model,
      fipeCode: child.Code_FIPE,
      label: child.Model.toUpperCase(),
      value: child.Model,
      type: child.Type,
    }));

    setVehicles(
      models.sort((a, b) =>
        a.model.toUpperCase() > b.model.toUpperCase() ? 1 : -1
      )
    );
  }

  const handleOpenModal = async (title, style) => {
    setModalTitle(title);
    setStyleModal({
      ...style,
      marginLeft: '5px',
      marginTop: 0,
    });
    setOpenModal(true);
  };

  const handleModal = () => {
    setOpenModal(false);

    if (!!createdManufacturer)
      dispatch(change('vehicle', 'Brand', createdManufacturer.Description));
  };

  const renderForm = () => {
    switch (modalTitle) {
      case 'Nova Marca':
        return (
          <ModalNewBrand
            Calling="Cadastro Produto"
            modal={true}
            handleModal={handleModal}
            dispatch={dispatch}
            readOnly={true}
            onlyManufacturers
            createdManufacturer={setCreatedManufacturer}
          />
        );
    }
  };

  const handleModelChange = (model) => {
    if (Type === '1' || Type === '2' || Type === '3') {
      setIsVehicleByPlate(false);
      setSelectedModel(model);
      dispatch(change('vehicle', 'Model', model.value));
    } else {
      dispatch(change('vehicle', 'Model', model));
    }
  };

  const loadTractorExcavatorBrands = async () => {
    try {
      const tractorExcavatorBrands =
        await tableFipeRepository.getOnlyBrandsByType({
          type: 'Trator/Escavadeira',
        });
      setTractorExcavatorBrands(
        tractorExcavatorBrands.sort((a, b) =>
          a.Brand.toUpperCase() > b.Brand.toUpperCase() ? 1 : -1
        )
      );
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar as marcas');
    }
  };

  const loadHarvesterBrands = async () => {
    try {
      const harvesterBrands = await tableFipeRepository.getOnlyBrandsByType({
        type: 'Colheitadeira',
      });
      setHarvesterBrands(
        harvesterBrands.sort((a, b) =>
          a.Brand.toUpperCase() > b.Brand.toUpperCase() ? 1 : -1
        )
      );
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar as marcas');
    }
  };

  const loadVehicleType = async () => {
    try {
      const vehicleTypes =
        await vehiclesRepository.getVehicleTypeByCompanySegments(companyId);
      setVehicleTypes(vehicleTypes);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tipos de veículo');
    }
  };

  const loadBicycleBrakeType = async () => {
    try {
      const bicycleBrakeTypes = await vehiclesRepository.getBicycleBrakeType();
      setBicycleBrakeTypes(bicycleBrakeTypes);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tipos de freio');
    }
  };

  const loadBicycleSuspensionType = async () => {
    try {
      const bicycleSuspensionTypes =
        await vehiclesRepository.getBicycleSuspensionType();
      setBicycleSuspensionTypes(bicycleSuspensionTypes);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tipos de suspensão');
    }
  };

  const loadBicycleFrontGears = async () => {
    try {
      const bicycleFrontGears = await vehiclesRepository.getBicycleFrontGears();
      setBicycleFrontGears(bicycleFrontGears);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tipos de marcha');
    }
  };

  const loadBicycleRearGears = async () => {
    try {
      const bicycleRearGears = await vehiclesRepository.getBicycleRearGears();
      setBicycleRearGears(bicycleRearGears);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tipos de marcha');
    }
  };

  const getBicycleRimSizes = async () => {
    try {
      const bicycleRimSizes = await vehiclesRepository.getBicycleRimSizes();
      setBicycleRimSizes(bicycleRimSizes);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tamanhos de aro');
    }
  };

  const getBicycleFrameSizes = async () => {
    try {
      const bicycleFrameSizes = await vehiclesRepository.getBicycleFrameSizes();
      setBicycleFrameSizes(bicycleFrameSizes);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tamanhos de quadro');
    }
  };

  const getBicycleModality = async () => {
    try {
      const bicycleModalities = await vehiclesRepository.getBicycleModality();
      setBicycleModalities(bicycleModalities);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar as modalidades');
    }
  };

  const getBicycleRearDerailleur = async () => {
    try {
      const bicycleRearDerailleurs =
        await vehiclesRepository.getBicycleRearDerailleur();
      setBicycleRearDerailleurs(bicycleRearDerailleurs);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os tipos de câmbio traseiro');
    }
  };

  const getBicycleFrameMaterial = async () => {
    try {
      const bicycleFrameMaterials =
        await vehiclesRepository.getBicycleFrameMaterial();
      setBicycleFrameMaterials(bicycleFrameMaterials);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar os tipos de material do quadro'
      );
    }
  };

  const getBicycleModelsByBrand = async () => {
    try {
      const bicycleModels = await vehiclesRepository.getBicycleModelsByBrand(
        Brand,
        companyId
      );
      setBicycleModels(bicycleModels);
    } catch (err) {
      console.log(err);
      toastr.error('Ocorreu um erro ao carregar os modelos de bicicleta');
    }
  };

  function getDefaultVehicleTypeBySegment() {
    switch (true) {
      case companySegments.includes(1) ||
        companySegments.includes(2) ||
        companySegments.includes(3) ||
        companySegments.includes(7):
        return '1';
      case companySegments.includes(8):
        return '4';
      default:
        return '5';
    }
  }

  useEffect(() => {
    dispatch(
      initialize('vehicle', {
        Brand: 0,
        hasSale: false,
        id: 0,
        Type: getDefaultVehicleTypeBySegment(),
        License_Plate: '',
        Model: 0,
      })
    );
    loadBicycleBrakeType();
    loadBicycleSuspensionType();
    loadBicycleFrontGears();
    loadBicycleRearGears();
    getBicycleRimSizes();
    getBicycleFrameSizes();
    getBicycleModality();
    getBicycleRearDerailleur();
    getBicycleFrameMaterial();
    loadVehicleType();
    loadBrands();
  }, []);

  useEffect(() => {
    if (!Editing) {
      dispatch(change('vehicle', 'Brand', ''));
      setSelectedModel('');
    }

    if (Type === '4') {
      loadBicycleManufacturers();
    }

    if (Type === '2') {
      loadTractorExcavatorBrands();
    }

    if (Type === '3') {
      loadHarvesterBrands();
    }
  }, [Type]);

  useEffect(() => {
    if (!Model) return;
    if (Type === '1') {
      setBrandByVehicle();
    }
  }, [Model, vehiclesOptions]);

  useEffect(() => {
    if (Type === '4') {
      if (Brand) {
        getBicycleModelsByBrand(Brand);
      }
    }
  }, [Brand]);

  return (
    <div className="form-new-vehicle__page">
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '17px',
            marginBottom: '10px',
          }}
        >
          <span>
            Tipo: <span style={{ color: 'red' }}>*</span>
          </span>
          <Field
            name="Type"
            required
            disabled={hasSale || Editing || isViewingData}
            as="select"
            component={RenderField}
            style={{ width: '212px' }}
          >
            {vehicleTypes?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.description}
              </option>
            ))}
          </Field>
        </div>
        <div
          style={{
            width: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <span
            style={{
              color: '#D9534F',
              fontSize: '13px',
            }}
          >
            * Campos Obrigatórios
          </span>
        </div>
      </div>

      {Type === '1' && (
        <CarMotobikeTruckForm
          loadingVehiclesByPlate={loadingVehiclesByPlate}
          licensePlateMask={licensePlateMask}
          hasSale={hasSale}
          loadVehicleByPlate={loadVehicleByPlate}
          License_Plate={License_Plate}
          RenderField={RenderField}
          brands={brands}
          isVehicleByPlate={isVehicleByPlate}
          Type={Type}
          Editing={Editing}
          vehiclesOptions={vehiclesOptions}
          defaultModelIndex={defaultModelIndex}
          selectedModel={selectedModel}
          modelInput={modelInput}
          loadingBrandModels={loadingBrandModels}
          handleModelChange={handleModelChange}
          isViewingData={isViewingData}
        />
      )}

      {Type === '2' && (
        <TractorExcavatorForm
          RenderField={RenderField}
          isVehicleByPlate={isVehicleByPlate}
          Type={Type}
          brands={tractorExcavatorBrands}
          Editing={Editing}
          vehiclesOptions={vehiclesOptions}
          defaultModelIndex={defaultModelIndex}
          selectedModel={selectedModel}
          modelInput={modelInput}
          loadingBrandModels={loadingBrandModels}
          handleModelChange={handleModelChange}
          isViewingData={isViewingData}
        />
      )}

      {Type === '3' && (
        <HarvesterForm
          RenderField={RenderField}
          isVehicleByPlate={isVehicleByPlate}
          Type={Type}
          brands={harvesterBrands}
          Editing={Editing}
          vehiclesOptions={vehiclesOptions}
          defaultModelIndex={defaultModelIndex}
          selectedModel={selectedModel}
          modelInput={modelInput}
          loadingBrandModels={loadingBrandModels}
          handleModelChange={handleModelChange}
          isViewingData={isViewingData}
        />
      )}

      {Type === '4' && (
        <BikeForm
          manufacturers={bicycleManufacturer}
          loadBicycleManufacturers={loadBicycleManufacturers}
          getBicycleModelsByBrand={getBicycleModelsByBrand}
          handleModelChange={handleModelChange}
          RenderField={RenderField}
          bicycleRearDerailleurId={bicycleRearDerailleurId}
          bicycleBrakeTypes={bicycleBrakeTypes}
          bicycleSuspensionTypes={bicycleSuspensionTypes}
          bicycleFrontGears={bicycleFrontGears}
          bicycleRearGears={bicycleRearGears}
          bicycleRimSizes={bicycleRimSizes}
          bicycleFrameSizes={bicycleFrameSizes}
          bicycleModalities={bicycleModalities}
          bicycleRearDerailleurs={bicycleRearDerailleurs}
          bicycleFrameMaterials={bicycleFrameMaterials}
          bicycleModels={bicycleModels}
          dispatch={dispatch}
          change={change}
          isViewingData={isViewingData}
        />
      )}

      {Type === '5' && (
        <OthersVehiclesForm
          RenderField={RenderField}
          handleModelChange={handleModelChange}
          handleOpenModal={handleOpenModal}
          dispatch={dispatch}
          change={change}
          isViewingData={isViewingData}
          Brand={Brand}
        />
      )}

      <Modal
        dialogClassName="modal-formProduto"
        show={openModal}
        onHide={() => setOpenModal(false)}
        animation={true}
      >
        {modalTitle === 'Nova Marca' ? (
          <>{renderForm()}</>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <strong>{modalTitle}</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-formProduto-body" style={styleModal}>
              {renderForm()}
              <span id="required-field-label">* Campos Obrigatórios</span>
            </Modal.Body>
          </>
        )}
      </Modal>
    </div>
  );
};

export default MainData;
