import React from 'react';
import { format, subDays, max, parseISO } from 'date-fns';
import { useBranding } from 'hooks/useBranding';
import { toastr } from 'react-redux-toastr';
import { OnboardingRepository } from 'v2/repositories/OnboardingRepository';
import {
  faClipboard,
  faBarsProgress,
  faCheckDouble,
} from '@fortawesome/free-solid-svg-icons';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { formatOnboardingTableData } from '../utils';
export const useOnboarding = () => {
  const { loadPlansByBranding } = useBranding();
  const { setIsLoading, isLoading } = useLoading();
  const { formatOnboardingDataSheet } = formatOnboardingTableData;

  const getPlans = async (setValue) => {
    const plans = await loadPlansByBranding();
    setValue('plans', plans);
    return plans;
  };

  const getOptionsPlans = (plans, brandingArray) => {
    const serializedBrandingPlans = plans
      ?.filter((brandingPlan) =>
        brandingArray?.length
          ? brandingArray.some((branding) => brandingPlan.id === branding.value)
          : true
      )
      .reduce((prev, curr) => [...prev, ...curr.Plans], [])
      .map((option) => ({
        value: option.id,
        label: option.description,
      }));

    return serializedBrandingPlans;
  };

  const dateTypes = [
    { label: 'Cadastro da Company', value: 'register' },
    { label: '1º Assinatura', value: 'firstSignature' },
    { label: 'Início do Onboarding', value: 'startOnboarding' },
    { label: 'Finalização do Onboarding', value: 'finishOnboarding' },
  ];
  const productOptions = [
    { label: 'Serviços', value: 'servicos' },
    { label: 'Produtos', value: 'produtos' },
    { label: 'Todos', value: 'todos' },
  ];

  const onboardingStatusOptions = [
    { label: 'Todos', value: '*' },
    { label: 'Não Realizado', value: 'notstarted' },
    { label: 'Em Andamento', value: 'doing' },
    { label: 'Finalizado', value: 'finished' },
  ];
  const engagementOptions = [
    { label: 'Todos', value: '*' },
    { label: 'Engajado', value: 'Engajado' },
    { label: 'Neutro', value: 'Neutro' },
    { label: 'Desengajado', value: 'Desengajado' },
  ];
  const minDate = '2024-12-01';
  const formatMinDate = parseISO('2024-12-01');

  const today = new Date();
  const calculatedStartDate = subDays(today, 31);
  const initialStartDate = format(
    max([calculatedStartDate, formatMinDate]),
    'yyyy-MM-dd'
  );

  const initialEndDate = format(today, 'yyyy-MM-dd');

  const formatData = (dataArray) => {
    return dataArray.some((item) => item.value === '*')
      ? ''
      : dataArray.map((item) => item.value).join(',');
  };

  const getOnboardingData = async (setValue, getValues, type) => {
    const {
      initialDate,
      finalDate,
      dateType,
      selectedStatus,
      selectedPlans,
      selectedBranding,
      selectedEngagement,
      query,
      controlTable,
    } = getValues();

    if (new Date(finalDate) < new Date(initialDate)) {
      toastr.warning('A data final deve ser maior que a data inicial.');
      return;
    }

    if (
      new Date(finalDate) < new Date('2024-12-01') ||
      new Date(initialDate) < new Date('2024-12-01')
    ) {
      toastr.warning(
        'Não foi possível aplicar a data anterior a 01/12/2024',
        'Devido a liberação da função de onboarding e quantidade de clientes, a data mínima não pode ser inferior a 01/12/2024.'
      );
      return;
    }

    const data = {
      type,
      dateType: dateType.value,
      query,
      page: controlTable.page,
      limit: controlTable.pageLimit,
      initialDate,
      finalDate,
      status: formatData(selectedStatus),
      plans: formatData(selectedPlans),
      branding: formatData(selectedBranding),
      satisfaction: formatData(selectedEngagement),
    };

    try {
      setIsLoading(true);

      const onboardingData = await OnboardingRepository.onboardingReport(data);

      if (onboardingData.status === 204) {
        toastr.success('Nenhuma informação encontrada.');

        setValue('spreadsheet', []);
        setValue('onboardingDataTable', []);
        return;
      }

      if (type === 'spreadsheet') {
        const format = formatOnboardingDataSheet(onboardingData.data.report);
        return setValue('spreadsheet', format);
      }

      setValue('onboardingDataTable', onboardingData.data);
      setValue(
        'controlTable.totalPages',
        Math.ceil(onboardingData.data.count / controlTable.pageLimit)
      );
    } catch (err) {
      toastr.error('Ocorreu um erro ao buscar os dados.');
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  const infoCardsData = [
    {
      icon: faClipboard,
      primaryText: 0,
      secondaryText: (
        <>
          Total <strong>Não Iniciados</strong>
        </>
      ),
      containerStyle: { background: '#2C3F50' },
      iconStyle: { background: '#142A3D' },

      status: 'notstarted',
    },
    {
      icon: faBarsProgress,
      primaryText: 0,
      containerStyle: { background: '#D9534F' },
      iconStyle: { background: '#D43F3A' },
      secondaryText: (
        <>
          Total <strong>Em Andamento</strong>
        </>
      ),

      status: 'doing',
    },
    {
      icon: faCheckDouble,
      primaryText: 0,
      secondaryText: (
        <>
          Total <strong>Finalizado</strong>
        </>
      ),
      containerStyle: { background: '#5CB85C' },
      iconStyle: { background: '#4CAE4C' },
      status: 'finished',
    },
    {
      emoji: '😄',
      primaryText: 0,
      secondaryText: (
        <>
          Total <strong>Engajado</strong>
        </>
      ),
      containerStyle: { background: '#92D36E' },
      iconStyle: { background: '#4CAE4C' },
      status: 'Engajado',
    },
    {
      emoji: '😐',
      primaryText: 0,
      secondaryText: (
        <>
          Total <strong>Neutro</strong>
        </>
      ),
      containerStyle: { background: '#FFB253' },
      iconStyle: { background: '#FFA834' },
      status: 'Neutro',
    },
    {
      emoji: '☹️',
      primaryText: 0,
      secondaryText: (
        <>
          Total <strong>Desengajado</strong>
        </>
      ),
      containerStyle: { background: '#FF5D55' },
      iconStyle: { background: '#D9534F' },
      status: 'Desengajado',
    },
  ];

  const populateInfoCardsData = (onboardingData) => {
    return infoCardsData.map((card) => {
      // Encontra o item correspondente no header
      const matchingStatus = onboardingData?.header?.find(
        (item) => item.status === card.status
      );
      return {
        ...card,
        primaryText: matchingStatus ? matchingStatus.quantity : 0,
      };
    });
  };

  return {
    dateTypes,
    productOptions,
    onboardingStatusOptions,
    engagementOptions,
    minDate,
    initialStartDate,
    initialEndDate,
    getPlans,
    getOnboardingData,
    getOptionsPlans,
    populateInfoCardsData,
    isLoading,
  };
};
