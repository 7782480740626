import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';

import { useAuth } from '../../../contexts/auth';
import useFilters from '../../../hooks/useFilters';
import { currency, encrypt } from '../../components/ToNormalize/ToNormalize';
import constants from '../../../utils/constants';
import { getDateOnlyFromDate } from '../../../utils/dateHelpers';
import NFSesRepository from '../../../repositories/NFSes';
import settingsNFSeRepository from '../../../repositories/SettingsNFSe';

import { Modal } from 'react-bootstrap';

import Card from 'components/Card/Card.jsx';
import UnavailableFeatureModal from '../../components/UnavailableFeatureModal';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import NFSeActions from './NFSeActions';
import { SERVICE_INVOICE_STATUS_OPTIONS } from 'v2/utils/constants';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { SelectMulti } from 'v2/components/Select/Multi';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';

const HomeServiceInvoices = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [blockTax, setBlockTax] = useState(false);
  const [invoiceEnvironment, setInvoiceEnvironment] = useState('');
  const [isNFSeSet, setIsNFSeSet] = useState(true);
  const [nfseErrorDescription, setNfseErrorDescription] = useState('');
  const [isNFSeIssueErrorModalOpen, setIsNFSeIssueErrorModalOpen] =
    useState(false);

  const { companyId } = useAuth();

  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);
  const [type, setType] = useState('');

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [isInitialFiltersDefined, setIsInitialFiltersDefined] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);

  function handleSetInitialFilters() {
    const params = new URLSearchParams(window.location.search);
    const statusParam = params.get('status');

    const initialDateParam = params.get('initialDate');
    const finalDateParam = params.get('finalDate');

    if (statusParam) {
      const status = statusParam.split(',');
      const statusToSelect = SERVICE_INVOICE_STATUS_OPTIONS.filter((option) =>
        status.includes(option.value)
      );

      setSelectedStatus(statusToSelect);
    }

    if (initialDateParam) {
      setInitialDate(initialDateParam);
    }

    if (finalDateParam) {
      setFinalDate(finalDateParam);
    }

    setIsInitialFiltersDefined(true);
  }

  useEffect(() => {
    if (!isInitialFiltersDefined) {
      handleSetInitialFilters();
    }
  }, [isInitialFiltersDefined]);

  useEffect(() => {
    if (!companyId) {
      setNoCompanyModal(true);
    } else {
      loadServiceInvoices();
      checkNFSeSettings();
    }
  }, []);
  useEffect(() => {
    if (!!companyId) {
      loadServiceInvoices();
    }
  }, [
    queryDebounced,
    selectedStatus,
    invoiceEnvironment,
    initialDate,
    finalDate,
    currentPage,
  ]);

  const checkNFSeSettings = async () => {
    try {
      const settingsNFSe = await settingsNFSeRepository.search({
        companyId: companyId,
      });

      setIsNFSeSet(settingsNFSe.isModuleActiveinAPIFiscal);
    } catch (err) {
      console.log(err);
      setIsNFSeSet(false);
    }
  };

  const loadServiceInvoices = async () => {
    setLoading(true);
    const statusString = selectedStatus
      .filter((status) => status.value !== '*')
      .map((status) => `'${status.value.trim()}'`)
      .join(',');

    try {
      const NFSes = await NFSesRepository.getAllByCompany(companyId, {
        page: currentPage + 1,
        limit: pageLimit,
        query,
        status: statusString,
        invoiceEnvironment,
        initialDate,
        finalDate,
      });
      setInvoices(NFSes.nfses);
      setTotalPages(Math.ceil(NFSes.pagination.total / pageLimit));
    } catch (err) {
      console.log(err);
      return toastr.warning(
        'Ocorreu um erro ao carregar as notas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  function filterByEnvironment(invoice) {
    if (!invoiceEnvironment) return true;

    return invoice['environment'] === invoiceEnvironment;
  }

  const renderBtnStatus = (value) => {
    let color = '';
    switch (value) {
      case 'Aberta':
        color = '#ffc40d';
        break;
      case 'Aberta c/ Erro':
        color = '#ffc40d';
      case 'Processando':
        color = '#5bc0de';
        break;
      case 'Emitida':
        color = '#46a546';
        break;
      case 'Cancelada':
        color = '#bd362f';
        break;
      default:
        break;
    }

    return (
      <Button
        id="botaoReactTable"
        style={{
          backgroundColor: color,
        }}
      >
        {value}
      </Button>
    );
  };

  async function handleEditInvoice(NFSe) {
    const hash = await encrypt(String(NFSe.id), '@OS-dig:NfseId');
    history.push(`${constants.ROUTES.NFSE}?id=${hash}`);
  }

  function handleChangeNFSe(NFSe, index) {
    const newInvoices = [...invoices];
    newInvoices[index] = NFSe;
    setInvoices(newInvoices);
  }

  function handleRemoveInvoice(index) {
    setInvoices(invoices.filter((_, i) => i !== index));
  }

  function handleNewInvoiceButton() {
    if (!isNFSeSet)
      return toastr.error('Realize a liberação da NFS-e e tente novamente');

    if (!!companyId) {
      history.push(constants.ROUTES.NFSE);
    } else {
      setBlockTax(true);
    }
  }

  return (
    <Card
      content={
        <div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            id="vendaHeader"
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={handleNewInvoiceButton}
                style={{ fontSize: '12px' }}
              >
                + Nova Nota Fiscal
              </button>
            </div>

            <div>
              <SearchInput
                placeholder="Pesquisa por Venda, RPS, Nota ou Cliente"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>

            <InputContainer variant="column">
              <InputLabel
                style={{
                  fontSize: '12px',
                  margin: 0,
                  padding: 0,
                  fontWeight: 'bold',
                  position: 'relative',
                  top: '5px',
                }}
              >
                Status:
              </InputLabel>
              <SelectMulti
                width={130}
                height={35}
                options={SERVICE_INVOICE_STATUS_OPTIONS}
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e)}
                hasAllOption
                selectAllByDefault
                fontSize={'12px'}
                borderColor="#E3E3E3"
              />
            </InputContainer>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={{ fontSize: '12px' }}>
                <strong>Ambiente: </strong>
              </span>
              <select
                className="form-control foco-input"
                value={invoiceEnvironment}
                onChange={(e) => setInvoiceEnvironment(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="Produção">Produção</option>
                <option value="Homologação">Homologação</option>
              </select>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              marginBottom: '10px',
              padding: 0,
              gap: '10px',
            }}
          >
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Inicial:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataInicial"
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>

            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Data Final:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="dataFinal"
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
                style={{ fontSize: '12px' }}
              />
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={invoices}
              columns={[
                {
                  Header: 'Data',
                  accessor: 'date',
                  width: 100,
                  Cell: (props) =>
                    !props.value
                      ? ''
                      : format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy'),
                },
                {
                  Header: 'NFS',
                  accessor: 'NFS',
                  width: 80,
                  Cell: (props) => (
                    <a onClick={() => handleEditInvoice(props.original)}>
                      <button className="btn-link">{props.value}</button>
                    </a>
                  ),
                },
                {
                  Header: 'RPS',
                  accessor: 'code',
                  width: 80,
                },
                {
                  Header: 'Série',
                  accessor: 'serie',
                  width: 80,
                },
                {
                  Header: 'Venda',
                  accessor: 'saleCode',
                  width: 80,
                },
                {
                  Header: 'Cliente',
                  accessor: 'customerName',
                  width: 300,
                  headerClassName: 'text-center',
                  className: 'texto',
                },
                {
                  Header: 'Valor',
                  accessor: 'servicesTotal',
                  Cell: (props) => <>{currency(props.value)}</>,
                },
                {
                  Header: 'Ambiente',
                  accessor: 'environment',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          props.value === 'Homologação' ? '#1c0c4f' : 'b70f0a',
                      }}
                    >
                      {props.value}
                    </Button>
                  ),
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Cell: (props) => renderBtnStatus(props.value),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  filterable: false,
                  Cell: (props) => {
                    return !companyId ? (
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="false"
                        style={{ cursor: 'pointer' }}
                        onClick={() => setNoCompanyModal(true)}
                      />
                    ) : (
                      <NFSeActions
                        NFSe={props.original}
                        onEdit={handleEditInvoice}
                        onChange={(NFSe) => handleChangeNFSe(NFSe, props.index)}
                        onDelete={() => handleRemoveInvoice(props.index)}
                        setNfseErrorDescription={setNfseErrorDescription}
                        setIsNFSeIssueErrorModalOpen={
                          setIsNFSeIssueErrorModalOpen
                        }
                      />
                    );
                  },
                },
              ]}
              defaultPageSize={10}
              loading={loading}
              showPagination={true}
              page={currentPage}
              onPageChange={(value) => setCurrentPage(value)}
              pages={totalPages}
              onPageSizeChange={(size) => setPageLimit(size)}
              sortable={true}
              manual
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'createdAt',
                  desc: true,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhuma nota fiscal encontrada"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>

          <UnavailableFeatureModal
            show={blockTax}
            onHide={() => setBlockTax(false)}
          />

          <Modal
            show={isNFSeIssueErrorModalOpen}
            onHide={() => {
              setNfseErrorDescription('');
              setIsNFSeIssueErrorModalOpen(false);
            }}
            dialogClassName="modal-60w"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <strong>{'Erro na Emissão'}</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <pre
                style={{
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-line',
                }}
              >
                {`${nfseErrorDescription}`}
              </pre>
            </Modal.Body>
            <Modal.Footer>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  className="button-h35 button-red"
                  name="Fechar"
                  onClick={(e) => {
                    setNfseErrorDescription('');
                    setIsNFSeIssueErrorModalOpen(false);
                  }}
                  fill
                >
                  Fechar
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      }
    />
  );
};

export default withRouter(HomeServiceInvoices);
