import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import RenderField from 'components/RenderField';
import { currencyMask } from '../../../../utils/masks';

import providerRepository from '../../../../repositories/Providers';
import cashierBankRepository from '../../../../repositories/CashierBank';
import accountPlansRepository from '../../../../repositories/AccountPlans';

import NewProviderHyperlinkWithFieldLabel from '../../../../client/components/NewProviderHyperlinkWithFieldLabel';

import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import { useSelector, useDispatch } from 'react-redux';
import {
  cnpjMask,
  onlyNumbers,
} from 'client/components/ToNormalize/ToNormalize';
import { SelectFormOfPayment } from 'v2/client/components/SelectFormOfPayment';

const daysBetweenInstallmentsNumbers = new Array(30).fill();

const MainData = () => {
  const [providers, setProviders] = useState([]);
  const [providerNameSuggestions, setProviderNameSuggestions] = useState([]);
  const [cashierBanks, setCashierBanks] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);
  const [paymentValue, setPaymentValue] = useState(null);
  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const { providerId, searchProvider } = useSelector(
    (state) => state.form.generateCurrentBill.values
  );

  useEffect(() => {
    if (!!companyId) loadCombos();
  }, [companyId]);

  useEffect(() => {
    if (!!searchProvider) handleSearchProvider();
  }, [searchProvider]);

  function handleSearchProvider() {
    let providerCpfCnpj = searchProvider.split('|')[0];
    let providerName = searchProvider.split('|')[1];

    if (!!providerCpfCnpj) {
      providerCpfCnpj = onlyNumbers(providerCpfCnpj.trim());

      if (!!providerCpfCnpj) {
        getProviderFromCpfCnpj(providerCpfCnpj);
      } else {
        if (!!providerName) {
          getProviderFromName(providerName.trim());
        }
      }
    }
  }

  async function getAccountPlans() {
    try {
      const { data } = await accountPlansRepository.index({
        companyId,
        type: 'Débito',
        isActive: true,
      });

      setAccountPlans(data);
    } catch (err) {
      toastr.warning('Erro ao carregar Planos de Conta');
    }
  }

  function getProviderFromCpfCnpj(providerCpfCnpj) {
    const provider = providers.find(
      (provider) => provider.cpfCnpj === providerCpfCnpj
    );

    if (!!provider) {
      dispatch([
        change('generateCurrentBill', 'providerId', provider.id),
        change(
          'generateCurrentBill',
          'searchProviderName',
          provider.companyName
        ),
      ]);
    }
  }

  function getProviderFromName(providerName) {
    const provider = providers.find(
      (provider) => provider.companyName === providerName
    );

    if (!!provider) {
      dispatch([
        change('generateCurrentBill', 'providerId', provider.id),
        change(
          'generateCurrentBill',
          'searchProviderName',
          provider.companyName
        ),
      ]);
    }
  }

  function handleRemoveClient() {
    dispatch([
      change('generateCurrentBill', 'providerId', null),
      change('generateCurrentBill', 'searchProviderName', null),
      change('generateCurrentBill', 'searchProvider', ''),
    ]);
  }

  function loadCombos() {
    loadCashierBanks();
    loadActiveProviders();
    getAccountPlans();
  }

  async function loadCashierBanks() {
    try {
      const data =
        await cashierBankRepository.getAllByCompanyActiveSortedByDescription(
          companyId
        );
      setCashierBanks(data);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar os caixas/bancos. Por favor, tente novamente'
      );
    }
  }

  async function loadActiveProviders() {
    try {
      const data = await providerRepository.getAllActiveByCompany(companyId);

      const providerNameSuggestions = data.map((provider) => {
        return `${cnpjMask(provider.cpfCnpj)} | ${provider.companyName} | ${
          provider.tradingName
        }`;
      });

      setProviders(data);
      setProviderNameSuggestions(providerNameSuggestions);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao buscar os fornecedores. Por favor, tente novamente'
      );
    }
  }

  async function handleSelectCreatedProvider(provider) {
    await loadActiveProviders();
    dispatch(
      change(
        'generateCurrentBill',
        'searchProvider',
        `${cnpjMask(provider.cpfCnpj)} | ${provider.companyName} | ${
          provider.tradingName
        }`
      )
    );
  }

  return (
    <>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ paddingLeft: '0px', paddingRight: '0px' }}
      >
        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="initialDate"
            component={RenderField}
            label="Data Inicial:"
            type="date"
            required
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="finalDate"
            component={RenderField}
            label="Data Final:"
            type="date"
            required
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="daysBetweenInstallments"
            component={RenderField}
            label="Dias Entre Parcelas:"
            required
            as="select"
          >
            {daysBetweenInstallmentsNumbers.map((item, index) => {
              const value = index + 1;
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </Field>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="valueByInstallment"
            component={RenderField}
            label="Valor de cada parcela:"
            required
            {...currencyMask}
          />
        </Col>
      </Col>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ paddingLeft: '0px', paddingRight: '0px' }}
      >
        <Col xs={12} sm={12} md={6} lg={7}>
          <NewProviderHyperlinkWithFieldLabel
            label="Fornecedor"
            fieldId="searchProvider"
            handleSelectCreatedProvider={handleSelectCreatedProvider}
            required
          />
          {!providerId ? (
            <>
              <Field
                name="searchProvider"
                id="searchProvider"
                component={RenderField}
                placeholder="Pesquisa por CNPJ/CPF, Razão Social ou Nome Fantasia"
                required
                list="providerSuggestions"
              />
              <datalist id="providerSuggestions">
                {providerNameSuggestions.map((provider) => (
                  <option key={provider} value={provider}></option>
                ))}
              </datalist>
            </>
          ) : (
            <Field
              id="searchProvider"
              name="searchProviderName"
              className="selected-provider-field"
              component={RenderField}
              onClick={handleRemoveClient}
              required
            />
          )}
        </Col>
        <Col xs={12} sm={12} md={3} lg={2}>
          <Field
            label="Caixa/Banco:"
            name="cashierBankId"
            component={RenderField}
            as="select"
            required
          >
            <option value="">Selecione</option>
            {cashierBanks.map(
              ({ id, description, CashierBankType: { initials } }) => (
                <option key={id} value={id}>
                  {initials} - {description}
                </option>
              )
            )}
          </Field>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <label>
            Formas de Pgto.:<span style={{ color: 'red' }}>*</span>
          </label>

          <SelectFormOfPayment
            value={paymentValue || ''}
            onChange={(item) => {
              setPaymentValue(item);
              dispatch(
                change(
                  'generateCurrentBill',
                  'formOfPaymentId',
                  item && item.value ? item.value.id : ''
                )
              );
            }}
            width="100%"
          />
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} style={{ padding: 0 }}>
        <Col xs={5} sm={5} md={5} lg={5}>
          <Field
            name="accountPlanId"
            label="Plano de Contas:"
            required
            component={RenderField}
            maxLength="100"
            as="select"
          >
            <option value="">Selecione</option>
            {accountPlans.map((acc) => (
              <option key={acc.id} value={acc.id}>
                {acc.description}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={7} sm={7} md={7} lg={7}>
          <Field
            name="observations"
            label="Observações:"
            component={RenderField}
            maxLength="100"
            as="textarea"
          />
        </Col>
      </Col>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '0px',
        }}
      >
        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="installmentQuantity"
            label="Quantidade de parcelas:"
            component={RenderField}
            disabled
          />
        </Col>
        <Col xs={12} sm={12} md={3} lg={3}>
          <Field
            name="total"
            component={RenderField}
            label="Total R$ do Pedido:"
            disabled
            {...currencyMask}
          />
        </Col>
      </Col>
    </>
  );
};

export default MainData;
