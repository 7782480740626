import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import RenderField from '../../../../../components/RenderField';
import AdvertisingImage from './AdvertisingImage';
import { toastr } from 'react-redux-toastr';
import { SelectBranding } from 'v2/components/Select';
import { SelectMulti } from 'v2/components/Select/Multi';

const MainData = ({
  type,
  setType,
  handleBrandingChange,
  branding,
  isActive,
  productOptions,
  selectedProducts,
  setSelectedProducts,
  showAllOption,
}) => {
  function handleTypeChange(value) {
    if (isActive) {
      return toastr.warning(
        'A propaganda está ativa e a mudança do tipo poderá impactar na publicidade ativa. Desative a propaganda antes de editar.'
      );
    }
    setType(value);
  }

  useEffect(() => {
    if (type === 'EXCLUSIVE') {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(productOptions);
    }
  }, [type]);

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          justifyContent: 'flex-start',
        }}
      >
        <fieldset>
          <span className="fontSize-14">
            Tipo:<span style={{ color: 'red' }}>*</span>
          </span>
          <select
            id="type"
            value={type}
            onChange={(e) => handleTypeChange(e.target.value)}
            className="form-control foco-input"
          >
            <option value="GLOBAL">Global</option>
            <option value="EXCLUSIVE">Exclusivo</option>
          </select>
        </fieldset>
        <fieldset>
          <span className="fontSize-14">
            Produto:<span style={{ color: 'red' }}>*</span>
          </span>
          {type === 'EXCLUSIVE' ? (
            <SelectBranding
              value={branding}
              onChange={handleBrandingChange}
              hideLabel
              height={35}
              showAllOption={showAllOption}
            />
          ) : (
            <SelectMulti
              width={130}
              height={35}
              options={productOptions}
              value={selectedProducts}
              onChange={(e) => setSelectedProducts(e)}
              hasAllOption
            />
          )}
        </fieldset>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          justifyContent: 'flex-start',
          marginTop: '10px',
        }}
      >
        <fieldset>
          <Field
            label="Descrição:"
            name="description"
            component={RenderField}
            fontWeight="normal"
            fontSize="14px"
            required
            style={{ width: '300px' }}
          />
        </fieldset>
        <fieldset>
          <Field
            label="Link de Redirecionamento:"
            name="redirectUrl"
            fontWeight="normal"
            fontSize="14px"
            component={RenderField}
            style={{ width: '500px' }}
          />
        </fieldset>
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'flex-start',
          marginTop: '10px',
        }}
      >
        <span className="fontSize-14">
          Imagem:<span style={{ color: 'red' }}>*</span>
          <span className="fontSize-14"> (Tamanho Max.: 540x142px)</span>
        </span>
        <AdvertisingImage />
      </div>
    </>
  );
};

export default MainData;
