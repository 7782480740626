import styled from 'styled-components';

export const InputTextWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '40px')};
  display: flex;
  flex-direction: column;
`;

export const InputTextStyled = styled.input`
  width: 100%;
  height: 100%;
  font-weight: ${(props) => props.fontWeight};
  border: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: ${(props) => props.theme.radii.xs};
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.colors.branding1000} !important;
  }
`;
