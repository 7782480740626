import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { CLIENT_PATH_ROUTES } from 'v2/constants/routes';

export const getBreadcrumbData = (isEditing) => {
  return {
    data: ['Início', 'Relatório', 'Onboarding'],
    path: [
      '/client/home',
      null,
      '/client/' + CLIENT_PATH_ROUTES.FINANCE_CALENDAR.BASE,
    ],
  };
};

const emojis = [
  {
    description: 'Desengajado',
    icon: '☹️',
  },
  {
    description: 'Neutro',
    icon: '😐',
  },
  {
    description: 'Engajado',
    icon: '😄',
  },
];

const statusMap = {
  finished: {
    label: 'Finalizado',
    color: '#5CB85C',
  },
  doing: {
    label: 'Em andamento',
    color: '#D9534F',
  },
  notstarted: {
    label: 'Não iniciado',
    color: '#2C3F50',
  },
  null: {
    label: 'Não iniciado',
    color: '#2C3F50',
  },
  default: {
    label: '-',
  },
};

const getStatusDetails = (status, satisfaction) => {
  const emoji = emojis.find((e) => e.description === satisfaction)?.icon || '';
  const { label: statusLabel, color: statusColor } =
    statusMap[status] || statusMap.default;

  return {
    statusLabel,
    statusColor,
    statusEmoji: emoji,
  };
};

const validateData = (value) => value || '-';

const translateStatus = (status) => statusMap[status]?.label || '-';

const formatOnboardingDataSheet = (data) => {
  return data.map((item) => ({
    registerDate: item.registerDate
      ? format(new Date(item.registerDate), 'dd/MM/yyyy')
      : '-',
    firstSignatureDate: item.firstSignatureDate
      ? format(new Date(item.firstSignatureDate), 'dd/MM/yyyy')
      : '-',
    status: translateStatus(item.status),
    cpfCnpj: cpfOrCnpjMask(item.cpfCnpj),
    companyName: validateData(item.companyName),
    onboardingResponsable: validateData(item.onboardingResponsable),
    onboardingInitialDate: item.onboardingInitialDate
      ? format(new Date(item.onboardingInitialDate), 'dd/MM/yyyy')
      : '-',
    onboardingFinalDate: item.onboardingFinalDate
      ? format(new Date(item.onboardingFinalDate), 'dd/MM/yyyy')
      : '-',
    onboardingDurationDays:
      item.onboardingDurationDays !== null
        ? `${
            item.onboardingDurationDays === 0 ? 1 : item.onboardingDurationDays
          } dia(s)`
        : '-',
    branding: validateData(item.branding),
    plan: validateData(item.plan),
  }));
};

export const formatOnboardingTableData = {
  getStatusDetails,
  validateData,
  translateStatus,
  formatOnboardingDataSheet,
};
