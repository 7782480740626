import config from '../config';
import api from '../services/api';

const getAllByCompanyAndDefaults = async (companyId, params) => {
  try {
    const response = await api.get(
      config.endpoint + `natureoftransactions/by-company/${companyId}`,
      {
        params,
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};
const getAllPaginated = async (companyId, params) => {
  try {
    const response = await api.get(
      config.endpoint + `natureoftransactions/all-by-company/${companyId}`,
      {
        params,
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompany = async (companyId, params) => {
  try {
    const response = await api.get(
      config.endpoint + `natureoftransactions?companyId=${companyId}`,
      {
        params,
      }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getAllByCompanyAndDefaults,
  getAllByCompany,
  getAllPaginated,
};
