import api from 'v2/services/api';

const BASE_URL = '/mobile-checklist';

export const hasMobileChecklistBySaleId = async (saleId) => {
  const res = await api.get(`${BASE_URL}/has-by-sale/${saleId}`);
  return res.data;
};

export const getPrintMobileChecklist = async (saleId) => {
  const res = await api.get(`${BASE_URL}/print/${saleId}`);
  return res.data;
};

export const MobileChecklistRepository = {
  hasMobileChecklistBySaleId,
  getPrintMobileChecklist,
};
