import React from 'react';
import { InputTextArea } from 'v2/components/Input';

const OnboardingFeedbackOnlyContent = ({
  emojiSelected,
  setEmojiSelected,
  feedbackObservation,
  setFeedbackObservation,
  iconSize = 'normal',
  isLoading,
  nonePlaceholder,
}) => {
  const emojis = [
    {
      description: 'Desengajado',
      icon: '☹️',
    },
    {
      description: 'Neutro',
      icon: '😐',
    },
    {
      description: 'Engajado',
      icon: '😄',
    },
  ];

  const iconSizeMap = {
    small: 'large',
    normal: '45px',
  };

  const getButtonStyles = (description) => {
    return {
      fontWeight: description === emojiSelected ? 'bold' : 'regular',
      opacity:
        emojiSelected && emojiSelected !== '' && emojiSelected === description
          ? 1
          : 0.5,
    };
  };

  return (
    <>
      <div className="flex row evenly">
        {emojis.map((emoji) => (
          <button
            key={emoji.description}
            className="button button-icon flex column center radius-4"
            onClick={() =>
              emojiSelected && isLoading
                ? null
                : setEmojiSelected(emoji.description)
            }
            //disabled={isLoading}
            style={{
              ...getButtonStyles(emoji.description),
              width: '95px',
            }}
          >
            <div
              className="flex center"
              style={{ fontSize: iconSizeMap[iconSize] }}
            >
              {emoji.icon}
            </div>
            <>{emoji.description}</>
          </button>
        ))}
      </div>
      <InputTextArea
        className="mt-05"
        height="120px"
        disabled={isLoading}
        value={feedbackObservation}
        onChange={(e) => setFeedbackObservation(e.target.value)}
        placeholder={
          nonePlaceholder
            ? ''
            : 'Insira aqui as informações que entenda como motivadoras para sua escolha'
        }
      />
    </>
  );
};

export default OnboardingFeedbackOnlyContent;
