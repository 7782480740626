import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import Input from '../../../views/Clients/NewClient/FormClient/components/Input';

import api from '../../../../services/api';
import config from '../../../../config';
import providersRepository from '../../../../repositories/Providers';

import {
  description,
  phoneMask,
  cnpjMask,
  onlyNumbers,
  cepMask,
  email,
} from '../../ToNormalize/ToNormalize';
import { validadePhone } from '../../Validations/Validations';

const Select = ({ input, children, ...rest }) => (
  <select className="form-control foco-input" {...rest} {...input}>
    {children}
  </select>
);

function ProviderPage({ nextPage, previousPage }) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const dispatch = useDispatch();

  const {
    providerInDB,
    companyName,
    tradingName,
    IE,
    Number_Phone1,
    Zipcode,
    Address,
    Address_Number,
    Neighborhood,
    Complement,
    State,
    City,
  } = useSelector((state) => state.form.PurchaseXmlImport.values);

  const { values } = useSelector((state) => state.form.PurchaseXmlImport);

  useEffect(() => {
    setStates([{ id: 1, Name: State }]);

    setCities([
      {
        id: 1,
        Name: City,
      },
    ]);
  }, []);

  const checkComercialPhone = (value) => {
    value = onlyNumbers(value);
    if (value.charAt([0]) === '0') return false;
    return true;
  };

  async function loadCities() {
    const response = await api.get(config.endpoint + `address/cities`);
    setCities(response.data || []);
  }

  function handleCity(city) {
    switch (city) {
      case 'Birigüi':
        return 'Birigui';
      default:
        return city;
    }
  }

  function handleCep(obj) {
    const n = obj.City.indexOf("'");
    if (n > 0) {
      let City = obj.City.replace("'", '`');
      City = City.replace(
        City.charAt([n - 1]),
        City.charAt([n - 1]).toLowerCase()
      );
      obj.City = City;
    }

    dispatch([
      change('PurchaseXmlImport', 'Address', obj.Address),
      change('PurchaseXmlImport', 'Neighborhood', obj.Neighborhood),
      change('PurchaseXmlImport', 'City', obj.City),
      change('PurchaseXmlImport', 'State', obj.State),
    ]);
  }

  async function citiesByState(State_id) {
    const response = await api.get(
      config.endpoint + `address/cities/ByStateId/${State_id}`
    );
    setCities(response.data || []);
  }

  function handleState(UF) {
    if (!UF) return loadCities();
    for (let i = 0; i < states.length; i++) {
      if (UF === states[i].Initials) {
        return citiesByState(states[i].id);
      }
    }
  }

  async function handleNext() {
    if (providerInDB) {
      return nextPage();
    }

    return create(values);
  }

  const create = async (values) => {
    const validate = await handleValidateFields(values);
    if (!validate) return;

    const {
      Address,
      Address_Number,
      City,
      Complement,
      IE,
      Neighborhood,
      Reference_Point,
      State,
      Zipcode,
      Number_Phone1,
      companyName,
      cpfCnpj,
      statusIE,
      tradingName,
    } = values;
    await dispatch([
      change('PurchaseXmlImport', 'loading', true),
      change(
        'PurchaseXmlImport',
        'loadingMessage',
        'Aguarde, cadastrando Fornecedor'
      ),
    ]);

    try {
      const response = await api.post(config.endpoint + 'providers', {
        provider: {
          companyName,
          tradingName,
          cpfCnpj: onlyNumbers(cpfCnpj),
          type: 'Juridica',
          IE,
          statusIE,
          email,
          companyId,
          isActive: true,
          date: new Date().toLocaleDateString(),
        },
        address: {
          zipcode: onlyNumbers(Zipcode),
          street: Address,
          complement: Complement,
          neighborhood: Neighborhood,
          city: City,
          state: State,
          streetNumber: Address_Number,
          referencePoint: Reference_Point,
        },
        phones: {
          commercialPhone: Number_Phone1 ? onlyNumbers(Number_Phone1) : '',
          cellPhone: '',
          contact: '',
        },
      });

      let providerInDBCreated;
      const { providers } = await providersRepository.getAllByCompany({
        companyId,
        page: 1,
        limit: 100,
        query: onlyNumbers(cpfCnpj),
      });

      await providers.forEach((prov) => {
        if (prov.id === response.data.id) {
          const address = prov.Address.length
            ? {
                ...prov.Address[0],
                addressId: prov.Address[0].id,
                Zipcode: cepMask(prov.Address[0].Zipcode),
              }
            : {};
          delete address.id;
          const phone = prov.Phones.length
            ? {
                ...prov.Phones[0],
                phonesId: prov.Phones[0].id,
                Number_Phone1: phoneMask(prov.Phones[0].Number_Phone1),
              }
            : {};
          delete phone.id;
          return (providerInDBCreated = {
            ...prov,
            ...address,
            ...phone,
          });
        }
      });

      await dispatch(
        change('PurchaseXmlImport', 'providerInDB', providerInDBCreated)
      );

      nextPage();
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao salvar o fornecedor. Por favor, tente novamente'
      );
    } finally {
      dispatch(change('PurchaseXmlImport', 'loading', false));
    }
  };

  const update = async () => {
    const validate = await handleValidateFields(values);
    if (!validate) return;

    const {
      Address,
      Address_Number,
      City,
      email,
      Complement,
      IE,
      Neighborhood,
      Reference_Point,
      State,
      Zipcode,
      Number_Phone1,
      companyName,
      cpfCnpj,
      statusIE,
      tradingName,
      providerInDB: { id, addressId, phonesId },
    } = values;
    await dispatch([
      change('PurchaseXmlImport', 'loading', true),
      change(
        'PurchaseXmlImport',
        'loadingMessage',
        'Aguarde, atualizando o cadastro do Fornecedor'
      ),
    ]);

    try {
      await api.put(config.endpoint + `providers/${id}`, {
        provider: {
          companyName,
          tradingName,
          cpfCnpj: onlyNumbers(cpfCnpj),
          type: 'Juridica',
          IE,
          statusIE,
          email,
          companyId,
          isActive: true,
          date: new Date().toLocaleDateString(),
        },
        address: {
          addressId,
          zipcode: onlyNumbers(Zipcode),
          street: Address,
          complement: Complement,
          neighborhood: Neighborhood,
          city: City,
          state: State,
          streetNumber: Address_Number,
          referencePoint: Reference_Point,
        },
        phones: {
          phonesId,
          commercialPhone: Number_Phone1 ? onlyNumbers(Number_Phone1) : '',
          cellPhone: '',
          contact: '',
        },
      });

      let providerInDBCreated;
      const { providers } = await providersRepository.getAllByCompany({
        companyId,
        page: 1,
        limit: 100,
        query: onlyNumbers(cpfCnpj),
      });

      await providers.forEach((prov) => {
        if (prov.id === id) {
          const address = prov.Address.length
            ? {
                ...prov.Address[0],
                addressId: prov.Address[0].id,
                Zipcode: cepMask(prov.Address[0].Zipcode),
              }
            : {};
          delete address.id;
          const phone = prov.Phones.length
            ? {
                ...prov.Phones[0],
                phonesId: prov.Phones[0].id,
                Number_Phone1: phoneMask(prov.Phones[0].Number_Phone1),
              }
            : {};
          delete phone.id;
          return (providerInDBCreated = {
            ...prov,
            ...address,
            ...phone,
          });
        }
      });

      await dispatch(
        change('PurchaseXmlImport', 'providerInDB', providerInDBCreated)
      );

      nextPage();
    } catch (err) {
      console.log(err.response);
      toastr.warning(
        'Ocorreu um erro ao atualizar o fornecedor. Por favor, tente novamente'
      );
    } finally {
      dispatch(change('PurchaseXmlImport', 'loading', false));
    }
  };

  const handleValidateFields = async (values) => {
    const {
      Address,
      Address_Number,
      City,
      Neighborhood,
      State,
      Zipcode,
      Number_Phone1,
      companyName,
      tradingName,
    } = values;

    if (
      !Address ||
      !Address_Number ||
      !Neighborhood ||
      !City ||
      !State ||
      !Zipcode ||
      !Number_Phone1 ||
      !companyName ||
      !tradingName
    ) {
      toastr.warning('Campos obrigatórios não preenchidos');
      return false;
    }

    if (onlyNumbers(Zipcode) < 8) {
      toastr.warning('CEP inválido');
      return false;
    }

    if (!validadePhone(Number_Phone1)) {
      toastr.warning('Telefone Comercial inválido');
      return false;
    }

    return true;
  };

  return (
    <>
      {providerInDB && (
        <span id="span-provider-in-db">
          Campos com borda sinalizada indicam diferença ao já cadastrado
        </span>
      )}
      <div className="xml-import-provider-container">
        <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
          <Col lg={4} md={7} sm={8} xs={12}>
            <Field
              label="Razão Social"
              name="companyName"
              component={Input}
              normalize={description}
              maxLength="80"
              style={{
                borderColor: `${
                  providerInDB
                    ? providerInDB.companyName !== companyName
                      ? '#ffcc00'
                      : '#e3e3e3'
                    : '#e3e3e3'
                }`,
              }}
              disabled
            />
          </Col>
          <Col lg={4} md={7} sm={8} xs={12}>
            <Field
              label="Nome Fantasia"
              name="tradingName"
              component={Input}
              normalize={description}
              maxLength="80"
              style={{
                borderColor: `${
                  providerInDB
                    ? providerInDB.tradingName !== tradingName
                      ? '#ffcc00'
                      : '#e3e3e3'
                    : '#e3e3e3'
                }`,
              }}
              disabled
            />
          </Col>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
          <Col lg={2} md={3} sm={4} xs={12}>
            <Field
              label="CNPJ"
              name="cpfCnpj"
              component={Input}
              normalize={cnpjMask}
              placeholder=""
              maxLength="18"
              disabled
            />
          </Col>
          <Col lg={2} md={3} sm={4} xs={12}>
            <Field
              label="Inscrição Estadual"
              name="IE"
              component={Input}
              maxLength="14"
              style={{
                borderColor: `${
                  providerInDB
                    ? providerInDB.IE !== IE
                      ? '#ffcc00'
                      : '#e3e3e3'
                    : '#e3e3e3'
                }`,
              }}
              disabled
            />
          </Col>
          <Col lg={2} md={3} sm={4} xs={12}>
            <Field
              label="Telefone Comercial"
              name="Number_Phone1"
              component={Input}
              normalize={(value) => {
                if (!checkComercialPhone(value)) return;
                return phoneMask(value);
              }}
              style={{
                borderColor: `${
                  providerInDB
                    ? providerInDB.Number_Phone1 !== Number_Phone1
                      ? '#ffcc00'
                      : '#e3e3e3'
                    : '#e3e3e3'
                }`,
              }}
            />
          </Col>
          <Col lg={4} md={7} sm={8} xs={12}>
            <Field
              label="Email"
              name="email"
              component={Input}
              normalize={email}
              maxLength="80"
              style={{
                borderColor: `${
                  providerInDB
                    ? providerInDB.Email !== email
                      ? '#ffcc00'
                      : '#e3e3e3'
                    : '#e3e3e3'
                }`,
              }}
              disabled
            />
          </Col>
        </Col>

        <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
          <Col lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <Col lg={2} md={2} sm={3} xs={12}>
              <Field
                label="CEP"
                name="Zipcode"
                component={Input}
                normalize={cepMask}
                placeholder="57073-342"
                style={{
                  borderColor: `${
                    providerInDB
                      ? providerInDB.Zipcode !== Zipcode
                        ? '#ffcc00'
                        : '#e3e3e3'
                      : '#e3e3e3'
                  }`,
                }}
                disabled
              />
            </Col>
            <Col lg={4} md={7} sm={6} xs={12}>
              <Field
                label="Endereço"
                name="Address"
                component={Input}
                placeholder="Rua Nilson Urias"
                style={{
                  borderColor: `${
                    providerInDB
                      ? providerInDB.Address?.toUpperCase() !== Address
                        ? '#ffcc00'
                        : '#e3e3e3'
                      : '#e3e3e3'
                  }`,
                }}
                disabled
              />
            </Col>
            <Col lg={1} md={3} sm={3} xs={12}>
              <Field
                label="Número"
                name="Address_Number"
                component={Input}
                normalize={onlyNumbers}
                placeholder="298"
                style={{
                  borderColor: `${
                    providerInDB
                      ? providerInDB.Address_Number !== Address_Number
                        ? '#ffcc00'
                        : '#e3e3e3'
                      : '#e3e3e3'
                  }`,
                }}
                disabled
              />
            </Col>
            <Col lg={3} md={6} sm={6} xs={12}>
              <Field
                label="Bairro"
                name="Neighborhood"
                component={Input}
                placeholder="Cidade Universitária"
                style={{
                  borderColor: `${
                    providerInDB
                      ? providerInDB.Neighborhood?.toUpperCase() !==
                        Neighborhood?.toUpperCase()
                        ? '#ffcc00'
                        : '#e3e3e3'
                      : '#e3e3e3'
                  }`,
                }}
                disabled
              />
            </Col>
            <Col lg={2} md={6} sm={6} xs={12}>
              <Field
                label="Complemento"
                name="Complement"
                component={Input}
                style={{
                  borderColor: `${
                    providerInDB
                      ? providerInDB.Complement != Complement
                        ? '#ffcc00'
                        : '#e3e3e3'
                      : '#e3e3e3'
                  }`,
                }}
                disabled
              />
            </Col>
          </Col>

          <Col lg={3} md={6} sm={6} xs={12}>
            <Field
              label="Ponto de Referência"
              name="Reference_Point"
              component={Input}
              maxLength="15"
              placeholder="Ao lado da escola"
            />
          </Col>

          <Col lg={3} md={6} sm={6} xs={12}>
            <label>Estado</label>
            <Field
              name="State"
              component={Select}
              disabled={disabled || !states.length}
              onChange={({ target: { value } }) => {
                handleState(value);
                dispatch(change('PurchaseXmlImport', 'City', ''));
              }}
              style={{
                borderColor: `${
                  providerInDB
                    ? providerInDB.State !== State
                      ? '#ffcc00'
                      : '#e3e3e3'
                    : '#e3e3e3'
                }`,
              }}
            >
              <option name="" value="">
                Selecione
              </option>
              {states.map((e) => {
                return (
                  <option key={e.id} value={e.Initials}>
                    {e.Name}
                  </option>
                );
              })}
            </Field>
          </Col>

          <Col lg={3} md={6} sm={6} xs={12}>
            <label>Cidade</label>
            <Field
              name="City"
              component={Select}
              disabled={disabled || !cities.length}
              style={{
                borderColor: `${
                  providerInDB
                    ? providerInDB.City !== City
                      ? '#ffcc00'
                      : '#e3e3e3'
                    : '#e3e3e3'
                }`,
              }}
            >
              <option value="">Selecione</option>
              {cities.map((c) => {
                return (
                  <option key={c.id} value={c.Name}>
                    {c.Name}
                  </option>
                );
              })}
            </Field>
          </Col>
        </Col>

        <div className="xml-import-provider-buttons">
          <button
            type="button"
            className="xml-import-button-danger"
            onClick={() => previousPage()}
          >
            Voltar
          </button>
          {providerInDB ? (
            <div className="xml-import-provider-buttons-right">
              <button
                type="button"
                className="xml-import-button-info"
                onClick={() => update(values)}
              >
                Atualizar Cadastro
              </button>
              <button
                type="button"
                className="xml-import-button-success"
                onClick={handleNext}
              >
                Avançar
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="xml-import-button-success"
              onClick={handleNext}
            >
              Avançar
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ProviderPage;
