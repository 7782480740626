import React, { useState } from 'react';

import SearchInput from 'components/SearchInput';
import { SelectBrand } from 'v2/client/components/SelectBrand';

const ProductsFilterForm = ({ query, setQuery, type, setType, setBrandId }) => {
  const [brandSelected, setBrandSelected] = useState(null);

  const handleBrandChange = (item) => {
    setBrandSelected(item);
    setBrandId(item && item.value ? item.value.id : '');
    setType(item && item.value ? item.value.id : '');
  };
  return (
    <div className="products-filter-row">
      <SearchInput
        placeholder="Pesquisa por Descrição, Código, Ref. Fabricante"
        value={query}
        style={{
          fontSize: 12,
          width: 350,
        }}
        containerStyle={{ margin: '0 10px' }}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
          marginRight: '10px',
        }}
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <strong>Marca: </strong>
        </span>
        <SelectBrand
          value={brandSelected}
          onChange={handleBrandChange}
          width="100%"
          placeholder="Todas"
          isClearable
        />
      </div>
    </div>
  );
};

export default ProductsFilterForm;
