import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MainData } from './components/MainData';
import useOnboardingStore from './store/onboarding-store';
import LoadingSpinnerFullHeight from 'v2/components/LoadingSpinner';
import { FooterButtons } from './components/FooterButtons';

export const OnboardingModal = ({
  isOpen,
  handleClose,
  companyId,
  onboardingHeaderId,
  handleReload,
  companyName,
}) => {
  const {
    handleLoadData,
    setCompanyId,
    setOnboardingHeaderId,
    isFetching,
    setCompanyName,
    resetStore,
  } = useOnboardingStore();

  useEffect(() => {
    if (isOpen) {
      setCompanyId(companyId);
      setOnboardingHeaderId(onboardingHeaderId);
      setCompanyName(companyName);
      handleLoadData();
    }
  }, []);

  function handleCloseModal() {
    resetStore();
    handleClose();
  }

  return (
    <Modal show={isOpen} onHide={handleCloseModal} dialogClassName="modal-70w">
      <Modal.Header style={{ padding: '5px 10px' }}>
        <Modal.Title>
          <strong>Onboarding</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isFetching ? (
          <LoadingSpinnerFullHeight />
        ) : (
          <MainData
            handleClose={handleCloseModal}
            handleReload={handleReload}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <FooterButtons
          handleClose={handleCloseModal}
          handleReload={handleReload}
        />
      </Modal.Footer>
    </Modal>
  );
};
