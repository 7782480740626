import styled from 'styled-components';

export const OnboardingContainer = styled.main`
  margin-top: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const OnboardingFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 60%;

  .filters {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: end;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;
