import config from '../config';
import api from '../services/api';

const getAllActiveByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `providers?companyId=${companyId}&isActive=1`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getProviderPaginated = async (params) => {
  try {
    const response = await api.get(config.endpoint + `providers/paginated`, {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllByCompany = async (params) => {
  try {
    const response = await api.get(config.endpoint + `providers`, {
      params,
    });

    return response.data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id) => {
  try {
    const response = await api.get(config.endpoint + `providers/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getProviderAndProducts = async (id) => {
  try {
    const response = await api.get(
      config.endpoint + `providers/${id}/products`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const deleteProductRelationship = async (id, productId) => {
  try {
    await api.delete(config.endpoint + `providers/${id}/products/${productId}`);
  } catch (err) {
    throw err;
  }
};

const updateProductRelationship = async (id, productId, relation) => {
  try {
    await api.put(
      config.endpoint + `providers/${id}/products/${productId}`,
      relation
    );
  } catch (err) {
    throw err;
  }
};

const createProductRelationship = async (id, relation) => {
  try {
    await api.post(config.endpoint + `providers/${id}/products`, relation);
  } catch (err) {
    throw err;
  }
};

const getProviderAndProductsByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `providers/${companyId}/products/by-company`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const search = async (attributes) => {
  try {
    const response = await api.post(
      config.endpoint + `providers/search`,
      attributes
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const create = async (provider) => {
  try {
    const response = await api.post(config.endpoint + 'providers', provider);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (id, provider) => {
  try {
    await api.put(config.endpoint + `providers/${id}`, provider);
  } catch (err) {
    throw err;
  }
};

const getProvidersProducts = (query) =>
  api.get(config.endpoint + 'providers/providers-products', {
    params: query,
  });

const getAllProvidersProducts = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `providers/company/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const importProviders = async (params) => {
  try {
    const response = await api.post(
      config.endpoint + 'providers/import',
      params
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getAllActiveByCompany,
  getAllByCompany,
  getById,
  getProviderAndProducts,
  deleteProductRelationship,
  updateProductRelationship,
  createProductRelationship,
  getProviderAndProductsByCompany,
  getProvidersProducts,
  getAllProvidersProducts,
  search,
  create,
  update,
  importProviders,
  getProviderPaginated,
};
