import {
  faCheck,
  faTriangleExclamation,
  faX,
} from '@fortawesome/free-solid-svg-icons';

export const useMobileChecklist = () => {
  const StatusButtonIconChecklist = {
    Approved: 'Aprovado',
    Alert: 'Alerta',
    Rejected: 'Reprovado',
    NotApplicable: 'Não se aplica',
  };

  const buttonIconData = [
    {
      id: StatusButtonIconChecklist.Approved,
      icon: faCheck,
      status: StatusButtonIconChecklist.Approved,
      backgroundColor: '#5CB85C',
      borderColor: '#4CAE4C',
    },
    {
      id: StatusButtonIconChecklist.Alert,
      icon: faTriangleExclamation,
      status: StatusButtonIconChecklist.Alert,
      backgroundColor: '#FFBC3C',
      borderColor: '#FFB400',
    },
    {
      id: StatusButtonIconChecklist.Rejected,
      icon: faX,
      status: StatusButtonIconChecklist.Rejected,
      backgroundColor: '#D9534F',
      borderColor: '#D43F3A',
    },
    {
      id: StatusButtonIconChecklist.NotApplicable,
      icon: null,
      status: StatusButtonIconChecklist.NotApplicable,
      backgroundColor: '#5BC0DE',
      borderColor: '#46B8DA',
    },
  ];

  return {
    StatusButtonIconChecklist,
    buttonIconData,
  };
};
