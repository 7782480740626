import React, { useEffect, useState } from 'react';
import MyCompanyData from './MyCompanyData';

import { change } from 'redux-form';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { maxLength, cepMask } from 'client/components/ToNormalize/ToNormalize';
import moment from 'moment';

import companyConfigRepository from 'repositories/CompanyConfig';
import advertisementsRepository from 'repositories/Advertisements';
import adminsRepository from 'repositories/Admins';

const BundleCompanyData = ({ companyId, reload }) => {
  const dispatch = useDispatch();
  const [previousSuperLogicaId, setPreviousSuperLogicaId] = useState(null);
  const [addressInformation, setAddressInformation] = useState(null);

  useEffect(() => {
    if (companyId) {
      try {
        loadCompany();
      } catch (err) {
        console.log(err);
        return toastr.warning('Erro ao carregar a empresa', 'Tente novamente!');
      }
    }
  }, [reload]);

  const loadCompany = async () => {
    const response = await adminsRepository.getClientFullInformation(companyId);
    const companyConfig = await companyConfigRepository.show(companyId);

    const {
      Address,
      Company_Name,
      Cpf_Cnpj,
      Email,
      Phones,
      Trading_Name,
      id,
      Type,
      Url_Logo,
      isCompanyRegisteredInAPIFiscal,
      Signature,
      createdAt,
      updatedAt,
      lastLogin,
      Users,
      lastSignature,
      OnboardingVideoVisualizedDate,
      Sacado_id: superlogicaId,
    } = response.client;

    const brandingId = lastSignature?.Plans?.brandingId;

    const advertisements =
      await advertisementsRepository.listExclusiveAdsByBranding(brandingId);

    setPreviousSuperLogicaId(superlogicaId);
    setAddressInformation(Address[0]);

    dispatch([
      change('meusDados', 'Type', Type),
      change('meusDados', 'Company_Name', Company_Name),
      change('meusDados', 'Company_id', id),
      change('meusDados', 'Trading_Name', Trading_Name),
      change('meusDados', 'Cpf_Cnpj', Cpf_Cnpj),
      change('meusDados', 'Email', Email),
      change('meusDados', 'registerApiFiscal', isCompanyRegisteredInAPIFiscal),
      change(
        'meusDados',
        'Zipcode',
        maxLength(cepMask(Address[0].Zipcode), 10)
      ),
      change('meusDados', 'Address', Address[0].Address),
      change('meusDados', 'Address_Number', Address[0].Address_Number),
      change('meusDados', 'Complement', Address[0].Complement),
      change('meusDados', 'Neighborhood', Address[0].Neighborhood),
      change('meusDados', 'Country', Address[0].Country),
      change('meusDados', 'State', Address[0].State),
      change('meusDados', 'Address_id', Address[0].id),
      change('meusDados', 'signaturepayments', Signature),
      change('meusDados', 'advertisement', companyConfig.advertisementId),
      change('meusDados', 'advertisementOptions', advertisements),
      change(
        'meusDados',
        'dataCadastro',
        moment(createdAt).format('DD/MM/YYYY')
      ),
      change(
        'meusDados',
        'dataAtualizacao',
        moment(updatedAt).format('DD/MM/YYYY')
      ),
      change('meusDados', 'lastLogin', moment(lastLogin).format('DD/MM/YYYY')),
      change('meusDados', 'logo', {
        name: Url_Logo?.split('amazonaws.com/')[1],
        preview: Url_Logo,
        uploaded: true,
      }),
      change('meusDados', 'allUsers', Users),
      change('meusDados', 'signatureStatus', lastSignature.SignatureStatus_id),
      change(
        'meusDados',
        'OnboardingVideoVisualizedDate',
        OnboardingVideoVisualizedDate
      ),
      change(
        'meusDados',
        'signatureEndDate',
        moment(lastSignature.End_Date).format('DD/MM/YYYY')
      ),
      change('meusDados', 'lastSignatureId', lastSignature.id),
      change('meusDados', 'superLogicaId', superlogicaId),
    ]);

    const n = Address[0].City.indexOf("'");
    if (n > 0) {
      let City = Address[0].City.replace("'", '`');
      City = City.replace(
        City.charAt([n - 1]),
        City.charAt([n - 1]).toLowerCase()
      );
      Address[0].City = City;
    }
    dispatch(change('meusDados', 'City', Address[0].City));

    if (!!Phones.length) {
      dispatch(
        change('meusDados', 'Phone', phoneMask(Phones[0].Number_Phone1))
      );
      dispatch(change('meusDados', 'Phone_id', Phones[0].id));
      dispatch(change('meusDados', 'Contato', Phones[0].Contact));
    }

    let userActive = [];
    for (let i = 0; i < Users.length; i++) {
      if (Users[i].IsActive) {
        userActive.push(Users[i]);
      }
    }

    dispatch(change('meusDados', 'userActive', userActive.length));
    dispatch(change('meusDados', 'totalUsuarios', Users.length));
  };

  return (
    <MyCompanyData
      reload={reload}
      dispatch={dispatch}
      previousSuperLogicaId={previousSuperLogicaId}
      setPreviousSuperLogicaId={setPreviousSuperLogicaId}
      addressInformation={addressInformation}
    />
  );
};

export default BundleCompanyData;
