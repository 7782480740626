import React, { useCallback, useEffect, useState } from 'react';
import { Table } from 'v2/components/Table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import SearchInput from 'components/SearchInput';

import { kmMask } from 'client/components/ToNormalize/ToNormalize';
import warrantiesRepository from '../../../repositories/Warranties';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { format } from 'date-fns';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { useFilters } from 'v2/hooks/useFilters';
import { usePagination } from 'v2/hooks/usePagination';
import { getWarranty } from 'v2/repositories/WarrantyRepository';

const HomeWarranties = ({ history }) => {
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [warranties, setWarranties] = useState([]);
  const [loading, setLoading] = useState(false);

  const { companyId } = useAuth();

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const loadWarranties = async (filters, pagination) => {
    setLoading(true);

    try {
      const { warranties, count } = await getWarranty(companyId, {
        ...pagination,
        ...filters,
      });

      setWarranties(warranties);
      setTotal(count);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as garantias. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFiltersChange = useCallback(
    (filters) => {
      if (isFirstLoad) {
        setIsFirstLoad(false);
        return;
      }
      loadWarranties(filters, { page, limit });
    },
    [isFirstLoad]
  );

  const { query, setQuery } = useFilters(handleFiltersChange);

  const { page, setPage, limit, setLimit, setTotal, totalPages } =
    usePagination(loadWarranties);

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.WARRANTY)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Nova Garantia
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <DownloadXlsButton
                archiveName={`Garantias - ${format(new Date(), 'dd/MM/yyyy')}`}
                data={warranties}
                disabled={!!!warranties.length}
                className="btn btn-export"
                columns={[
                  {
                    name: 'Descrição',
                    acessor: 'Description',
                  },
                  {
                    name: 'KM',
                    acessor: 'Kilometers',
                  },
                  {
                    name: 'Meses',
                    acessor: 'Months',
                  },
                ]}
                style={{ fontSize: '12px' }}
              >
                <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                &nbsp;Exportar .xls
              </DownloadXlsButton>
            </div>
          </div>
          <div>
            <Table
              columns={[
                {
                  Header: 'Código',
                  id: 'Code',
                  accessor: (props) => Number(props.Code),
                  headerClassName: 'text-left',
                  width: 100,
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'KM',
                  id: 'Kilometers',
                  accessor: (props) => Number(props.Kilometers),
                  headerClassName: 'text-left',
                  width: 120,
                  Cell: (props) => kmMask(props.value.toString()),
                },
                {
                  Header: 'Meses',
                  id: 'Months',
                  accessor: (props) => Number(props.Months),
                  headerClassName: 'text-left',
                  width: 120,
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  width: 100,
                  filterable: false,
                  Cell: (props) => (
                    <Link to={{ pathname: `warranty#${props.original.id}` }}>
                      <FontAwesomeIcon
                        title="Editar"
                        cursor="pointer"
                        style={{
                          height: '1.5em',
                          width: '1.5em',
                          color: 'black',
                        }}
                        icon={faEdit}
                      />
                    </Link>
                  ),
                },
              ]}
              data={warranties}
              defaultPageSize={10}
              pageSize={limit}
              page={page}
              onPageChange={setPage}
              manual
              onPageSizeChange={setLimit}
              pages={totalPages}
              loading={loading}
              fontSize="12px"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeWarranties);
