import React, { useState } from 'react';
import {
  faEdit,
  faTrashAlt,
  faPrint,
  faSearch,
  faWindowClose,
  faShareAltSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastr } from 'react-redux-toastr';

import { INVOICE_STATUS_DESCRIPTION } from '../../../utils/constants';
import SATCFesRepository from '../../../repositories/SATCFe';

import AlertModal from 'components/AlertModal/AlertModal';
import CancelSATModal from './CancelSATModal';
import Loader from '../../../components/Loader';
import ShareSATCFe from '../../components/ShareSATCFe/ShareSATCFe';
import { useFilters } from 'v2/hooks/useFilters';
import { usePagination } from 'v2/hooks/usePagination';

const SATCFeActions = ({ SATCFe, onChange, onEdit }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    useState(false);
  const [isLoadingDeleteInvoice, setIsLoadingDeleteInvoice] = useState(false);
  const [loadingConsult, setLoadingConsult] = useState(false);
  const [isShareSATCFeModalOpen, setIsShareSATCFeModalOpen] = useState(false);

  const isOpen = SATCFe.status === INVOICE_STATUS_DESCRIPTION.OPEN;
  const isOpenWithErrors =
    SATCFe.status === INVOICE_STATUS_DESCRIPTION.OPEN_WITH_ERRORS;
  const isDisabled = SATCFe.status === INVOICE_STATUS_DESCRIPTION.DISABLED;
  const isIssued = SATCFe.status === INVOICE_STATUS_DESCRIPTION.ISSUED;
  const isInContingency =
    SATCFe.status === INVOICE_STATUS_DESCRIPTION.CONTINGENCY;
  const { page, limit } = usePagination();
  const {
    query,
    type,
    status,
    dateType,
    initialDate,
    finalDate,
    getFiltersPayload,
  } = useFilters();
  const updatedFilters = {
    query,
    type,
    status,
    dateType,
    initialDate,
    finalDate,
    ...getFiltersPayload(),
  };

  async function handleDeleteSATCFe() {
    setIsLoadingDeleteInvoice(true);
    try {
      await SATCFesRepository.deleteSATCFe(SATCFe.id);
      toastr.success('SAT deletada com sucesso');
      setIsDeleteModalOpen(false);
      onChange(updatedFilters, { page, limit });
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao deletar a SAT. Por favor, tente novamente'
      );
    } finally {
      setIsLoadingDeleteInvoice(false);
    }
  }

  function handleOpenPDFDownloadPage() {
    const hash = `Vend=v723dha5tHSSpS83711${SATCFe.saleId}5422hsGsdAQLk8423oPL31?fiscal=true`;
    const satDocumentLink = `${window.location.origin}/sheets/${hash}`;

    window.open(satDocumentLink, '_blank');
  }

  async function handleConsultSATCFeInContingency() {
    setLoadingConsult(true);
    try {
      await SATCFesRepository.consultContingency(SATCFe.id);
      toastr.success('SAT consultado com sucesso.');
      onChange(updatedFilters, { page, limit });
    } catch (err) {
      console.error(err.response);
      toastr.error(
        'Ocorreu um erro ao consultar SAT em contingência. Por favor, tente novamente'
      );
    } finally {
      setLoadingConsult(false);
    }
  }

  if (isDisabled) return null;

  return (
    <div style={{ display: 'flex' }}>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => onEdit(SATCFe)}
        className="btn-acoes"
        style={{ color: '#000000', fontSize: '14px' }}
      />
      {(isOpen || isOpenWithErrors) && (
        <FontAwesomeIcon
          onClick={() => setIsDeleteModalOpen(true)}
          icon={faTrashAlt}
          className="btn-acoes"
          style={{ color: '#e61610', fontSize: '14px' }}
        />
      )}
      {isInContingency && (
        <FontAwesomeIcon
          icon={faSearch}
          onClick={handleConsultSATCFeInContingency}
          className="btn-acoes"
          style={{ color: '#444444', fontSize: '14px' }}
        />
      )}
      {isIssued && (
        <>
          <FontAwesomeIcon
            icon={faPrint}
            onClick={handleOpenPDFDownloadPage}
            className="btn-acoes"
            style={{ color: '#444444', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faWindowClose}
            onClick={() => setShowCancelConfirmationModal(true)}
            className="btn-acoes"
            style={{ color: '#e61610', fontSize: '14px' }}
          />
          <FontAwesomeIcon
            icon={faShareAltSquare}
            onClick={() => setIsShareSATCFeModalOpen(true)}
            className="btn-acoes"
            style={{ color: '#72bb53', fontSize: '14px' }}
          />
        </>
      )}

      <AlertModal
        message="Deseja realmente excluir a nota?"
        show={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onHide={() => setIsDeleteModalOpen(false)}
        onSubmit={handleDeleteSATCFe}
        loading={isLoadingDeleteInvoice}
      />

      <CancelSATModal
        show={showCancelConfirmationModal}
        onCancel={() => setShowCancelConfirmationModal(false)}
        reloadInvoices={() => onChange(updatedFilters, { page, limit })}
        satId={SATCFe.id}
      />

      {isShareSATCFeModalOpen && (
        <ShareSATCFe
          show={isShareSATCFeModalOpen}
          animation
          onHide={() => setIsShareSATCFeModalOpen(false)}
          SATCFe={SATCFe}
          onSubmit={() => setIsShareSATCFeModalOpen(false)}
        />
      )}

      {loadingConsult && <Loader message="Aguarde, consultando SAT" />}
    </div>
  );
};

export default SATCFeActions;
