import config from '../config';
import api from '../services/api';

const create = async (companyId, data) => {
  const response = await api.post(
    config.endpoint + `brands/${companyId}`,
    data
  );

  return response.data;
};

const getAllByCompany = async (companyId, params) => {
  try {
    const response = await api.get(
      config.endpoint + `brands/by-company/${companyId}`,
      {
        params,
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllActiveProductsBrandsByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint + `brands/active-products-by-company/${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getByDescription = async (description) => {
  const reponse = await api.get(`searchByDescription/${description}`);
  return reponse.data;
};

const getByCompanyAndDescription = async (companyId, description) => {
  const reponse = await api.get(
    `brands/search/ByDesc/${companyId}/${description}`
  );
  return reponse.data;
};

const getLastCode = async (companyId) => {
  const reponse = await api.get(`brands/LastOne/${companyId}`);
  return reponse.data;
};

const getBicycleBrandsOrdered = async (companyId) => {
  const reponse = await api.get(`brands/bicycle-brands-ordered/${companyId}`);
  return reponse.data;
};

export default {
  create,
  getAllByCompany,
  getByDescription,
  getByCompanyAndDescription,
  getLastCode,
  getBicycleBrandsOrdered,
  getAllActiveProductsBrandsByCompany,
};
