import { useAuth } from 'contexts/auth';
import React, { useCallback, useEffect, useState } from 'react';

import advertisementsRepository from '../../../repositories/Advertisements';
import { UseBannerContext } from '../../../contexts/bannerContext';
import './styles.css';
import { useThemeContext } from 'v2/contexts/themeContext';
import { SimpleCarousel } from 'v2/components/SimpleCarousel';

const Advertisements = () => {
  const [advertisements, setAdvertisements] = useState({});
  const { isAdvertisementOpen, setIsAdvertisementOpen } = UseBannerContext();
  const { companyId, userId } = useAuth();
  const { brandingId } = useThemeContext();

  useEffect(() => {
    if (companyId) loadAdvertisements();
  }, [companyId]);

  const loadAdvertisements = async () => {
    try {
      let exclusiveAd =
        await advertisementsRepository.getExclusiveAdByCompanyId(
          companyId,
          brandingId
        );
      let globalAds = await advertisementsRepository.getActiveGlobalAd(
        companyId,
        brandingId
      );
      if ((globalAds && globalAds.length > 0) || exclusiveAd) {
        setIsAdvertisementOpen(true);
      } else {
        setIsAdvertisementOpen(false);
      }
      setAdvertisements({
        exclusiveAd,
        globalAds,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const openAdInNewTab = useCallback((ad) => {
    if (!ad.redirectUrl) return;
    window.open(ad.redirectUrl, '_blank');
    advertisementsRepository.click({ userId, advertisementId: ad.id });
  });

  if (!isAdvertisementOpen) return null;
  return (
    isAdvertisementOpen && (
      <div className="advertisements-container">
        <a
          className="close-advertisement"
          onClick={() => setIsAdvertisementOpen(false)}
        >
          Fechar
        </a>

        <div className="ads-wrapper">
          {advertisements.globalAds && advertisements.globalAds.length > 0 && (
            <div className="left-ad-container">
              <SimpleCarousel
                items={advertisements.globalAds}
                interval={4000}
                onClick={openAdInNewTab}
              />
            </div>
          )}
          {advertisements.exclusiveAd && (
            <div className="right-ad-container">
              <img
                src={advertisements.exclusiveAd.imageUrl || ''}
                alt={advertisements.exclusiveAd.description}
                onClick={() => openAdInNewTab(advertisements.exclusiveAd)}
                className="advertisement-image"
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default Advertisements;
