import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';
import { useDispatch } from 'react-redux';

import s3Repository from '../../../../../repositories/S3';
import advertisementsRepository from '../../../../../repositories/Advertisements';
import Form from './Form';
import { getbrandingNameById } from 'v2/helpers/brandingHelpers';
import { BRANDINGS } from 'v2/utils/constants';
import { options } from '@fullcalendar/core/preact';

const Advertising = ({ advertisingId, onCancel, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('GLOBAL');
  const [branding, setBranding] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (advertisingId) {
      loadAdvertising();
    }
  }, [advertisingId]);

  const extractBrandingValues = (brandingArray) => {
    return brandingArray
      .filter((branding) => branding.value !== '*')
      .map((branding) => branding.value);
  };

  async function loadAdvertising() {
    setLoading(true);

    try {
      const advertising = await advertisementsRepository.show(advertisingId);
      const {
        code,
        description,
        type,
        id,
        imageUrl,
        isActive,
        redirectUrl,
        brandingId,
        Details,
      } = advertising;

      setType(type);

      const values = {
        id,
        code,
        description,
        imageUrl: Details[0]?.imageUrl ?? imageUrl,
        isActive,
        redirectUrl,
        type,
        Details,
      };
      if (type === 'EXCLUSIVE' && Details.length === 0 && brandingId === null) {
        setBranding({
          value: productOptions.map((option) => option.value),
          label: 'Todos',
        });
      } else if (type === 'EXCLUSIVE') {
        setBranding({
          value: Details[0]?.brandingId ?? brandingId,
          label: getbrandingNameById(Details[0]?.brandingId ?? brandingId),
        });
      } else {
        const isAllSelected = BRANDINGS.every(
          (branding, index) => branding.id === Details[index]?.brandingId
        );
        if (isAllSelected) {
          const selectedProducts = Details.map((detail) => ({
            value: detail.brandingId,
            label: getbrandingNameById(detail.brandingId),
          }));
          setSelectedProducts([
            ...selectedProducts,
            { value: '*', label: getbrandingNameById('Todos') },
          ]);
          setLoading(false);
          return dispatch(initialize('advertising', values));
        }
        const selectedProducts = Details.map((detail) => ({
          value: detail.brandingId,
          label: getbrandingNameById(detail.brandingId),
        }));
        setSelectedProducts(selectedProducts);
      }

      dispatch(initialize('advertising', values));
    } catch (err) {
      toastr.warning(
        err.response?.data?.message ||
          'Ocorreu um erro ao carregar a propaganda. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  function handleSubmit(values) {
    const { file, description, imageUrl } = values;

    if (!type || !description || (!file && !imageUrl)) {
      return toastr.warning(
        'Não foi possível salvar',
        'Preencha todos os campos obrigatórios e tente novamente'
      );
    }

    if (type === 'EXCLUSIVE' && !branding) {
      return toastr.warning(
        'Não foi possível salvar',
        'Selecione uma marca para anúncios exclusivos'
      );
    }

    if (type !== 'EXCLUSIVE' && selectedProducts.length === 0) {
      return toastr.warning(
        'Não foi possível salvar',
        'Selecione ao menos um produto'
      );
    }
    if (!advertisingId) {
      return create(values);
    }
    return update(values);
  }

  async function create(values) {
    const { redirectUrl, file, description } = values;
    let selectedProductsSerialized = selectedProducts
      .filter((product) => product.value !== '*')
      .map((product) => product.value);
    try {
      const imageUrl = await s3Repository.uploadImage({
        file,
        name: Math.floor(Math.random() * 100001),
      });

      const advertising = await advertisementsRepository.create({
        description,
        type,
        imageUrl,
        redirectUrl,
        brandingId: branding ? branding.value : selectedProductsSerialized,
      });

      toastr.success('Propaganda cadastrada com sucesso!');
      onSubmit(advertising);
    } catch (err) {
      if (err.response) {
        toastr.warning(err.response.data.message);
      } else {
        toastr.warning(
          'Ocorreu um erro ao salvar a propaganda. Por favor, tente novamente'
        );
      }
    }
    setLoading(false);
  }

  async function update(values) {
    const { redirectUrl, file, id, imageUrl, imageUrlToRemove, description } =
      values;
    setLoading(true);
    try {
      let imageUrlUploaded = imageUrl;
      if (file) {
        imageUrlUploaded = await s3Repository.uploadImage({
          file,
          name: Math.floor(Math.random() * 100001),
        });
      }
      if (imageUrlToRemove) {
        await s3Repository.deleteImages([imageUrlToRemove]);
      }

      await advertisementsRepository.update(id, {
        type,
        description,
        imageUrl: imageUrlUploaded || '',
        redirectUrl,
        brandingId: branding?.value || extractBrandingValues(selectedProducts),
      });
      toastr.success('Propaganda salva com sucesso!');
      onSubmit();
    } catch (err) {
      toastr.warning(
        err.response?.data?.message ||
          'Ocorreu um erro ao atualizar a propaganda. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  const productOptions = [
    { value: '*', label: 'Todos' },
    ...BRANDINGS.map(({ id, name }) => ({ value: id, label: name })),
  ];

  return (
    <Form
      initialValues={{}}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      loading={loading}
      type={type}
      setType={setType}
      branding={branding}
      handleBrandingChange={(e) => {
        setBranding(e);
      }}
      productOptions={productOptions}
      selectedProducts={selectedProducts}
      setSelectedProducts={setSelectedProducts}
    />
  );
};

export default Advertising;
