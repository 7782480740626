import { Document, Page, pdf, PDFViewer, View } from '@react-pdf/renderer';

import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useLoading } from 'v2/hooks/useLoading/useLoading';
import { getPrintMobileChecklist } from 'v2/repositories/MobileChecklistRepository';

import { ItemChecklist } from './ItemsChecklist';
import { VehicleChecklist } from './VehicleChecklist';
import { ExternalChecklist } from './ExternalChecklist';
import { HeaderChecklist } from './HeaderChecklist';
import { CustomerChecklist } from './CustomerChecklist';
import { SaleChecklist } from './SaleChecklist';
import { styleChecklist } from './MobileChecklist.styles';
import { ConclusionChecklist } from './ConclusionChecklist';

const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export function MobileChecklist({ params }) {
  const { LoadingComponent, fetchLoading } = useLoading();

  const [report, setReport] = useState(null);

  async function handleLoadData() {
    if (!params) return;
    try {
      const response = await getPrintMobileChecklist(params);
      setReport(response);
    } catch (error) {
      console.error(error);
      toastr.error('Erro ao carregar dados', error.message);
    }
  }

  const pdfContent = (
    <Document
      title={`impresso-checklist-${format(new Date(), 'dd/MM/yyyy')}`}
      subject={`Venda Nº:${report?.sale?.code}`}
    >
      <Page style={styleChecklist.page}>
        <View wrap={false}>
          <HeaderChecklist company={report?.company} />
        </View>
        <SaleChecklist sale={report?.sale} />
        <CustomerChecklist customer={report?.customer} />
        <VehicleChecklist
          external={report?.external}
          vehicle={report?.vehicle}
          internal={report?.internal}
        />
        <ExternalChecklist external={report?.external} />
        <View break>
          <ItemChecklist items={report?.items} />
        </View>
        <ConclusionChecklist conclusion={report?.conclusion} />
      </Page>
    </Document>
  );

  const downloadPDF = async (pdfContent) => {
    try {
      const blob = await pdf(pdfContent).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `checklist-${format(new Date(), 'dd-MM-yyyy')}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      toastr.error('Erro ao gerar PDF', error.message);
    }
  };

  useEffect(() => {
    fetchLoading(handleLoadData);
  }, [params]);

  useEffect(() => {
    if (report && isMobileDevice()) {
      fetchLoading(downloadPDF(pdfContent));
    }
  }, [report]);

  if (isMobileDevice()) {
    return (
      <LoadingComponent>
        <div className="flex center w-100 h-100">
          <button
            className="button button-h35 button-green"
            onClick={() => fetchLoading(downloadPDF(pdfContent))}
          >
            Baixar Novamente
          </button>
        </div>
      </LoadingComponent>
    );
  }

  return (
    <LoadingComponent>
      <PDFViewer
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      >
        {pdfContent}
      </PDFViewer>
    </LoadingComponent>
  );
}
