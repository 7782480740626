import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpen,
  faEdit,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import DownloadXlsButton from '../../../components/DownloadXlsButton';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import Card from 'components/Card/Card.jsx';
import { getProvidersToExport } from './excelHelpers';
import { xlsColumns } from './excelHelpers';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import {
  cnpjMask,
  cpfMask,
  phoneMask,
} from 'client/components/ToNormalize/ToNormalize';
import providersRepository from '../../../repositories/Providers';
import useFilters from '../../../hooks/useFilters';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import { format } from 'date-fns';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';
import ImportFromXlsModal from 'client/components/ImportFromXlsModal';
import SearchInput from 'components/SearchInput';

import './styles.css';
import useDebounce from 'hooks/useDebounce';

const HomeProviders = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [providers, setProviders] = useState([]);
  const [providersToExport, setProvidersToExport] = useState([]);
  const [isImportFromXlsModalOpen, setIsImportFromXlsModalOpen] =
    useState(false);

  const { isPlanFree, isPlanStart } = usePlanSignatureContext();

  const { companyId } = useAuth();
  const { statusFilter, typeFilter } = useFilters();
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);
  const { type, setType } = typeFilter;
  const { status, setStatus } = statusFilter;

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (!!companyId) {
      loadProviders();
    }
  }, [currentPage, pageLimit, queryDebounced, status, type]);

  useEffect(() => {
    if (!!companyId) {
      fetchProvidersToDownload();
    }
  }, [status, type]);

  const loadProviders = async () => {
    setLoading(true);
    try {
      const { providers, total } = await providersRepository.getAllByCompany({
        companyId,
        page: currentPage + 1,
        limit: pageLimit,
        query,
        status,
        type,
      });

      const serializedProviders = providers.map((provider) => {
        return {
          ...provider,
          Phone: provider.Phones[0]
            ? provider.Phones[0].Number_Phone1
              ? provider.Phones[0].Number_Phone1
              : provider.Phones[0].Number_Phone2
            : '',
        };
      });

      setProviders(serializedProviders);
      setTotalPages(Math.ceil(total / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os fornecedores. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };
  const fetchProvidersToDownload = async () => {
    setLoading(true);
    try {
      const providers = await providersRepository.getAllByCompany({
        companyId,
        status,
        type,
      });

      const serializedProviders = providers.map((provider) => {
        return {
          ...provider,
          Phone: provider.Phones[0]
            ? provider.Phones[0].Number_Phone1
              ? provider.Phones[0].Number_Phone1
              : provider.Phones[0].Number_Phone2
            : '',
        };
      });

      const providersInfoToExport = await getProvidersToExport(
        serializedProviders
      );
      setProvidersToExport(providersInfoToExport);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao exportar o xls. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  return (
    <>
      <Card
        content={
          <div>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <ModalAlertCompany
                  show={noCompanyModal}
                  onHide={() => setNoCompanyModal(false)}
                />
                <button
                  className="btn btn-sucesso button-h35"
                  onClick={() =>
                    !!companyId
                      ? history.push(constants.ROUTES.PROVIDER)
                      : setNoCompanyModal(true)
                  }
                  style={{ fontSize: '12px' }}
                >
                  + Novo Fornecedor
                </button>
              </div>
              <div>
                <SearchInput
                  placeholder="Pesquisa por Nome Fantasia, CPF/CNPJ ou Razão Social"
                  value={query}
                  onChange={(e) => handleChangeQuery(e.target.value)}
                  background={'#FFFFFF'}
                  style={{
                    fontSize: '12px',
                    height: '35px',
                    width: '350px',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Status:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  onChange={(event) => setStatus(event.target.value)}
                  value={status}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Ambos</option>
                  <option value="true">Ativo</option>
                  <option value="false">Inativo</option>
                </select>
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Tipo:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  onChange={(event) => setType(event.target.value)}
                  value={type}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Ambos</option>
                  <option value="Juridica">Jurídica</option>
                  <option value="Fisica">Física</option>
                </select>
              </div>
              <div>
                <DownloadXlsButton
                  archiveName={`Fornecedores - ${format(
                    new Date(),
                    'dd-MM-yyyy-HH-mm'
                  )}`}
                  data={providersToExport}
                  className="btn btn-export"
                  disabled={loading || !providers.length}
                  columns={xlsColumns}
                  style={{ fontSize: '12px' }}
                >
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />{' '}
                  Exportar .xls
                </DownloadXlsButton>
              </div>

              {!isPlanFree && !isPlanStart ? (
                <div>
                  <button
                    className="btn button-h35"
                    onClick={() => setIsImportFromXlsModalOpen(true)}
                    title="Importar Fornecedores"
                    style={{
                      backgroundColor: '#5bc0de',
                      borderColor: '#5bc0de',
                      color: '#FFFFFF',
                      fontSize: '12px',
                    }}
                  >
                    <FontAwesomeIcon color="white" icon={faFileUpload} />
                    &nbsp;Importar Fornecedores
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>

            <div>
              <ReactTable
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  width: '100%',
                  fontSize: '12px',
                }}
                data={providers}
                columns={[
                  {
                    Header: 'CPF/CNPJ',
                    accessor: 'cpfCnpj',
                    headerClassName: 'text-center',
                    className: 'texto',
                    Cell: (props) => (
                      <>
                        {!!props.value
                          ? props.original.type === 'Juridica'
                            ? cnpjMask(props.value)
                            : cpfMask(props.value)
                          : null}
                      </>
                    ),
                  },
                  {
                    Header: 'Tipo',
                    accessor: 'type',
                    headerClassName: 'text-center',
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor:
                            props.value === 'Fisica' ? '#0d45a4' : '#631678',
                        }}
                      >
                        {props.value}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Nome/Razão Social',
                    accessor: 'companyName',
                    headerClassName: 'text-center',
                    className: 'texto',
                  },
                  {
                    Header: 'Nome Fantasia',
                    accessor: 'tradingName',
                    headerClassName: 'text-center',
                    className: 'texto',
                  },
                  {
                    Header: 'Telefone',
                    accessor: 'Phone',
                    headerClassName: 'text-center',
                    className: 'texto',
                    Cell: (props) => (
                      <>{props.value !== '' ? phoneMask(props.value) : ''}</>
                    ),
                  },
                  {
                    Header: 'Status',
                    accessor: 'isActive',
                    headerClassName: 'text-center',
                    width: 100,
                    className: 'texto',
                    Cell: (props) => (
                      <Button
                        id="botaoReactTable"
                        style={{
                          backgroundColor: !!props.value ? 'green' : 'red',
                        }}
                      >
                        {!!props.value ? 'Ativo' : 'Inativo'}
                      </Button>
                    ),
                  },
                  {
                    Header: 'Ações',
                    accessor: 'id',
                    headerClassName: 'text-left',
                    filterable: false,
                    className: 'texto',
                    width: 100,
                    Cell: (props) => (
                      <div className="table-actions">
                        <Link to={{ pathname: `provider#${props.value}` }}>
                          <FontAwesomeIcon
                            title="Editar"
                            cursor="pointer"
                            icon={faEdit}
                          />
                        </Link>
                        <Link
                          to={{ pathname: `provider-products#${props.value}` }}
                        >
                          <FontAwesomeIcon
                            title="Produtos fornecedores"
                            cursor="pointer"
                            icon={faBoxOpen}
                          />
                        </Link>
                      </div>
                    ),
                  },
                ]}
                defaultPageSize={10}
                loading={loading}
                page={currentPage}
                onPageChange={(value) => setCurrentPage(value)}
                pages={totalPages}
                onPageSizeChange={(size) => setPageLimit(size)}
                showPagination={true}
                sortable={true}
                manual
                showPaginationTop={false}
                showPaginationBottom={true}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                defaultSorted={[
                  {
                    id: 'tradingName',
                    desc: false,
                  },
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum Fornecedor encontrado"
                pageText="Página"
                ofText="de"
                rowsText="linhas"
              />
            </div>
          </div>
        }
      />
      <ImportFromXlsModal
        show={isImportFromXlsModalOpen}
        onCancel={() => setIsImportFromXlsModalOpen(false)}
        importType="providers"
        loadItems={loadProviders}
      />
    </>
  );
};

export default withRouter(HomeProviders);
