import React, { useState, useEffect } from 'react';
import './styles.css';

export const SimpleCarousel = ({ items, interval = 4000, onClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (items.length <= 1) return;

    const timer = setInterval(() => {
      if (!isHovered) {
        setCurrentIndex((prevIndex) =>
          prevIndex + 1 >= items.length ? 0 : prevIndex + 1
        );
      }
    }, interval);

    return () => clearInterval(timer);
  }, [items.length, interval, isHovered]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 >= items.length ? 0 : prevIndex + 1
    );
  };

  if (items.length === 0) return null;

  return (
    <div
      className="simple-carousel"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className="carousel-slide"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {items.map((item, index) => (
          <div key={index} className="carousel-item">
            <img
              src={item.imageUrl || item.Details[0].imageUrl}
              alt={item.description}
              onClick={() => onClick(item)}
              className="advertisement-image"
            />
          </div>
        ))}
      </div>
      {items.length > 1 && (
        <>
          <button className="carousel-button prev-button" onClick={handlePrev}>
            ‹
          </button>
          <button className="carousel-button next-button" onClick={handleNext}>
            ›
          </button>
        </>
      )}
    </div>
  );
};
