import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import { isCNPJ } from 'brazilian-values';
import { styleChecklist } from './MobileChecklist.styles';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';

export function HeaderChecklist({ company }) {
  if (!company) return <View />;
  const { address, cpfCnpj, logo, email, name, phone } = company;

  return (
    <>
      <View fixed style={styleChecklist.flexRow}>
        {logo && (
          <View
            style={{
              width: 'auto',
              height: 'auto',
              maxWidth: 138,
              maxHeight: 83,
              border: '1px solid black',
              marginRight: 10,
            }}
          >
            <Image
              src={logo}
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
            />
          </View>
        )}
        <View
          fixed
          style={{ flexDirection: 'column', justifyContent: 'space-evenly' }}
        >
          <Text style={styleChecklist.mainTitle}>Checklist de Entrada</Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Text style={[styleChecklist.bold]}>{name}</Text>
            <View style={styleChecklist.flexRow}>
              <Text style={styleChecklist.bold}>
                {isCNPJ(cpfCnpj) ? 'CNPJ' : 'CPF'}:{' '}
              </Text>
              <Text>{cpfOrCnpjMask(cpfCnpj)}</Text>
            </View>
          </View>
          <Text>
            {address.address}, {address.number}, {address.neighborhood} -{' '}
            {address.city}/{address.state}
          </Text>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <View style={[styleChecklist.flexRow, styleChecklist.spaceRow]}>
              <Text style={styleChecklist.bold}>Telefone: </Text>
              <Text>{phoneMask(phone)}</Text>
            </View>
            <View style={styleChecklist.flexRow}>
              <Text style={styleChecklist.bold}>Email: </Text>
              <Text>{email}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styleChecklist.line} />
    </>
  );
}
