import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { useAuth } from 'contexts/auth';
import useDebounce from 'hooks/useDebounce';

import kitsRepository from 'repositories/Kit';
import pdvRepository from 'repositories/PDVs';
import brandsRepository from 'repositories/Brands';
import ProductsRepository from 'repositories/Products';

import ImagesModal from 'client/components/ProductsServicesTable/ImagesModal';
import SearchModalTableSimilarity from './SearchModalTableSimilarity';

import './styles.css';

const SearchSimilarityModal = ({
  handleClose,
  product,
  handleAddItemCallback,
  allowPromotionalPrices,
}) => {
  const { companyId } = useAuth();

  const similarProductId = product.productId;

  const [productToVisualize, setProductToVisualize] = useState({});
  const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);

  const vehicleModelA = '';
  const productQueryA = '';

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);

  const [productQueryInput, setProductQueryInput] = useState('');
  const [vehicleModelInput, setVehicleModelInput] = useState('');
  const [yearVehicleInput, setYearVehicleInput] = useState('');

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [brandId, setBrandId] = useState([
    {
      value: '*',
      label: 'Todas',
    },
  ]);
  const [searchType, setSearchType] = useState('Produtos');

  const [kits, setKits] = useState([]);
  const [pagesKits, setPagesKits] = useState(0);
  const [kitSearchQuery, setKitSearchQuery] = useState('');

  const [productQueryDebounced, setProductQueryDebounced] = useState('');
  const [vehicleModelDebounced, setVehicleModelDebounced] = useState('');
  const [kitSearchDebounced, setKitSearchDebounced] = useState('');
  const [yearVehicleDebounced, setYearVehicleDebounced] = useState('');

  const debouncedSaveQuery = useDebounce(setProductQueryDebounced, 600);
  const debouncedSaveVehicleModel = useDebounce(setVehicleModelDebounced, 600);
  const debouncedKitSearchQuery = useDebounce(setKitSearchDebounced, 600);
  const debouncedKitSearchYear = useDebounce(setYearVehicleDebounced, 600);

  const [brands, setBrands] = useState([]);

  const loadBrands = async () => {
    try {
      //TODO: viabilizar remover o limit 9999(select multi async)
      const brands = await brandsRepository.getAllByCompany(companyId, {
        page: 1,
        limit: 9999,
      });
      const serializedBrands = brands.rows.map(
        (brand) => ({
          label: brand.Description,
          value: brand.id,
        }),
        [brands.rows]
      );

      serializedBrands.unshift({ value: '*', label: 'Todas' });
      setBrands(serializedBrands);
      setBrandId(serializedBrands);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    }
  };

  useEffect(() => {
    loadBrands();
  }, []);

  const handleOpenProductImageModal = async (product) => {
    try {
      const productsPhotos = await ProductsRepository.getPhotos(
        product.productId
      );

      if (!productsPhotos.length)
        return toastr.warning(
          'Produto sem foto',
          'Nenhuma foto foi identificada para esse produto'
        );

      setProductToVisualize({ ...product, ProductsPhotos: productsPhotos });
      setIsImagesModalOpen(true);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar as fotos do produto',
        'Por favor, tente novamente'
      );
    }
  };

  const handleSearchProducts = async () => {
    setLoading(true);
    try {
      const isAllBrandIdSelected = brandId.some((brand) => brand.value === '*');
      const brandIds = brandId
        .filter((brand) => brand.value !== '*')
        .map((brand) => brand.value)
        .join(',');

      const params = {
        limit: pageLimit,
        page: currentPage + 1,
        productQueryA: productQueryA ? productQueryA : productQueryDebounced,
        vehicleModelA: vehicleModelA ? vehicleModelA : vehicleModelDebounced,
        productQueryB: productQueryDebounced,
        vehicleModelB: vehicleModelDebounced,
        similarProductId,
      };

      if (!isAllBrandIdSelected) {
        params.brandsIds = `[${brandIds}]`;
      }

      if (!!yearVehicleDebounced) {
        params.yearVehicle = yearVehicleDebounced;
      }

      const response = await pdvRepository.getProducts(companyId, params);

      response.products = response.products.filter(
        (obj) => obj.productId !== product.productId
      );

      setTotalProducts(response.count);
      setData(response.products);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Não foi possível realizar a busca de produtos.',
        'Por favor, tente novamente.'
      );
    } finally {
      setLoading(false);
    }
  };

  async function loadKits() {
    setLoading(true);

    try {
      const response = await kitsRepository.getAll({
        companyId,
        status: 1,
        page: currentPage + 1,
        limit: pageLimit,
        query: kitSearchDebounced,
        similarProductId,
      });

      const serializedKits = response.kits.rows.map((kit) => ({
        Products: response.kitItemsProducts.filter(
          (product) => product.kitId === kit.id
        ),
        Services: response.kitItemsServices.filter(
          (service) => service.kitId === kit.id
        ),
        productDescription: kit.description,
        productSalePrice: kit.priceTotal,
        productCode: '---',
        productManufacturerRef: '---',
        productBarCode: kit.barCode,
        productQuantity: '---',
        brandDescription: '---',
        vehicleModel: '---',
        productLocation: '---',
      }));

      setPagesKits(Math.ceil(response.kits.count / pageLimit));
      setKits(serializedKits);
    } catch (err) {
      console.error(err);
      if (!kits) {
        toastr.warning(
          'Ocorreu um erro ao buscar os kits. Por favor, tente novamente.'
        );
      }
    }
    setLoading(false);
  }

  function handleChangeProductQuery(value) {
    setProductQueryInput(value);
    debouncedSaveQuery(value);
  }

  function handleChangeVehicleQuery(value) {
    setVehicleModelInput(value);
    debouncedSaveVehicleModel(value);
  }

  function handleChangeQuerySearchKit(value) {
    setKitSearchQuery(value);
    debouncedKitSearchQuery(value);
  }

  function handleChangeYear(value) {
    const valueOnlyNumbers = value.replace(/\D/g, '');

    if (value === '') {
      setYearVehicleInput(valueOnlyNumbers);
      debouncedSaveVehicleModel(valueOnlyNumbers);
      return;
    }

    const firstDigit = Number(valueOnlyNumbers[0]);

    if (firstDigit > 2 || firstDigit === 0) {
      setYearVehicleInput('');
      debouncedSaveVehicleModel('');

      return toastr.warning(
        'Ano inválido',
        'Insira um ano válido para aplicação e tente novamente'
      );
    }

    setYearVehicleInput(valueOnlyNumbers);
    setYearVehicleDebounced(valueOnlyNumbers);
  }

  useEffect(() => {
    handleSearchProducts();

    if (searchType === 'Kit') {
      loadKits();
    }
  }, [
    pageLimit,
    currentPage,
    productQueryDebounced,
    vehicleModelDebounced,
    brandId,
    searchType,
    kitSearchDebounced,
    yearVehicleDebounced,
  ]);

  return (
    <>
      <Modal
        dialogClassName="modal-80w"
        show={true}
        onHide={handleClose}
        animation
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>
              Similaridade do Produto {product.productDescription}
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchModalTableSimilarity
            loading={loading}
            handleClose={handleClose}
            data={data}
            totalProducts={totalProducts}
            totalPages={totalPages}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            brandId={brandId}
            setBrandId={setBrandId}
            productQuery={productQueryInput}
            setProductQueryDebounced={setProductQueryDebounced}
            vehicleModel={vehicleModelInput}
            setVehicleModelDebounced={setVehicleModelDebounced}
            handleChangeProductQuery={handleChangeProductQuery}
            handleChangeVehicleQuery={handleChangeVehicleQuery}
            handleAddItem={handleAddItemCallback}
            allowPromotionalPrices={allowPromotionalPrices}
            searchType={searchType}
            handleChangeQuerySearchKit={handleChangeQuerySearchKit}
            kits={kits}
            pagesKits={pagesKits}
            setSearchType={setSearchType}
            kitSearchQuery={kitSearchQuery}
            brands={brands}
            handleChangeYear={handleChangeYear}
            yearVehicle={yearVehicleInput}
            handleOpenProductImageModal={handleOpenProductImageModal}
            hideSimilarityIcon
            hideSearchTypeSelect
          />
        </Modal.Body>
      </Modal>

      {isImagesModalOpen && (
        <ImagesModal
          onHide={() => setIsImagesModalOpen(false)}
          onCancel={() => setIsImagesModalOpen(false)}
          productDescription={productToVisualize.Description}
          productPhotos={productToVisualize.ProductsPhotos}
        />
      )}
    </>
  );
};

export default SearchSimilarityModal;
