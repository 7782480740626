import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { format } from 'date-fns';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from 'contexts/auth';
import { toastr } from 'react-redux-toastr';
import Button from 'client/components/CustomButton/Button.jsx';

import billsToReceiveRepository from 'repositories/BillsToReceive';
import accountPlansRepository from 'repositories/AccountPlans';
import billsToPayRepository from 'repositories/BillsToPay';

import useFilters from 'hooks/useFilters';
import { usePlanSignatureContext } from 'contexts/plan-signature';

const HomeTitleListing = () => {
  const { companyId } = useAuth();

  const [type, setType] = useState('billsToReceive');
  const [typeDate, setTypeDate] = useState('dueDate');
  const [accountPlanId, setAccountPlanId] = useState('');
  const [loading, setLoading] = useState(false);
  const [bills, setBills] = useState([]);
  const [noDataText, setNoDataText] = useState(
    'Selecione o período e filtros para gerar o relatório'
  );
  const [accountPlansOptions, setAccountPlansOptions] = useState([]);

  const [billsTotal, setBillsTotal] = useState(0);

  const { dateFilter, statusFilter } = useFilters();
  const { initialDate, setInitialDate, finalDate, setFinalDate, filterByDate } =
    dateFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();

  const loadAccountPlans = async () => {
    try {
      const { data: accountPlans } = await accountPlansRepository.index({
        companyId,
        isActive: true,
      });

      setAccountPlansOptions(accountPlans);
    } catch (err) {
      toastr.error(
        'OS Digital',
        'Ocorreu um erro ao carregar os planos de conta. Tente novamente!'
      );
    }
  };

  useEffect(() => {
    setStatus('Aberto');
    loadAccountPlans();
  }, []);

  useEffect(() => {
    setBills([]);
    setBillsTotal(0);
  }, [initialDate, finalDate, status, typeDate, type, accountPlanId]);

  useEffect(() => {
    setAccountPlanId('');
  }, [type]);

  const getBills = async () => {
    setLoading(true);
    setBillsTotal(0);
    try {
      let bills;
      if (type === 'billsToReceive') {
        bills = await billsToReceiveRepository.getAllByCompanyPerPeriod(
          companyId,
          initialDate,
          finalDate,
          status,
          typeDate,
          accountPlanId
        );
      } else {
        bills = await billsToPayRepository.getAllByCompanyPerPeriod(
          companyId,
          initialDate,
          finalDate,
          status,
          typeDate,
          accountPlanId
        );
      }
      const serializedBills = bills.map((bill) => ({
        dueDate: bill.dueDate,
        title: bill.code,
        sale: bill.Sales?.Code
          ? bill.Sales?.Code
          : bill.BillsToReceiveSales?.length === 1
          ? bill.BillsToReceiveSales[0]?.Sales?.Code
          : bill.BillsToReceiveSales?.length > 1
          ? 'Várias'
          : '',
        purchase: bill.Purchases?.code,
        parcel: bill.installment,
        customer: bill.Customer?.Company_Name,
        provider: bill.Provider?.companyName,
        addedValue: currency(bill.addedValue),
        discount: currency(bill.discountValue),
        openValue: currency(bill.openValue),
        total: currency(bill.subTotal),
        status: bill.BillStatus.Description,
        creation: bill.createdAt,
        accountPlans: bill.AccountPlans?.description,
        cashierBank: bill.CashierBanks?.description,
        dueDateFormated: format(
          getDateOnlyFromDate(bill.dueDate),
          'dd/MM/yyyy'
        ),
        creationFormated: format(new Date(bill.createdAt), 'dd/MM/yyyy'),
      }));

      if (accountPlanId !== '') {
        const billsTotal = bills.reduce(
          (prev, curr) => prev + curr.subTotal,
          0
        );
        setBillsTotal(billsTotal);
      }

      setBills(serializedBills);
      if (!serializedBills.length) {
        toastr.warning(
          'Nenhum título foi localizado com os filtros definidos.'
        );

        setNoDataText('Nenhum título foi localizado.');
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Ocorreu um erro ao carregar títulos. Por favor, tente novamente'
      );
    }
    setLoading(false);
  };

  return (
    <>
      <section className="return-forecast-container">
        <fieldset style={{ width: '165px' }}>
          <label>Títulos:</label>
          <select
            className="form-control foco-input"
            onChange={(event) => setType(event.target.value)}
            value={type}
          >
            <option value="billsToReceive">Contas a Receber</option>
            <option value="billsToPay">Contas a Pagar</option>
          </select>
        </fieldset>
        <section>
          <fieldset style={{ width: '165px' }}>
            <label>Tipo de Data:</label>
            <select
              className="form-control foco-input"
              onChange={(event) => setTypeDate(event.target.value)}
              value={typeDate}
            >
              <option value="dueDate">Vencimento</option>
              <option value="creation">Criação</option>
            </select>
          </fieldset>
          <fieldset>
            <label>Data Inicial:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={initialDate}
              max={finalDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <label>Data Final:</label>
            <input
              type="date"
              className="form-control foco-input"
              value={finalDate}
              min={initialDate}
              max="9999-12-31"
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </fieldset>

          <fieldset style={{ width: '165px' }}>
            <label>Status:</label>
            <select
              className="form-control foco-input"
              onChange={(event) => setStatus(event.target.value)}
              value={status}
            >
              <option value="Aberto">Em Aberto</option>
              <option value="Finalizado">Finalizado</option>
              <option value="Cancelado">Cancelado</option>
              <option value="">Todos</option>
            </select>
          </fieldset>

          <fieldset style={{ width: '165px' }}>
            <label>Conta:</label>
            <select
              className="form-control foco-input"
              onChange={(event) => setAccountPlanId(event.target.value)}
              value={accountPlanId}
            >
              <option value="">Todas</option>
              {accountPlansOptions
                .filter((accountPlan) =>
                  type === 'billsToReceive'
                    ? accountPlan.type === 'Crédito'
                    : accountPlan.type === 'Débito'
                )
                .map((accountPlan) => (
                  <option key={accountPlan.id} value={accountPlan.id}>
                    {accountPlan.description}
                  </option>
                ))}
            </select>
          </fieldset>
          <div>
            <button
              className="btn btn-sucesso"
              type="submit"
              disabled={initialDate === '' || finalDate === ''}
              onClick={getBills}
            >
              <span
                className={`${loading ? 'fa fa-spinner fa-pulse fa-1x' : ''}`}
              />{' '}
              Gerar relatório
            </button>
          </div>
          <DownloadXlsButton
            archiveName={`Listagem de Títulos ${
              status === '' ? 'Todos' : status
            }
              -${format(new Date(), 'dd-MM-yyyy')}`}
            data={bills}
            className="btn btn-export export-forecast"
            disabled={loading || !bills.length}
            columns={[
              {
                name: 'Vencimento',
                acessor: 'dueDateFormated',
              },
              {
                name: 'Título',
                acessor: 'title',
              },
              {
                name: type === 'billsToReceive' ? 'Venda' : 'Compra',
                acessor: type === 'billsToReceive' ? 'sale' : 'purchase',
              },
              {
                name: 'Parcela',
                acessor: 'parcel',
              },
              {
                name: type === 'billsToReceive' ? 'Cliente' : 'Fornecedor',
                acessor: type === 'billsToReceive' ? 'customer' : 'provider',
              },
              {
                name: 'Acréscimo',
                acessor: 'addedValue',
              },
              {
                name: 'Desconto',
                acessor: 'discount',
              },
              {
                name: 'Valor em Aberto',
                acessor: 'openValue',
              },
              {
                name: 'Total',
                acessor: 'total',
              },
              {
                name: 'Status',
                acessor: 'status',
              },
              {
                name: 'Criação',
                acessor: 'creationFormated',
              },
              {
                name: 'Caixa/Banco',
                acessor: 'cashierBank',
              },
              !isPlanFree &&
                !isPlanBasic &&
                !isPlanStart && {
                  name: 'Plano de Contas',
                  acessor: 'accountPlans',
                },
            ]}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </section>
      </section>
      <ReactTable
        style={{
          fontSize: '12px',
          marginTop: '30px',
          width: '100%',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
        data={bills}
        columns={[
          {
            Header: 'Vencimento',
            accessor: 'dueDate',
            headerClassName: 'text-left',
            width: 110,
            className: 'texto',
            Cell: (props) => (
              <span>
                {format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')}
              </span>
            ),
          },
          {
            Header: 'Título',
            accessor: 'title',
            headerClassName: 'text-left',
            width: 80,
            className: 'texto',
          },
          {
            Header: type === 'billsToReceive' ? 'Venda' : 'Compra',
            accessor: type === 'billsToReceive' ? 'sale' : 'purchase',
            headerClassName: 'text-left',
            width: 80,
            className: 'texto',
          },
          {
            Header: type === 'billsToReceive' ? 'Cliente' : 'Fornecedor',
            accessor: type === 'billsToReceive' ? 'customer' : 'provider',
            headerClassName: 'text-left',
            width: 190,
            className: 'texto',
          },
          {
            Header: 'Acréscimo',
            accessor: 'addedValue',
            headerClassName: 'text-left',
            width: 100,
            className: 'texto',
          },
          {
            Header: 'Desconto',
            accessor: 'discount',
            headerClassName: 'text-left',
            width: 100,
            className: 'texto',
          },
          {
            Header: 'Valor em Aberto',
            accessor: 'openValue',
            headerClassName: 'text-left',
            width: 120,
            className: 'texto',
          },
          {
            Header: 'Total',
            accessor: 'total',
            headerClassName: 'text-left',
            width: 100,
            className: 'texto',
          },
          {
            Header: 'Status',
            accessor: 'status',
            headerClassName: 'text-left',
            width: 140,
            className: 'texto',
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor:
                    props.value === 'Aberto'
                      ? '#ffcc00'
                      : props.value === 'Finalizado'
                      ? 'green'
                      : 'red',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Conta',
            accessor: 'accountPlans',
            headerClassName: 'text-left',
            width: 200,
            className: 'texto',
            Cell: (props) => {
              return <span>{props.value || '-'}</span>;
            },
          },
          {
            Header: 'Criação',
            accessor: 'creation',
            headerClassName: 'text-left',
            width: 100,
            className: 'texto',
            Cell: (props) => (
              <span>
                {format(getDateOnlyFromDate(props.value), 'dd/MM/yyyy')}
              </span>
            ),
          },
        ]}
        defaultPageSize={10}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText={noDataText}
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        defaultSorted={[
          { id: typeDate === 'dueDate' ? 'dueDate' : 'creation', desc: true },
        ]}
      />
      {accountPlanId !== '' && (
        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <span>
            <strong>Total: {currency(billsTotal)}</strong>
          </span>
        </div>
      )}
    </>
  );
};
export default HomeTitleListing;
