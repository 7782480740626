import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ReactTable from 'react-table';
import Button from 'client/components/CustomButton/Button.jsx';

const CFOPTable = ({
  natureOfTransactions,
  totalPages,
  currentPage,
  setCurrentPage,
  pageLimit,
  setPageLimit,
  loading,
  companyId,
  setNoCompanyModal,
}) => {
  return (
    <div>
      <ReactTable
        key={`${currentPage}-${pageLimit}`}
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          fontSize: '12px',
        }}
        data={natureOfTransactions}
        columns={[
          {
            Header: 'CFOP',
            id: 'CFOP',
            accessor: (props) => Number(props.CFOP),
            headerClassName: 'text-left',
            Cell: (props) =>
              companyId ? (
                <Link
                  id="linkVenda"
                  to={{ pathname: `CFOP#${props.original.id}` }}
                  title="Clique para editar"
                >
                  <button className="btn-link">{props.value.toFixed(3)}</button>
                </Link>
              ) : (
                <Link id="linkVenda">
                  <button
                    className="btn-link"
                    onClick={() => setNoCompanyModal(true)}
                  >
                    {props.value.toFixed(3)}
                  </button>
                </Link>
              ),
          },
          {
            Header: 'Descrição',
            accessor: 'Description',
            headerClassName: 'text-left',
          },
          {
            Header: 'Tipo',
            accessor: 'Type',
            headerClassName: 'text-left',
            Cell: (props) => (
              <Button
                id="botaoReactTable"
                style={{
                  zIndex: '0',
                  width: '100%',
                  height: '100%',
                  backgroundColor:
                    props.value === 'Entrada'
                      ? 'green'
                      : props.value === 'Saída'
                      ? 'red'
                      : props.value === 'Simples Remessa'
                      ? 'purple'
                      : props.value === 'Devolução de Entrada'
                      ? 'blue'
                      : 'gray',
                }}
              >
                {props.value}
              </Button>
            ),
          },
          {
            Header: 'Ações',
            accessor: 'Actions',
            headerClassName: 'text-left',
            filterable: false,
            width: 100,
            Cell: (props) =>
              companyId ? (
                <Link to={{ pathname: `CFOP#${props.original.id}` }}>
                  <FontAwesomeIcon
                    title="Editar"
                    cursor="pointer"
                    style={{
                      height: '1.5em',
                      width: '1.5em',
                      color: 'black',
                    }}
                    icon={faEdit}
                  />
                </Link>
              ) : (
                <i
                  className="fa fa-pencil-square-o"
                  aria-hidden="false"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setNoCompanyModal(true)}
                />
              ),
          },
        ]}
        defaultPageSize={10}
        pageSize={pageLimit}
        loading={loading}
        page={currentPage}
        onPageChange={(value) => setCurrentPage(value)}
        pages={totalPages}
        onPageSizeChange={(size) => setPageLimit(size)}
        showPagination={true}
        sortable={true}
        manual
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: 'Description',
            desc: false,
          },
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />
    </div>
  );
};

export default CFOPTable;
