import React, { useEffect, useState, useCallback } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTools,
  faTrashAlt,
  faEquals,
  faCopy,
  faCloudDownloadAlt,
  faFileUpload,
  faCarSide,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import { format } from 'date-fns';

import Card from 'components/Card/Card.jsx';
import DownloadXlsButton from 'components/DownloadXlsButton';
import ImportFromXlsModal from 'client/components/ImportFromXlsModal';
import Button from 'client/components/CustomButton/Button.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import AlertModal from 'components/AlertModal';
import { currency, encrypt } from 'client/components/ToNormalize/ToNormalize';
import { useAuth } from '../../../contexts/auth';
import constants from '../../../utils/constants';
import ProductsRepository from 'v2/repositories/ProductsRepository';
import productsRepository from '../../../repositories/Products';
import brandsRepository from '../../../repositories/Brands';
import kitsRepository from '../../../repositories/Kit';

import useDebounce from '../../../hooks/useDebounce';
import SearchInput from '../../../components/SearchInput';
import { usePlanSignatureContext } from '../../../contexts/plan-signature';

import './styles.css';
import DuplicateProductModal from './components/DuplicateProductModal';
import BulkDeletionModal from './BulkDeletionModal';
import CompanyConfigRepository from 'repositories/CompanyConfig';
import NewProductWithKitModal from './components/NewProductWithKitModal';
import { SelectBrand } from 'v2/client/components/SelectBrand';

const HomeProducts = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [isImportFromXlsModalOpen, setIsImportFromXlsModalOpen] =
    useState(false);

  const [newProductWithKitModal, setNewProductWithKitModal] = useState(false);

  const [pagesProduct, setPagesProduct] = useState(0);
  const [pagesKit, setPagesKit] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [searchQueryProduct, setSearchQueryProduct] = useState('');
  const [queryProductDebounced, setQueryProductDebounced] = useState('');
  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState('');
  const [statusProduct, setStatusProduct] = useState('');
  const [usedProducts, setUsedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [deletedProducts, setDeletedProducts] = useState(false);
  const [productsToExport, setProductsToExport] = useState([]);
  const [isXlsExportReadyProducts, setIsXlsExportReadyProducts] =
    useState(false);
  const [isXlsExportLoadingProducts, setIsXlsExportLoadingProducts] =
    useState(false);

  const [kits, setKits] = useState([]);
  const [searchQueryKit, setSearchQueryKit] = useState('');
  const [queryKitDebounced, setQueryKitDebounced] = useState('');
  const [statusKit, setStatusKit] = useState('');
  const [kitsToExport, setKitsToExport] = useState([]);
  const [isXlsExportReadyKits, setIsXlsExportReadyKits] = useState(false);
  const [isXlsExportLoadingKits, setIsXlsExportLoadingKits] = useState(false);

  const [displayBy, setDisplayBy] = useState('products');
  const [isPriceWholesale, setIsPriceWholesale] = useState(false);
  const [priceDisplayBy, setPriceDisplayBy] = useState('retail');
  const [isKitConfigOn, setIsKitConfigOn] = useState(false);
  const [showBulkDeletionModal, setShowBulkDeletionModal] = useState(false);
  const [isActive, setIsActive] = useState('');
  const [brandSelected, setBrandSelected] = useState(null);

  const [isDeletingProduct, setIsDeletingProduct] = useState(false);

  const handleShowBulkDeletionModal = (status) => {
    setShowBulkDeletionModal(status);
  };

  const { companyId } = useAuth();

  const debouncedSaveQuerySearchProduct = useDebounce(
    setQueryProductDebounced,
    1200
  );
  const debouncedSaveQuerySearchKit = useDebounce(setQueryKitDebounced, 1200);

  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();

  useEffect(() => {
    loadProducts({
      page: 0,
      pageSize,
      query: searchQueryProduct,
      status: isActive,
      brandId,
    });
    if (productsToExport.length > 0) {
      loadProductsToExport({
        query: searchQueryProduct,
        status: statusProduct,
        brandId,
      });
    }
  }, [isActive, brandId, queryProductDebounced]);

  useEffect(() => {
    if (displayBy === 'kits') {
      loadKits({
        page: 0,
        pageSize,
        query: searchQueryKit,
        status: statusKit,
      });
      if (kitsToExport.length > 0) {
        loadKitsToExport({
          query: searchQueryKit,
          status: statusKit,
        });
      }
    }
  }, [statusKit, queryKitDebounced]);

  useEffect(() => {
    if (deletedProducts) {
      loadProducts({
        page: 0,
        pageSize,
        query: searchQueryProduct,
        status: statusProduct,
        brandId,
      });
      setDeletedProducts(false);
    }
  }, [deletedProducts]);

  useEffect(() => {
    if (displayBy === 'products') {
      handleClearFiltersKit();
      loadProducts({
        page: 0,
        pageSize,
        query: searchQueryProduct,
        status: isActive,
        brandId,
      });
    } else {
      handleClearFiltersProduct();
      loadKits({
        page: 0,
        pageSize,
        query: searchQueryKit,
        status: isActive,
      });
    }
  }, [displayBy]);

  useEffect(() => {
    loadKitConfig();
  }, []);

  async function handleClearFiltersKit() {
    setSearchQueryKit('');
    setStatusKit('');
    setIsActive('');
  }

  async function handleClearFiltersProduct() {
    setSearchQueryProduct('');
    setStatusProduct('');
    setBrandId('');
    setIsActive('');
  }

  const handleBrandChange = (item) => {
    setBrandSelected(item);
    setBrandId(item && item.value ? item.value.id : '');
  };

  async function loadKits(state) {
    setLoading(true);

    try {
      const response = await kitsRepository.getAll({
        companyId,
        status: state.status,
        page: state.page + 1,
        limit: state.pageSize,
        query: state.query,
      });

      const serializeKits = response.kits.rows.map((kit) => ({
        ...kit,
      }));

      setPagesKit(Math.ceil(response.kits.count / state.pageSize));
      setKits(serializeKits);
    } catch (err) {
      console.error(err);
      if (!kits) {
        toastr.warning(
          'Ocorreu um erro ao buscar os kits. Por favor, tente novamente.'
        );
      }
    }
    setLoading(false);
  }

  async function loadKitsToExport(state) {
    setIsXlsExportReadyKits(false);
    setIsXlsExportLoadingKits(true);
    toastr.warning(
      'Preparando Exportação',
      'Avisaremos quando estiver concluída. Por favor, aguarde.'
    );
    try {
      const response = await kitsRepository.getAll({
        companyId,
        status: state.status,
        page: state.page + 1,
        limit: state.pageSize,
        query: state.query,
      });

      const serializeKits = response.kits.rows.map((kit) => ({
        ...kit,
        priceFormatted: currency(kit.priceTotal),
        isActive: kit.isActive ? 'Ativo' : 'Inativo',
      }));

      setKitsToExport(serializeKits);
      setIsXlsExportReadyKits(true);
      toastr.success(
        'Exportação Pronta',
        'Clique no botão para baixar o relatório.'
      );
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os kits para exportação. Por favor, tente novamente mais tarde.'
      );
    } finally {
      setIsXlsExportLoadingKits(false);
    }
  }

  async function loadProducts(state) {
    setLoading(true);
    const typePriceProd = await CompanyConfigRepository.show(companyId);
    setIsPriceWholesale(typePriceProd.sellingPriceType);
    try {
      if (usedProducts.length === 0) await getUsedProducts();

      const response = await ProductsRepository.index({
        companyId: companyId || 0,
        page: state.page + 1,
        limit: state.pageSize,
        query: state.query,
        isActive: isActive,
        brandId: state.brandId,
      });

      setPagesProduct(Math.ceil(response.count / state.pageSize));
      setProducts(response.rows);
    } catch (err) {
      console.error(err);
      if (!products) {
        toastr.warning(
          'Ocorreu um erro ao buscar os produtos. Por favor, tente novamente'
        );
      }
    }
    setLoading(false);
  }

  async function loadProductsToExport(state) {
    setIsXlsExportReadyProducts(false);
    setIsXlsExportLoadingProducts(true);
    toastr.warning(
      'Preparando Exportação',
      'Avisaremos quando estiver concluída. Por favor, aguarde.'
    );
    try {
      const response = await ProductsRepository.index({
        companyId: companyId || 0,
        query: state.query,
        isActive: state.status,
        brandId: state.brandId,
        page: 1,
        limit: 100000,
      });

      setProductsToExport(response.rows);
      setIsXlsExportReadyProducts(true);
      toastr.success(
        'Exportação Pronta',
        'Clique no botão para baixar o relatório.'
      );
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os produtos para exportação. Por favor, tente novamente mais tarde.'
      );
    } finally {
      setIsXlsExportLoadingProducts(false);
    }
  }

  function loadXlsExportProducts(state) {
    setIsXlsExportLoadingProducts(true);
    loadProductsToExport(state);
  }

  function loadXlsExportKits(state) {
    setIsXlsExportLoadingKits(true);
    loadKitsToExport(state);
  }

  function handleChangeQuerySearchProduct(value) {
    setSearchQueryProduct(value);
    debouncedSaveQuerySearchProduct(value);
  }

  function handleChangeQuerySearchKit(value) {
    setSearchQueryKit(value);
    debouncedSaveQuerySearchKit(value);
  }

  const handleSimilarityConsult = useCallback((id) => {
    const productIdHash = encrypt(id, '@OS-dig:productId');
    history.push(
      `/client/${constants.ROUTES.SIMILARITIES}?id=${productIdHash}`
    );
  }, []);

  const handleLinkedServiceConsult = useCallback((product) => {
    if (!product.brand) {
      return toastr.warning(
        `O produto não possui MARCA cadastrada, para adicionar um serviço vinculado, verifique o cadastro do produto selecionado`
      );
    }
    const productIdHash = encrypt(product.id, '@OS-dig:productId');
    history.push(
      `/client/${constants.ROUTES.LINKED_SERVICES}?id=${productIdHash}`
    );
  }, []);
  const handlePriceDisplayBy = (value) => {
    setPriceDisplayBy(value);
    toastr.success('Alterado exibição de preço.');
  };

  const handlePartApplicationConsult = useCallback((product) => {
    if (!product.brand) {
      return toastr.warning(
        `O produto não possui MARCA cadastrada, para adicionar aplicação de peça, verifique o cadastro do produto selecionado`
      );
    }
    const productIdHash = encrypt(product.id, '@OS-dig:productId');
    history.push(
      `/client/${constants.ROUTES.PART_APPLICATIONS}?id=${productIdHash}`
    );
  }, []);

  const getUsedProducts = async () => {
    // const usedProducts = await productsRepository.getAllUsed({
    //   Company_id: companyId,
    // })
    // setUsedProducts(usedProducts)
  };

  const handleDelete = (product) => {
    setSelectedProduct(product.id);
    setShowDeleteProductModal(true);
  };

  const deleteProduct = async () => {
    setIsDeletingProduct(true);
    try {
      const params = {
        Company_id: companyId,
        id: selectedProduct,
      };

      const permiss = await productsRepository.getAllUsed(params);

      if (permiss.length > 0) {
        toastr.warning(
          'O produto possui vínculos com entradas, vendas ou fornecedores. Produtos já utilizados não podem ser excluídos.'
        );
        setIsDeletingProduct(false);

        return;
      }

      const response = await productsRepository.deleteProduct(selectedProduct);

      if (response.status !== 202) {
        throw new Error(
          'Ocorreu um erro ao deletar o produto, tente novamente mais tarde'
        );
      }

      toastr.success('Produto excluído com sucesso.');

      await loadProducts({
        page,
        pageSize,
        query: searchQueryProduct,
        isActive: statusProduct,
        brandId,
      });
    } catch (err) {
      toastr.error(err.message);
    } finally {
      setShowDeleteProductModal(false);
      setIsDeletingProduct(false);
    }
  };

  function confirmDuplicateProduct(product) {
    setProduct(product);
    setDuplicateModal(true);
  }

  async function duplicateProduct() {
    setLoading(true);

    try {
      const prod = await productsRepository.getById(product.id);
      if (!prod) throw new Error();

      history.push({ pathname: '/client/product', productInfo: product });
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao duplicar o produto. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  const loadKitConfig = async () => {
    try {
      const companyConfig = await CompanyConfigRepository.show(companyId);
      setIsKitConfigOn(companyConfig.kitRegistration);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as configurações da empresa. Por favor, tente novamente mais tarde.'
      );
    }
  };

  const productsImportDisabled = async () => {
    toastr.warning(
      'Função em manutenção',
      'A importação de produtos está em manutenção, em breve a mesma será reativada. Qualquer dúvida, entre em contato com via atendimento online.'
    );
  };

  return (
    <>
      <Card
        content={
          displayBy === 'products' ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    padding: 0,
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: '10px',
                  }}
                >
                  <ModalAlertCompany
                    show={noCompanyModal}
                    onHide={() => setNoCompanyModal(false)}
                  />
                  <button
                    className="btn btn-sucesso button-h35"
                    onClick={() =>
                      isKitConfigOn && !isPlanFree
                        ? setNewProductWithKitModal(true)
                        : history.push(constants.ROUTES.PRODUCT)
                    }
                    style={{ fontSize: '12px' }}
                  >
                    + Novo Produto
                  </button>

                  <div>
                    <SearchInput
                      placeholder="Pesquisa por Descrição, Código, Ref. Fabricante, Cód. de Barras e Apelido"
                      value={searchQueryProduct}
                      style={{
                        fontSize: '12px',
                        height: '35px',
                        width: '460px',
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                      onChange={(e) =>
                        handleChangeQuerySearchProduct(e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <span style={{ fontSize: '12px' }}>
                      <strong>Exibir: </strong>
                    </span>
                    <select
                      className="form-control foco-input"
                      name="displayBy"
                      value={displayBy}
                      onChange={(e) => setDisplayBy(e.target.value)}
                      style={{ fontSize: '12px' }}
                    >
                      <option value="products" selected>
                        Produtos
                      </option>
                      <option value="kits">Kit</option>
                    </select>
                  </div>
                  {isPriceWholesale === 'Preço Atacado e Varejo' && (
                    <div>
                      <span style={{ fontSize: '12px' }}>
                        <strong>Tipo de Preço:</strong>
                      </span>
                      <select
                        className="form-control foco-input"
                        name="displayBy"
                        value={priceDisplayBy}
                        onChange={async (e) =>
                          handlePriceDisplayBy(e.target.value)
                        }
                        style={{ fontSize: '12px' }}
                      >
                        <option value="retail" selected>
                          Varejo
                        </option>
                        <option value="wholesale">Atacado</option>
                      </select>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    padding: 0,
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: '10px',
                  }}
                >
                  <div>
                    <span style={{ fontSize: '12px' }}>
                      <strong>Status: </strong>
                    </span>
                    <select
                      className="form-control foco-input"
                      name="searchStatus"
                      value={isActive}
                      onChange={(e) => setIsActive(e.target.value)}
                      style={{ fontSize: '12px' }}
                    >
                      <option value="">Ambos</option>
                      <option value="1">Ativo</option>
                      <option value="2">Inativo</option>
                    </select>
                  </div>
                  <div>
                    <span style={{ fontSize: '12px' }}>
                      <strong>Marca: </strong>
                    </span>
                    <SelectBrand
                      value={brandSelected}
                      onChange={handleBrandChange}
                      width="100%"
                      placeholder="Todas"
                      isClearable
                    />
                  </div>
                  <div>
                    {isXlsExportReadyProducts ? (
                      <DownloadXlsButton
                        archiveName={`Produtos-
                  ${format(new Date(), 'dd-MM-yyyy-HH-mm')}`}
                        data={productsToExport}
                        className="btn btn-export export-stone-link"
                        style={{ fontSize: '12px' }}
                        columns={[
                          {
                            name: 'Código',
                            acessor: 'code',
                          },
                          {
                            name: 'Ref. Fabricante',
                            acessor: 'manufacturerRef',
                          },
                          {
                            name: 'Localização',
                            acessor: 'location',
                          },
                          {
                            name: 'Descrição',
                            acessor: 'description',
                          },
                          {
                            name: 'Estoque',
                            acessor: 'stock',
                          },
                          {
                            name: 'Marca',
                            acessor: 'brand',
                          },
                          {
                            name: 'Valor',
                            acessor:
                              priceDisplayBy === 'wholesale'
                                ? 'salePriceWholesale'
                                : 'salePrice',
                          },
                          {
                            name: 'Status',
                            acessor: 'isActive',
                          },
                        ]}
                      >
                        <FontAwesomeIcon
                          color="white"
                          icon={faCloudDownloadAlt}
                        />
                        &nbsp;Iniciar Download
                      </DownloadXlsButton>
                    ) : (
                      <button
                        className="btn btn-export button-h35"
                        onClick={() =>
                          loadXlsExportProducts({
                            query: searchQueryProduct,
                            statusProduct,
                            brandId,
                          })
                        }
                        style={{ fontSize: '12px' }}
                        disabled={isXlsExportLoadingProducts}
                      >
                        {isXlsExportLoadingProducts ? (
                          <span className="fa fa-spinner fa-pulse fa-1x"></span>
                        ) : (
                          <FontAwesomeIcon
                            color="white"
                            icon={faCloudDownloadAlt}
                          />
                        )}
                        &nbsp;
                        {isXlsExportLoadingProducts
                          ? 'Processando'
                          : 'Exportar .xls'}
                      </button>
                    )}
                  </div>

                  {!isPlanFree && !isPlanStart ? (
                    <div>
                      <button
                        className="btn button-h35"
                        onClick={() => setIsImportFromXlsModalOpen(true)}
                        title="Importar Produtos"
                        // onClick={() => productsImportDisabled()}
                        // title="Função em manutenção"
                        style={{
                          backgroundColor: '#5bc0de',
                          borderColor: '#5bc0de',
                          color: '#FFFFFF',
                          fontSize: '12px',
                        }}
                      >
                        <FontAwesomeIcon cursor="pointer" icon={faFileUpload} />
                        &nbsp;Importar Produtos
                      </button>
                    </div>
                  ) : (
                    ''
                  )}

                  {!isPlanFree ? (
                    <div>
                      <button
                        className="btn button-h35"
                        onClick={() => handleShowBulkDeletionModal(true)}
                        title="Exclusão em massa"
                        style={{
                          backgroundColor: '#d55152',
                          borderColor: '#d55152',
                          color: '#FFFFFF',
                          fontSize: '12px',
                        }}
                      >
                        <FontAwesomeIcon cursor="pointer" icon={faTrashAlt} />
                        &nbsp;Exclusão em massa
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div>
                <ReactTable
                  style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    width: '100%',
                    fontSize: '12px',
                  }}
                  data={products}
                  columns={[
                    {
                      Header: 'Código',
                      id: 'code',
                      accessor: (props) => Number(props.code),
                      width: 58,
                    },
                    {
                      Header: 'Ref. Fabricante',
                      accessor: 'manufacturerRef',
                    },
                    {
                      Header: 'Localização',
                      accessor: 'location',
                    },
                    {
                      Header: 'Descrição',
                      accessor: 'description',
                      width: 300,
                    },
                    {
                      Header: 'Estoque',
                      accessor: 'stock',
                    },
                    {
                      Header: 'Marca',
                      accessor: 'brand',
                    },
                    {
                      Header: 'Valor',
                      accessor:
                        priceDisplayBy === 'wholesale'
                          ? 'salePriceWholesale'
                          : 'salePrice',
                      Cell: (props) => currency(props.value),
                      width: 90,
                    },
                    {
                      Header: 'Status',
                      accessor: 'isActive',
                      width: 80,
                      Cell: (props) => (
                        <Button
                          id="botaoReactTable"
                          style={{
                            backgroundColor:
                              props.value === 1 ? 'green' : 'red',
                          }}
                        >
                          {props.value == 1 ? 'Ativo' : 'Inativo'}
                        </Button>
                      ),
                    },
                    {
                      Header: 'Ações',
                      accessor: 'id',
                      filterable: false,
                      width: 173,
                      Cell: (props) => (
                        <>
                          <Link to={{ pathname: `product#${props.value}` }}>
                            <FontAwesomeIcon
                              title="Editar"
                              cursor="pointer"
                              style={{
                                height: '1.6em',
                                width: '1.6em',
                                color: 'black',
                              }}
                              icon={faEdit}
                            />
                          </Link>
                          {!isPlanFree && !isPlanStart && !isPlanBasic && (
                            <FontAwesomeIcon
                              onClick={() => {
                                handlePartApplicationConsult(props.original);
                              }}
                              title="Aplicação de peça"
                              cursor="pointer"
                              style={{
                                height: '1.6em',
                                width: '1.6em',
                                marginLeft: '3px',
                                marginRight: '3px',
                              }}
                              icon={faCarSide}
                            />
                          )}
                          {!isPlanFree && !isPlanStart && !isPlanBasic && (
                            <FontAwesomeIcon
                              onClick={() =>
                                handleSimilarityConsult(props.value)
                              }
                              title="Consulta de Similaridades"
                              cursor="pointer"
                              style={{
                                height: '1.6em',
                                width: '1.6em',
                                color: 'black',
                              }}
                              icon={faEquals}
                            />
                          )}
                          <FontAwesomeIcon
                            onClick={() =>
                              handleLinkedServiceConsult(props.original)
                            }
                            title="Serviços Vinculados"
                            cursor="pointer"
                            style={{
                              height: '1.6em',
                              width: '1.6em',
                              marginLeft: '3px',
                              marginRight: '3px',
                            }}
                            icon={faTools}
                          />
                          <FontAwesomeIcon
                            onClick={() => {
                              confirmDuplicateProduct(props.original);
                            }}
                            title="Duplicar produto"
                            cursor="pointer"
                            style={{
                              height: '1.6em',
                              width: '1.6em',
                              marginLeft: '3px',
                              marginRight: '3px',
                            }}
                            icon={faCopy}
                          />

                          <FontAwesomeIcon
                            title="Excluir"
                            cursor="pointer"
                            style={{
                              height: '1.6em',
                              width: '1.6em',
                              color: '#bd362f',
                            }}
                            icon={faTrashAlt}
                            onClick={() => handleDelete(props.original)}
                          />
                        </>
                      ),
                    },
                  ]}
                  defaultPageSize={10}
                  loading={loading}
                  showPagination
                  sortable
                  showPaginationTop={false}
                  showPaginationBottom
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  pages={pagesProduct}
                  manual
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando..."
                  noDataText="Nenhum Produto encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="linhas"
                  defaultSorted={[{ id: 'Code', desc: false }]}
                  onFetchData={(state, _) => {
                    // Ativa a condição apenas na troca de pagina,
                    // evitando chamar esta junto do useEffect
                    if (state.page !== page || state.pageSize !== pageSize) {
                      loadProducts({
                        ...state,
                        query: searchQueryProduct,
                        isActive,
                        brandId,
                      });
                    }
                  }}
                  onPageChange={(page) => setPage(page)}
                  onPageSizeChange={(newPageSize, _) =>
                    setPageSize(newPageSize)
                  }
                />
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <div
                  style={{
                    padding: 0,
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'flex-end',
                    gap: '10px',
                  }}
                >
                  <ModalAlertCompany
                    show={noCompanyModal}
                    onHide={() => setNoCompanyModal(false)}
                  />
                  <button
                    className="btn btn-sucesso button-h35"
                    onClick={() =>
                      isKitConfigOn && !isPlanFree
                        ? setNewProductWithKitModal(true)
                        : history.push(constants.ROUTES.PRODUCT)
                    }
                    style={{ fontSize: '12px' }}
                  >
                    + Novo Produto
                  </button>
                  <div>
                    <SearchInput
                      placeholder="Pesquisa por Descrição ou Código de Barras"
                      value={searchQueryKit}
                      style={{
                        fontSize: '12px',
                        height: '35px',
                        width: '312px',
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                      onChange={(e) =>
                        handleChangeQuerySearchKit(e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <span style={{ fontSize: '12px' }}>
                      <strong>Exibir: </strong>
                    </span>
                    <select
                      className="form-control foco-input"
                      name="displayBy"
                      value={displayBy}
                      onChange={(e) => setDisplayBy(e.target.value)}
                      style={{ fontSize: '12px' }}
                    >
                      <option value="products" selected>
                        Produtos
                      </option>
                      <option value="kits">Kit</option>
                    </select>
                  </div>
                  <div>
                    <span style={{ fontSize: '12px' }}>
                      <strong>Status: </strong>
                    </span>
                    <select
                      className="form-control foco-input"
                      name="searchStatus"
                      value={statusKit}
                      onChange={(e) => setStatusKit(e.target.value)}
                      style={{ fontSize: '12px' }}
                    >
                      <option value="">Ambos</option>
                      <option value={1}>Ativo</option>
                      <option value={0}>Inativo</option>
                    </select>
                  </div>
                  {isXlsExportReadyKits ? (
                    <DownloadXlsButton
                      archiveName={`Exportação de KIT`}
                      data={kitsToExport}
                      className="btn btn-export export-stone-link"
                      style={{ fontSize: '12px' }}
                      columns={[
                        {
                          name: 'Descrição',
                          acessor: 'description',
                        },
                        {
                          name: 'Código de Barras',
                          acessor: 'barCode',
                        },
                        {
                          name: 'Qtd. Produto',
                          acessor: 'productQuantity',
                        },
                        {
                          name: 'Qtd. Serviço',
                          acessor: 'productService',
                        },
                        {
                          name: 'Preço',
                          acessor: 'priceTotal',
                        },
                        {
                          name: 'Status',
                          acessor: 'isActive',
                        },
                      ]}
                    >
                      <FontAwesomeIcon
                        color="white"
                        icon={faCloudDownloadAlt}
                      />
                      &nbsp;Iniciar Download
                    </DownloadXlsButton>
                  ) : (
                    <button
                      className="btn button-h35"
                      onClick={() =>
                        loadXlsExportKits({
                          query: searchQueryKit,
                          status: statusKit,
                        })
                      }
                      disabled={isXlsExportLoadingKits}
                      style={{
                        backgroundColor: '#3c8af7',
                        borderColor: '#3c8af7',
                        color: '#FFFFFF',
                        fontSize: '12px',
                      }}
                    >
                      {isXlsExportLoadingKits ? (
                        <span className="fa fa-spinner fa-pulse fa-1x"></span>
                      ) : (
                        <FontAwesomeIcon
                          color="white"
                          icon={faCloudDownloadAlt}
                        />
                      )}
                      &nbsp;
                      {isXlsExportLoadingKits ? 'Processando' : 'Exportar .xls'}
                    </button>
                  )}
                </div>
              </div>

              <div>
                <ReactTable
                  style={{
                    width: '100%',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '12px',
                  }}
                  data={kits}
                  columns={[
                    {
                      Header: 'Descrição',
                      accessor: 'description',
                      width: 350,
                    },
                    {
                      Header: 'Código de Barras',
                      accessor: 'barCode',
                      width: 200,
                    },
                    {
                      Header: 'Qtd. Produto',
                      accessor: 'productQuantity',
                      width: 100,
                    },
                    {
                      Header: 'Qtd. Serviço',
                      accessor: 'productService',
                      width: 100,
                    },
                    {
                      Header: 'Preço',
                      accessor: 'priceTotal',
                      Cell: (props) => currency(props.value),
                      width: 100,
                    },
                    {
                      Header: 'Status',
                      accessor: 'isActive',
                      width: 80,
                      Cell: (props) => (
                        <Button
                          id="botaoReactTable"
                          style={{
                            backgroundColor:
                              props.value === 1 ? 'green' : 'red',
                          }}
                        >
                          {props.value == 1 ? 'Ativo' : 'Inativo'}
                        </Button>
                      ),
                    },
                    {
                      Header: 'Ações',
                      accessor: 'id',
                      filterable: false,
                      width: 70,
                      Cell: (props) => (
                        <>
                          <Link
                            to={{
                              pathname: `product-service-kit#${props.value}`,
                            }}
                          >
                            <FontAwesomeIcon
                              title="Editar"
                              cursor="pointer"
                              style={{
                                height: '1.6em',
                                width: '1.6em',
                                color: 'black',
                              }}
                              icon={faEdit}
                            />
                          </Link>
                          <FontAwesomeIcon
                            title="Excluir"
                            cursor="pointer"
                            style={{
                              height: '1.6em',
                              width: '1.6em',
                              color: '#bd362f',
                              marginLeft: '5px',
                            }}
                            icon={faTrashAlt}
                            onClick={() => {}}
                          />
                        </>
                      ),
                    },
                  ]}
                  defaultPageSize={10}
                  loading={loading}
                  showPagination
                  sortable
                  showPaginationTop={false}
                  showPaginationBottom
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  pages={pagesKit}
                  manual
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando..."
                  noDataText="Nenhum Produto encontrado"
                  pageText="Página"
                  ofText="de"
                  rowsText="linhas"
                  defaultSorted={[{ id: 'description', desc: false }]}
                  onFetchData={(state, _) => {
                    // Ativa a condição apenas na troca de pagina,
                    // evitando chamar esta junto do useEffect
                    if (state.page !== page || state.pageSize !== pageSize) {
                      loadKits({
                        ...state,
                        query: searchQueryKit,
                        status: statusKit,
                      });
                    }
                  }}
                  onPageChange={(page) => setPage(page)}
                  onPageSizeChange={(newPageSize, _) =>
                    setPageSize(newPageSize)
                  }
                />
              </div>
            </div>
          )
        }
      />
      {
        <AlertModal
          show={showDeleteProductModal}
          title="OS Digital"
          message="Você tem certeza que deseja excluir o produto cadastrado? A exclusão é irrevesível e deverá cadastrar o produto novamente se necessário."
          onHide={() => setShowDeleteProductModal(false)}
          onCancel={() => setShowDeleteProductModal(false)}
          onSubmit={deleteProduct}
          loading={isDeletingProduct}
        />
      }
      {newProductWithKitModal && (
        <NewProductWithKitModal
          onCancel={() => setNewProductWithKitModal(false)}
          history={history}
        />
      )}

      {duplicateModal && (
        <DuplicateProductModal
          product={product}
          onCancel={() => setDuplicateModal(false)}
          onSubmit={() => duplicateProduct()}
        />
      )}
      {showBulkDeletionModal ? (
        <BulkDeletionModal
          showBulkDeletionModal={showBulkDeletionModal}
          handleShowBulkDeletionModal={handleShowBulkDeletionModal}
          brands={brands}
          setDeletedProducts={setDeletedProducts}
        />
      ) : (
        ''
      )}
      <ImportFromXlsModal
        show={isImportFromXlsModalOpen}
        onCancel={() => setIsImportFromXlsModalOpen(false)}
        importType="products"
        loadItems={loadProducts}
        loadItemsParams={{
          page: 0,
          pageSize,
          query: searchQueryProduct,
          statusProduct,
          brandId,
        }}
      />
    </>
  );
};
export default withRouter(HomeProducts);
