import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatLicensePlate } from 'client/components/ToNormalize/ToNormalize';

import React from 'react';
import { styleChecklist } from './MobileChecklist.styles';
import { FuelGaugeChecklist } from './FuelGaugeChecklist';

export function VehicleChecklist({ external, vehicle, internal }) {
  if (!external || !vehicle || !internal) {
    return <View />;
  }

  const { principal } = external;
  const { brand, chassi, color, model, plate, renavam } = vehicle;
  const { fuelStatus, observations } = internal;

  return (
    <>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <View
          style={{
            marginRight: 10,
            width: 250,
            height: 130,
          }}
        >
          <Image
            src={principal}
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
            }}
          />
        </View>

        <View
          style={{
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <View
              style={[
                styleChecklist.flexRow,
                styleChecklist.fontSize14,
                {
                  width: '100%',
                },
              ]}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Text style={[styleChecklist.bold, { marginRight: 5 }]}>
                  {formatLicensePlate(plate)}
                </Text>
                <Text
                  style={[
                    {
                      width: '250px',
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                    },
                  ]}
                >
                  {brand} - {model}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ justifyContent: 'space-around' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styleChecklist.spaceRow}>Cor: {color}</Text>
              <Text style={styleChecklist.spaceRow}>Chassi: {chassi}</Text>
              <Text style={styleChecklist.spaceRow}>Renavam: {renavam}</Text>
            </View>

            <View style={styleChecklist.flexRow}>
              <Text>Combustível: </Text>
              <FuelGaugeChecklist level={fuelStatus} />
            </View>

            <View style={styleChecklist.flexRow}>
              <Text>Observações Internas: {observations}</Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

const imageStyles = StyleSheet.create({
  gridItem: {
    width: '100%',
    aspectRatio: 1,
    marginBottom: 10,
    padding: 5,
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});
