import React from 'react';
import { format } from 'date-fns';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
import { formatOnboardingTableData } from '../utils';
import { BrandingLabel } from 'v2/components/BrandingLabel';
import { planHelper } from 'v2/helpers/planHelper';

export const useConfigColumnsOnboarding = (setValue) => {
  const { getStatusDetails, validateData } = formatOnboardingTableData;
  const getDataModal = (props) => {
    const onBoardingModal = {
      isOpen: true,
      companyId: props.original.companyId,
      onboardingHeaderId: props.original.onboardingHeaderId,
      companyName: props.original.companyName,
    };

    setValue('onBoardingModal', onBoardingModal);
  };
  const status = (status, satisfaction) => {
    const { statusLabel, statusColor, statusEmoji } = getStatusDetails(
      status,
      satisfaction
    );

    return (
      <div className="flex gap-075 start w-100">
        <span
          style={{
            backgroundColor: statusColor,
            color: 'white',
            borderRadius: '5px',

            display: 'inline-block',
            textAlign: 'center',
            padding: '3px 12px',
          }}
        >
          {statusLabel}
        </span>
        <span>{statusEmoji}</span>
      </div>
    );
  };

  const { PlanLabel } = planHelper;

  const columns = [
    {
      Header: 'Dt. Cadastro',
      accessor: 'registerDate',
      Cell: (props) =>
        props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '-',
    },
    {
      Header: 'Dt. 1 Assinatura',
      accessor: 'firstSignatureDate',
      width: 120,
      Cell: (props) =>
        props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '-',
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 140,
      Cell: (props) => status(props.value, props.original.satisfaction),
    },
    {
      Header: 'CPF/CNPJ',
      accessor: 'cpfCnpj',
      width: 150,
      Cell: (props) => {
        return (
          <a
            style={{
              color: '#007bff',
              textDecoration: 'underline',
            }}
            href="#"
            onClick={() => getDataModal(props)}
          >
            {cpfOrCnpjMask(props.value)}
          </a>
        );
      },
    },
    {
      Header: 'Razão Social/Nome',
      accessor: 'companyName',
      width: 200,
      Cell: (props) => validateData(props.value),
    },
    {
      Header: 'Responsável',
      accessor: 'onboardingResponsable',
      Cell: (props) => validateData(props.value),
    },
    {
      Header: 'Dt. Inicio',
      accessor: 'onboardingInitialDate',
      Cell: (props) =>
        props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '-',
    },
    {
      Header: 'Dt. Finalizado',
      accessor: 'onboardingFinalDate',
      Cell: (props) =>
        props.value ? format(new Date(props.value), 'dd/MM/yyyy') : '-',
    },
    {
      Header: 'Duração',
      accessor: 'onboardingDurationDays',
      Cell: (props) =>
        validateData(
          props.value !== null
            ? `${props.value === 0 ? 1 : props.value} dia(s)`
            : '-'
        ),
    },
    {
      Header: 'Produto',
      accessor: 'brandingId',
      Cell: (props) =>
        props.value ? (
          <BrandingLabel brandingId={props.value} noBrandingText="Sem Plano" />
        ) : (
          validateData(props.value)
        ),
    },
    {
      Header: 'Plano',
      accessor: 'planId',
      width: 150,
      Cell: (props) =>
        props.value ? (
          <PlanLabel planId={props.value} planName={props.original.plan} />
        ) : (
          validateData(props.value)
        ),
    },
  ];
  const columnsSheet = [
    {
      name: 'Dt. Cadastro',
      acessor: 'registerDate',
    },
    {
      name: 'Dt. 1 Assinatura',
      acessor: 'firstSignatureDate',
    },
    {
      name: 'Status',
      acessor: 'status',
    },
    {
      name: 'CPF/CNPJ',
      acessor: 'cpfCnpj',
    },
    {
      name: 'Razão Social/Nome',
      acessor: 'companyName',
    },
    {
      name: 'Responsável',
      acessor: 'onboardingResponsable',
    },
    {
      name: 'Dt. Inicio',
      acessor: 'onboardingInitialDate',
    },
    {
      name: 'Dt. Finalizado',
      acessor: 'onboardingFinalDate',
    },
    {
      name: 'Duração',
      acessor: 'onboardingDurationDays',
    },
    {
      name: 'Produto',
      acessor: 'branding',
    },
    {
      name: 'Plano',
      acessor: 'plan',
    },
  ];
  return {
    columns,
    columnsSheet,
    validateData,
    getStatusDetails,
  };
};
