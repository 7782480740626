import React, { Component, useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, format, isAfter, subDays } from 'date-fns/esm';

import TableByProvider from './TableByProvider';
import DownloadXlsButton from 'components/DownloadXlsButton';
import purchasesRepository from '../../../../../repositories/Purchases';
import { getPurchasesInfoToExport, xlsColumns } from './excelHelpers';
import ChartByProvider from './ChartByProvider';

const PurchasesByStatus = () => {
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('15');
  const [purchasesFiltered, setPurchasesFiltered] = useState([]);
  const [purchasesInfoToExport, setPurchasesInfoToexport] = useState([]);
  const [purchasesGrouped, setPurchasesGrouped] = useState([]);
  const [purchasesChartData, setPurchasesChartData] = useState([]);

  const companyId = localStorage.getItem('ID_EMPRESA');

  useEffect(() => {
    if (!!companyId) {
      loadPurchases();
    }
  }, [period]);

  useEffect(() => {
    handlePurchasesProviderTable();
  }, [purchasesFiltered]);

  useEffect(() => {
    const purchasesChartData = purchasesGrouped.map((purchase) => [
      purchase.name,
      purchase.quantity,
    ]);
    setPurchasesChartData(purchasesChartData);

    const purchasesInfoToExport = getPurchasesInfoToExport(purchasesGrouped);
    setPurchasesInfoToexport(purchasesInfoToExport);
  }, [purchasesGrouped]);

  const handlePurchasesProviderTable = async () => {
    const purchasesGrouped = [];

    purchasesFiltered.forEach((purchase) => {
      const findedIndex = purchasesGrouped.findIndex(
        (child) => child.providerId === purchase.providerId
      );

      if (findedIndex !== -1) {
        purchasesGrouped[findedIndex].amount =
          purchasesGrouped[findedIndex].amount + purchase.total;
        purchasesGrouped[findedIndex].quantity++;
      } else {
        purchasesGrouped.push({
          providerId: purchase.providerId,
          name: purchase.Provider.companyName,
          cnpj: purchase.Provider.cpfCnpj,
          amount: purchase.total,
          quantity: 1,
          entryAt: purchase.entryAt,
        });
      }
    });

    setPurchasesGrouped(purchasesGrouped);
  };

  const loadPurchases = async () => {
    setLoading(true);
    try {
      let queryInitialDate;
      let queryFinalDate;
      switch (period) {
        case '15':
          queryInitialDate = format(subDays(new Date(), 15), 'yyyy-MM-dd');
          queryFinalDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case '30':
          queryInitialDate = format(subDays(new Date(), 30), 'yyyy-MM-dd');
          queryFinalDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case '60':

        default:
          queryInitialDate = format(subDays(new Date(), 60), 'yyyy-MM-dd');
          queryFinalDate = format(new Date(), 'yyyy-MM-dd');
          break;
      }
      const purchases = await purchasesRepository.getPurchases({
        companyId,
        initialDate: queryInitialDate,
        finalDate: queryFinalDate,
        page: 1,
        limit: 999,
      });
      setPurchasesFiltered(purchases.rows);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as compras. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relatorio-por-pagamento-container">
      <header>
        <div>
          <span>
            <strong>Período:</strong>
          </span>
          <select
            className="form-control foco-input"
            name="period"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
          >
            <option value={15}>Ultimos 15 dias</option>
            <option value={30}>Ultimos 30 dias</option>
            <option value={60}>Ultimos 60 dias</option>
          </select>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <DownloadXlsButton
            archiveName="ComprasPorFornecedor"
            data={purchasesInfoToExport}
            className="btn btn-export"
            disabled={loading || !purchasesInfoToExport.length}
            columns={xlsColumns}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
      </header>

      <section>
        <div style={{ padding: 5 }}>
          <TableByProvider data={purchasesGrouped} loading={loading} />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '100px',
          }}
        >
          <ChartByProvider data={purchasesChartData} />
        </div>
      </section>
    </div>
  );
};

export default PurchasesByStatus;
