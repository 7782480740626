import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { change, Field, FieldArray } from 'redux-form';
import RenderField from '../../../../../components/RenderField';
import Item from '../Item';
import Installments from './Installments';
import paymentConditionsRepository from '../../../../../repositories/PaymentConditions';
import paymentFormsRepository from '../../../../../repositories/PaymentForms';
import { useAuth } from '../../../../../contexts/auth';
import {
  createInstallments,
  splitParcelsValue,
} from '../../../../../utils/createInstallments';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import { Totals } from '../Totals';
import { GeneralDiscount } from '../GeneralDiscount';
import { currencyMask } from 'utils/masks';
import customersRepository from 'repositories/Customers';
import './styles.css';
import { useBonus } from 'hooks/useBonus';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import { useBranding } from 'hooks/useBranding';
import { isDefaultCustomer } from 'utils/isDefaultCustomer';
import { listAllSaleSubtypeActive } from 'v2/repositories/SalesSubtypeRepository';

const Payment = () => {
  const [paymentConditions, setPaymentConditions] = useState([]);
  const [paymentForms, setPaymentForms] = useState([]);
  const [salesSubtypes, setSalesSubtypes] = useState([]);
  const [hasStonePaymentForm, setHasStonePaymentForm] = useState(false);
  const [updateTotalPdvTrigger, setUpdateTotalPdvTrigger] = useState(false);

  const {
    paymentConditionId,
    installments,
    totalSale,
    client,
    customerId,
    hasCustomer,
    salesSubtypeId,
    generateTitleAfterCloseSale,
  } = useSelector((state) => state.form.pdvOnline.values);

  const { isPlanFree, isPlanStart, isPlanBasic, isPaidWorkmotor } =
    usePlanSignatureContext();

  const { companyHasCRMBonus, redeemedBonus } = useBonus();

  const { isWorkmotor } = useBranding();

  const { company, companyId } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!paymentForms.length) return;

    const hasStonePaymentForm = !!paymentForms.find(
      (paymentForm) => paymentForm.isStoneIntegration
    );
    setHasStonePaymentForm(hasStonePaymentForm);
  }, [paymentForms]);

  useEffect(() => {
    if (companyId) {
      loadPaymentConditions();
      loadSalesSubtype();
      loadPaymentForms();
      getCustomerInfo();
    }
  }, [companyId]);

  useEffect(() => {
    if (!generateTitleAfterCloseSale) {
      dispatch(change('pdvOnline', 'paymentConditionId', null));
      return;
    }
  }, [generateTitleAfterCloseSale]);

  useEffect(() => {
    if (!paymentConditionId) {
      dispatch(change('pdvOnline', 'installments', []));
      return;
    }

    const condition = paymentConditions.find(
      (condition) => String(condition.id) === paymentConditionId
    );

    if (
      Number(totalSale) < Number(condition.minValue) &&
      Number(condition.minValue) > 0
    ) {
      toastr.error(
        'Valor mínimo não atingido.',
        `O valor da venda/os não atingiu o mínimo necessário para usar a condição. Para ser utilizada, necessário custar ${currency(
          Number(condition.minValue)
        )}. Verifique e tente novamente ou selecione outra condição.`
      );
      dispatch(change('pdvOnline', 'installments', []));
      dispatch(change('pdvOnline', 'paymentConditionId', null));
      return;
    }

    const bonus = companyHasCRMBonus && redeemedBonus ? redeemedBonus : 0;

    if (condition) {
      const installments = splitParcelsValue(
        createInstallments(condition),
        totalSale - bonus
      );
      dispatch(change('pdvOnline', 'installments', installments));
    }
  }, [paymentConditionId]);

  useEffect(() => {
    if (salesSubtypes.length && salesSubtypeId) {
      const subType = salesSubtypes.find(
        (item) => Number(item.id) === Number(salesSubtypeId)
      );

      if (subType) {
        dispatch(
          change(
            'pdvOnline',
            'generateTitleAfterCloseSale',
            subType.generateTitleAfterCloseSale
          )
        );

        dispatch(
          change('pdvOnline', 'salesSubtypeDescription', subType.description)
        );
      }
    }
  }, [salesSubtypeId, salesSubtypes]);

  useEffect(() => {
    const total = !installments.length
      ? 0
      : installments.length === 1
      ? installments[0].value
      : installments
          .map((installment) => installment.value)
          .reduce((prev, curr) => prev + curr);

    dispatch(change('pdvOnline', 'paidValue', total));
  }, [installments]);

  async function loadPaymentConditions() {
    try {
      const paymentConditions =
        await paymentConditionsRepository.getAllActiveByCompany(companyId);
      setPaymentConditions(paymentConditions);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as condições de pagamento. Por favor, tente novamente'
      );
    }
  }

  async function loadSalesSubtype() {
    try {
      const response = await listAllSaleSubtypeActive(companyId);
      setSalesSubtypes(response);
    } catch (err) {
      console.log(err);
      toastr.error(
        'Erro ao carregar os subtypes',
        'Tente novamente! Caso persista, contate o suporte.'
      );
    }
  }

  async function getCustomerInfo() {
    try {
      const customerInfo = await customersRepository.show(customerId);

      dispatch(
        change('pdvOnline', 'availableCredit', customerInfo.availableCredit)
      );

      if (customerInfo.Company_id) {
        dispatch(change('pdvOnline', 'hasCustomer', true));
      } else {
        dispatch(change('pdvOnline', 'hasCustomer', false));
      }
    } catch (err) {
      console.log(err);
      toastr.error(
        'Não foi possível carregar os dados do cliente.',
        'Por favor, tente novamente.'
      );
    }
  }

  async function loadPaymentForms() {
    try {
      const paymentForms = await paymentFormsRepository.getAllByCompany({
        companyId,
        page: 1,
        limit: 9999,
        status: 1,
      });
      setPaymentForms(paymentForms.rows);
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as formas de pagamento. Por favor, tente novamente'
      );
    }
  }

  function getLabel(value) {
    if (value === 'payment') {
      return paymentConditions.map((condition) => (
        <option
          key={condition.id}
          value={condition.id}
          className={isWorkmotor ? getLabelColor(condition) : ''}
        >
          {isWorkmotor && Number(condition.minValue) > 0
            ? `${condition.Description} - Mínimo: ${currency(
                Number(condition.minValue)
              )}`
            : condition.Description}
        </option>
      ));
    }
    if (value === 'subtype') {
      return salesSubtypes.map((subtype) => (
        <option key={subtype.id} value={subtype.id}>
          {subtype.description}
        </option>
      ));
    }
  }

  function getLabelColor(condition) {
    if (
      condition &&
      Number(totalSale) < Number(condition.minValue) &&
      Number(condition.minValue) > 0
    ) {
      return 'option-background-color';
    } else {
      return '';
    }
  }

  return (
    <div id="pdv-payment-step">
      <section className="payment-wrapper">
        <legend>Dados do Pagamento</legend>
        <main className="payment-data">
          <div>
            <div style={{ gap: 10 }} className="payment-condition">
              <div style={{ marginLeft: 15 }}>
                <label htmlFor="payment-condition">Condição de Pagamento</label>
                <Field
                  component={RenderField}
                  name="paymentConditionId"
                  as="select"
                  id="payment-condition"
                  disabled={!generateTitleAfterCloseSale}
                >
                  <option value="">Selecione</option>
                  {getLabel('payment')}
                </Field>
              </div>

              {!isPlanFree && !isPlanStart && (
                <div style={{ marginLeft: 15 }}>
                  <label htmlFor="sale-type">Tipo de venda</label>
                  <Field
                    component={RenderField}
                    name="salesSubtypeId"
                    as="select"
                    id="sales-subtype"
                  >
                    {getLabel('subtype')}
                  </Field>
                </div>
              )}
              {hasStonePaymentForm && (
                <span>
                  Será aceito apenas uma forma de pagamento pela Stone.
                </span>
              )}

              {isPaidWorkmotor &&
                !isDefaultCustomer(client, 'cpfCnpj') &&
                company?.companyConfig.manageInternalCredit && (
                  <Field
                    name="availableCredit"
                    component={RenderField}
                    label="Crédito Disponível:"
                    disabled
                    {...currencyMask}
                  />
                )}
            </div>
          </div>
          <hr className="break-line" />
          <div>
            <FieldArray
              name="installments"
              props={{
                paymentForms,
                installments,
              }}
              rerenderOnEveryChange={false}
              component={Installments}
            />
          </div>
        </main>
      </section>

      <section className="products-list">
        <Item setUpdateTotalPdvTrigger={setUpdateTotalPdvTrigger} readOnly />
        <footer style={{ marginTop: '10px' }}>
          <GeneralDiscount
            setUpdateTotalPdvTrigger={setUpdateTotalPdvTrigger}
            updateTotalPdvTrigger={updateTotalPdvTrigger}
          />
          <Totals />
        </footer>
      </section>
    </div>
  );
};

export default Payment;
