import React, { useEffect } from 'react';
import { Table } from 'v2/components/Table';
import { useConfigColumnsOnboarding } from '../hooks/useConfigColumnsOnboarding';
import { useFormContext } from 'react-hook-form';
import { useOnboarding } from '../hooks/useOnboarding';

export function OnboardingTable() {
  const { watch, getValues, setValue } = useFormContext();
  const { page, pageLimit, totalPages } = watch('controlTable');
  const { getOnboardingData, isLoading } = useOnboarding();

  const { columns } = useConfigColumnsOnboarding(setValue);

  const onboardingDataTable = watch('onboardingDataTable');

  useEffect(() => {
    if (onboardingDataTable?.report) {
      getOnboardingData(setValue, getValues, 'table');
    }
  }, [page, pageLimit]);

  return (
    <div className="mt-2">
      <Table
        width="100%"
        columns={columns}
        data={onboardingDataTable?.report || []}
        pageSize={pageLimit}
        page={page}
        onPageChange={(value) => setValue('controlTable.page', value)}
        manual
        onPageSizeChange={(value) => setValue('controlTable.pageLimit', value)}
        pages={totalPages}
        loading={isLoading}
      />
    </div>
  );
}
