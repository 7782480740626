import React from 'react';
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import { styleChecklist } from './MobileChecklist.styles';

const translations = {
  front: 'Frente',
  rear: 'Traseira',
  rightSide: 'Lateral Direita',
  leftSide: 'Lateral Esquerda',
  additional: 'Adicional',
  observations: 'Observações',
};

const ChecklistImage = ({ title, imageUrl }) => (
  <View style={[imageStyles.gridItem, { marginTop: 10 }]}>
    <Text style={styleChecklist.bold}>{title}</Text>
    <Image src={imageUrl} style={imageStyles.image} />
  </View>
);

export function ExternalChecklist({ external }) {
  if (!external) return <View />;

  const { additional, observations, principal, ...mainImages } = external;

  const renderGridImages = (images) => {
    const rows = [];
    for (let i = 0; i < images.length; i += 4) {
      const rowItems = images.slice(i, i + 4);
      rows.push(
        <View key={i} style={imageStyles.gridRow}>
          {rowItems.map((item, index) => (
            <ChecklistImage
              key={index}
              title={item.description || translations[item.key]}
              imageUrl={item.url || item.value}
            />
          ))}
        </View>
      );
    }
    return rows;
  };

  return (
    <View style={{ justifyContent: 'space-between' }}>
      <View style={{ marginBottom: 10 }}>
        <Text style={[styleChecklist.spaceColumn, styleChecklist.bold]}>
          Observações Externas:
        </Text>
        <Text>{observations || ''}</Text>
      </View>

      {renderGridImages(
        Object.entries(mainImages).map(([key, value]) => ({
          key,
          value,
        }))
      )}

      {additional?.length > 0 && (
        <>
          <Text style={styleChecklist.bold}>Fotos Adicionais</Text>
          {renderGridImages(additional)}
        </>
      )}
    </View>
  );
}

const imageStyles = StyleSheet.create({
  gridContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  gridRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  gridItem: {
    width: '25%',
    aspectRatio: 1,
    marginBottom: 10,
    padding: 5,
    alignItems: 'start',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
});
