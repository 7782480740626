import React from 'react';
import { Modal } from 'react-bootstrap';
import OnboardingFeedbackOnlyContent from './OnboardingFeedbackOnlyContent';
import useOnboardingStore from '../../store/onboarding-store';

const OnboardingFeedbackModal = ({ handleClose, handleReload }) => {
  const {
    isFeedbackModalOpen,
    setIsFeedbackModalOpen,
    companyName,
    emojiSelected,
    setEmojiSelected,
    feedbackObservation,
    setFeedbackObservation,
    handleValidateFinishedSave,
    isLoading,
  } = useOnboardingStore();

  return (
    <Modal show={isFeedbackModalOpen} onHide={null} dialogClassName="modal-40w">
      <Modal.Header style={{ padding: '5px 10px' }}>
        <Modal.Title>
          <strong>Onboarding</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex column">
          <strong>Razão Social/Nome: {companyName}</strong>
          <span>
            Informe o engajamento do cliente após a conclusão do onboarding:
          </span>
          <OnboardingFeedbackOnlyContent
            companyName={companyName}
            emojiSelected={emojiSelected}
            setEmojiSelected={setEmojiSelected}
            feedbackObservation={feedbackObservation}
            setFeedbackObservation={setFeedbackObservation}
            isLoading={isLoading}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex end row gap-050">
          <button
            disabled={isLoading}
            className="button button-red"
            onClick={() => setIsFeedbackModalOpen(false)}
          >
            Voltar
          </button>
          <button
            className="button button-green"
            disabled={isLoading}
            onClick={async () => {
              if (await handleValidateFinishedSave()) {
                setIsFeedbackModalOpen(false);
                handleReload();
                handleClose();
              }
            }}
          >
            Finalizar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OnboardingFeedbackModal;
