import { currency } from '../../../client/components/ToNormalize/ToNormalize';

export const xlsColumns = [
  {
    name: 'Descrição',
    acessor: 'Description',
  },
  {
    name: 'Valor (R$)',
    acessor: 'Price',
  },
  {
    name: 'Horas',
    acessor: 'Hours_Expected',
  },
  {
    name: 'Status',
    acessor: 'isActive',
  },
  {
    name: 'Família',
    acessor: 'familyDescription',
  },
  {
    name: 'Garantia',
    acessor: 'warrantyDescription',
  },
  {
    name: 'Valor de Comissão',
    acessor: 'comissionRate',
  },
  {
    name: 'Revisão',
    acessor: 'revisionDescription',
  },
  {
    name: 'Informações Complementares',
    acessor: 'addtionalInformation',
  },
];

export const getServicesToExport = (services, revisions, warranties) => {
  return services.map((service) => {
    let revision = revisions.filter(
      (revision) => revision.id === service.Review_id
    );
    let warranty = warranties.filter(
      (warranty) => warranty.id === service.Warranty_id
    );
    return {
      addtionalInformation: service.Additional_Information,
      comissionRate: service.Commission_Rate,
      Description: service.Description,
      Price: currency(service.Price),
      Hours_Expected: service?.Hours_Expected || '000:00',
      isActive: service.IsActive == 1 ? 'Ativo' : 'Inativo',
      familyId: service.Family_id,
      warrantyId: service.Warranty_id,
      reviewId: service.Review_id,
      familyDescription: service.Families ? service.Families.Description : '',
      revisionDescription: revision[0]?.Description,
      warrantyDescription: warranty[0]?.Description,
    };
  });
};
