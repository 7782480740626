import config from '../config';
import api from '../services/api';

const getPhotos = async (productId) => {
  const res = await api.get(`products/photos/${productId}`);
  return res.data;
};

const getAllByFamilyOrBrand = async ({
  initialDate,
  finalDate,
  companyId,
  familyId,
  brandId,
  isActive,
  reportType,
}) => {
  try {
    const response = await api.get(
      `products/product-family-brand/${companyId}`,
      {
        params: {
          initialDate,
          finalDate,
          familyId,
          brandId,
          isActive,
          reportType,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

const getAllByCompany = async (companyId, query) => {
  try {
    const response = await api.get(
      config.endpoint + `products/paginated-products?Company_id=${companyId}`,
      {
        params: { query },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const countAllByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint +
        `products/paginated-products/count?Company_id=${companyId}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllTray = async (companyId, imported) => {
  try {
    const response = await api.get(config.endpoint + `tray/products`, {
      params: {
        companyId,
        imported,
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const sendProductTray = async ({ products, trayToken, trayUrl }) => {
  try {
    const response = await api.post(config.endpoint + `tray/products`, {
      products,
      trayToken,
      trayUrl,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const updateProductTray = async ({ products, trayToken, trayUrl }) => {
  try {
    const response = await api.put(config.endpoint + `tray/products`, {
      products,
      trayToken,
      trayUrl,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAll = async (params) => {
  const response = await api.get(config.endpoint + 'products', {
    params: params,
  });

  return response.data;
};

const getAllPaginated = async (queryParams) => {
  const response = await api.get(
    config.endpoint + 'products/paginated-products',
    {
      params: queryParams,
    }
  );
  return response;
};

const getAllUnused = async (queryParams) => {
  const response = await api.get(config.endpoint + 'products/unuseds', {
    params: queryParams,
  });

  return response;
};

const getAllUsed = async (queryParams) => {
  const response = await api.get(config.endpoint + 'products/used', {
    params: queryParams,
  });

  return response.data;
};

const getById = async (productId) => {
  try {
    const response = await api.get(config.endpoint + `products/${productId}`);

    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllActivesByCompany = async (companyId) => {
  try {
    const response = await api.get(
      config.endpoint +
        `products/paginated-products?Company_id=${companyId}&IsActive=1`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const search = async (params) => {
  const response = await api.post(
    config.endpoint + 'products/search/one',
    params
  );
  return response.data;
};

const importProducts = async (params) => {
  const response = await api.post(config.endpoint + 'products/import', params);
  return response.data;
};

const minMaxStockReport = async (companyId, queryParams) => {
  const response = await api.get(
    config.endpoint + `products/${companyId}/mix-max-stock-report`,
    {
      params: queryParams,
    }
  );

  return response.data;
};
const minMaxStockReportXls = async (companyId, queryParams) => {
  const response = await api.get(
    config.endpoint + `products/${companyId}/report-xls/min-max-stock`,
    {
      params: queryParams,
    }
  );

  return response.data;
};

const getAllPaginatedToAddItem = (queryParams) => {
  return api.get(config.endpoint + `products/paginated/addItems`, {
    params: queryParams,
  });
};

const getProductsUnderMinStock = (companyId) => {
  return api.get(config.endpoint + `products/${companyId}/under-min-stock`);
};

const bulkDeletion = (productsArray) => {
  console.log(productsArray);
  return api.delete(`products/bulk_deletion`, {
    data: { products: productsArray },
  });
};

const deleteProduct = async (productId) => {
  const response = await api.delete(`products/${productId}`, {
    validateStatus: () => true,
  });

  return response;
};

const createProduct = async (data) => {
  const response = await api.post('/products', data, {
    validateStatus: () => true,
  });

  return response.data;
};

const updateProduct = async (productId, productInfo) => {
  const response = await api.put(`/products/${productId}`, productInfo, {
    validateStatus: () => true,
  });

  return response.data;
};

const getInventoryReport = async (companyId, params) => {
  const { data } = await api.get(`report/products/inventory/${companyId}/`, {
    params,
  });
  return data;
};

const getProductsWithoutMovement = async (companyId, params) => {
  const { data } = await api.get(
    `report/products/products-without-movement/${companyId}`,
    { params }
  );
  return data;
};

const getProductListingLocation = async (companyId, queryParams) => {
  const { data } = await api.get(
    `report/product-listing-location-report/${companyId}`,
    {
      params: queryParams,
    }
  );
  return data;
};

const getPartsPerApplication = async (companyId, params) => {
  const { data } = await api.get(
    `report/parts-per-suppliers-application/${companyId}`,
    {
      params: params,
    }
  );
  return data;
};

export default {
  getAllByFamilyOrBrand,
  getAllByCompany,
  countAllByCompany,
  getAllActivesByCompany,
  getById,
  search,
  getAllPaginated,
  minMaxStockReport,
  minMaxStockReportXls,
  getAllPaginatedToAddItem,
  getProductsUnderMinStock,
  deleteProduct,
  getAll,
  getAllUnused,
  getAllUsed,
  bulkDeletion,
  importProducts,
  updateProduct,
  createProduct,
  getAllTray,
  sendProductTray,
  updateProductTray,
  getInventoryReport,
  getProductsWithoutMovement,
  getProductListingLocation,
  getPartsPerApplication,
  getPhotos,
};
