import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, format, isAfter, subDays } from 'date-fns/esm';

import TableByStatus from './TableByStatus';
import DownloadXlsButton from 'components/DownloadXlsButton';
import purchasesRepository from '../../../../../repositories/Purchases';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { getPurchasesInfoToExport, xlsColumns } from './excelHelpers';
import constants from '../../../../../utils/constants';
import ChartByStatus from './ChartByStatus';

const PurchasesByStatus = () => {
  const [loading, setLoading] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [period, setPeriod] = useState('15');
  const [status, setStatus] = useState('');

  const [purchasesFiltered, setPurchasesFiltered] = useState([]);
  const [purchasesGroupedByStatus, setPurchasesGroupedByStatus] = useState([]);
  const [purchasesChartData, setPurchasesChartData] = useState([]);
  const [purchasesInfoToExport, setPurchasesInfoToexport] = useState([]);

  const companyId = localStorage.getItem('ID_EMPRESA');

  useEffect(() => {
    if (!!companyId && !!validations()) {
      loadPurchases();
    }
  }, [period, initialDate, finalDate, status]);

  const validations = () => {
    if (period !== '0') {
      return true;
    }

    if (!initialDate || !finalDate) {
      toastr.warning('Por favor, preencha as datas corretamente');
      return false;
    }
    if ((initialDate && !finalDate) || (!initialDate && finalDate)) {
      toastr.warning('Informe a data inicial e final.');
      return false;
    }
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const purchasesInfoToExport = getPurchasesInfoToExport(purchasesFiltered);
    setPurchasesInfoToexport(purchasesInfoToExport);
    handlePurchasesTableData();
  }, [purchasesFiltered]);

  useEffect(() => {
    const purchasesChartData = purchasesGroupedByStatus.map((purchase) => [
      purchase.status,
      purchase.quantity,
    ]);
    setPurchasesChartData(purchasesChartData);
  }, [purchasesGroupedByStatus]);

  const handlePurchasesTableData = () => {
    const purchasesGroupedByStatus = [];

    purchasesFiltered.forEach((purchase) => {
      const findedIndex = purchasesGroupedByStatus.findIndex(
        (child) => child.purchaseStatusId === purchase.purchaseStatusId
      );

      if (findedIndex !== -1) {
        purchasesGroupedByStatus[findedIndex].amount =
          purchasesGroupedByStatus[findedIndex].amount + purchase.total;
        purchasesGroupedByStatus[findedIndex].quantity++;
      } else {
        purchasesGroupedByStatus.push({
          amount: purchase.total,
          status:
            constants.PURCHASES_STATUS_DESCRIPTION[purchase.purchaseStatusId],
          purchaseStatusId: purchase.purchaseStatusId,
          quantity: 1,
        });
      }
    });

    setPurchasesGroupedByStatus(purchasesGroupedByStatus);
  };

  const loadPurchases = async () => {
    setLoading(true);
    try {
      let queryInitialDate;
      let queryFinalDate;
      switch (period) {
        case '15':
          queryInitialDate = format(subDays(new Date(), 15), 'yyyy-MM-dd');
          queryFinalDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case '30':
          queryInitialDate = format(subDays(new Date(), 30), 'yyyy-MM-dd');
          queryFinalDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case '60':
          queryInitialDate = format(subDays(new Date(), 60), 'yyyy-MM-dd');
          queryFinalDate = format(new Date(), 'yyyy-MM-dd');
          break;
        case '0':
        default:
          queryInitialDate = initialDate;
          queryFinalDate = finalDate;
          break;
      }
      const purchases = await purchasesRepository.getPurchases({
        companyId,
        purchaseStatusId: status,
        initialDate: queryInitialDate,
        finalDate: queryFinalDate,
        page: 1,
        limit: 999,
      });
      setPurchasesFiltered(purchases.rows);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as compras. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relatorio-por-pagamento-container">
      <header>
        <div>
          <span>
            <strong>Período:</strong>
          </span>
          <select
            className="form-control foco-input"
            name="period"
            value={period}
            onChange={(e) => {
              setPeriod(e.target.value);
              if (e.target.value === '0') setIsCustom(true);
              else setIsCustom(false);
            }}
          >
            <option value={15}>Ultimos 15 dias</option>
            <option value={30}>Ultimos 30 dias</option>
            <option value={60}>Ultimos 60 dias</option>
            <option value={0}>Personalizar</option>
          </select>
        </div>

        {isCustom && (
          <>
            <div>
              <span>
                <strong>Data Inicial:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="initialDate"
                value={initialDate}
                max={
                  finalDate
                    ? format(
                        new Date(getDateOnlyFromDate(finalDate)),
                        'yyyy-MM-dd'
                      )
                    : format(new Date(), 'yyyy-MM-dd')
                }
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </div>

            <div>
              <span>
                <strong>Data Final:</strong>
              </span>
              <input
                className="form-control foco-input"
                type="date"
                name="finalDate"
                value={finalDate}
                min={
                  initialDate
                    ? format(
                        new Date(getDateOnlyFromDate(initialDate)),
                        'yyyy-MM-dd'
                      )
                    : undefined
                }
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </div>
          </>
        )}
        <div>
          <span>
            <strong>Tipo:</strong>
          </span>
          <select
            className="form-control foco-input"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Todos</option>
            <option value={constants.PURCHASES_STATUS.CLOSED}>
              Finalizada
            </option>
            <option value={constants.PURCHASES_STATUS.OPEN}>Em Aberto</option>
            <option value={constants.PURCHASES_STATUS.CANCELED}>
              Cancelada
            </option>
          </select>
        </div>

        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <DownloadXlsButton
            archiveName={`compras-por-status${format(
              new Date(),
              'dd/MM/yyyy'
            )}`}
            data={purchasesInfoToExport}
            className="btn btn-export"
            disabled={loading || !purchasesInfoToExport.length}
            columns={xlsColumns}
          >
            <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} /> Exportar
            .xls
          </DownloadXlsButton>
        </div>
      </header>

      <section>
        <div style={{ padding: 5 }}>
          <TableByStatus data={purchasesGroupedByStatus} loading={loading} />
        </div>

        <div
          style={{
            padding: 5,
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '100px',
          }}
        >
          <ChartByStatus data={purchasesChartData} />
        </div>
      </section>
    </div>
  );
};

export default PurchasesByStatus;
