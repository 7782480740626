import api from 'v2/services/api';

export const getNewOnboarding = async () => {
  try {
    const res = await api.get(`/onboarding/new`);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getOnboarding = async ({ companyId, id }) => {
  const res = await api.get(`/onboarding/${companyId}?id=${id}`);
  return res.data;
};

export const create = async (data) => {
  const res = await api.post(`/onboarding`, data);
  return res.data;
};

export const update = async (data, id) => {
  const res = await api.put(`/onboarding/${id}`, data);
  return res.data;
};

export const onboardingReport = async (data) => {
  try {
    const res = await api.get(`/onboarding/report`, {
      params: data,
    });
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const OnboardingRepository = {
  getNewOnboarding,
  getOnboarding,
  create,
  update,
  onboardingReport,
};
