import React from 'react';

const RenderField = ({
  input,
  label,
  list,
  type,
  placeholder,
  rest,
  disabled,
  max,
  required,
  maxLength,
  as,
  children,
  style,
  id,
  onClick,
  className,
  inputRef,
  fontWeight,
  fontSize,
  meta: { touched, error, warning },
}) => (
  <div>
    {!!label && (
      <label
        htmlFor={label}
        className="label-form"
        style={{ fontWeight, fontSize }}
      >
        {label}
        {required && <span style={{ color: 'red' }}>*</span>}
      </label>
    )}
    <div>
      {as === 'select' ? (
        <select
          {...input}
          style={style}
          id={label || id}
          disabled={disabled}
          {...rest}
          className={`form-control foco-input ${className}`}
          ref={inputRef}
        >
          {children}
        </select>
      ) : as === 'textarea' ? (
        <textarea
          {...input}
          style={style}
          maxLength={maxLength}
          id={label || id}
          disabled={disabled}
          {...rest}
          className={`form-control foco-input ${className}`}
          placeholder={placeholder}
          ref={inputRef}
        />
      ) : (
        <input
          onClick={onClick}
          {...input}
          list={list}
          style={style}
          maxLength={maxLength}
          id={!label ? id : label}
          disabled={disabled}
          {...rest}
          className={`form-control foco-input ${className}`}
          placeholder={placeholder}
          type={type}
          max={max || ''}
          ref={inputRef}
        />
      )}

      {touched &&
        ((error && <span style={{ color: 'red' }}>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

export default RenderField;
