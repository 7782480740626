import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';

import clientRoutes from '../routes';
import { useAuth } from '../../contexts/auth';
import { usePlanSignatureContext } from '../../contexts/plan-signature';

import ClientHeaderNav from '../components/HeaderNav';
import { Sidebar } from 'v2/components/layout';

import './styles.css';
import UserSessionClosedModal from 'components/UserSessionClosedModal';
import { HelpVideoButton } from 'client/components/HelpVideoButton';
import { UseBannerContext } from '../../contexts/bannerContext';
import { useThemeContext } from 'v2/contexts/themeContext';

const Template = ({ children, active, setActive }) => {
  return (
    <>
      <ClientHeaderNav />
      <Sidebar active={active} setActive={setActive} />
      {children}
    </>
  );
};

const AdminLoggedRoutes = ({ match, history }) => {
  const [active, setActive] = useState('');
  const [isUserSessionClosedModalOpen, setIsUserSessionClosedModalOpen] =
    useState(false);

  const {
    checkUsersDevice,
    clearToken,
    signOut,
    isUserSessionClosed,
    isCompanyActive,
    userId,
  } = useAuth();
  const { isSignatureValid, isUserBlocked, isSignatureManuallyBlocked } =
    usePlanSignatureContext();

  useEffect(() => {
    if (isUserBlocked) {
      history.push('/blocked-client');
    }
  }, [isUserBlocked]);

  useEffect(() => {
    if ((isSignatureManuallyBlocked || !isSignatureValid) && !isUserBlocked) {
      history.push('/canceled-client');
    }
  }, [isSignatureValid, isSignatureManuallyBlocked]);

  useEffect(() => {
    if (!isCompanyActive) {
      history.push('/company-block');
    }
  }, [isCompanyActive]);

  useEffect(() => {
    if (!isCompanyActive) {
      history.push('/company-block');
    }
  }, [isCompanyActive]);

  useEffect(() => {
    if (isUserSessionClosed && userId) {
      setIsUserSessionClosedModalOpen(true);
    }
  }, [isUserSessionClosed]);

  async function handleCloseSession() {
    setIsUserSessionClosedModalOpen(false);
    await clearToken(userId);
    signOut();
  }
  const { isAdvertisementOpen } = UseBannerContext();
  const { setDocumentTitle } = useThemeContext();

  return (
    <Template active={active} setActive={setActive}>
      <div
        style={
          isAdvertisementOpen && match.url === '/client/home'
            ? { paddingTop: '170px' }
            : null
        }
      >
        <div
          id="main-panel"
          className={`main-panel ${
            match.url === '/client/home' ? 'main-panel-advertisements' : ''
          }`}
        >
          <HelpVideoButton />

          <Switch>
            {clientRoutes.map((route, key) => {
              const Component = route.component;

              return (
                <Route
                  exact
                  key={key}
                  path={`/client/${route.path}`}
                  render={(props) => {
                    setActive(route.active);
                    checkUsersDevice();
                    setDocumentTitle(route.name);

                    return <Component {...props} />;
                  }}
                />
              );
            })}
            <Redirect path="/client-login" to="/client/home" />
          </Switch>
        </div>
      </div>

      <UserSessionClosedModal
        show={isUserSessionClosedModalOpen}
        onSubmit={handleCloseSession}
      />
    </Template>
  );
};

export default withRouter(AdminLoggedRoutes);
