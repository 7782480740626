import React from 'react';
import { Container } from './IndicateGain.styles';

export function IndicateGainButton() {
  const openLink = () => {
    window.open('https://indique.buzz/workinovacao?bl_ref=WP5Y2', '_blank');
  };

  return (
    <Container onClick={openLink}>
      <div style={{ width: '18px', height: '18px', flexShrink: 0 }}>🎉</div>
      <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
        Indique & Ganhe
      </span>
    </Container>
  );
}
