import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { initialize } from 'redux-form';
import { getYear, addYears } from 'date-fns';
import { onlyNumbers } from 'client/components/ToNormalize/ToNormalize';
import BundleFormVehicle from './Vehicle';
import vehiclesRepository from '../../../../repositories/Vehicles';
import './styles.css';

const FormVehicle = ({
  isViewingData,
  vehicleId,
  customerId,
  companyId,
  onCancel,
  onSubmit,
  saleId,
  setSelectedVehicleType,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!vehicleId) {
      loadVehicle();
    }
  }, [vehicleId]);

  async function loadVehicle() {
    setLoading(true);
    try {
      const vehicle = await vehiclesRepository.show(vehicleId);
      const {
        id,
        Brand,
        Model,
        engineNumber,
        Maintenance_Object,
        License_Plate,
        Year_Manufacture,
        Year_Model,
        Color,
        Kilometers,
        hasSale,
        NChassi,
        Vin_Renavam,
        Fuel_Type,
        vehicleTypeId,
        serialIdentification,
        bicycleModelId,
        bicycleModalityId,
        bicycleFrameMaterialId,
        bicycleFrameSizeId,
        bicycleRimSizeId,
        bicycleSuspensionTypeId,
        bicycleBrakeTypeId,
        bicycleRearDerailleurId,
        bicycleFrontGearId,
        bicycleRearGearId,
        BicycleModel,
        BicycleRearDerailleurVehicle,
      } = vehicle;

      const data = {
        id,
        Brand: Brand,
        Model,
        engineNumber,
        License_Plate,
        Maintenance_Object,
        Year_Manufacture,
        Year_Model,
        Color,
        Kilometers,
        NChassi,
        Vin_Renavam,
        hasSale,
        Type: vehicleTypeId.toString(),
        Editing: true,
        Fuel_Type,
        serialIdentification,
        bicycleModelId,
        bicycleModalityId,
        bicycleFrameMaterialId,
        bicycleFrameSizeId,
        bicycleRimSizeId,
        bicycleSuspensionTypeId,
        bicycleBrakeTypeId,
        bicycleRearDerailleurId: bicycleRearDerailleurId?.toString(),
        bicycleFrontGearId,
        bicycleRearGearId,
        otherRearDerailleurDescription:
          BicycleRearDerailleurVehicle?.description,
      };

      dispatch(initialize('vehicle', data));
    } catch (err) {
      console.error(err);
      toastr.warning(
        'Ocorreu um erro ao carregar os dados do veículo. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  async function handleSubmit(values) {
    const {
      Type,
      Brand,
      License_Plate,
      Maintenance_Object,
      Year_Manufacture,
      Year_Model,
      Kilometers,
      Model,
      serialIdentification,
      bicycleModelId,
    } = values;
    if (!Maintenance_Object && !License_Plate && !serialIdentification) {
      return toastr.warning(
        'Não foi possível salvar',
        'Preencha todos os campos obrigatórios para salvar e tente novamente'
      );
    }

    if (Type == '1') {
      if (
        !Brand ||
        !Type ||
        !License_Plate ||
        !selectedModel ||
        !Kilometers ||
        !Model ||
        !Year_Manufacture ||
        !Year_Model
      ) {
        return toastr.warning('Por favor, preencha os campos obrigatórios');
      }
    } else if (Type == '5') {
      if (
        !Type ||
        !Brand ||
        !Maintenance_Object ||
        !Model ||
        !Year_Manufacture ||
        !Year_Model ||
        !Kilometers
      ) {
        return toastr.warning('Por favor, preencha os campos obrigatórios');
      }
    } else if (Type == '4') {
      if (!serialIdentification || !Brand || !bicycleModelId) {
        return toastr.warning(
          'Não foi possível salvar.',
          'Preencha todos os campos obrigatórios e tente novamente.'
        );
      }
    } else if (Type === '2' || Type === '3') {
      if (
        !serialIdentification ||
        !Brand ||
        !Model ||
        !Year_Manufacture ||
        !Year_Model
      ) {
        return toastr.warning(
          'Não foi possível salvar.',
          'Preencha todos os campos obrigatórios e tente novamente.'
        );
      }
    }

    if (License_Plate && License_Plate.length < 7) {
      return toastr.warning('Placa incompleta');
    }
    if (parseInt(Year_Model) < parseInt(Year_Manufacture)) {
      return toastr.warning('Atenção: ano de fabricação maior que ano modelo');
    }
    if (parseInt(Year_Manufacture) < 1900) {
      return toastr.warning('Ano de fabricação inválido');
    }
    if (parseInt(Year_Manufacture) > getYear(new Date())) {
      return toastr.warning(
        'Por favor, altere o ano de fabricação e o ano do veículo para prosseguir.'
      );
    }
    const twoYears = addYears(new Date(), 2);
    if (parseInt(Year_Model) >= getYear(new Date(twoYears))) {
      return toastr.warning(
        'Por favor, altere o ano de fabricação e o ano do veículo para prosseguir.'
      );
    }

    if (!vehicleId) {
      create(values);
    } else {
      update(values);
    }
  }

  async function create(values) {
    const { Kilometers, License_Plate, NChassi, Vin_Renavam } = values;
    setLoading(true);

    try {
      if (!!License_Plate) {
        let vehicleHasOpenSales;
        if (saleId) {
          vehicleHasOpenSales =
            await vehiclesRepository.vehicleValidateOpenSalesWithSale({
              licensePlate: License_Plate,
              companyId,
              saleId,
            });
        } else {
          vehicleHasOpenSales =
            await vehiclesRepository.vehicleValidateOpenSales({
              licensePlate: License_Plate,
              companyId,
            });
        }

        if (vehicleHasOpenSales) {
          setLoading(false);
          return toastr.warning(
            'Não foi possível cadastrar o veículo',
            'A placa está vinculada a orçamento e/ou ordens de serviço em aberto para outros clientes. Realize o fechamento desses itens antes de realizar o cadastro do veículo. '
          );
        }
      }

      const vehicle = await vehiclesRepository.create({
        ...values,
        vehicleTypeId: parseInt(values.Type),
        Kilometers: Kilometers ? onlyNumbers(Kilometers) : 0,
        Company_id: companyId,
        Customer_id: customerId,
        Chassis: NChassi,
        Renavam: Vin_Renavam,
        bicycleRearDerailleurId: parseInt(values.bicycleRearDerailleurId),
      });

      toastr.success('Veículo Cadastrado');
      await onSubmit(vehicle);
      if (setSelectedVehicleType) {
        setSelectedVehicleType(parseInt(values.Type));
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      if (err.response) {
        return toastr.warning(err.response.data.message);
      }
      toastr.warning(
        'Ocorreu um erro ao salvar o veículo. Por favor, tente novamente'
      );
    }
  }

  async function update(values) {
    const { Kilometers, NChassi, Vin_Renavam } = values;

    try {
      await vehiclesRepository.update(vehicleId, {
        ...values,
        vehicleTypeId: parseInt(values.Type),
        Kilometers: Kilometers ? onlyNumbers(Kilometers) : 0,
        Chassis: NChassi,
        Renavam: Vin_Renavam,
        bicycleRearDerailleurId: parseInt(values.bicycleRearDerailleurId),
      });

      toastr.success('Sucesso!', 'Veículo atualizado.');
      await onSubmit();
      if (setSelectedVehicleType) {
        setSelectedVehicleType(parseInt(values.Type));
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      if (err.response) {
        return toastr.warning(err.response.data.message);
      }
      toastr.warning(
        'Ocorreu um erro ao atualizar o veículo. Por favor, tente novamente'
      );
    }
  }

  return (
    <BundleFormVehicle
      onSubmit={handleSubmit}
      loading={loading}
      initialValues={{}}
      vehicleId={vehicleId}
      onCancel={onCancel}
      selectedModel={selectedModel}
      setSelectedModel={setSelectedModel}
      isViewingData={isViewingData}
    />
  );
};

export default FormVehicle;
