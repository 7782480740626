import { Approach, FormApproach } from 'v2/views/client/Approach';
import { Department, FormDepartment } from 'v2/views/client/Department';
import { ProfitPanel } from 'v2/views/client/ProfitPanel';
import { RegisterSubtype, FormSaleSubtype } from 'v2/views/client/SaleSubtype';
import { AccessProfiles } from 'v2/views/client/Profiles/AccessProfiles';
import { Section, FormSection } from 'v2/views/client/Section';
import { CSTConversor } from 'v2/views/client/CSTConversor/CSTConversor';
import { FormCSTConversor } from 'v2/views/client/CSTConversor/Form/FormCSTConversor';
import { GroupItems } from 'v2/views/client/GroupItems';
import { FormGroupItem } from 'v2/views/client/GroupItems';
import { Defects } from 'v2/views/client/Defects';
import { FormDefect } from 'v2/views/client/Defects/Form';
import { Checklist } from 'v2/views/client/Checklist';
import { FormChecklist } from 'v2/views/client/Checklist/Form';
import { Corrections } from 'v2/views/client/Corrections';
import { FormCorrection } from 'v2/views/client/Corrections/Form';
import ListRefusedItems from 'client/views/Reports/ListRefusedItems';
import CostCenter from 'client/views/Reports/CostCenter';
import { BankConciliation } from 'v2/views/client/BankConciliation/BankConciliation';
import { BankConciliationDetails } from 'v2/views/client/BankConciliation/components/BankConciliationDetails/BankConciliationDetails';

import { KanbanScheduling } from 'v2/views/client/KanbanScheduling';

import { FinanceCalendar } from 'v2/views/client/FinanceCalendar/FinanceCalendar';

export const CLIENT_PATH_ROUTES = {
  APPROACH: {
    BASE: 'approach',
    FORM_NEW: `approach/new`,
    FORM_EDIT: `approach/edit/:id`,
    FORM_EDIT_RAW: `approach/edit/`,
  },
  DEPARTMENT: {
    BASE: 'department',
    FORM_NEW: `department/new`,
    FORM_EDIT: `department/edit/:departmentId`,
    FORM_EDIT_RAW: `department/edit/`,
  },
  SECTION: {
    BASE: 'section',
    FORM_NEW: `section/new`,
    FORM_EDIT: `section/edit/:sectionId`,
    FORM_EDIT_RAW: `section/edit/`,
  },
  SALE_SUBTYPE: {
    BASE: 'sale-subtype',
    FORM_NEW: `sale-subtype/new`,
    FORM_EDIT: `sale-subtype/edit/:id`,
    FORM_EDIT_RAW: `sale-subtype/edit/`,
  },
  ACCESS_PROFILE: {
    BASE: 'access-profile',
    FORM_EDIT: `access-profile/edit/:id_hash`,
    FORM_EDIT_RAW: `access-profile/edit/`,
  },
  PROFILES: {
    BASE: 'profiles',
  },
  CST_CONVERSOR: {
    BASE: 'cst-conversor',
    FORM_NEW: `cst-conversor/new`,
    FORM_EDIT: `cst-conversor/edit/:id`,
    FORM_EDIT_RAW: `cst-conversor/edit/`,
  },
  PROFIT_PANEL: {
    BASE: 'profit-panel',
  },
  GROUP_ITEMS: {
    BASE: 'group-items',
    FORM_NEW: `group-items/new`,
    FORM_EDIT: `group-items/edit/:groupItemId`,
    FORM_EDIT_RAW: `group-items/edit/`,
  },
  DEFECTS: {
    BASE: 'defects',
    FORM_NEW: `defects/new`,
    FORM_EDIT: `defects/edit/:id`,
    FORM_EDIT_RAW: `defects/edit/`,
  },
  CHECKLIST: {
    BASE: 'checklist',
    FORM_NEW: `checklist/new`,
    FORM_EDIT: `checklist/edit/:id`,
    FORM_EDIT_RAW: `checklist/edit/`,
  },
  CORRECTIONS: {
    BASE: 'corrections',
    FORM_NEW: `corrections/new`,
    FORM_EDIT: `corrections/edit/:id`,
    FORM_EDIT_RAW: `corrections/edit/`,
  },
  REJECTED_ITEMS_BY_CUSTOMER: {
    BASE: 'rejected-items-by-customer',
  },
  BANK_CONCILIATION: {
    BASE: 'bank-conciliation',
    NEW_CONCILIATION: 'bank-conciliation/new-conciliation',
    VIEW_CONCILIATION: 'bank-conciliation/view-conciliation/:id',
  },
  COST_CENTER_REPORT: {
    BASE: 'cost-center-report',
  },

  KANBAN_SCHEDULES: {
    BASE: 'kanban-schedules',
  },

  FINANCE_CALENDAR: {
    BASE: 'finance-calendar',
  },
};

export const CLIENT_ROUTES = [
  {
    path: CLIENT_PATH_ROUTES.APPROACH.BASE,
    active: 'cadastros',
    name: 'Abordagem',
    component: Approach,
  },
  {
    path: CLIENT_PATH_ROUTES.APPROACH.FORM_NEW,
    active: 'cadastros',
    name: 'Nova Abordagem',
    component: FormApproach,
  },
  {
    path: CLIENT_PATH_ROUTES.APPROACH.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Abordagem',
    component: FormApproach,
  },
  {
    path: CLIENT_PATH_ROUTES.DEPARTMENT.BASE,
    active: 'cadastros',
    name: 'Departamento',
    component: Department,
  },
  {
    path: CLIENT_PATH_ROUTES.DEPARTMENT.FORM_NEW,
    active: 'cadastros',
    name: 'Novo Departamento',
    component: FormDepartment,
  },
  {
    path: CLIENT_PATH_ROUTES.DEPARTMENT.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Departamento',
    component: FormDepartment,
  },
  {
    path: CLIENT_PATH_ROUTES.GROUP_ITEMS.BASE,
    active: 'cadastros',
    name: 'Grupo de Itens',
    component: GroupItems,
  },
  {
    path: CLIENT_PATH_ROUTES.GROUP_ITEMS.FORM_NEW,
    active: 'cadastros',
    name: 'Novo Grupo de Itens',
    component: FormGroupItem,
  },
  {
    path: CLIENT_PATH_ROUTES.GROUP_ITEMS.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Grupo de Itens',
    component: FormGroupItem,
  },
  {
    path: CLIENT_PATH_ROUTES.DEFECTS.BASE,
    active: 'cadastros',
    name: 'Defeitos',
    component: Defects,
  },
  {
    path: CLIENT_PATH_ROUTES.DEFECTS.FORM_NEW,
    active: 'cadastros',
    name: 'Novo Defeito',
    component: FormDefect,
  },
  {
    path: CLIENT_PATH_ROUTES.DEFECTS.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Defeito',
    component: FormDefect,
  },
  {
    path: CLIENT_PATH_ROUTES.CHECKLIST.BASE,
    active: 'cadastros',
    name: 'Checklist',
    component: Checklist,
  },
  {
    path: CLIENT_PATH_ROUTES.CHECKLIST.FORM_NEW,
    active: 'cadastros',
    name: 'Novo Checklist',
    component: FormChecklist,
  },
  {
    path: CLIENT_PATH_ROUTES.CHECKLIST.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Checklist',
    component: FormChecklist,
  },
  {
    path: CLIENT_PATH_ROUTES.CORRECTIONS.BASE,
    active: 'cadastros',
    name: 'Correções',
    component: Corrections,
  },
  {
    path: CLIENT_PATH_ROUTES.CORRECTIONS.FORM_NEW,
    active: 'cadastros',
    name: 'Nova Correção',
    component: FormCorrection,
  },
  {
    path: CLIENT_PATH_ROUTES.CORRECTIONS.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Correção',
    component: FormCorrection,
  },
  {
    path: CLIENT_PATH_ROUTES.SECTION.BASE,
    active: 'cadastros',
    name: 'Seção',
    component: Section,
  },
  {
    path: CLIENT_PATH_ROUTES.SECTION.FORM_NEW,
    active: 'cadastros',
    name: 'Nova Seção',
    component: FormSection,
  },
  {
    path: CLIENT_PATH_ROUTES.SECTION.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Seção',
    component: FormSection,
  },
  {
    path: CLIENT_PATH_ROUTES.SALE_SUBTYPE.BASE,
    active: 'cadastros',
    name: 'Subtipo de Venda',
    component: RegisterSubtype,
  },
  {
    path: CLIENT_PATH_ROUTES.SALE_SUBTYPE.FORM_NEW,
    active: 'cadastros',
    name: 'Novo Subtipo de Venda',
    component: FormSaleSubtype,
  },
  {
    path: CLIENT_PATH_ROUTES.SALE_SUBTYPE.FORM_EDIT,
    active: 'cadastros',
    name: 'Editar Subtipo de Venda',
    component: FormSaleSubtype,
  },
  {
    path: CLIENT_PATH_ROUTES.ACCESS_PROFILE.FORM_EDIT,
    active: 'home',
    name: 'Permissões e Acessos',
    component: AccessProfiles,
  },
  {
    path: CLIENT_PATH_ROUTES.CST_CONVERSOR.BASE,
    active: 'products',
    name: 'Conversor de CST para CSOSN',
    component: CSTConversor,
  },
  {
    path: CLIENT_PATH_ROUTES.CST_CONVERSOR.FORM_NEW,
    active: 'products',
    name: 'Novo Cadastro',
    component: FormCSTConversor,
  },
  {
    path: CLIENT_PATH_ROUTES.CST_CONVERSOR.FORM_EDIT,
    active: 'products',
    name: 'Editar Cadastro',
    component: FormCSTConversor,
  },
  {
    path: CLIENT_PATH_ROUTES.REJECTED_ITEMS_BY_CUSTOMER.BASE,
    active: 'relatorios',
    name: 'Itens Recusados pelos Clientes (Orçamento Digital)',
    component: ListRefusedItems,
  },
  {
    path: CLIENT_PATH_ROUTES.BANK_CONCILIATION.BASE,
    active: 'financial',
    name: 'Conciliação Bancária',
    component: BankConciliation,
  },
  {
    path: CLIENT_PATH_ROUTES.BANK_CONCILIATION.NEW_CONCILIATION,
    active: 'financial',
    name: 'Nova Conciliação Bancária',
    component: BankConciliationDetails,
  },
  {
    path: CLIENT_PATH_ROUTES.BANK_CONCILIATION.VIEW_CONCILIATION,
    active: 'financial',
    name: 'Visualizar Conciliação Bancária',
    component: BankConciliationDetails,
  },
  {
    path: CLIENT_PATH_ROUTES.COST_CENTER_REPORT.BASE,
    active: 'relatorios',
    name: 'Centro de Custo',
    component: CostCenter,
  },
  {
    path: CLIENT_PATH_ROUTES.KANBAN_SCHEDULES.BASE,
    active: 'vendas',
    name: 'Agenda (Kanban)',
    component: KanbanScheduling,
  },
  {
    path: CLIENT_PATH_ROUTES.FINANCE_CALENDAR.BASE,
    active: 'financial',
    name: 'Calendário Financeiro',
    component: FinanceCalendar,
  },
];
