import React from 'react';
import { Modal, Col } from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton';

import { reduxForm, Field, change } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import TextArea from '../../Clients/NewClient/FormClient/components/TextArea';

import { currency } from '../../../components/ToNormalize/ToNormalize';
import { useEffect } from 'react';

function FormCancelInvoiceModal({
  onCancel,
  handleSubmit,
  loading,
  nfceSelected,
  hasManySales,
  setCurrentStep,
}) {
  const dispatch = useDispatch();
  const {
    cancelInvoice: {
      values: { justification },
    },
  } = useSelector((state) => state.form);

  useEffect(() => {
    dispatch(change('cancelInvoice', 'justification', ''));
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton onHide={onCancel}>
        <Modal.Title>
          <strong>Cancelamento de {nfceSelected.type}</strong>
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              {nfceSelected.type}:{' '}
              <span style={{ fontWeight: 'normal' }}>{nfceSelected?.code}</span>
            </span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Cliente:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {nfceSelected?.clientName}
              </span>
            </span>
            <span
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Valor:{' '}
              <span style={{ fontWeight: 'normal' }}>
                {currency(nfceSelected?.total || 0)}
              </span>
            </span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col style={{ marginBottom: 5 }}>
          <label>Informe o motivo do cancelamento abaixo:</label>
          <span
            style={{
              position: 'absolute',
              right: 15,
              top: 30,
              color: justification?.length >= 15 ? 'green' : 'red',
            }}
          >
            {justification?.length || 0} caracteres
          </span>
          <Field
            name="justification"
            component={TextArea}
            placeholder="O motivo deverá ter pelo menos 15 caracteres"
          />
        </Col>
        <Col style={{ textAlign: 'justify' }}>
          {nfceSelected.type === 'NFC-e' ? (
            <span
              style={{
                fontSize: 12,
                fontWeight: 'bold',
                color: 'red',
              }}
            >
              O prazo de cancelamento é de 30 minutos a partir do envio da nota.
              Alguns estados possuem prazos de cancelamento de 24 horas. Valide
              com sua contabilidade o prazo máximo de cancelamento.
            </span>
          ) : (
            <div
              style={{
                fontSize: 12,
                color: 'red',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                gap: '10px',
              }}
            >
              <span>
                O prazo de cancelamento varia de estado para estado. Em caso de
                dúvidas, verifique com a contabilidade.
              </span>
              <strong>
                Caso a venda tenha apenas essa nota emitida, a venda será
                cancelada, inclusive a nota agrupada.
              </strong>
            </div>
          )}
        </Col>
      </Modal.Body>
      <Modal.Footer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Button bsStyle="danger" disabled={loading} onClick={onCancel} fill>
            Voltar
          </Button>
          <Button
            onClick={hasManySales ? () => setCurrentStep(2) : null}
            bsStyle="info"
            disabled={loading || !justification || justification?.length < 15}
            fill
            type="submit"
          >
            Cancelar {nfceSelected.type}
            <span className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} />
          </Button>
        </div>
      </Modal.Footer>
    </form>
  );
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  form: 'cancelInvoice',
})(FormCancelInvoiceModal);
