import api from '../services/api';
import config from '../config';

const index = async (params) => {
  try {
    const response = await api.get('account-plans', {
      params,
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAccountPlanById = (accountPlanId) =>
  api.get(config.endpoint + `account-plans/${accountPlanId}`);

const createAccountPlan = (data) =>
  api.post(config.endpoint + 'account-plans', data);

const updateAccountPlan = (accountPlanId, data) =>
  api.put(config.endpoint + `account-plans/${accountPlanId}`, data);

const getAccountPlanByType = (companyId, type) =>
  api.get(
    config.endpoint + `account-plans/get-by-type/${companyId}?type=${type}`
  );

export default {
  index,
  getAccountPlanById,
  createAccountPlan,
  updateAccountPlan,
  getAccountPlanByType,
};
