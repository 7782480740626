import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { InputContainer } from 'v2/components/Input';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';
import useOnboardingStore from '../store/onboarding-store';
import CustomizedTabs from 'v2/components/Tabs/Tabs';
import { TrainingTable } from './TrainingTable';
import { ModulesTable } from './ModulesTable';
import {
  OnboardingFeedbackModal,
  OnboardingFeedbackOnlyContent,
} from './OnboardingFeedback';

export const MainData = ({ handleClose, handleReload }) => {
  const {
    selectedTab,
    setSelectedTab,
    legends,
    completeTrainingStatus,
    completeModuleStatus,
    initialDate,
    finalDate,
    responsable,
    setResponsable,
    isLoading,
    emojiSelected,
    setEmojiSelected,
    feedbackObservation,
    setFeedbackObservation,
  } = useOnboardingStore();

  const tabLabels = [
    <div className="flex center gap-025">
      <span>Boas-vindas e Treinamento</span>
      {completeTrainingStatus && (
        <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#5cb85c" />
      )}
    </div>,
    <div className="flex center gap-025">
      <span>Módulos</span>
      {completeModuleStatus && (
        <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#5cb85c" />
      )}
    </div>,
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderTabContent = (index) => {
    switch (index) {
      case 0:
        return <TrainingTable />;
      case 1:
        return <ModulesTable />;
      default:
        return 0;
    }
  };

  const renderTabs = () => {
    return (
      <CustomizedTabs
        tabLabels={tabLabels}
        value={selectedTab}
        onChange={handleTabChange}
      />
    );
  };

  return (
    <div className="flex column gap-050">
      <div>
        <div
          className="flex center justify-center text-red text-end text-bold fontSize-14"
          style={{
            width: '100px',
            height: '25px',
            borderBottom: '4px solid #d43f3a',
            marginLeft: '-15px',
            marginTop: '-15px',
          }}
        >
          Legenda
        </div>

        <div className="flex between gap-1 h-100px">
          <div className="flex column gap-050">
            <div className="flex row gap-075 mt-075">
              {legends.map((legend) => (
                <div
                  className="flex center justify-center h-35 radius-4 fontSize-12 gap-025 p-050"
                  style={{ backgroundColor: legend.color, color: 'white' }}
                >
                  {legend.iconName && (
                    <span className="text-bold">{legend.iconName}</span>
                  )}
                  {legend.icon && (
                    <FontAwesomeIcon icon={legend.icon} size="lg" />
                  )}
                  {legend.description}
                </div>
              ))}
            </div>
            <div className="flex row gap-075 ">
              <InputContainer gap="0" style={{ width: '250px' }}>
                <InputLabel className="fontSize-14 text-gray1" required>
                  Nome do Responsável:
                </InputLabel>
                <InputText
                  maxLength={50}
                  height={'35px'}
                  type="text"
                  value={responsable}
                  isDisabled={isLoading}
                  onChange={(e) => setResponsable(e.target.value)}
                />
              </InputContainer>
              <InputContainer gap="0">
                <InputLabel className="fontSize-14 text-gray1">
                  Data Inicial:
                </InputLabel>
                <InputText
                  height={'35px'}
                  type="date"
                  isDisabled
                  value={initialDate}
                  onChange={(e) => null}
                />
              </InputContainer>
              <InputContainer gap="0">
                <InputLabel className="fontSize-14 text-gray1">
                  Data Final:
                </InputLabel>
                <InputText
                  height={'35px'}
                  type="date"
                  isDisabled
                  value={finalDate}
                  onChange={(e) => null}
                />
              </InputContainer>
            </div>
          </div>
          <div className="flex column center w-100 p-1">
            {isLoading && completeTrainingStatus && completeModuleStatus && (
              <OnboardingFeedbackOnlyContent
                emojiSelected={emojiSelected}
                setEmojiSelected={setEmojiSelected}
                feedbackObservation={feedbackObservation}
                setFeedbackObservation={setFeedbackObservation}
                iconSize="small"
                nonePlaceholder
                isLoading={isLoading}
              />
            )}
          </div>
        </div>

        <div
          className="flex column gap-075 w-100"
          style={{ width: 'calc(100% + 7.5px)', marginLeft: '-7.5px' }}
        >
          {renderTabs()}
          {renderTabContent(selectedTab)}
        </div>
      </div>

      <OnboardingFeedbackModal
        handleClose={handleClose}
        handleReload={handleReload}
      />
    </div>
  );
};
