import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { useSelector, useDispatch } from 'react-redux';

import { currency } from 'client/components/ToNormalize/ToNormalize';
import {
  addItems,
  handleFreight,
  handleDiscountType,
  handleDiscounts,
  handleChange,
  handleConfirmEditItem,
} from './redux/actions';

import PercentInput from '../../../../components/Percent';
import CurrencyInput from '../../../../components/Currency';
import Button from 'client/components/CustomButton/CustomButton.jsx';
import ItemsTable from './ItemsTable';
import ItemsModal from './ItemsModal';

import { useAuth } from '../../../../../contexts/auth';
import { usePlanSignatureContext } from '../../../../../contexts/plan-signature';

import employeesRepository from '../../../../../repositories/Employees';
import companyConfigRepository from '../../../../../repositories/CompanyConfig';
import discountGroupRepository from 'repositories/DiscountGroups';
import discountPriceProductsRepository from 'repositories/DiscountPriceProducts';
import discountGroupServicesRepository from 'repositories/DiscountGroupServices';
import { FragaModal } from 'v2/client/components/FragaModal';
import { useSidebar } from 'v2/hooks/useSidebar/useSidebar';
import { ModalDiscountReleaseWithPassword } from 'v2/components/Modals/DiscountRelease/ModalDiscountReleaseWithPassword';

export default function ItensVenda() {
  const dispatch = useDispatch();
  const { company, companyId } = useAuth();
  const { validateFeatureAvailability } = useSidebar();
  const { isPlanFree, isPlanStart, isPlanBasic } = usePlanSignatureContext();
  const isPlanPrime = !isPlanFree && !isPlanStart && !isPlanBasic;
  const [discountGroups, setDiscountGroups] = useState([]);
  const [selectedDiscountGroupId, setSelectedDiscountGroupId] = useState(null);
  const [discountGroupProducts, setDiscountGroupProducts] = useState([]);
  const [discountGroupServices, setDiscountGroupServices] = useState([]);
  const [isConfirmPartsConsultation, setIsConfirmPartsConsultation] =
    useState(false);
  const [
    isModalDiscountReleaseWithPasswordOpen,
    setIsModalDiscountReleaseWithPasswordOpen,
  ] = useState(false);
  const [hasKitRegistration, setHasKitRegistration] = useState(false);
  const [showFragaModalOpen, setShowFragaModalOpen] = useState(false);
  const [itemToEditIndex, setItemToEditIndex] = useState(null);
  const [itemsToEdit, setItemsToEdit] = useState([]);
  const { vehicle = { values: {} } } = useSelector((state) => state.form);
  const {
    items,
    selectedClient,
    selectedVehicle,
    openItemsModal,
    subTotal,
    total,
    discountType,
    generalDiscountPercentage,
    generalDiscountCash,
    freightValue,
    saleStatusId,
    employeeId,
    returnedValue,
  } = useSelector((state) => state.saleReducer);

  const [employees, setEmployees] = useState([]);

  const isAllowedDiscountGreater = validateFeatureAvailability(
    {
      subFeatureId: 118,
    },
    false
  );

  useEffect(() => {
    if (companyId) {
      loadEmployees();
      loadDiscountTables();
      getCompanyConfig();
    }
  }, [companyId]);
  useEffect(() => {
    if (companyId) {
      getDiscountGroupProducts();
      getDiscountGroupServices();
    }
  }, [selectedDiscountGroupId]);

  async function getCompanyConfig() {
    const { kitRegistration } = await companyConfigRepository.show(companyId);

    setHasKitRegistration(kitRegistration);
  }

  const loadEmployees = async () => {
    try {
      const { data } =
        await employeesRepository.getEmployeesThatPerformServicesOrSellProducts(
          companyId,
          {
            isActive: 1,
          }
        );
      return setEmployees(data);
    } catch (err) {
      console.log(err);
      toastr.warning('Ocorre um erro ao carregar os funcionários.');
    }
  };

  if (isConfirmPartsConsultation && !selectedVehicle.License_Plate) {
    setIsConfirmPartsConsultation(false);
    toastr.warning(
      'OS Digital',
      'A função está disponível apenas para carros, motos e caminhões. Qualquer outro tipo de veículo deverá cadastrar a peça previamente antes de fazer a venda/ordem de serviço.'
    );
  }

  const handleVehicleData = async () => {
    setShowFragaModalOpen(true);
  };

  const handleAddItems = async (value) => {
    const updatedValue = value.map((item) => ({
      ...item,
      Amount:
        item.Discount_Value === 0
          ? Number((item.Unit_Value * item.Quantity).toFixed(2))
          : Number(
              item.Quantity *
                (
                  item.Unit_Value -
                  (item.Unit_Value * item.Discount_Value) / 100
                ).toFixed(2)
            ),
    }));

    setItemsToEdit(updatedValue);

    const isAboveLimit = updatedValue.some(
      (item) =>
        item.hasDiscountLimit &&
        parseFloat(item.discountLimit) < item.Discount_Value
    );

    const itemIndex = updatedValue.findIndex(
      (item) =>
        item.hasDiscountLimit &&
        parseFloat(item.discountLimit) < item.Discount_Value
    );

    setItemToEditIndex(itemIndex);

    if (isAboveLimit) {
      if (company.companyConfig.discountAboveLimit === 'PASSWORD_REQUIRED') {
        await dispatch(
          handleConfirmEditItem(
            itemIndex,
            updatedValue[itemIndex],
            updatedValue,
            isAllowedDiscountGreater,
            setIsModalDiscountReleaseWithPasswordOpen
          )
        );
        return;
      } else {
        toastr.warning(
          'Não autorizado',
          `O desconto inserido é maior que o valor permitido (Desconto máximo: ${updatedValue[itemIndex].discountLimit}%)`
        );
        return;
      }
    }
    dispatch(addItems(updatedValue, items));
    if (generalDiscountPercentage > 0 || generalDiscountCash > 0) {
      dispatch([
        handleDiscounts(generalDiscountPercentage, 'generalDiscountPercentage'),
        { type: 'HANDLE_CHANGE', payloadType: 'installments', payload: [] },
        {
          type: 'HANDLE_CHANGE',
          payloadType: 'selectedCondOfPayment',
          payload: {},
        },
      ]);
    }
  };

  const handleDiscountPercentage = (value) => {
    let discount = Number(value);

    if (discount > 99.99 || subTotal === 0 || !value) discount = 0;

    dispatch([
      handleDiscounts(discount, 'generalDiscountPercentage'),
      { type: 'HANDLE_CHANGE', payloadType: 'installments', payload: [] },
      {
        type: 'HANDLE_CHANGE',
        payloadType: 'selectedCondOfPayment',
        payload: {},
      },
    ]);
  };

  const handleDiscountCash = (value) => {
    let discount = Number(value);

    if (discount > subTotal) {
      toastr.warning('O Desconto não pode ultrapassar o subtotal');
      discount = 0;
    }

    dispatch([
      handleDiscounts(parseFloat(discount), 'generalDiscountCash'),
      { type: 'HANDLE_CHANGE', payloadType: 'installments', payload: [] },
      {
        type: 'HANDLE_CHANGE',
        payloadType: 'selectedCondOfPayment',
        payload: {},
      },
    ]);

    const input = document.getElementById('discountInput');
    input.blur();
    input.focus();
  };

  const handleFreightChange = (value) => {
    let freight = Number(value);

    dispatch([handleFreight(parseFloat(freight), 'freightValue')]);

    const input = document.getElementById('freightValueInput');
    input.blur();
    input.focus();
  };

  const hasAnyItemReturned = items.some(
    (item) => item.QuantityItemsReturned > 0
  );

  async function loadDiscountTables() {
    try {
      const discountGroups = await discountGroupRepository.getAll(companyId);
      setDiscountGroups(discountGroups);
    } catch (err) {
      console.log(err.mensage);
    }
  }
  async function getDiscountGroupProducts() {
    try {
      if (selectedDiscountGroupId) {
        const products = await discountPriceProductsRepository.show(
          selectedDiscountGroupId
        );

        setDiscountGroupProducts(products);
      } else {
        setDiscountGroupProducts([]);
      }
    } catch (err) {
      console.log(err.mensage);
    }
  }
  async function getDiscountGroupServices() {
    try {
      if (selectedDiscountGroupId) {
        const services = await discountGroupServicesRepository.show(
          selectedDiscountGroupId
        );
        setDiscountGroupServices(services);
      } else {
        setDiscountGroupServices([]);
      }
    } catch (err) {
      console.log(err.mensage);
    }
  }

  return (
    <Col>
      <Col id="reviewHeader" style={{ marginTop: '15px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
          }}
        >
          <Button
            type="button"
            id="btn-nova-revisao"
            className="btn btn-sucesso"
            bsStyle="info"
            fill
            onClick={() => dispatch(handleChange(true, 'openItemsModal'))}
            disabled={
              saleStatusId === 5 ||
              saleStatusId === 2 ||
              saleStatusId === 6 ||
              saleStatusId === 9 ||
              saleStatusId === 7
            }
            style={{ margin: '0 0 0 15px' }}
          >
            + Adicionar Item
          </Button>

          {!isPlanFree && !isPlanStart && (
            <Button
              type="button"
              id="btn-nova-revisao"
              className="btn btn-sucesso"
              bsStyle="info"
              fill
              onClick={() => handleVehicleData()}
              disabled={
                saleStatusId === 5 ||
                saleStatusId === 2 ||
                saleStatusId === 6 ||
                saleStatusId === 7 ||
                saleStatusId === 9
              }
              style={{ margin: '0 0 0 15px' }}
            >
              + Adicionar Item via Consulta Online
            </Button>
          )}
        </div>
        {!isPlanFree && !isPlanStart && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            {!isPlanBasic && !!discountGroups.length && (
              <div
                style={{
                  display: 'flex',
                  width: '70%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <label>Tabela de Preço Diferenciada:</label>
                <select
                  className="form-control foco-input"
                  onChange={(e) =>
                    setSelectedDiscountGroupId(Number(e.target.value))
                  }
                  value={employeeId}
                  style={{ maxWidth: '250px' }}
                >
                  <option name="" value="">
                    Selecione
                  </option>
                  {discountGroups.map((e) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
        )}
        <Col
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            color: 'red',
          }}
        >
          {hasAnyItemReturned && (
            <span>Itens em vermelho indicam devolução</span>
          )}
        </Col>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12}>
        <ItemsTable />
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} id="rodape-itensVenda">
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <span>Frete:</span>
          <CurrencyInput
            disabled={
              saleStatusId === 5 ||
              saleStatusId === 2 ||
              saleStatusId === 6 ||
              saleStatusId === 7 ||
              saleStatusId === 9
            }
            id="freightValueInput"
            className="form-control foco-input"
            value={freightValue}
            onChangeEvent={(e) => handleFreightChange(e.target.value)}
            style={{ textAlign: 'right' }}
          />
        </div>
        {!!returnedValue && (
          <Col style={{ marginTop: 5, marginRight: 10 }}>
            <span>
              Valor Devolvido: <strong>{currency(returnedValue)}</strong>
            </span>
          </Col>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            minWidth: '60%',
          }}
        >
          <Col style={{ marginTop: 5 }}>
            <span>
              Subtotal: <strong>{currency(subTotal)}</strong>
            </span>
          </Col>
          <Col
            style={{
              display: 'flex',
              width: '45%',
              padding: '0px 10px 0px 10px',
              justifyContent: 'center',
            }}
          >
            <span style={{ marginTop: 5 }}>Desconto:&nbsp;</span>
            <select
              style={{ width: '60%' }}
              className="form-control foco-input"
              value={discountType}
              disabled={
                saleStatusId === 5 ||
                saleStatusId === 2 ||
                saleStatusId === 6 ||
                saleStatusId === 7 ||
                saleStatusId === 9
              }
              onChange={(e) =>
                dispatch(handleDiscountType(e.target.value, items))
              }
            >
              <option value="%">%</option>
              <option value="R$">R$</option>
            </select>
            {discountType === '%' ? (
              <PercentInput
                disabled={
                  saleStatusId === 5 ||
                  saleStatusId === 2 ||
                  saleStatusId === 6 ||
                  saleStatusId === 7 ||
                  saleStatusId === 9
                }
                value={generalDiscountPercentage}
                className="form-control foco-input"
                onChangeEvent={(e) => handleDiscountPercentage(e.target.value)}
              />
            ) : (
              <CurrencyInput
                disabled={
                  saleStatusId === 5 ||
                  saleStatusId === 2 ||
                  saleStatusId === 6 ||
                  saleStatusId === 7 ||
                  saleStatusId === 9
                }
                id="discountInput"
                className="form-control foco-input"
                value={generalDiscountCash}
                onChangeEvent={(e) => handleDiscountCash(e.target.value)}
              />
            )}
          </Col>
          <Col style={{ marginTop: 5 }}>
            <a>
              Total: <strong>{currency(total)}</strong>
            </a>
          </Col>
        </div>
      </Col>
      {openItemsModal && (
        <ItemsModal
          onCancel={() => dispatch(handleChange(false, 'openItemsModal'))}
          clientName={selectedClient.Company_Name}
          customerId={selectedClient.id}
          onSubmit={(e) => handleAddItems(e)}
          discountGroupProducts={discountGroupProducts}
          discountGroupServices={discountGroupServices}
          allowPromotionalPrices={true}
          hasKitSearch={hasKitRegistration && (isPlanPrime || isPlanBasic)}
        />
      )}

      {showFragaModalOpen && (
        <FragaModal
          show={showFragaModalOpen}
          onSubmit={(e) => handleAddItems(e)}
          setShowFragaModalOpen={setShowFragaModalOpen}
          isLocal={false}
          selectedVehicleData={vehicle.values}
        />
      )}
      <ModalDiscountReleaseWithPassword
        open={isModalDiscountReleaseWithPasswordOpen}
        onClose={() => setIsModalDiscountReleaseWithPasswordOpen(false)}
        itemToEdit={itemsToEdit[itemToEditIndex]}
        itemToEditIndex={itemToEditIndex}
        items={itemsToEdit}
      />
    </Col>
  );
}
