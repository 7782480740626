import { Container } from './SchedulingTrainingButton.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';

export function SchedulingTrainingButton() {
  const handleOpenScheduling = () => {
    return window.open(
      'https://outlook.office365.com/book/TreinamentodoSistema1Hora@workg.com.br/',
      '_blank'
    );
  };

  return (
    <Container onClick={handleOpenScheduling}>
      <FontAwesomeIcon icon={faCalendarDays} fontSize={18} cursor="pointer" />
      <span style={{ whiteSpace: 'nowrap' }}>Agendar Treinamento</span>
    </Container>
  );
}
