import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import { Col, Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { change } from 'redux-form';

import RenderField from 'components/RenderField';
import { currencyMask } from 'utils/masks';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import AlertModal from '../../../../components/AlertModal/AlertModal';

import FormTextForInvoices from '../../../views/TextForInvoices/NewTextForInvoices/NewTextForInvoices';
import textForInvoicesRepository from '../../../../repositories/TextsForInvoices';
import intermediaryRepository from '../../../../repositories/Intermediary';
import { INVOICE_FINALITY } from '../../../../utils/constants';
import { cnpjMask } from '../../../components/ToNormalize/ToNormalize';
import { useAuth } from 'contexts/auth';
import Intermediary from '../../Intermediary/NewIntermediary/FormNewIntermediary';
import Toggle from 'react-toggle';

const OtherInfos = () => {
  const [text, setText] = useState([]);
  const [textModal, setTextModal] = useState(false);
  const [loadingText, setLoadingText] = useState(true);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [textId, setTextId] = useState('');
  const [intermediaries, setIntermediaries] = useState([]);
  const [isIntermediaryModalOpen, setIsIntermediaryModalOpen] = useState(false);

  const { companyId } = useAuth();
  const dispatch = useDispatch();
  const {
    isInvoiceDenied,
    finality,
    isInvoiceCanceled,
    isInvoiceIssuedInContingency,
    isInvoiceIssued,
    presenceIndicator,
    intermediaryId,
    selectedTextsForInvoices,
    calculateIPIOnICMS,
    calculateIPIOnICMSST,
  } = useSelector((state) => state.form.NFe.values);

  useEffect(() => {
    if (!!companyId) getIntermediaries();
  }, [companyId]);

  useEffect(() => {
    dispatch(change('NFe', 'intermediaryId', ''));
  }, [presenceIndicator]);

  async function getIntermediaries() {
    try {
      const { data } = await intermediaryRepository.getAllByCompany({
        companyId,
        isActive: 1,
      });
      setIntermediaries(data);
      if (intermediaryId) {
        checkIntermediaryInputed(data);
      }
    } catch (err) {
      toastr.error(
        err.response?.data?.message ??
          'Ocorreu algum problema ao buscar intermediadores.'
      );
    }
  }

  function checkIntermediaryInputed(data) {
    const intermediaryInputedFound = data.find(
      (intermediary) => intermediary.id === intermediaryId
    );
    if (!intermediaryInputedFound)
      dispatch(change('NFe', 'intermediaryId', ''));
  }

  function openModalTextForInvoices() {
    setTextModal(true);
    if (text.length < 1) getText();
  }

  async function getText() {
    setLoadingText(true);
    try {
      const textsForInvoices = await textForInvoicesRepository.getAllByCompany({
        companyId,
        status: true,
      });
      const serializedTexts = textsForInvoices.map((item) => ({
        ...item,
        checked: selectedTextsForInvoices?.includes(item.id) ? true : false,
      }));
      setText(serializedTexts);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os textos para notas fiscais. Por favor, tente novamente'
      );
    } finally {
      setLoadingText(false);
    }
  }

  function handleCloseTextModal() {
    let anyCheckedText = text.filter((t) => t.checked === true);
    if (anyCheckedText.length > 0) setIsCancelModalOpen(true);
    else {
      setTextModal(false);
      setText(text.map((t) => ({ ...t, checked: false })));
    }
  }

  function handleCloseModal() {
    setIsCancelModalOpen(false);
    setTextModal(false);
    // setText([]);
    setText(text.map((t) => ({ ...t, checked: false })));
  }

  function handleCheckTextReactTable(item) {
    setLoadingText(true);

    const selectedTexts = text.map((t) => {
      if (t.id === item.id) return { ...t, checked: !item.checked };
      else return { ...t };
    });

    dispatch([change('NFe', 'selectedTextsForInvoices', [])]);

    setText(selectedTexts);
    setLoadingText(false);
  }

  function handleIncludeText() {
    const filterText = text.filter((t) => t.checked).map((t) => t.id);

    dispatch([change('NFe', 'textsForInvoice', filterText)]);

    setTextModal(false);
  }

  function handleModal() {
    setOpenModal(false);
    getText();
    setTextModal(true);
  }

  const renderForm = () => {
    switch (modalTitle) {
      case 'Novo Texto':
        return (
          <FormTextForInvoices
            Calling="NFe"
            modal={true}
            handleModal={handleModal}
            dispatch={dispatch}
            readOnly={true}
          />
        );
      case 'Editar Texto':
        return (
          <FormTextForInvoices
            Calling="NFe"
            textForInvoicesId={textId}
            modal={true}
            handleModal={handleModal}
            dispatch={dispatch}
            readOnly={true}
          />
        );
      case 'Editar Intermediador':
        return (
          <Intermediary
            intermediaryId={intermediaryId}
            onCancel={handleCancelIntermediaryModal}
            onSubmit={handleSubmitIntermediaryModal}
          />
        );
      default:
        return <div />;
    }
  };

  const handleOpenModal = async (title, textForInvoicesId) => {
    setModalTitle(title);
    setTextId(textForInvoicesId);
    setTextModal(false);
    setOpenModal(true);
  };

  function renderTextTable() {
    return (
      <>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: '0px', paddingLeft: 0, marginBottom: 10 }}
        >
          <div className="">
            <ModalAlertCompany
              show={noCompanyModal}
              onHide={() => setNoCompanyModal(false)}
            />
            <button
              id="btn-nova-revisao"
              className="btn btn-sucesso"
              onClick={() =>
                handleOpenModal('Novo Texto', { marginBottom: '65px' })
              }
            >
              + Novo Texto
            </button>
          </div>
        </Col>
        <Col>
          <ReactTable
            style={{
              marginTop: 10,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            data={text}
            columns={[
              {
                accessor: 'checked',
                width: 50,
                className: 'text-center',
                Cell: (props) => (
                  <input
                    style={{ position: 'relative', verticalAlign: 'bottom' }}
                    type="checkbox"
                    checked={props.value}
                    onChange={() => handleCheckTextReactTable(props.original)}
                    id="checkbox-ncm_table"
                  />
                ),
              },
              {
                Header: 'Texto',
                accessor: 'text',
              },
              {
                Header: 'Ações',
                accessor: 'id',
                headerClassName: 'text-center',
                filterable: false,
                className: 'texto',
                width: 50,
                Cell: (props) => (
                  <FontAwesomeIcon
                    title="Editar"
                    cursor="pointer"
                    style={{ height: '1.5em', width: '1.5em', color: 'black' }}
                    icon={faEdit}
                    onClick={() => handleOpenModal('Editar Texto', props.value)}
                  />
                ),
              },
            ]}
            defaultPageSize={5}
            loading={loadingText}
            sortable={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            defaultSorted={[
              {
                id: 'text',
                desc: false,
              },
            ]}
          />
        </Col>
      </>
    );
  }

  const handleOpenIntermediaryModal = () => {
    if (intermediaryId) {
      setModalTitle('Editar Intermediador');
      setOpenModal(true);
    }
  };

  const handleCancelIntermediaryModal = () => {
    setOpenModal(false);
  };

  const handleSubmitIntermediaryModal = () => {
    setOpenModal(false);
    getIntermediaries();
  };

  const disableInputs =
    isInvoiceDenied ||
    isInvoiceCanceled ||
    isInvoiceIssuedInContingency ||
    isInvoiceIssued;

  return (
    <div id="NF-e-other-infos">
      <div>
        {finality !== INVOICE_FINALITY.DEVOLUTION && (
          <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '2px 2px' }}>
            <Field
              name="presenceIndicator"
              component={RenderField}
              label="Indicador de Presença"
              as="select"
              disabled={disableInputs}
            >
              <option value="1">Venda presencial</option>
              <option value="2">Venda não presencial, pela Internet</option>
              <option value="3">Venda não presencial, Teleatendimento</option>
              <option value="5">
                Venda presencial, fora do estabelecimento
              </option>
              <option value="9">Venda não presencial, outros.</option>
              <option value="0">
                Operação sem intermediador (em site ou plataforma própria)
              </option>
            </Field>
          </Col>
        )}

        <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '2px 2px' }}>
          <Field
            name="otherExpenses"
            component={RenderField}
            label="Outras Despesas"
            {...currencyMask}
            disabled={disableInputs}
          />
        </Col>

        <Col
          xs={3}
          sm={3}
          md={3}
          lg={3}
          style={{
            padding: '2px 2px',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '180px',
          }}
        >
          <label>Texto Fiscal</label>
          <Field
            name="textsForInvoice"
            component={() => (
              <button
                type="button"
                className="text-for-invoices-button"
                onClick={openModalTextForInvoices}
                disabled={disableInputs}
              >
                Aplicar Texto Fiscal
              </button>
            )}
          />
        </Col>
        {finality !== INVOICE_FINALITY.DEVOLUTION && (
          <Col xs={12} sm={12} md={2} lg={2} style={{ minWidth: '300px' }}>
            <div className="div-a-modal">
              <a
                onClick={handleOpenIntermediaryModal}
                style={{ cursor: intermediaryId ? 'pointer' : 'not-allowed' }}
              >
                Visualizar Intermediador
              </a>
            </div>
            <Field
              name="intermediaryId"
              component={RenderField}
              label="Intermediador"
              as="select"
              disabled={
                !(
                  presenceIndicator === '2' ||
                  presenceIndicator === '3' ||
                  presenceIndicator === '4' ||
                  presenceIndicator === '9'
                ) || disableInputs
              }
            >
              <option value="">Selecione</option>
              {intermediaries.map((intermediary) => (
                <option key={intermediary.id} value={intermediary.id}>
                  {intermediary.description}
                  {' - '}
                  {cnpjMask(intermediary.cpfCnpj)}
                </option>
              ))}
            </Field>
          </Col>
        )}

        {/* <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="toggle-column"
          style={{ width: 'fit-content' }}
        >
          <div>
            <label>Calcula IPI no ICMS</label>
          </div>
          <Toggle
            checked={calculateIPIOnICMS}
            onChange={() =>
              dispatch(change('NFe', 'calculateIPIOnICMS', !calculateIPIOnICMS))
            }
          />
        </Col> */}

        {/* <Col
          xs={12}
          sm={12}
          md={2}
          lg={2}
          className="toggle-column"
          style={{ width: 'fit-content' }}
        >
          <div>
            <label>Calcula IPI no ICMS ST</label>
          </div>
          <Toggle
            checked={calculateIPIOnICMSST}
            onChange={() =>
              dispatch(
                change('NFe', 'calculateIPIOnICMSST', !calculateIPIOnICMSST)
              )
            }
          />
        </Col> */}
      </div>
      <div>
        <Col xs={12} sm={12} md={12} lg={12} style={{ padding: '2px 2px' }}>
          <Field
            name="additionalInformation"
            component={RenderField}
            label="Informações Complementares"
            as="textarea"
            maxLength="4500"
            disabled={
              isInvoiceDenied ||
              isInvoiceCanceled ||
              isInvoiceIssuedInContingency ||
              isInvoiceIssued
            }
          />
        </Col>
      </div>

      <Modal
        show={textModal}
        animation={true}
        dialogClassName="modal-textForInvoices"
      >
        <Modal.Header closeButton onHide={handleCloseTextModal}>
          <Modal.Title>
            <strong>Textos para Notas Fiscais</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderTextTable()}</Modal.Body>
        <Modal.Footer>
          <div style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              className="btn-danger btn btn-fill"
              fill
              style={{ marginTop: '6px' }}
              onClick={handleCloseTextModal}
            >
              Cancelar
            </Button>
            <Button
              className="btn-fill btn btn-info"
              fill
              onClick={handleIncludeText}
            >
              Incluir Texto
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <AlertModal
        show={isCancelModalOpen}
        onHide={() => setIsCancelModalOpen(false)}
        onCancel={() => setIsCancelModalOpen(false)}
        message="Deseja fechar o modal e não adicionar os textos selecionados?"
        onSubmit={handleCloseModal}
      />

      <Modal
        dialogClassName="modal-60w"
        show={openModal}
        onHide={() => setOpenModal(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>{modalTitle}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ marginLeft: '5px', marginTop: 0, display: 'flex' }}
        >
          {renderForm()}
          <span id="required-field-label">* Campos Obrigatórios</span>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OtherInfos;
