import api from '../services/api';

export const create = async (saleData) => {
  const { data } = await api.post('sales', saleData);
  return data;
};

export const update = async (id, saleData) => {
  const { data } = await api.put('sales/' + id, saleData);
  return data;
};

export const verifyCRMBonusByCustomer = async (customerId) => {
  const { data } = await api.get('sales/' + customerId);
  return data;
};

const getMediaById = async (saleId) => {
  try {
    const response = await api.get(`sales/media/${saleId}`);

    return response.data;
  } catch (err) {
    throw err;
  }
};

const validationIsNFCe = async (nfeId) => {
  const res = await api.get(`sales/have-nfce/${nfeId}`);
  return res.data;
};

const validateGroupedTitles = async (saleId) => {
  const res = await api.get(`sales/grouped-titles/${saleId}`);
  return res.data;
};

const SaleRepository = {
  getMediaById,
  create,
  update,
  verifyCRMBonusByCustomer,
  validationIsNFCe,
  validateGroupedTitles,
};

export default SaleRepository;
