import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, change, initialize } from 'redux-form';
import { withRouter } from 'react-router';
import { Col } from 'react-bootstrap';

import CardForm from 'client/components/CardForm';
import DadosBasicosProduto from './MainData';
import DadosEstoque from './DadosEstoque';
import DadosFiscais from './DadosFiscais';
import ProductPhotos from './ProductsPhotos';
import FormSubmitButtons from 'client/components/FormSubmitButtons';
import AlertModal from '../../../../../components/AlertModal/AlertModal';
import constants from '../../../../../utils/constants';
import { useAuth } from '../../../../../contexts/auth';

import productsRepository from '../../../../../repositories/Products';
import { usePlanSignatureContext } from 'contexts/plan-signature';
import AtacVarDadosEstoque from './AtacVarDadosEstoque';

let FormProduct = ({
  ProductId,
  handleSubmit,
  loading,
  history,
  onCancel,
  productInfo,
}) => {
  const [status, setStatus] = useState(true);
  const [dadosBasicos, setDadosBasicos] = useState(true);
  const [dadosEstoque, setDadosEstoque] = useState(false);
  const [dadosFiscais, setDadosFiscais] = useState(false);
  const [isPhotosCardVisible, setIsPhotosCardVisible] = useState(false);
  const [modal, setModal] = useState(false);

  const [loaded, setLoaded] = useState(true);

  const dispatch = useDispatch();
  const { isPlanFree, isPlanStart } = usePlanSignatureContext();
  const { company } = useAuth();

  useEffect(() => {
    if (!!ProductId) loadProduct();
    if (!!productInfo) loadProductInformation();
  }, [ProductId]);

  const loadProduct = async () => {
    const response = await productsRepository.getById(ProductId);

    setLoaded(false);

    const data = {
      Code: response.Code,
      Description: response.Description,
      surname: response.surname,
      Stock_Quantity: response.Stock_Quantity,
      Sale_Price: response.Sale_Price,
      Family: response.Family_id,
      familyDescription: response.Families
        ? response.Families.Description || ''
        : '',
      Brand: response.Brand_id,
      brandDescription: response.Brands
        ? response.Brands.Description || ''
        : '',
      Warranty: response.Warranty_id,
      Review: response.Review_id,
      TypeUnityItem: response.Unit_Type,
      Purchase_Price: response.Purchase_Price,
      Profit_Margin: response.Profit_Margin || 0,
      profitMarginWholesale: response.profitMarginWholesale,
      Sale_Price: response.Sale_Price,
      salePriceWholesale: response.salePriceWholesale,
      Multiplier_Base: response.Multiplier_Base,
      Manufacturer_Ref: response.Manufacturer_Ref,
      Location: response.Location,
      Bar_Code: response.Bar_Code,
      Additional_Info: response.Additional_Info,
      Status: response.IsActive,
      Tribute_BarCode: response.Tribute_BarCode,
      Origin: response.Origin,
      CSOSN: response.CSOSN,
      CST: response.CST,
      CFOP_InState_Code: String(response.CFOP_InState_Code),
      CFOP_OutState_Code: String(response.CFOP_OutState_Code),
      NCM_Code: response.NCM_Code,
      CEST_Code: response.CEST_Code,
      PIS_Exit: response.PIS_Exit,
      COFINS_Exit: response.COFINS_Exit,
      PIS_Entry: response.PIS_Entry,
      calculateStOnSale: response.calculateStOnSale,
      COFINS_Entry: response.COFINS_Entry,
      IPI: response.IPI,
      ANP: response.ANP,
      ANP_Description: response.ANP_Description,
      product_type: response.product_type,
      hasDiscountLimit: response.hasDiscountLimit,
      discountLimit: response.discountLimit,
      singlePhase: response.singlePhase ? true : false,
      production: response.production,
      ANP_UF_Code: response.ANP_UF_Code,
      weight: response.Weight,
      minStock: response.minStock === 0 ? null : response.minStock,
      maxStock: response.maxStock === 0 ? null : response.maxStock,
      Commission_Rate: response.Commission_Rate,
      updateDescriptionOnSale: response.updateDescriptionOnSale,
      photos: response.ProductsPhotos.map((photo) => ({
        id: photo.id,
        name: `${company.Cpf_Cnpj}/products/${photo.name}`,
        preview: photo.link,
        uploaded: true,
      })),
      OriginalFactoryCode: response.OriginalFactoryCode,
      AlternativeCode: response.AlternativeCode,
      cstIpiEntryId: response.cstIpiEntryId,
      // cstIpiExitId: response.cstIpiExitId,
      // cstIpiEntry: response.cstIpiEntryId,
      // cstIpiExit: response.cstIpiExitId,
      // ipiFramework: response.ipiFramework,
    };

    setLoaded(true);
    dispatch(initialize('formProduto', data));

    setStatus(response.IsActive === 1);
  };

  const loadProductInformation = async () => {
    const product = await productsRepository.getById(productInfo.id);
    setLoaded(false);

    const data = {
      Description: product.Description,
      surname: product.surname,
      Stock_Quantity: product.Stock_Quantity,
      Sale_Price: product.Sale_Price,
      Family: product.Family_id,
      familyDescription: product.Families.Description || '',
      Brand: product.Brand_id,
      brandDescription: product.Brands.Description || '',
      Warranty: product.Warranty_id,
      Review: product.Review_id,
      TypeUnityItem: product.Unit_Type,
      Purchase_Price: product.Purchase_Price,
      Profit_Margin: product.Profit_Margin || 100,
      Sale_Price: product.Sale_Price,
      Multiplier_Base: product.Multiplier_Base,
      Location: product.Location,
      Additional_Info: product.Additional_Info,
      Status: product.IsActive,
      Origin: product.Origin,
      CSOSN: product.CSOSN,
      CST: product.CST,
      CFOP_InState_Code: String(product.CFOP_InState_Code),
      CFOP_OutState_Code: String(product.CFOP_OutState_Code),
      NCM_Code: product.NCM_Code,
      CEST_Code: product.CEST_Code,
      PIS_Exit: product.PIS_Exit,
      COFINS_Exit: product.COFINS_Exit,
      PIS_Entry: product.PIS_Entry,
      calculateStOnSale: product.calculateStOnSale,
      COFINS_Entry: product.COFINS_Entry,
      IPI: product.IPI,
      ANP: product.ANP,
      ANP_Description: product.ANP_Description,
      ANP_UF_Code: product.ANP_UF_Code,
      minStock: product.minStock === 0 ? null : product.minStock,
      maxStock: product.maxStock === 0 ? null : product.maxStock,
      Commission_Rate: product.Commission_Rate,
      updateDescriptionOnSale: product.updateDescriptionOnSale,
      hasDiscountLimit: product.hasDiscountLimit,
      discountLimit: product.discountLimit,
      singlePhase: product.singlePhase,
      production: product.production,
      OriginalFactoryCode: product.OriginalFactoryCode,
      AlternativeCode: product.AlternativeCode,
      // cstIpiEntryId: product.cstIpiEntryId,
      // cstIpiExitId: product.cstIpiExitId,
      // cstIpiEntry: product.cstIpiEntryId,
      // cstIpiExit: product.cstIpiExitId,
      // ipiFramework: product.ipiFramework,
    };

    dispatch(initialize('formProduto', data));
    setLoaded(true);

    setStatus(product.IsActive === 1);
  };

  const handleToggle = async () => {
    setStatus(!status);
    dispatch(change('formProduto', 'Status', !status ? 1 : 2));
  };

  function cancelModal() {
    setModal(false);
    if (onCancel) return onCancel();
    history.push(constants.ROUTES.PRODUCTS);
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}
    >
      <CardForm
        show={dadosBasicos}
        title="Dados do Produto"
        onClick={() => setDadosBasicos(!dadosBasicos)}
      >
        <DadosBasicosProduto
          status={status}
          handleToggle={handleToggle}
          dispatch={dispatch}
          hasId={!!ProductId}
        />

        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <button
            className="btn-green"
            type="button"
            onClick={() => {
              setDadosBasicos(false);
              setDadosEstoque(true);
              setDadosFiscais(false);
            }}
            style={{ position: 'absolute', right: 0, top: -40 }}
          >
            Prosseguir
          </button>
        </Col>
      </CardForm>

      <CardForm
        show={dadosEstoque}
        title="Dados do Estoque"
        onClick={() => {
          setDadosEstoque(!dadosEstoque);
        }}
      >
        {company.companyConfig.sellingPriceType === 'Preço Atacado e Varejo' ? (
          <AtacVarDadosEstoque
            status={status}
            dispatch={dispatch}
            loaded={loaded}
            setLoaded={setLoaded}
          />
        ) : (
          <DadosEstoque
            status={status}
            dispatch={dispatch}
            loaded={loaded}
            setLoaded={setLoaded}
          />
        )}

        <Col lg={12} md={12} sm={12} xs={12}>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px',
            }}
          >
            <button
              className="btn-green"
              type="button"
              onClick={() => {
                setDadosEstoque(false);
                setDadosBasicos(false);
                setDadosFiscais(true);
              }}
              style={{ marginRight: '-30px' }}
            >
              Prosseguir
            </button>
          </Col>
        </Col>
      </CardForm>

      <CardForm
        show={dadosFiscais}
        title="Dados Fiscais (Opcional)"
        onClick={() => setDadosFiscais(!dadosFiscais)}
      >
        <DadosFiscais />

        {!isPlanFree && (
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <button
              className="btn-green"
              type="button"
              onClick={() => {
                setDadosEstoque(false);
                setDadosBasicos(false);
                setDadosFiscais(false);
                setIsPhotosCardVisible(true);
              }}
              style={{ position: 'absolute', right: 0, top: -40 }}
            >
              Prosseguir
            </button>
          </Col>
        )}
      </CardForm>

      {!isPlanFree && !isPlanStart && (
        <CardForm
          show={isPhotosCardVisible}
          title="Fotos do Produto (Opcional)"
          onClick={() => setIsPhotosCardVisible(!isPhotosCardVisible)}
        >
          <ProductPhotos />
        </CardForm>
      )}

      <FormSubmitButtons
        loading={loading}
        hasId={!!ProductId}
        onCancel={() => setModal(true)}
        onSubmit={handleSubmit}
      />

      {modal && (
        <AlertModal
          show={modal}
          onHide={() => setModal(false)}
          onCancel={() => setModal(false)}
          message="Essa ação não salvará nenhuma das informações apresentadas nessa tela. Deseja realmente cancelar?"
          onSubmit={cancelModal}
        />
      )}
    </form>
  );
};

// FormProduct = reduxForm({ form: 'formProduto', enableReinitialize: true })(FormProduct)
// const selector = formValueSelector('formProduto')
// const mapStateToProps = state => ({
//   formProduto: selector(state, 'formProduto')
// })

// export default connect(mapStateToProps)(withRouter(FormProduct))

export default reduxForm({
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  form: 'formProduto',
  initialValues: {
    Profit_Margin: 0,
  },
})(withRouter(FormProduct));
