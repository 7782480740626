import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Button from '../../components/CustomButton/Button.jsx';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import brandsRepository from '../../../repositories/Brands';
import SearchInput from '../../../components/SearchInput';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import { useAuth } from '../../../contexts/auth';
import useDebounce from 'hooks/useDebounce.js';

const HomeBrands = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);
  const [brands, setBrands] = useState([]);

  const [type, setType] = useState(['']);

  const { companyId } = useAuth();
  const { queryFilter } = useFilters();
  const { query, setQuery } = queryFilter;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(1);
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  useEffect(() => {
    if (!!companyId) {
      loadBrands();
    }
  }, [page, limit, queryDebounced, type]);

  async function loadBrands() {
    setLoading(true);

    try {
      let serializedType = type.join(',');
      if (type[0] === '') {
        serializedType = '';
      }
      const brands = await brandsRepository.getAllByCompany(companyId, {
        page: page + 1,
        limit,
        Type: serializedType,
        query: queryDebounced,
      });
      setPages(Math.ceil(brands.count / limit));
      setBrands(brands.rows);
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao carregar as marcas. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.BRAND)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Nova Marca
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Descrição"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Tipo:</strong>
              </span>
              <select
                onChange={(e) => {
                  setType(e.target.value.split(','));
                }}
                className="form-control foco-input"
                name="type"
                value={type}
                style={{ fontSize: '12px' }}
              >
                <option value="">Ambos</option>
                <option value="product">Produto</option>
                <option value="manufacturer,bicycleManufacturer">
                  Montadora
                </option>
              </select>
            </div>
          </div>
          <div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={brands}
              columns={[
                {
                  Header: 'Código',
                  id: 'Code',
                  accessor: (props) => Number(props.Code),
                  headerClassName: 'text-left',
                  width: 100,
                },
                {
                  Header: 'Tipo',
                  accessor: 'Type',
                  headerClassName: 'text-left',
                  width: 100,
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor:
                          props.value === 'product' ? '#5cb85c' : '#428bca',
                      }}
                    >
                      {props.value === 'product' ? 'Produto' : 'Montadora'}
                    </Button>
                  ),
                },
                {
                  Header: 'Descrição',
                  accessor: 'Description',
                  headerClassName: 'text-left',
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  headerClassName: 'text-left',
                  width: 100,
                  filterable: false,
                  Cell: (props) =>
                    !props.original.Company_id ? (
                      <FontAwesomeIcon
                        title="Marcas padrões do sistema não podem ser alteradas"
                        cursor="not-allowed"
                        style={{
                          height: '1.5em',
                          width: '1.5em',
                          color: 'black',
                        }}
                        icon={faEdit}
                      />
                    ) : (
                      <Link to={{ pathname: `brand#${props.original.id}` }}>
                        <FontAwesomeIcon
                          title="Editar"
                          cursor="pointer"
                          style={{
                            height: '1.5em',
                            width: '1.5em',
                            color: 'black',
                          }}
                          icon={faEdit}
                        />
                      </Link>
                    ),
                },
              ]}
              page={page}
              pages={pages}
              onPageChange={(page) => setPage(page)}
              pageSize={limit}
              onPageSizeChange={(size) => setLimit(size)}
              manual
              defaultPageSize={50}
              loading={loading}
              showPagination
              sortable
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              defaultSorted={[
                {
                  id: 'Description',
                  desc: false,
                },
              ]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeBrands);
