import config from '../config';
import api from '../services/api';

const getPurchases = async (params) => {
  try {
    const { data } = await api.get(config.endpoint + `purchases`, {
      params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const getPurchasesStatus = async () => {
  try {
    const { data } = await api.get(config.endpoint + 'purchase-status');
    return data;
  } catch (err) {
    throw err;
  }
};

const getById = async (id) => {
  try {
    const response = await api.get(config.endpoint + `purchases/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (id, purchase) => {
  try {
    const response = await api.put(
      config.endpoint + `purchases/${id}`,
      purchase
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const create = async (purchase) => {
  try {
    const response = await api.post(config.endpoint + 'purchases', purchase);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const cancel = async (id) => {
  try {
    const response = await api.post(
      config.endpoint + `purchases/cancel?purchaseId=${id}`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllPurchasesByCompany = async (companyId, params) => {
  try {
    const response = await api.get(
      config.endpoint + `purchases/company/${companyId}`,
      { params }
    );

    return response.data;
  } catch (err) {
    throw err;
  }
};

const generatePurchasesSupplier = async ({
  companyId,
  initialDate,
  finalDate,
  providerId,
}) => {
  try {
    const response = await api.get(
      config.endpoint + `report/purchases-supplier-report/${companyId}`,
      {
        params: {
          initialDate,
          finalDate,
          providerId,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getPurchases,
  getPurchasesStatus,
  getAllPurchasesByCompany,
  generatePurchasesSupplier,
  getById,
  update,
  create,
  cancel,
};
