import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleChecklist } from './MobileChecklist.styles';
import { format } from 'date-fns';
export function SaleChecklist({ sale }) {
  if (!sale) return <View />;
  const { code, date, seller } = sale;

  /* console.log(sale); */
  return (
    <>
      <View style={styleChecklist.flexRow}>
        <View style={[styleChecklist.flexRow, styleChecklist.spaceRow]}>
          <Text style={styleChecklist.bold}>OS: </Text>
          <Text>{code}</Text>
        </View>
        <View style={[styleChecklist.flexRow, styleChecklist.spaceRow]}>
          <Text style={styleChecklist.bold}>Data de Realização: </Text>
          <Text>{format(new Date(date), 'dd/MM/yyyy')}</Text>
        </View>
        <View style={[styleChecklist.flexRow, styleChecklist.spaceRow]}>
          <Text style={styleChecklist.bold}>Vendedor: </Text>
          <Text>{seller}</Text>
        </View>
      </View>
      <View style={styleChecklist.line} />
    </>
  );
}
