import React, { useState, useEffect, memo } from 'react';
import { Col, Modal } from 'react-bootstrap';
import ReactTable from 'react-table';
import { Field, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import { toastr } from 'react-redux-toastr';
import './style.css';

import api from '../../../../../services/api';
import config from '../../../../../config';
import { useAuth } from '../../../../../contexts/auth';

import NCMNBSRepository from '../../../../../repositories/NCMNBS';

import Input from '../../../Clients/NewClient/FormClient/components/Input';
import Select from '../../../Clients/NewClient/FormClient/components/Select';
import Button from '../../../../components/CustomButton/CustomButton';
import {
  PRODUCT_ORIGIN,
  PRODUCT_PIS_COFINS_ENTRY,
  PRODUCT_PIS_COFINS_EXIT,
  PRODUCT_CSOSN,
  PRODUCT_CST,
} from '../../../../../utils/constants';

import { onlyNumbers } from '../../../../components/ToNormalize/ToNormalize';
import { CstIpiRepository } from 'v2/repositories/CstIpiRepository';
import useDebounce from 'hooks/useDebounce';

const RenderAnpList = ({ data }) => (
  <datalist id="ANP_List">
    {data.map((item) => (
      <option key={item.id} value={item.code}>
        {item.description}
      </option>
    ))}
  </datalist>
);
export const ANP_LIST = memo(RenderAnpList);
export const NCMList = memo(RenderAnpList);

const RenderCstIpiExitList = ({ data }) => (
  <datalist id="CST_IPI_Exit_List">
    {data.map((item) => (
      <option key={item.id} value={item.code}>
        {item.description}
      </option>
    ))}
  </datalist>
);
export const CST_IPI_EXIT = memo(RenderCstIpiExitList);

const RenderCstIpiEntryList = ({ data }) => (
  <datalist id="CST_IPI_Entry_List">
    {data.map((item) => (
      <option key={item.id} value={item.code}>
        {item.description}
      </option>
    ))}
  </datalist>
);
export const CST_IPI_ENTRY = memo(RenderCstIpiEntryList);

const ipiMask = createNumberMask({
  decimalPlaces: 2,
  suffix: '%',
});

const cfopMask = createTextMask({
  pattern: '9.999',
  guide: false,
});

export default function DadosFiscais() {
  const { companyId } = useAuth();
  const [ncm, setNcm] = useState([]);
  const [ncmModal, setNcmModal] = useState(false);
  const [loadingNcmReactTable, setLoadingNcmReactTable] = useState(true);
  const [taxRegime, setTaxRegime] = useState('');
  const [anp, setAnp] = useState([]);
  const [states, setStates] = useState([]);
  const [companyUf, setCompanyUf] = useState('');
  const [query, setQuery] = useState('');
  const [cfop, setCfop] = useState([]);
  const [cstIpiExit, setCstIpiSaida] = useState([]);
  const [cstIpiEntry, setCstIpiEntrada] = useState([]);

  const [ncmQueryDebounced, setNcmQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setNcmQueryDebounced, 500);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const {
    formProduto: {
      values: { NCM_Item, ANP, NCM_Code },
    },
  } = useSelector((state) => state.form);
  const dispatch = useDispatch();

  useEffect(() => {
    getCompany();
    getAnp();
    getStates();
    getCFOPs();
    getCstIpiSaida();
    getCstIpiEntrada();
  }, []);

  useEffect(() => {
    getNCM();
  }, [currentPage, pageLimit, ncmQueryDebounced]);

  const handleChangeNcmQuery = (e) => {
    setQuery(e.target.value);
    debouncedSaveQuery(e.target.value);
  };

  async function getNCM() {
    setLoadingNcmReactTable(true);

    try {
      const response = await NCMNBSRepository.getAll({
        companyId,
        limit: pageLimit,
        page: currentPage + 1,
        query: ncmQueryDebounced,
      });

      setNcm(response.ncmNbs);
      setTotalPages(Math.ceil(response.count / pageLimit));
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar os NCM e NBS. Por favor, tente novamente'
      );
    } finally {
      setLoadingNcmReactTable(false);
    }
  }

  async function getCompany() {
    try {
      await api
        .get(config.endpoint + `company/dadosCompany/${companyId}`)
        .then((resp) => {
          setCompanyUf(resp.data.data.Address[0].State);
          setTaxRegime(resp.data.data.TaxRegime);
        });
    } catch (err) {
      console.log(err.response);
    }
  }

  async function getAnp() {
    await api.get(config.endpoint + 'anp').then((resp) => setAnp(resp.data));
  }
  async function getCstIpiSaida() {
    try {
      const response = await CstIpiRepository.getExit();
      setCstIpiSaida(response);
    } catch (error) {
      console.error(
        'Erro ao buscar CST IPI Saída:',
        error.response ? error.response.data : error.message
      );
    }
  }
  async function getCstIpiEntrada() {
    try {
      const response = await CstIpiRepository.getEntry();
      setCstIpiEntrada(response);
    } catch (error) {
      console.error(
        'Erro ao buscar CST IPI Entrada:',
        error.response ? error.response.data : error.message
      );
    }
  }

  async function getStates() {
    await api
      .get(config.endpoint + 'address/states')
      .then((resp) => setStates(resp.data));
  }

  async function getCFOPs() {
    try {
      const response = await api.get(
        config.endpoint + `natureoftransactions/by-company/${companyId}`
      );
      const CFOPsOutState = response.data.filter(
        (CFOP) => CFOP.type === 'Saída'
      );
      setCfop(CFOPsOutState);
    } catch (err) {
      console.log(err.response);
      setCfop([]);
    }
  }

  // function handleNCM(code) {
  //   dispatch(change('formProduto', 'CEST_Code', ''));
  //   ncm.forEach(item => {
  //     if (item.code === code) {
  //       return dispatch(change('formProduto', 'CEST_Code', item.CEST));
  //     };
  //   });
  // };

  function handleCheckNcmReactTable(item) {
    setLoadingNcmReactTable(true);
    setNcm(
      ncm.map((n) => {
        if (n.id === item.id) return { ...n, checked: !item.checked };
        return { ...n, checked: false };
      })
    );
    setLoadingNcmReactTable(false);
  }

  function handleOpenNcmModal() {
    const ncmRegistred = NCM_Item ? JSON.parse(NCM_Item) : null;
    if (ncmRegistred) {
      setNcm(
        ncm.map((n) => {
          if (n.id === ncmRegistred.id) return { ...n, checked: true };
          return n;
        })
      );
    }
    setNcmModal(true);
  }

  function handleCloseNcmModal() {
    setNcmModal(false);
    setQuery('');
    setNcm(ncm.map((n) => ({ ...n, checked: false })));
  }

  // function handleClearNcmSelecteds() {
  //   setNcm(ncm.map(n => ({ ...n, checked: false })));
  //   dispatch([
  //     change('formProduto', 'NCM_Code', ''),
  //     change('formProduto', 'CEST_Code', ''),
  //     change('formProduto', 'NCM_Item', '')
  //   ]);
  // };

  function handleIncludeNcm() {
    const filterNcm = ncm.filter((n) => n.checked);
    if (!filterNcm.length) return toastr.warning('Nenhum NCM selecionado');

    dispatch([
      change('formProduto', 'NCM_Code', filterNcm[0].code),
      change('formProduto', 'CEST_Code', filterNcm[0].CEST),
      change('formProduto', 'NCM_Item', JSON.stringify(filterNcm[0])),
    ]);

    handleCloseNcmModal();
  }

  function handleSearchAnp(target) {
    codeOnly(target);

    dispatch(change('formProduto', 'ANP_Description', ''));
    anp.forEach((item) => {
      if (String(item.code) === target.value) {
        dispatch(change('formProduto', 'ANP_UF_Code', companyUf));
        return dispatch(
          change('formProduto', 'ANP_Description', item.description)
        );
      }
    });
  }

  // function handleSearchCstIpiEntry(target) {
  //   const selectedValue = target.value;
  //   const selectedItem = cstIpiEntry.find((item) => item.id === selectedValue);
  //   if (selectedItem) {
  //     dispatch(change('formProduto', 'cstIpiEntryId', Number(selectedItem.id)));
  //   }
  // }
  // function handleSearchCstIpiExit(target) {
  //   const selectedValue = target.value;

  //   const selectedItem = cstIpiExit.find((item) => item.id === selectedValue);
  //   if (selectedItem) {
  //     dispatch(change('formProduto', 'cstIpiExitId', Number(selectedItem.id)));
  //   }
  // }

  function codeOnly(target) {
    let option = target[target.selectedIndex];
    let displayValue = target[0];

    displayValue.innerHTML = option.value;
    displayValue.value = option.value;
  }

  function renderNcmTable() {
    return (
      <>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginLeft: '0px', paddingLeft: 0, marginBottom: 10 }}
        >
          <i
            id="iconepesquisar"
            style={{ cursor: 'pointer' }}
            className="fa fa-search"
          />
          <input
            style={{ paddingLeft: 35, maxWidth: 600 }}
            type="text"
            className="form-control"
            placeholder="Pesquisa por Código e Descrição"
            value={query}
            onChange={handleChangeNcmQuery}
          />
        </Col>
        <Col>
          <ReactTable
            data={ncm}
            columns={[
              {
                accessor: 'checked',
                width: 50,
                className: 'text-center',
                Cell: (props) => (
                  <input
                    type="checkbox"
                    checked={props.value}
                    onChange={() => handleCheckNcmReactTable(props.original)}
                    id="checkbox-ncm_table"
                  />
                ),
              },
              {
                Header: 'Código',
                accessor: 'code',
                headerClassName: 'text-center',
                className: 'text-center',
                width: 120,
              },
              {
                Header: 'EX',
                accessor: 'ex',
                headerClassName: 'text-center',
                className: 'text-center',
                width: 80,
                Cell: (props) => <>{props.value ? props.value : '---'}</>,
              },
              {
                Header: 'Descrição',
                accessor: 'description',
              },
              {
                Header: 'CEST',
                accessor: 'CEST',
                headerClassName: 'text-center',
                className: 'text-center',
                width: 120,
              },
            ]}
            defaultPageSize={10}
            pageSize={pageLimit}
            onPageSizeChange={(value) => {
              setPageLimit(value);
            }}
            page={currentPage}
            onPageChange={(value) => setCurrentPage(value)}
            pages={totalPages}
            manual
            loading={loadingNcmReactTable}
            showPagination={true}
            sortable={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            defaultSorted={[
              {
                id: 'description',
                desc: true,
              },
            ]}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Nenhuma NCM encontrado"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
        </Col>
      </>
    );
  }

  return (
    <>
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          alignItems: 'center',
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
        }}
      >
        <Col xs={12} sm={3} md={3} lg={3}>
          <label>Origem da Mercadoria</label>
          <Field name="Origin" component={Select}>
            <option value="">Selecione</option>
            {PRODUCT_ORIGIN.map((item, index) => (
              <option key={index} value={index}>
                {index} - {item}
              </option>
            ))}
          </Field>
        </Col>
        {taxRegime?.includes('Simples Nacional') ||
        taxRegime?.includes('MEI - Simples Nacional') ? (
          <Col xs={12} sm={1} md={1} lg={1}>
            <label>CSOSN</label>
            <Field
              name="CSOSN"
              type="text"
              component={Select}
              list="CSOSN_List"
              maxLength="3"
              normalize={onlyNumbers}
            >
              <option id="CSOSN_List"></option>
              {PRODUCT_CSOSN.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Field>
          </Col>
        ) : (
          <Col xs={12} sm={1} md={1} lg={1}>
            <label>CST</label>
            <Field
              name="CST"
              type="text"
              list="CST_List"
              maxLength="2"
              component={Select}
              normalize={onlyNumbers}
            >
              <option id="CST_List"></option>
              {PRODUCT_CST.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Field>
          </Col>
        )}
        <Col xs={12} sm={3} md={3} lg={3}>
          <label>CFOP (Dentro do Estado)</label>
          <Field
            name="CFOP_InState_Code"
            type="text"
            component={Select}
            normalize={onlyNumbers}
            {...cfopMask}
            list="CFOP_InState_List"
            onChange={({ target }) => codeOnly(target)}
          >
            <option id="CFOP_InState_List" disabled selected>
              Código CFOP
            </option>
            <option id="CFOP_InState_List">Selecione</option>
            {cfop
              .filter((c) => c.CFOP[0] === '5')
              .map((c) => (
                <option key={c.id} value={c.CFOP}>
                  {c.CFOP} - {c.description}
                </option>
              ))}
          </Field>
        </Col>
        <Col xs={12} sm={3} md={3} lg={3}>
          <label>CFOP (Fora do Estado)</label>
          <Field
            name="CFOP_OutState_Code"
            type="text"
            component={Select}
            normalize={onlyNumbers}
            {...cfopMask}
            list="CFOP_OutState_List"
            onChange={({ target }) => codeOnly(target)}
          >
            <option id="CFOP_InState_List" disabled selected>
              Código CFOP
            </option>
            <option id="CFOP_InState_List">Selecione</option>
            {cfop
              .filter((c) => c.CFOP[0] === '6')
              .map((c) => (
                <option key={c.id} value={c.CFOP}>
                  {c.CFOP} - {c.description}
                </option>
              ))}
          </Field>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <div>
            <div className="div-label-modal">
              <label>NCM</label>
            </div>
            <div className="div-a-modal">
              <a onClick={handleOpenNcmModal}>Alterar NCM</a>
            </div>
          </div>
          <Field
            name="NCM_Code"
            type="text"
            component={Input}
            title={`Clique aqui para ${NCM_Code ? 'alterar' : 'incluir'} o NCM`}
            placeholder={`Clique aqui para ${
              NCM_Code ? 'alterar' : 'incluir'
            } o NCM`}
            style={{ cursor: 'pointer' }}
            disabled={NCM_Code}
            onClick={handleOpenNcmModal}
            onChange={() => dispatch(change('formProduto', 'CEST_Code', ''))}
          />
        </Col>
      </Col>

      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{
          alignItems: 'center',
          width: '100%',
          paddingLeft: '0',
          paddingRight: '0',
        }}
      >
        <Col xs={12} sm={2} md={2} lg={2}>
          <Field
            label="CEST"
            name="CEST_Code"
            type="text"
            component={Input}
            disabled
          />
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <label
            style={{
              width: '100px',
            }}
          >
            ST PIS(Saída)
          </label>
          <Field name="PIS_Exit" component={Select}>
            <option value=""></option>
            {PRODUCT_PIS_COFINS_EXIT.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <label>ST COFINS(Saída)</label>
          <Field name="COFINS_Exit" component={Select}>
            <option value=""></option>
            {PRODUCT_PIS_COFINS_EXIT.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Field>
        </Col>
        <Col
          xs={12}
          sm={2}
          md={2}
          lg={2}
          // style={{
          //   width: '160px',
          // }}
        >
          <label>ST PIS(Entrada)</label>
          <Field name="PIS_Entry" component={Select}>
            <option value=""></option>
            {PRODUCT_PIS_COFINS_ENTRY.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <label>ST COFINS(Entrada)</label>
          <Field name="COFINS_Entry" component={Select}>
            <option value=""></option>
            {PRODUCT_PIS_COFINS_ENTRY.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Field>
        </Col>
        <Col
          xs={12}
          sm={2}
          md={2}
          lg={2}
          style={{ width: '100%', paddingLeft: '0', paddingRight: '0' }}
        >
          <Col xs={12} sm={2} md={2} lg={2}>
            <label>Código ANP</label>
            <Field
              name="ANP"
              component={Select}
              list="ANP_List"
              onChange={({ target }) => handleSearchAnp(target)}
            >
              <option id="ANP_List" disabled selected>
                Código ANP
              </option>
              <option id="ANP_List">Selecione</option>
              {anp.map((item) => (
                <option key={item.id} value={item.code}>
                  {item.code} - {item.description}
                </option>
              ))}
            </Field>
          </Col>

          <Col xs={12} sm={4} md={4} lg={4}>
            <Field
              label="Descrição ANP"
              name="ANP_Description"
              component={Input}
              disabled
            />
          </Col>

          <Col xs={12} sm={1} md={1} lg={1} style={{ width: '100px' }}>
            <label>UF</label>
            <Field name="ANP_UF_Code" component={Select}>
              <option value=""></option>
              {states.map((item) => (
                <option key={item.id} value={item.Initials}>
                  {item.Initials}
                </option>
              ))}
            </Field>
          </Col>

          <Col xs={12} sm={2} md={2} lg={2}>
            <label>ST na Compra: </label>
            <Field name="calculateStOnSale" component={Select}>
              <option value={true}>Calcula</option>
              <option value={false}>Não Calcula</option>
            </Field>
          </Col>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} style={{ width: '110px' }}>
          <Field
            label="% IPI"
            name="IPI"
            component={Input}
            {...ipiMask}
            maxLength="7"
          />
        </Col>
        {/* <Col xs={12} sm={2} md={2} lg={2}>
          <label>CST IPI (Saida):</label>
          <Field
            name="cstIpiExitId"
            component={Select}
            list="CST_IPI_Exit_List"
            onChange={({ target }) => handleSearchCstIpiExit(target)}
          >
            <option id="CST_IPI_Exit_List">Selecione</option>
            {cstIpiExit.map((item) => (
              <option key={item.id} value={item.id}>
                {item.code}
                {item.description}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <label>CST IPI (Entrada):</label>
          <Field
            name="cstIpiEntryId"
            component={Select}
            list="CST_IPI_Entry_List"
            onChange={({ target }) => handleSearchCstIpiEntry(target)}
          >
            <option id="CST_IPI_Entry_List">Selecione</option>
            {cstIpiEntry.map((item) => (
              <option key={item.id} value={item.id}>
                {item.code} {item.description}
              </option>
            ))}
          </Field>
        </Col>
        <Col xs={12} sm={2} md={2} lg={2}>
          <Field
            label="Enquadramento:"
            name="ipiFramework"
            component={Input}
            normalize={onlyNumbers}
            maxLength={3}
          />
        </Col> */}
      </Col>
      <Modal
        show={ncmModal}
        onHide={handleCloseNcmModal}
        animation={true}
        dialogClassName="modal-formProduto"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Selecionar NCM</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderNcmTable()}</Modal.Body>
        <Modal.Footer>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button bsStyle="danger" fill onClick={handleCloseNcmModal}>
              Fechar
            </Button>
            <Button bsStyle="info" fill onClick={handleIncludeNcm}>
              Incluir NCM
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}
