import React from 'react';
import Input from '../../../Clients/NewClient/FormClient/components/Input';
import { Col } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import {
  description,
  name,
  email,
  phoneMask,
  cnpjMask,
  onlyNumbers,
} from '../../../../components/ToNormalize/ToNormalize';

function PJ({ edit }) {
  function checkCellphone(value) {
    value = onlyNumbers(value);
    if (value.length === 3 && value.charAt([2]) !== '9') return false;
    return true;
  }

  function checkComercialPhone(value) {
    value = onlyNumbers(value);
    if (value.charAt([0]) === '0') return false;
    return true;
  }

  return (
    <>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="CNPJ"
          required
          name="Cpf_Cnpj"
          component={Input}
          normalize={cnpjMask}
          placeholder=""
          maxLength="18"
          disabled={edit}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Inscrição Estadual"
          name="IE"
          component={Input}
          maxLength="14"
        />
      </Col>
      <Col lg={4} md={7} sm={8} xs={12}>
        <Field
          label="Razão Social"
          required
          name="Name"
          component={Input}
          normalize={description}
          placeholder="Indústria Mecânica Modelo Ltda."
          maxLength="80"
        />
      </Col>
      <Col lg={3} md={5} sm={3} xs={12}>
        <Field
          label="E-mail"
          name="Email"
          type="text"
          placeholder="seucliente@gmail.com"
          component={Input}
          normalize={email}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Telefone Comercial"
          name="Number_Phone1"
          placeholder="(21)2872-2294"
          component={Input}
          normalize={(value) => {
            if (!checkComercialPhone(value)) return;
            return phoneMask(value);
          }}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Telefone Celular"
          name="Number_Phone2"
          placeholder="(21)98473-6441"
          component={Input}
          normalize={(value) => {
            if (!checkComercialPhone(value)) return;
            if (!checkCellphone(value)) return value.substring(0, 3);
            return phoneMask(value);
          }}
        />
      </Col>
      <Col lg={2} md={3} sm={4} xs={12}>
        <Field
          label="Contato"
          name="Contact"
          placeholder="João"
          component={Input}
          normalize={name}
          maxLength=""
        />
      </Col>
    </>
  );
}

export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  form: 'cadastroTransportadora',
})(PJ);
