import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import Card from 'components/Card/Card.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import { useAuth } from 'contexts/auth';
import SearchInput from 'components/SearchInput';
import useFilters from '../../../hooks/useFilters';
import { getDateOnlyFromDate } from 'utils/dateHelpers';
import { currency } from 'client/components/ToNormalize/ToNormalize';
import cashierBanksRepository from '../../../repositories/CashierBank';
import cashierBanksHistoricRepository from '../../../repositories/CashierBanksHistoric';
import FormModalFinancialMovement from '../../components/FormModals/FinancialMovement';
import constants from '../../../utils/constants';
import OpenCashierBankHistoricModal from './OpenCashierBankHistoricModal';
import CloseCashierBankHistoricModal from './CloseCashierBankHistoricModal/CloseCashierBank';
import CloseCashierBankWithConferenceHistoricModal from './CloseCashierBankHistoricModal/CloseCashierBankWithConference.js';
import { encrypt } from 'client/components/ToNormalize/ToNormalize';
import useDebounce from 'hooks/useDebounce';

const HomeCashierBankHistoric = () => {
  const [cashierBanksHistoric, setCashierBanksHistoric] = useState([]);
  const [cashiers, setCashiers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateFilterType, setDateFilterType] = useState('openDate');
  const [
    isFormFinancialMovementModalOpen,
    setIsFormFinancialMovementModalOpen,
  ] = useState(false);
  const [
    isOpenCashierBankHistoricModalOpen,
    setIsOpenCashierBankHistoricModalOpen,
  ] = useState(false);
  const [
    isCloseCashierBankHistoricModalOpen,
    setIsCloseCashierBankHistoricModalOpen,
  ] = useState(false);
  const [
    isCloseCashierBankWithConferenceHistoricModalOpen,
    setIsCloseCashierBankWithConferenceHistoricModalOpen,
  ] = useState(false);
  const [cashierBankHistoricId, setCashierBankHistoricId] = useState(null);

  const { queryFilter, typeFilter, dateFilter } = useFilters();
  const { query, setQuery } = queryFilter;
  const { type, setType } = typeFilter;
  const { initialDate, setInitialDate, finalDate, setFinalDate } = dateFilter;
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);

  const { companyId, company } = useAuth();

  useEffect(() => {
    if (companyId) {
      loadCashierBanks();
      loadCashierBanksHistoric();
    }
  }, [companyId]);

  async function loadCashierBanks() {
    try {
      const cashiers =
        await cashierBanksRepository.getAllByCompanyAndTypeCashier(companyId);
      setCashiers(cashiers);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar os caixas. Por favor, tente novamente'
      );
    }
  }

  async function loadCashierBanksHistoric() {
    setLoading(true);
    try {
      const cashierBanksHistoric =
        await cashierBanksHistoricRepository.getAllByCompany({
          companyId,
          dateType: dateFilterType,
          cashierBankId: type,
          initialDate,
          finalDate,
          page: page + 1,
          limit,
          query: queryDebounced.replace(/[^0-9.,]/g, '').replace(',', '.'),
        });

      setPages(Math.ceil(cashierBanksHistoric.count / limit));
      setCashierBanksHistoric(cashierBanksHistoric.rows);
    } catch (err) {
      toastr.warning(
        'Ocorreu um erro ao carregar o histórico dos caixas. Por favor, tente novamente'
      );
    }
    setLoading(false);
  }

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  function handleSubmitFormFinancialMovementModal(financialMovement) {
    if (
      financialMovement.financialMovementStatusId ===
      constants.FINANCIAL_MOVEMENT_STATUS_ID.CLOSED
    ) {
      loadCashierBanksHistoric();
    }
    setIsFormFinancialMovementModalOpen(false);
  }

  function handleOpenCloseCashierBankHistoricModal(cashierBankHistoricId) {
    setCashierBankHistoricId(cashierBankHistoricId);
    if (company.cashierConference) {
      setIsCloseCashierBankWithConferenceHistoricModalOpen(true);
    } else {
      setIsCloseCashierBankHistoricModalOpen(true);
    }
  }

  function handleCloseModalOpenCashierBankModal() {
    setIsOpenCashierBankHistoricModalOpen(false);
    loadCashierBanksHistoric();
  }

  function handleSubmitCloseCashierBankHistoricModal() {
    const newCashierBanksHistoric = [...cashierBanksHistoric];
    const index = newCashierBanksHistoric.findIndex(
      (cashierBankHistoric) =>
        String(cashierBankHistoric.id) === String(cashierBankHistoricId)
    );
    if (index) {
      newCashierBanksHistoric[index].isOpen = false;
      newCashierBanksHistoric[index].closedDate = new Date();
    }

    setCashierBanksHistoric(newCashierBanksHistoric);
    setIsCloseCashierBankHistoricModalOpen(false);
    setIsCloseCashierBankWithConferenceHistoricModalOpen(false);
  }

  function handleOpenCloseCashierReport(cashierBankHistoricId) {
    const cashierBankHistoricIdHash = encrypt(
      cashierBankHistoricId,
      '@OS-dig:cashierBankHistoricIdToReport'
    );

    window.open(
      window.location.origin +
        `/report/close-cashier?cashierBankHistoricId=${cashierBankHistoricIdHash}`,
      '_blank'
    );
  }

  const validations = () => {
    if ((initialDate && !finalDate) || (!initialDate && finalDate)) {
      toastr.warning('Informe a data inicial e final.');
      return false;
    }
    if (
      new Date(initialDate) > new Date() ||
      new Date(finalDate) > new Date()
    ) {
      toastr.warning('Datas devem ser menores ou iguais a data atual.');
      return false;
    }

    if (finalDate && initialDate) {
      if (finalDate < initialDate) {
        toastr.warning('A data final deve ser maior que a data inicial.');
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (!!validations() && !!companyId) {
      loadCashierBanksHistoric();
    }
  }, [
    page,
    limit,
    queryDebounced,
    dateFilterType,
    type,
    initialDate,
    finalDate,
    limit,
  ]);

  return (
    <>
      <Card
        content={
          <>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className="btn btn-sucesso button-h35"
                onClick={() => setIsOpenCashierBankHistoricModalOpen(true)}
                style={{ fontSize: '12px' }}
              >
                + Abrir Caixa
              </button>
              <div>
                <SearchInput
                  placeholder="Pesquisa por Saldo Inicial, Total Recebido, Total Pago ou Saldo Final"
                  value={query}
                  onChange={(e) => handleChangeQuery(e.target.value)}
                  background={'#FFFFFF'}
                  style={{
                    fontSize: '12px',
                    height: '35px',
                    width: '410px',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Caixa:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="">Todos</option>
                  {cashiers.map((cashier) => (
                    <option value={cashier.id} key={cashier.id}>
                      {cashier.description}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              style={{
                padding: 0,
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'flex-end',
                gap: '10px',
              }}
            >
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Tipo de Data:</strong>
                </span>
                <select
                  className="form-control foco-input"
                  type="date"
                  name="dateFilterType"
                  value={dateFilterType}
                  onChange={(e) => setDateFilterType(e.target.value)}
                  style={{ fontSize: '12px' }}
                >
                  <option value="openDate">Abertura</option>
                  <option value="closedDate">Fechamento</option>
                </select>
              </div>
              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Inicial:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataInicial"
                  max={
                    finalDate
                      ? format(
                          new Date(getDateOnlyFromDate(finalDate)),
                          'yyyy-MM-dd'
                        )
                      : format(new Date(), 'yyyy-MM-dd')
                  }
                  onChange={(e) => setInitialDate(e.target.value)}
                  value={initialDate}
                  style={{ fontSize: '12px' }}
                />
              </div>

              <div>
                <span style={{ fontSize: '12px' }}>
                  <strong>Data Final:</strong>
                </span>
                <input
                  className="form-control foco-input"
                  type="date"
                  name="dataFinal"
                  min={
                    initialDate
                      ? format(
                          new Date(getDateOnlyFromDate(initialDate)),
                          'yyyy-MM-dd'
                        )
                      : undefined
                  }
                  value={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                  style={{ fontSize: '12px' }}
                />
              </div>
              <div>
                <button
                  className="btn button-h35"
                  style={{
                    backgroundColor: '#337ab7',
                    borderColor: '#337ab7',
                    color: '#FFFFFF',
                    fontSize: '12px',
                  }}
                  onClick={() => setIsFormFinancialMovementModalOpen(true)}
                >
                  Movimentação de Caixa/Banco
                </button>
              </div>
            </div>
            <ReactTable
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                width: '100%',
                fontSize: '12px',
              }}
              data={cashierBanksHistoric}
              columns={[
                {
                  Header: 'Data de Abertura',
                  accessor: 'openDate',
                  Cell: (props) =>
                    format(new Date(props.value), 'dd/MM/yyyy HH:mm'),
                },
                {
                  Header: 'Caixa',
                  accessor: 'CashierBank.description',
                },
                {
                  Header: 'Saldo Inicial',
                  accessor: 'initialValue',
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Total Entrada (+)',
                  accessor: 'receivedValue',
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Total Saída (-)',
                  accessor: 'paidValue',
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Saldo Final',
                  accessor: 'finalValue',
                  Cell: (props) => currency(props.value),
                },
                {
                  Header: 'Data de Fechamento',
                  accessor: 'closedDate',
                  width: 150,
                  Cell: (props) =>
                    props.value &&
                    format(new Date(props.value), 'dd/MM/yyyy HH:mm'),
                },
                {
                  Header: 'Status',
                  accessor: 'isOpen',
                  Cell: (props) => (
                    <Button
                      id="botaoReactTable"
                      style={{
                        backgroundColor: props.value ? '#ffcc00' : '#5cb85c',
                      }}
                    >
                      {props.value ? 'Aberto' : 'Fechado'}
                    </Button>
                  ),
                },
                {
                  Header: 'Ações',
                  accessor: 'Actions',
                  filterable: false,
                  Cell: (props) => {
                    return (
                      <div className="table-actions">
                        {props.original.isOpen && (
                          <FontAwesomeIcon
                            cursor="pointer"
                            icon={faCashRegister}
                            onClick={() =>
                              handleOpenCloseCashierBankHistoricModal(
                                props.original.id
                              )
                            }
                          />
                        )}
                        <FontAwesomeIcon
                          icon={faChartPie}
                          cursor="pointer"
                          onClick={() =>
                            handleOpenCloseCashierReport(props.original.id)
                          }
                        />
                      </div>
                    );
                  },
                },
              ]}
              page={page}
              pages={pages}
              onPageChange={(page) => setPage(page)}
              pageSize={limit}
              onPageSizeChange={(size) => setLimit(size)}
              manual
              defaultPageSize={10}
              loading={loading}
              showPagination
              sortable
              defaultSorted={[
                {
                  id: 'openDate',
                  desc: true,
                },
              ]}
              showPaginationTop={false}
              showPaginationBottom
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Não há informação"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
            />
          </>
        }
      />
      {isFormFinancialMovementModalOpen && (
        <FormModalFinancialMovement
          onCancel={() => setIsFormFinancialMovementModalOpen(false)}
          onSubmit={handleSubmitFormFinancialMovementModal}
        />
      )}

      {isOpenCashierBankHistoricModalOpen && (
        <OpenCashierBankHistoricModal
          onCancel={() => setIsOpenCashierBankHistoricModalOpen(false)}
          onSubmit={() => handleCloseModalOpenCashierBankModal()}
        />
      )}
      {isCloseCashierBankHistoricModalOpen && (
        <CloseCashierBankHistoricModal
          onCancel={() => setIsCloseCashierBankHistoricModalOpen(false)}
          cashierBankHistoricId={cashierBankHistoricId}
          onSubmit={handleSubmitCloseCashierBankHistoricModal}
        />
      )}
      {isCloseCashierBankWithConferenceHistoricModalOpen && (
        <CloseCashierBankWithConferenceHistoricModal
          onCancel={() =>
            setIsCloseCashierBankWithConferenceHistoricModalOpen(false)
          }
          cashierBankHistoricId={cashierBankHistoricId}
          onSubmit={handleSubmitCloseCashierBankHistoricModal}
          handleSubmitFormFinancialMovementModal={
            handleSubmitFormFinancialMovementModal
          }
        />
      )}
    </>
  );
};

export default HomeCashierBankHistoric;
