import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import Card from 'components/Card/Card.jsx';
import ModalAlertCompany from 'client/components/ModalAlertCompany/ModalAlertCompany.jsx';
import Button from 'client/components/CustomButton/Button.jsx';
import useFilters from '../../../hooks/useFilters';
import constants from '../../../utils/constants';
import natureOfTransactionRepository from '../../../repositories/NatureOfTransactions';
import SearchInput from 'components/SearchInput';
import CFOPTable from './CFOPTable';
import useDebounce from 'hooks/useDebounce';

const HomeCFOPs = ({ history }) => {
  const [natureOfTransactions, setNatureOfTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noCompanyModal, setNoCompanyModal] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const companyId = localStorage.getItem('ID_EMPRESA');
  const { queryFilter, statusFilter } = useFilters();
  const [query, setQuery] = useState('');
  const [queryDebounced, setQueryDebounced] = useState('');
  const debouncedSaveQuery = useDebounce(setQueryDebounced, 600);
  const { status, setStatus, filterByStatus } = statusFilter;

  useEffect(() => {
    if (!!companyId) {
      setNatureOfTransactions([]);
      loadNatureOfTransactions();
    }
  }, []);

  const loadNatureOfTransactions = async () => {
    setLoading(true);
    try {
      const allData = [];
      const companyNatureOfTransactionAndDefaults =
        await natureOfTransactionRepository.getAllPaginated(companyId, {
          page: currentPage + 1,
          limit: pageLimit,
          query,
          status,
        });

      //OBJETO PARA TODOS OS CFOPS INDEPENDENTE DO COMPANY_ID
      for (
        let i = 0;
        i < companyNatureOfTransactionAndDefaults.data.length;
        i++
      ) {
        const obj = {
          id: companyNatureOfTransactionAndDefaults.data[i].id,
          CFOP: companyNatureOfTransactionAndDefaults.data[i].CFOP,
          Description:
            companyNatureOfTransactionAndDefaults.data[i].description,
          Type: companyNatureOfTransactionAndDefaults.data[i].type,
          Company_id: companyNatureOfTransactionAndDefaults.data[i].companyId,
        };
        allData.push(obj);
      }

      setNatureOfTransactions(allData);
      setTotalPages(
        Math.ceil(companyNatureOfTransactionAndDefaults.count / pageLimit)
      );
      return allData;
    } catch (err) {
      console.log(err);
      toastr.warning(
        'Ocorreu um erro ao buscar as naturezas de operação. Por favor, tente novamente'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeQuery = (value) => {
    setQuery(value);
    debouncedSaveQuery(value);
  };

  useEffect(() => {
    if (companyId) {
      loadNatureOfTransactions();
    }
  }, [currentPage, pageLimit, queryDebounced, status]);

  return (
    <Card
      content={
        <div>
          <div
            style={{
              padding: 0,
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'flex-end',
              gap: '10px',
            }}
          >
            <div>
              <ModalAlertCompany
                show={noCompanyModal}
                onHide={() => setNoCompanyModal(false)}
              />
              <button
                className="btn btn-sucesso button-h35"
                onClick={() =>
                  companyId
                    ? history.push(constants.ROUTES.CFOP)
                    : setNoCompanyModal(true)
                }
                style={{ fontSize: '12px' }}
              >
                + Novo CFOP
              </button>
            </div>
            <div>
              <SearchInput
                placeholder="Pesquisa por Código ou Descrição"
                value={query}
                onChange={(e) => handleChangeQuery(e.target.value)}
                background={'#FFFFFF'}
                style={{
                  fontSize: '12px',
                  height: '35px',
                  width: '312px',
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </div>
            <div>
              <span style={{ fontSize: '12px' }}>
                <strong>Status: </strong>
              </span>
              <select
                className="form-control foco-input"
                name="searchStatus"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ fontSize: '12px' }}
              >
                <option value="">Todos</option>
                <option value="Entrada">Entrada</option>
                <option value="Saída">Saída</option>
                <option value="Simples Remessa">Simples Remessa</option>
                <option value="Devolução de Entrada">
                  Devolução de Entrada
                </option>
                <option value="Devolução de Saída">Devolução de Saída</option>
              </select>
            </div>
          </div>
          <div>
            <CFOPTable
              natureOfTransactions={natureOfTransactions}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              loading={loading}
              companyId={companyId}
              setNoCompanyModal={setNoCompanyModal}
            />
          </div>
        </div>
      }
    />
  );
};

export default withRouter(HomeCFOPs);
