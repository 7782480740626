import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styleChecklist } from './MobileChecklist.styles';
import { phoneMask } from 'client/components/ToNormalize/ToNormalize';
import { cpfOrCnpjMask } from 'client/components/ToNormalize/ToNormalize';
export function CustomerChecklist({ customer }) {
  if (!customer) return <View />;
  const { cellphone, cpfCnpj, name, tradingName, email } = customer;
  return (
    <>
      <View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', marginRight: 10 }}>
            <Text style={styleChecklist.bold}>Cliente: </Text>
            <Text>{name || tradingName}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginRight: 10 }}>
            <Text style={styleChecklist.bold}>CPF/CNPJ: </Text>
            <Text>{cpfOrCnpjMask(cpfCnpj)}</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flexDirection: 'row', marginRight: 10 }}>
            <Text style={styleChecklist.bold}>Celular: </Text>
            <Text>{phoneMask(cellphone)}</Text>
          </View>
          <View style={{ flexDirection: 'row', marginRight: 10 }}>
            <Text style={styleChecklist.bold}>Email: </Text>
            <Text>{email} </Text>
          </View>
        </View>
      </View>
      <View style={styleChecklist.line} />
    </>
  );
}
