import { useEffect, useState } from 'react';

export const usePagination = (onPageOrLimitChange, initialPage = 0) => {
  const [page, setPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotalPages(Math.ceil(total / limit));
  }, [total, limit]);

  useEffect(() => {
    if (onPageOrLimitChange) {
      onPageOrLimitChange({ page, limit });
    }
  }, [page, limit]);

  const nextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const goToPage = (pageNumber) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setPage(pageNumber);
    }
  };

  return {
    page,
    setPage,
    totalPages,
    setTotalPages,
    limit,
    setLimit,
    total,
    setTotal,
    nextPage,
    previousPage,
    goToPage,
  };
};
