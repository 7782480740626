import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InputSelectDefault } from 'v2/components/Input';
import { OnboardingFilterContainer } from '../Onboarding.styles';
import { SearchInput } from 'v2/components/form';
import { SelectMultiBranding } from 'admin/v2/components/Input/Select/SelectMultiBranding';
import { SelectMulti } from 'v2/components/Select/Multi';
import { useOnboarding } from '../hooks/useOnboarding';
import DownloadXlsButton from 'components/DownloadXlsButton';
import { useConfigColumnsOnboarding } from '../hooks/useConfigColumnsOnboarding';
import { InputLabel } from 'v2/components/Input';
import { InputText } from 'v2/components/Input';

export function OnboardingFilter() {
  const { setValue, watch, getValues, register } = useFormContext();
  const { columnsSheet } = useConfigColumnsOnboarding();

  const {
    dateTypes,
    engagementOptions,
    onboardingStatusOptions,
    minDate,
    getOnboardingData,
    getOptionsPlans,
    isLoading,
  } = useOnboarding();

  const dateType = watch('dateType');
  const initialDate = watch('initialDate');
  const finalDate = watch('finalDate');
  const selectedBranding = watch('selectedBranding');
  const selectedStatus = watch('selectedStatus');
  const selectedEngagement = watch('selectedEngagement');
  const query = watch('query');
  const spreadsheet = watch('spreadsheet');

  const optionsPlans = watch('optionsPlans');

  const plans = watch('plans');
  const selectedPlans = watch('selectedPlans');
  const onboardingDataTable = watch('onboardingDataTable');

  useEffect(() => {
    if (selectedBranding?.length && plans?.length) {
      const options = getOptionsPlans(plans, selectedBranding);
      options.unshift({
        label: 'Vários',
        value: '*',
      });
      setValue('optionsPlans', options);
      setValue('selectedPlans', []);
    }
  }, [selectedBranding, plans]);

  const handleExportXml = async () => {
    return await getOnboardingData(setValue, getValues, 'spreadsheet');
  };

  return (
    <OnboardingFilterContainer>
      <div className="filters">
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Tipo de Data:
          </InputLabel>
          <InputSelectDefault
            height="35px"
            options={dateTypes}
            value={dateType}
            onChange={(value) => {
              setValue('dateType', value);
            }}
            width="200px"
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Inicial:
          </InputLabel>
          <InputText
            {...register('initialDate')}
            className="form-control foco-input fontSize-14"
            type="date"
            value={initialDate}
            onChange={(value) => {
              setValue('initialDate', value.target.value);
            }}
            min={minDate}
            height="35px"
            fontWeight="normal"
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Final:
          </InputLabel>

          <InputText
            {...register('finalDate')}
            className="form-control foco-input fontSize-14"
            type="date"
            height="35px"
            min={initialDate}
            onChange={(value) => {
              setValue('finalDate', value.target.value);
            }}
            value={finalDate}
            style={{ fontWeight: '' }}
            fontWeight="normal"
          />
        </div>
        <div className="flex w-100">
          <SearchInput
            width="450"
            placeholder="Pesquisa por CPF/CNPJ, Nome/Razão Social, Nome Fantasia"
            value={query}
            onChange={(e) => setValue('query', e.target.value)}
          />
        </div>
      </div>
      <div className="filters">
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Produto:
          </InputLabel>
          <SelectMultiBranding
            value={selectedBranding}
            height={'35px'}
            onChange={(e) => setValue('selectedBranding', e)}
            showAllOption
            selectAllByDefault
            isDisabled={isLoading}
            isLoading={isLoading}
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Planos:
          </InputLabel>
          <SelectMulti
            height="35px"
            options={optionsPlans || []}
            value={selectedPlans}
            onChange={(value) => {
              setValue('selectedPlans', value);
            }}
            selectAllByDefault
            customAllLabel="Vários"
            hasAllOption
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Status Onboarding:
          </InputLabel>
          <SelectMulti
            height="35px"
            hasAllOption
            {...register('selectedStatus')}
            id="selectedStatus"
            options={onboardingStatusOptions}
            isMulti={true}
            value={selectedStatus}
            onChange={(e) => setValue('selectedStatus', e)}
            selectAllByDefault
          />
        </div>
        <div>
          <InputLabel style={{ fontSize: 12, fontWeight: 'bold' }}>
            Engajamento:
          </InputLabel>
          <SelectMulti
            id="selectedEngagement"
            {...register('selectedEngagement')}
            height="35px"
            options={engagementOptions}
            value={selectedEngagement}
            onChange={(value) => {
              setValue('selectedEngagement', value);
            }}
            isMulti={true}
            hasAllOption
            selectAllByDefault
          />
        </div>
        <div className="buttons">
          <button
            disabled={isLoading}
            onClick={() => {
              setValue('controlTable.page', 0);
              getOnboardingData(setValue, getValues, 'table');
            }}
            className="button button-green button-h35"
          >
            Processar
          </button>

          <div className="flex center gap-025">
            {spreadsheet?.length ? (
              <DownloadXlsButton
                archiveName="Onboarding"
                data={spreadsheet}
                className="btn btn-export"
                columns={columnsSheet}
                style={{
                  height: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FontAwesomeIcon
                  color="white"
                  icon={faCloudDownloadAlt}
                  style={{ marginRight: '5px' }}
                />
                Baixar Relatório
              </DownloadXlsButton>
            ) : (
              <button
                className="btn btn-export"
                onClick={() => handleExportXml()}
                disabled={isLoading || !onboardingDataTable?.report?.length}
                style={{
                  height: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isLoading ? (
                  <span className="fa fa-spinner fa-pulse fa-1x"></span>
                ) : (
                  <FontAwesomeIcon color="white" icon={faCloudDownloadAlt} />
                )}
                &nbsp;Exportar .xls
              </button>
            )}
          </div>
        </div>
      </div>
    </OnboardingFilterContainer>
  );
}
